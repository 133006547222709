import React from 'react';
import { FormPageActionProps } from 'src/components/Page/formPageTypes';
import BorderFormPageHeader from 'src/components/Page/FormPageAction/BorderFormPageHeader';
import ContainedFormPageHeader from 'src/components/Page/FormPageAction/ContainedFormPageHeader';
import FormPageHeaderBase from 'src/components/Page/FormPageAction/FormPageHeaderBase';

export const FormPageAction: React.FC<FormPageActionProps> = ({
  headerVariant,
  ...otherProps
}) => {
  const getHeaderComponent = () => {
    if (headerVariant === 'bottomBorder')
      return <BorderFormPageHeader borderPosition="bottom" {...otherProps} />;

    if (headerVariant === 'topBorder')
      return <BorderFormPageHeader borderPosition="top" {...otherProps} />;

    if (headerVariant === 'contained')
      return <ContainedFormPageHeader {...otherProps} />;
    return <FormPageHeaderBase {...otherProps} />;
  };
  return <>{getHeaderComponent()}</>;
};
