import {
  paymentPreferencesSchema,
  LineItem as InvoiceLineItem,
  oneOffLineItemSchema,
  productLineItemSchema,
} from 'src/legacy/components/billing/new/types';
import { SubscriptionStatus } from 'src/constants';
import {
  LineItemType,
  MAX_LINE_ITEM_AMOUNT,
  MAX_TOTAL_AMOUNT,
  MAX_TOTAL_AMOUNT_ERROR,
  MIN_LINE_ITEM_AMOUNT_ERROR,
} from 'src/services/api/invoicesApi';
import { LineItemWithRate } from 'src/services/api/subscriptionsApi';
import { z } from 'zod';
import { Invoice } from 'src/store/payments/types';

const MIN_LINE_ITEM_AMOUNT = 0;

export enum SubscriptionCollectionMethod {
  ChargeAutomatically = 'charge_automatically',
  SendInvoice = 'send_invoice',
}

export enum SubscriptionInterval {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarterly = 'quarterly',
  Biannually = 'biannually',
  Year = 'year',
  SemiAnnually = 'semiannually',
}

export type LineItem = Omit<InvoiceLineItem, 'amount'> & { rate: number };

export type OneTimeLineItem = LineItem & {
  type: LineItemType.OneTime;
};

export type ProductLineItem = LineItem & {
  type: LineItemType.Product;
};

// Subscriptions do not allow having negative amounts
// So we're customising the amount field to not allow negative values
const amountFieldSchema = z
  .string({
    required_error: 'Price is required',
  })
  .refine(
    (value) => {
      const number = parseFloat(value);
      return number >= MIN_LINE_ITEM_AMOUNT;
    },
    { message: 'Price cannot be negative' },
  )
  .refine(
    (value) => {
      const number = parseFloat(value);
      return number <= MAX_LINE_ITEM_AMOUNT;
    },
    { message: `Amount can be at most ${MAX_LINE_ITEM_AMOUNT}` },
  );

const oneOffLineItemSchemaWithAmount = oneOffLineItemSchema.extend({
  amount: amountFieldSchema,
});

const lineItemSchemaWithAmount = z.discriminatedUnion('type', [
  oneOffLineItemSchemaWithAmount,
  productLineItemSchema,
]);

export const lineItemsWithAmountSchema = z.array(lineItemSchemaWithAmount);

const commonSubscriptionFieldsSchema = z.object({
  recipientId: z.string({
    required_error: 'Please select a recipient',
  }),
  clientUserId: z.string().optional(),
  companyId: z.string().optional(),
  lineItems: lineItemsWithAmountSchema,
  paymentPreferences: paymentPreferencesSchema,
  taxPercentage: z
    .string()
    .optional()
    .refine(
      (value) => {
        // Tax is totally optional so we can return true if it's not provided
        if (!value) return true;
        const parsedQuantity = parseFloat(value);
        return parsedQuantity >= 0 && parsedQuantity <= 100;
      },
      { message: 'Tax rate must be under 100%' },
    ),
  totalAmount: z
    .number()
    .min(MIN_LINE_ITEM_AMOUNT, MIN_LINE_ITEM_AMOUNT_ERROR)
    .max(MAX_TOTAL_AMOUNT, MAX_TOTAL_AMOUNT_ERROR),
  attachment: z.string().nullable(),
  memo: z
    .string()
    .max(500, { message: 'Memo must be under 500 characters' })
    .optional(),
  currency: z.string().nullable(),
  prorate: z.boolean().optional(),
  resetBillingCycle: z.boolean().optional(),
  interval: z.enum([
    SubscriptionInterval.Day,
    SubscriptionInterval.Week,
    SubscriptionInterval.Month,
    SubscriptionInterval.Quarterly,
    SubscriptionInterval.Biannually,
    SubscriptionInterval.Year,
    SubscriptionInterval.SemiAnnually,
  ]),
  scheduleStartDate: z.number().optional(),
  scheduleEndDate: z.number().optional(),
  scheduleIterations: z.number().optional(),
  status: z.enum([
    SubscriptionStatus.Active,
    SubscriptionStatus.Unpaid,
    SubscriptionStatus.Incomplete,
    SubscriptionStatus.PastDue,
    SubscriptionStatus.Canceled,
    SubscriptionStatus.Trialing,
    SubscriptionStatus.IncompleteExpired,
    SubscriptionStatus.NotStarted,
  ]),
  created: z.number().optional(),
});

const manualSubscriptionFieldsSchema = commonSubscriptionFieldsSchema.extend({
  collectionMethod: z.literal(SubscriptionCollectionMethod.SendInvoice),
  dueDate: z.string(),
  dueDays: z
    .string({
      required_error: 'Please enter the number of days till the payment is due',
    })
    .refine(
      (value) => {
        const parsedValue = parseFloat(value);
        return parsedValue > 0;
      },
      {
        message:
          'The number of days till the payment is due must be greater than 0',
      },
    )
    .refine(
      (value) => {
        const parsedValue = parseFloat(value);
        const DAYS_IN_TWO_YEARS = 365 * 2;
        return parsedValue <= DAYS_IN_TWO_YEARS;
      },
      {
        message: 'Due days cannot be more than 2 years in the future',
      },
    ),
});

const automaticSubscriptionFieldsSchema = commonSubscriptionFieldsSchema.extend(
  {
    collectionMethod: z.literal(
      SubscriptionCollectionMethod.ChargeAutomatically,
    ),
    primarySource: z.string().nullable(),
  },
);

export const subscriptionFieldsSchema = z.discriminatedUnion(
  'collectionMethod',
  [manualSubscriptionFieldsSchema, automaticSubscriptionFieldsSchema],
);

export const subscriptionSchema = z.object({
  fields: subscriptionFieldsSchema,
});

export const subscriptionTemplateFieldSchema = z.object({
  lineItems: lineItemsWithAmountSchema,
  taxPercentage: z
    .string()
    .optional()
    .refine(
      (value) => {
        // Tax is totally optional so we can return true if it's not provided
        if (!value) return true;
        const parsedQuantity = parseFloat(value);
        return parsedQuantity >= 0 && parsedQuantity <= 100;
      },
      { message: 'Tax rate must be under 100%' },
    ),
  totalAmount: z
    .number()
    .min(MIN_LINE_ITEM_AMOUNT, MIN_LINE_ITEM_AMOUNT_ERROR)
    .max(MAX_TOTAL_AMOUNT, MAX_TOTAL_AMOUNT_ERROR),
  memo: z
    .string()
    .max(500, { message: 'Memo must be under 500 characters' })
    .optional(),
  attachment: z.string().nullable(),
  interval: z.enum([
    SubscriptionInterval.Day,
    SubscriptionInterval.Week,
    SubscriptionInterval.Month,
    SubscriptionInterval.Quarterly,
    SubscriptionInterval.Biannually,
    SubscriptionInterval.Year,
    SubscriptionInterval.SemiAnnually,
  ]),
});

export const subscriptionTemplateSchema = z.object({
  fields: subscriptionTemplateFieldSchema,
  additionalFields: z.object({
    templateName: z.string({
      required_error: 'Template name is required',
    }),
  }),
});

export type SubscriptionSchema = z.infer<typeof subscriptionSchema>;
export type SubscriptionTemplateSchema = z.infer<
  typeof subscriptionTemplateSchema
>;

export type SubscriptionFields = {
  recipientId: string;
  clientUserId: string;
  companyId: string;
  memo: string;
  lineItems: LineItemWithRate[];
  interval: SubscriptionInterval;
  collectionMethod: string;
  daysUntilDue: number;
  taxPercentage: number;
  reActivatedBy: string;
  cancelledAt: string;
  scheduleEndDate: number;
  scheduleIterations: number;
  scheduleStartDate: number;
  allowPaymentViaCC: boolean;
  allowPaymentViaACH: boolean;
  absorbTransactionFees: boolean;
  currency: string;
  prorate: boolean;
  resetBillingCycle: boolean;
  attachment: string;
  paymentPreferences: SubscriptionSchema['fields']['paymentPreferences'];
  price?: number;
};

export type Subscription = {
  id: string;
  createdAt: string;
  fields: SubscriptionFields;
  additionalFields: {
    status: SubscriptionStatus;
    created: number;
    current_period_end: number;
    latest_invoice: Invoice;
  };
};
