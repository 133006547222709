import React from 'react';
import {
  Paper,
  Box,
  makeStyles,
  createStyles,
  Theme,
  Tooltip,
  Divider,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isString } from 'lodash';
import { useFormikContext } from 'formik';
import * as Colors from 'src/theme/colors';
import { RootState } from 'src/store/reduxTypes';
import { FormPageHeaderProps } from 'src/components/Page/formPageTypes';
import { drawerWidth } from 'src/components/Sidebar/DrawerSection';
import BaseTypography from 'src/components/Text/BaseTypography';
import Button from 'src/components/Button';
import {
  DESKTOP_APP_BAR_HEIGHT,
  MOBILE_APP_BAR_HEIGHT,
  BANNER_HEIGHT,
} from 'src/constants/uiConsts';
import MenuLogo from 'src/components/Navbar/MenuLogo';
import { togglePrimarySidebarMobile } from 'src/store/ui/actions';
import Activator from 'src/components/Activator';
import { setInvoicePreviewStatusAction } from 'src/store/payments/actions';
import { BackIcon, TrashIcon } from 'src/components/Icons';
import { ChevronLeft } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageHeader: {
      height: DESKTOP_APP_BAR_HEIGHT,
      position: 'absolute',
      border: 'unset',
      zIndex: theme.zIndex.drawer + 2,
      borderRadius: 0,
      padding: theme.spacing(1, 4.5),
      left: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down('sm')]: {
        left: 0,
        width: `100%`,
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1, 2),
        height: MOBILE_APP_BAR_HEIGHT,
      },
      boxSizing: 'border-box',
    },
    titleText: {
      color: Colors.BlackHeadings,
    },
    unsavedText: {
      color: theme.palette.primary.main,
    },
    confirmButton: {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(1),
      },
      '& button': {
        width: 'max-content', // allow button take max space required to fit content
      },
    },
    discardButton: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
      '& button': {
        width: 'max-content',
      },
    },
    deleteButtonContainer: {
      marginLeft: theme.spacing(1),
    },
    deleteButton: {
      color: Colors.red,
    },
    previewSeparator: {
      margin: theme.spacing(0, 2),
      height: 22,
    },
    backIconContainer: {
      fontSize: 20,
      paddingRight: theme.spacing(3),
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1.5),
      overflow: 'hidden',
      paddingRight: theme.spacing(2),
    },
  }),
);

const FormPageHeaderBase: React.FC<FormPageHeaderProps> = ({
  onCancel,
  isSubmitting,
  cancelLabel,
  actionLabel,
  title,
  isNew,
  className,
  handleSubmit,
  position = 'top',
  showActions = true,
  isSettingsForm,
  isDisabled,
  tooltipComponent,
  showDeleteActon = false,
  hideCancelAction = false,
  deleteLabel,
  onDelete,
  showBackButton = false,
  onBackButtonClick,
  cancelDisabled,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const formikContext = !isMobileView ? useFormikContext() : null;

  const bannerOptions = useSelector(
    (state: RootState) => state.ui.bannerOptions,
  );

  const classes = useStyles({ isSettingsForm });

  /**
   * This method it used to determine the form
   * action bar vertical position (top/bottom)
   * depending on where it is displayed.
   * e.g. in settings modal:
   * it should be on the top on mobile device
   * it should be on the bottom on desktop
   */
  const getFormActionPositionStyle = (): React.CSSProperties => {
    if (position === 'bottom') {
      return { bottom: 0 };
    }

    return {
      top: bannerOptions ? BANNER_HEIGHT : 0,
      position: 'fixed',
    };
  };
  /**
   * This method is used to determine the formPageActionBar
   * content positioning.
   * e.g. when a form page have a title, we should display
   * content spaced-between
   */
  const getFormPageActionJustifyContent = () => {
    if (title || isMobileView) return 'space-between';
    return 'flex-end';
  };
  const canPreviewInvoice = useSelector(
    (state: RootState) => state.payments.canPreviewInvoice,
  );
  const invoicePreviewEnabled = useSelector(
    (state: RootState) => state.payments.invoicePreviewEnabled,
  );

  const toggleInvoicePreviewStatus = () => {
    dispatch(setInvoicePreviewStatusAction(!invoicePreviewEnabled));
  };

  return (
    <Paper
      variant="outlined"
      className={`${classes.pageHeader} ${className}`}
      style={{ ...getFormActionPositionStyle() }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent={getFormPageActionJustifyContent()}
        height={1}
      >
        <Box className={classes.titleWrapper}>
          {showBackButton ? (
            <>
              {isMobileView ? (
                <MenuLogo
                  menuClickCallback={onBackButtonClick}
                  Icon={ChevronLeft}
                />
              ) : (
                <BackIcon
                  className={classes.backIconContainer}
                  onClick={onBackButtonClick}
                />
              )}
            </>
          ) : (
            <MenuLogo
              menuClickCallback={() =>
                dispatch(togglePrimarySidebarMobile({ isOpen: true }))
              }
            />
          )}
          {isString(title) ? (
            <BaseTypography fontType="18Medium" className={classes.titleText}>
              {title}
            </BaseTypography>
          ) : (
            title
          )}
        </Box>
        {showActions && (
          <Box display="flex">
            {canPreviewInvoice &&
              !isMobileView &&
              !isSettingsForm &&
              formikContext && (
                <>
                  <Box pt={0.5}>
                    <Activator
                      checked={invoicePreviewEnabled}
                      label="Preview"
                      onChange={toggleInvoicePreviewStatus}
                      onBlur={formikContext.handleBlur}
                      isFixedWidth={false}
                    />
                  </Box>
                  <Box mx={1} pt={0.5}>
                    <Divider
                      orientation="vertical"
                      className={classes.previewSeparator}
                    />
                  </Box>
                </>
              )}
            {!hideCancelAction && (
              <Box className={classes.discardButton}>
                <Button
                  htmlId="cancelFormButton"
                  variant="contained"
                  color="secondary"
                  onClick={onCancel}
                  disabled={cancelDisabled}
                >
                  {cancelLabel}
                </Button>
              </Box>
            )}
            {showDeleteActon && (
              <Box className={classes.deleteButtonContainer}>
                <Button
                  htmlId="deleteButton"
                  variant="contained"
                  color="secondary"
                  onClick={onDelete}
                  startIcon={<TrashIcon />}
                  className={classes.deleteButton}
                >
                  {deleteLabel}
                </Button>
              </Box>
            )}
            <Tooltip title={tooltipComponent || ''} interactive>
              <Box className={classes.confirmButton}>
                <Button
                  data-testid="submitFormButton"
                  htmlId="submitFormButton"
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                  disabled={isDisabled}
                  color="primary"
                >
                  {actionLabel || (isNew ? 'Create' : 'Update')}
                </Button>
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>
      <div />
    </Paper>
  );
};

export default FormPageHeaderBase;
