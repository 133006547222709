import { Tab as MuiTab, withStyles } from '@material-ui/core';
import ColorUtils from 'src/utils/ColorUtils';
import { typography13MediumStyle } from 'src/components/Text';

export const BaseTab = withStyles((theme) => ({
  root: {
    justifyContent: 'flex-start',
    padding: 0,
    minWidth: 'initial',
    marginRight: theme.spacing(5),
    '&$selected': {
      overflow: 'visible',
      '& $wrapper': {
        height: '100%',
        position: 'relative',
        color: theme.palette.primary.main,
        '&::after': {
          position: 'absolute',
          left: 0,
          bottom: '-1px',
          width: '100%',
          height: '1px',
          borderBottom: `1px solid ${
            ColorUtils.GetColorDarknessShades(theme.palette.primary.main).dark
          }`,
          content: '""',
        },
      },
    },
    '& $wrapper': {
      width: 'initial',
      ...typography13MediumStyle,
    },
  },
  wrapper: {},
  selected: {},
}))(MuiTab);
