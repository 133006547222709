import React, { useEffect } from 'react';
import {
  makeStyles,
  createStyles,
  Drawer,
  Theme,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { RIGHT_SIDEBAR_WIDTH } from 'src/constants';
import {
  BANNER_HEIGHT,
  DESKTOP_APP_BAR_HEIGHT,
  MOBILE_APP_BAR_HEIGHT,
} from 'src/constants/uiConsts';
import { zIndex } from 'src/theme/zindex';
import {
  RightSidebarHeader,
  RightSidebarHeaderProps,
} from 'src/components/RightSidebar/RightSidebarHeader';
import { RightSidebarShadow } from 'src/theme/shadows';
import { useAppDispatch } from 'src/hooks/useStore';
import { toggleRightSidebar } from 'src/store/ui/actions';

interface RightSidebarStyleProps {
  showBanner: boolean;
  sticky?: boolean;
  bgColor?: string;
  width?: number | string;
}

interface RightSidebarProps {
  sticky?: boolean;
  bgColor?: string;
  headerProps?: RightSidebarHeaderProps;
  showOnMount?: boolean;
  width?: number | string;
}

/**
 * This methods determines the sidebar
 * top padding. When it is sticky,
 * it shows on top of the app bar (top: 0).
 * otherwise, it should be always below
 * the appbar (top: appBarHeight).
 * Considering the case when the banner
 * is showing, right sidebar should be below
 * the banner.
 * @param props
 * @returns
 */
const getTopPadding = (props: RightSidebarStyleProps, isMobile = false) => {
  let topPadding = 0;
  const { showBanner, sticky } = props || {};
  if (showBanner) {
    topPadding += BANNER_HEIGHT;
  }

  if (sticky) {
    return topPadding;
  }

  topPadding += isMobile ? MOBILE_APP_BAR_HEIGHT : DESKTOP_APP_BAR_HEIGHT;
  return topPadding;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      backgroundColor: (props) => props.bgColor,
      border: 'none',
      boxShadow: RightSidebarShadow,
      [theme.breakpoints.up('sm')]: {
        top: (props: RightSidebarStyleProps) => getTopPadding(props),
        height: (props: RightSidebarStyleProps) =>
          `calc(100% - ${getTopPadding(props)}px)`,
        width: (props) => props.width || RIGHT_SIDEBAR_WIDTH,
      },
      [theme.breakpoints.down('xs')]: {
        top: (props: RightSidebarStyleProps) => getTopPadding(props, true),
        height: (props: RightSidebarStyleProps) =>
          `calc(100% - ${getTopPadding(props, true)}px)`,
        width: '100%',
      },
    },
    drawer: {
      width: (props) => props.width || RIGHT_SIDEBAR_WIDTH,
      flexShrink: 0,
      zIndex: zIndex.rightSidebar,
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
      },
    },
    content: {
      padding: theme.spacing(0, 2.5),
    },
  }),
);

export const RightSidebar: React.FC<RightSidebarProps> = ({
  children,
  sticky = true,
  bgColor = '#fff',
  headerProps,
  showOnMount = false,
  width,
}) => {
  const bannerOptions = useSelector(
    (state: RootState) => state.ui.bannerOptions,
  );
  const classes = useStyles({
    showBanner: Boolean(bannerOptions),
    sticky,
    bgColor,
    width,
  });
  const dispatch = useAppDispatch();
  const isRightSidebarOpen = useSelector(
    (state: RootState) => state.ui.rightSideBar.isOpen,
  );
  const theme = useTheme();
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('sm'));

  // this effect is used to open the right sidebar
  // when the showOnMount is true.
  useEffect(() => {
    // if showOnMount is true and it is desktop screen, open the right sidebar
    if (showOnMount && isDesktopScreen) {
      dispatch(
        toggleRightSidebar({
          isOpen: true,
        }),
      );
    }
  }, [showOnMount, isDesktopScreen]);

  return isRightSidebarOpen ? (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={isRightSidebarOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.content}>
        {headerProps && <RightSidebarHeader {...headerProps} />}
        {children}
      </div>
    </Drawer>
  ) : null;
};
