import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { GetWebSocketUrl } from 'src/constants/webSocketConsts/env';
import { wsMessageHandler, MsgEvent } from 'src/websocket';
import {
  AmplifyApiSettings,
  FlagsContext,
  PortalConfigContext,
} from 'src/context';
import { useWebSocketMutations } from 'src/hooks/useWebSocketMutations';
import { Events } from 'src/constants/webSocketConsts/events';
import { useAppSelector } from 'src/hooks/useStore';

const MaxReconnectAttempts = 10;

/**
 * Setup websocket that can be started
 * @param apiSettings settings for calling api
 */
const useWebSocket = (apiSettings: AmplifyApiSettings | null) => {
  const ws = React.useRef<WebSocket | null>(null);
  const queuedMessagesByType = React.useRef<Record<string, Array<any>>>({});
  const queuedMessagesFlushTimer = React.useRef<NodeJS.Timeout | null>(null);
  const wsUserId = React.useRef<string>('');
  const store = useAppSelector((state) => state);

  const [isConnected, setIsConnected] = React.useState(false);
  const [isConnecting, setIsConnecting] = React.useState(false);

  const webSocketMutations = useWebSocketMutations();

  const dispatch = useDispatch();

  const portalConfig = useContext(PortalConfigContext);
  const flags = useContext(FlagsContext);

  /**
   * Check all messages that have been queued and dispatch actions for each entity
   * and event type
   */
  const flushQueuedMessages = () => {
    Object.entries(queuedMessagesByType.current).forEach(
      ([messageEventType, items]) => {
        const [entity, event] = messageEventType.split('#');
        delete queuedMessagesByType.current[messageEventType];
        const actionForWSMsg = wsMessageHandler(
          {
            entity,
            event: event as Events,
            items,
          },
          portalConfig,
          wsUserId.current,
          webSocketMutations,
          flags, // flagsmith flags
          dispatch,
          store,
        );
        if (!actionForWSMsg) {
          return;
        }
        dispatch(actionForWSMsg);
      },
    );
  };

  /**
   * For a given message add it to queue of messages based on the entity and event type
   * If queue for this entity and event combination exists then add new items received in websocket
   * message to the queue otherwise start a new queue.
   * When items are added to queue set a timeout to flush the queue. If there was an existing timeout
   * clear that timeout so that new items can also be batched together.
   * This minimizes the re-renders in the app to only happen when you have a still period of no events
   * The still period is set to 1.5 seconds
   * @param msgData data recieved from websocket message
   */
  const queueMessage = (msgData: MsgEvent) => {
    const { entity, event, items } = msgData;
    const queuedMessageType = `${entity}#${event}`;

    if (queuedMessagesByType.current[queuedMessageType]) {
      // if there are already messages queued for this event type
      // then update array by adding new items

      // first setup a map of itemId to data for the new items being inserted
      const newMessageItemIdToData = items.reduce((map, item) => {
        if (item.id) {
          return { ...map, [item.id]: item };
        }
        return map;
      }, {});

      // now check for the existing messages and filter out any where the item has
      // an id that matches and item in the new ws messages
      // this takes the latest message for an item over an older one
      const existingMessages = queuedMessagesByType.current[
        queuedMessageType
      ].filter(
        (existingMessageItem) =>
          // message item without id does not need to be de-duped and new message data does not have this item
          !existingMessageItem.id ||
          !newMessageItemIdToData[existingMessageItem.id],
      );
      queuedMessagesByType.current[queuedMessageType] =
        existingMessages.concat(items);
    } else {
      // if there is no messages queued then set the intial array values
      queuedMessagesByType.current[queuedMessageType] = items;
    }

    // when message is queued set a timer to flush messages in queue
    if (queuedMessagesFlushTimer.current) {
      // if there was previous timer that can be reset
      clearTimeout(queuedMessagesFlushTimer.current);
    }
    queuedMessagesFlushTimer.current = setTimeout(() => {
      flushQueuedMessages();
    }, 1500);
  };

  /**
   * Use this method to start a websocket connection for receiving real-time events
   * @param userId The user that is forming the connection and wants to receive events
   * @param currentAttempNum Optional, which attempt number this is to connect
   * @param reconnectOnCloseAttempt Optional, if this attempt is a side-effect of the connection being closed
   */
  const startWebsocket = (
    userId: string,
    reconnectOnCloseAttempt?: boolean,

    currentAttempNum = 1,
  ) => {
    // if the socket is not already connected/connecting and this is not an attempt reconnect after closing
    // dont start webscoket, this prevents any consumer of useWebsocket for calling
    // start webSocket again and again
    if ((isConnected || isConnecting) && !reconnectOnCloseAttempt) {
      return;
    }
    setIsConnecting(true);
    console.info('Starting ws');
    if (!apiSettings || currentAttempNum > MaxReconnectAttempts) {
      return;
    }
    wsUserId.current = userId;
    let nextAttemptNum = currentAttempNum + 1;
    ws.current = new WebSocket(
      GetWebSocketUrl({
        apiUrl: apiSettings.endpoint,
        wssEndpoint: apiSettings.wssEndpoint,
        userId,
      }),
    );
    ws.current.onopen = (evt) => {
      console.info('websocket connected', evt);
      setIsConnected(true);
      setIsConnecting(false);
      // reset attempt number on successful connection
      nextAttemptNum = 1;
    };
    ws.current.onclose = (evt) => {
      console.info('websocket disconnected', evt);
      setIsConnected(false);
      setIsConnecting(false);
      startWebsocket(userId, true, nextAttemptNum);
    };
    ws.current.onmessage = (msgEvent) => {
      const msgData = JSON.parse(msgEvent.data);
      queueMessage(msgData);
    };
    ws.current.onerror = (evt) => {
      console.error('websocket error', evt);
    };
  };

  // ensure that websocket gets closed and cleared and internal is cleared
  useEffect(
    () => () => {
      if (queuedMessagesFlushTimer.current) {
        // if thre is an existing timer to flush messages then
        // clearTimeout and flush
        clearTimeout(queuedMessagesFlushTimer.current);
        queuedMessagesFlushTimer.current = null;
        flushQueuedMessages();
      }

      if (ws.current) {
        ws.current.onclose = null; // clear the event for onclose
        ws.current.close();
      }
    },
    [],
  );
  return { startWebsocket, isConnected };
};

export default useWebSocket;
