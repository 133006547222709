import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { AlertCircleIcon } from 'src/components/Icons';
import * as Colors from 'src/theme/colors';
import BaseTypography, { FontType } from 'src/components/Text/BaseTypography';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    gap: theme.spacing(1.5),
    alignItems: 'baseline',
  },
  alertIcon: {
    fontSize: '14px',
  },
  contentWrapper: {
    width: '100%',
  },
  info: {
    backgroundColor: Colors.HoverBackground,
    border: `1px solid ${Colors.HoverNonBorderBackground}`,
  },
  error: {
    backgroundColor: Colors.white,
    color: Colors.red,
  },
}));

interface CalloutProps {
  hideIcon?: boolean;
  className?: string;
  variant?: 'error' | 'info';
  textFontType?: FontType;
}
export const Callout: React.FC<CalloutProps> = React.memo(
  ({
    children,
    hideIcon = false,
    className,
    variant = 'info',
    textFontType = '13Regular',
  }) => {
    const classes = useStyles();

    return (
      <div
        className={clsx(
          className,
          classes.root,
          classes[variant as keyof typeof classes],
        )}
      >
        {!hideIcon && <AlertCircleIcon className={classes.alertIcon} />}
        <div className={classes.contentWrapper}>
          <BaseTypography fontType={textFontType}>{children}</BaseTypography>
        </div>
      </div>
    );
  },
);
