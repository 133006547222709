import React, { ComponentProps } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import {
  BaseTextField,
  BaseTextFieldProps,
} from 'src/components/TextField/BaseTextField';
import { GetCurrencyFormatter } from 'src/components/Formatters';

interface NumberFormatCustomProps {
  inputRef: (
    instance: ComponentProps<typeof NumberFormat>['getInputRef'] | null,
  ) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

/**
 * This method is used to get the thousand
 * separator and decimal separator of a number depending
 * upon browser language
 * @param number number to format
 */
function getLocaleThousandAndDecimalSeparators(number: string) {
  const formatter = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const partsFormatter = formatter.formatToParts(Number(number));

  return {
    decimalSeparator: partsFormatter?.find((part) => part.type === 'decimal')
      ?.value,
    thousandSeparator: partsFormatter?.find((part) => part.type === 'group')
      ?.value,
  };
}

const NumberFormatCustom = (props: NumberFormatCustomProps) => {
  const { inputRef, onChange, name, value, ...other } = props;

  const separatorProps = React.useMemo(() => {
    return getLocaleThousandAndDecimalSeparators(value);
  }, [value]);

  const handleValueChange = React.useCallback(
    (values: NumberFormatValues) => {
      onChange({
        target: {
          name,
          value: values.value,
        },
      });
    },
    [name, onChange],
  );
  return (
    <NumberFormat
      {...other}
      isNumericString
      value={value}
      name={name}
      getInputRef={inputRef}
      onValueChange={handleValueChange}
      {...separatorProps}
    />
  );
};

interface CurrencyTextFieldProps {
  currency: string;
}

export const CurrencyTextField: React.FC<
  CurrencyTextFieldProps & BaseTextFieldProps
> = ({ onChange, currency, ...textFieldProps }) => {
  const formatter = GetCurrencyFormatter(currency);
  const formatterOptions = formatter.formatToParts();

  const prefix =
    formatterOptions.find((opt) => opt.type === 'currency')?.value || '$';

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (onChange) {
      // since we are passing a prefix to the input, any
      // input value will have the prefix. We need to
      // remove currency symbol before passing to parent component
      let { value } = event.target;
      if (event.target.value.startsWith(prefix)) {
        value = event.target.value.replace(prefix, '');
      }
      onChange({
        ...event,
        target: {
          ...event.target,
          value,
        },
      });
    }
  };

  const textFieldInputProps = {
    InputProps: {
      inputComponent: NumberFormatCustom as any, // casting as any so that mui TextField doesn't complain
    },
    inputProps: {
      prefix,
    },
  };

  return (
    <BaseTextField
      {...textFieldProps}
      {...textFieldInputProps}
      onChange={handleChange}
    />
  );
};
