import React from 'react';
import { BlackHeadings, GraySmall } from 'src/theme/colors';
import BaseTypography from 'src/components/Text/BaseTypography';

interface HomePageSectionProps {
  title: string;
  rightElement?: React.ReactNode;
}

export const HomePageSection: React.FC<HomePageSectionProps> = ({
  children,
  title,
  rightElement,
}) => {
  const getRightElement = () => {
    if (!rightElement) {
      return null;
    }

    if (typeof rightElement === 'string') {
      return (
        <BaseTypography
          data-testid="section-subtitle"
          style={{ color: GraySmall }}
          fontType="13Regular"
        >
          {rightElement}
        </BaseTypography>
      );
    }

    return rightElement;
  };
  return (
    <div>
      <div className="flex justify-between items-center py-3">
        <BaseTypography style={{ color: BlackHeadings }} fontType="18Medium">
          {title}
        </BaseTypography>
        {getRightElement()}
      </div>

      <div className="flex flex-col gap-5">{children}</div>
    </div>
  );
};
