import React from 'react';
import * as Yup from 'yup';
import {
  FormControl,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { string } from 'yup';
import { FormikHelpers } from 'formik';
import { FormBodyAPI } from 'src/constants';
import { PortalConfigContext } from 'src/context';
import { removeDuplicates } from 'src/utils/array';
import { ComboBoxOption } from 'src/components/Select/ComboBox';
import {
  MultiCategorySelect,
  MultiCategorySelectProps,
} from 'src/components/Select/MultiCategorySelect';
import {
  ChannelMemberSelect,
  ChannelMemberSelectProps,
} from 'src/components/MultiSelect/ChannelMemberSelect';
import { RootState } from 'src/store';
import * as Colors from 'src/theme/colors';
import BaseTypography from 'src/components/Text/BaseTypography';

import { CLIENT_TYPE, COMPANY_TYPE } from 'src/constants/multiSelectConsts';
import { isUserEnabled } from 'src/utils/UserUtils';
import { useAppSelector } from 'src/hooks/useStore';
import { createSelector } from '@reduxjs/toolkit';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    channelFormDescriptionText: {
      marginBottom: theme.spacing(2),
      color: Colors.GraySmall,
    },
  }),
);

export type ChannelType =
  | 'messaging'
  | 'files'
  | 'fileChannel'
  | 'forms'
  | 'contracts'
  | 'billing'
  | 'extension'
  | 'inbox';

const messagesValidationSchema = Yup.object({
  fields: Yup.object().shape({
    name: Yup.string().required(),
    members: Yup.array().of(string()).required(),
    companyID: Yup.string(),
  }),
});

const formsValidationSchema = Yup.object().shape({
  fields: Yup.object().shape({
    recipientIds: Yup.array()
      .of(Yup.string())
      .test(
        'clients-array',
        'Please select at least one client.',
        (value) => value.length > 0,
      ),
  }),
});

const baseChannelValidationSchema = Yup.object().shape({
  fields: Yup.object().shape({
    membersType: Yup.string(),
    memberIDs: Yup.array()
      .of(Yup.string())
      .required('Please select at least one client.'),
  }),
});

export const useChannelForm = (channelType: ChannelType) => {
  const classes = useStyles();
  // when client assignment is enabled only clients can be selected
  // and all channel creates are multi-select
  const isMultiSelect =
    channelType === 'messaging' ||
    channelType === 'forms' ||
    channelType === 'fileChannel' ||
    channelType === 'extension';

  const showClientsInOtherCompanies = channelType === 'forms';
  const filterResultsLimit = channelType === 'forms' ? 10 : 5;

  const getValidationScheme = () => {
    let validator = Yup.object({});
    switch (channelType) {
      case 'messaging':
        validator = messagesValidationSchema;
        break;
      case 'forms':
        validator = formsValidationSchema;
        break;
      case 'fileChannel':
        validator = baseChannelValidationSchema;
        break;
      case 'extension':
        validator = baseChannelValidationSchema;
        break;
      default:
        break;
    }

    return validator;
  };

  const validationScheme = getValidationScheme();

  /**
   * text to describe the form based on the channel type
   */
  const renderInstructionText = () => {
    let text = '';
    switch (channelType) {
      case 'messaging':
        text = `Create a conversation where all recipients can see each other.`;
        break;
      case 'fileChannel':
        text =
          'Create a files channel where all included members can see uploaded files.';
        break;
      case 'extension':
        text = 'Create a channel where all members can see the app.';
        break;
      default:
        break;
    }
    if (text) {
      return (
        <BaseTypography className={classes.channelFormDescriptionText}>
          {text}
        </BaseTypography>
      );
    }
    return null;
  };

  const selector = createSelector(
    (state: RootState) => state.clients,
    (state: RootState) => state.users,
    (clients, users) => ({
      isClientsStateLoading: clients.isLoading,
      isUsersStateLoading: users.isLoading,
      activeClients: clients.clients,
    }),
  );

  const FormRenderer: React.FC<FormBodyAPI & FormikHelpers<any>> = ({
    values,
    setFieldValue,
    errors,
  }) => {
    const portalConfig = React.useContext(PortalConfigContext);

    const { isClientsStateLoading, isUsersStateLoading, activeClients } =
      useAppSelector(selector);

    /**
     * change the form data based on creation of a message channel with client(s) or company
     * @param options combobox options from the company/client selector - companies or clients
     */
    const handleMessagingMembersChanged = (
      _: any,
      options: ComboBoxOption[],
    ) => {
      const selectedCompanyOptions = options.filter(
        (opt) => opt.type === 'company',
      );
      const companyID = selectedCompanyOptions.at(0)?.id ?? '';
      const channelMembers: string[] = companyID
        ? [companyID]
        : options
            .filter((opt) => opt.type === CLIENT_TYPE)
            .map((opt) => opt.id);
      const channelGetstreamMembers = companyID
        ? [companyID]
        : options
            .filter((opt) => opt.type === CLIENT_TYPE)
            .map((opt) => opt.getstreamId)
            .filter(Boolean);
      const channelTitle =
        companyID || options.map((opt) => opt.label).join(', ');
      setFieldValue('fields.members', channelMembers);
      setFieldValue('fields.getstreamMembers', channelGetstreamMembers);
      setFieldValue('fields.companyID', companyID);
      setFieldValue('fields.name', channelTitle);
    };

    /**
     *
     * @param opts combobox options from the company/client selector - companies or clients
     */
    const handleChannelMembersChanged = (_: any, opts: ComboBoxOption[]) => {
      setFieldValue(
        'fields.memberIDs',
        opts.map((opt: ComboBoxOption) => opt.id),
      );
      if (opts.length > 0) {
        // value types will always be homogeneous so we can simply take the .type of the first selected option
        setFieldValue('fields.membersType', opts.at(0)?.type);
      }
    };

    const handleMembersChanged: MultiCategorySelectProps['onChange'] = (
      _,
      vals,
    ) => {
      if (!Array.isArray(vals)) return;

      if (channelType === 'extension') {
        const channelMembers = vals
          .filter((v) => v.type === CLIENT_TYPE)
          .map((v) => v.id);
        const companyIds = vals
          .filter((v) => v.type === COMPANY_TYPE)
          .map((v) => v.id);

        // get all clients that belong to selected companies
        const companiesAssociatedMembers = removeDuplicates(
          companyIds
            .map((cid) =>
              activeClients
                .filter((c) => c.fields.companyId === cid && isUserEnabled(c))
                .map((c) => `${portalConfig.portalHeader}_${c.id}`),
            )
            .flat(),
        );
        const channelMembersNames = vals.map((v) => v.label).join(', ');
        setFieldValue(
          'fields.companiesAssociatedMembers',
          companiesAssociatedMembers,
        );
        setFieldValue('fields.clientUserIds', channelMembers);
        setFieldValue('fields.name', channelMembersNames);
      }

      if (channelType === 'forms') {
        const membersIds = vals.map((val) => val.id);
        setFieldValue('fields.recipientIds', membersIds);
      }
    };

    const getValues = () => {
      let formValue: string | string[] = '';
      switch (channelType) {
        case 'messaging':
          formValue = [].concat(values.fields.members);
          break;
        case 'forms':
          formValue = values.fields.recipientIds || [];
          break;
        case 'fileChannel':
        case 'extension':
          formValue = values.fields.clientUserIds || [];
          break;
        default:
          break;
      }
      return formValue;
    };

    const renderMembersDropdown = () => {
      // if the clients or users are still loading, don't render the dropdown
      if (isClientsStateLoading || isUsersStateLoading) {
        return null;
      }

      let channelMemberProps:
        | Omit<ChannelMemberSelectProps, 'allowMultipleCompanies'>
        | undefined;
      if (channelType === 'messaging') {
        channelMemberProps = {
          memberIds: values.fields.members || [],
          onChange: handleMessagingMembersChanged,
          id: 'message-channel-multi-selector',
        };
      }
      if (channelType === 'fileChannel') {
        channelMemberProps = {
          memberIds: values.fields.memberIDs || [],
          onChange: handleChannelMembersChanged,
          id: 'file-channel-multi-selector',
        };
      }
      if (channelType === 'extension') {
        channelMemberProps = {
          memberIds: values.fields.memberIDs || [],
          onChange: handleChannelMembersChanged,
          id: 'extension-multi-selector',
        };
      }
      if (channelMemberProps) {
        return (
          <ChannelMemberSelect
            {...channelMemberProps}
            error={Boolean(errors?.fields?.memberIDs)}
            helperText={errors?.fields?.memberIDs || ''}
            allowMultipleCompanies={false}
          />
        );
      }
      return (
        <MultiCategorySelect
          textFieldVariant="medium"
          multiple={isMultiSelect}
          includeInternalUsers={false}
          filterResultsLimit={filterResultsLimit}
          values={getValues()}
          id="members-select"
          label="Select client(s)"
          onChange={handleMembersChanged}
          placeholder="Type to find clients"
          showPlaceholder={
            (channelType === 'messaging' &&
              (values.fields.members || []).length === 0) ||
            ((channelType === 'extension' || channelType === 'fileChannel') &&
              (values.fields.clientUserIds || []).length === 0)
          }
          showClientsInOtherCompanies={showClientsInOtherCompanies}
        />
      );
    };

    return (
      <FormControl fullWidth>
        {renderInstructionText()}
        {renderMembersDropdown()}
      </FormControl>
    );
  };

  return { validationScheme, FormRenderer };
};
