import React from 'react';
import BaseCanvas from 'src/components/Canvas/BaseCanvas';
import {
  SIGNATURE_FONT,
  SIGNATURE_FONT_SIZE,
} from 'src/components/Canvas/canvas_constants';
/**
 * This hook allows to convert a signature text to image
 * @param input
 */
export const useSignaturePreview = (input: string) => {
  const [signatureDataUrl, setSignatureDataUrl] = React.useState<string | null>(
    null,
  );
  const [signatureWidth, setSignatureWidth] = React.useState(0);

  const handleSignatureDataChanged = (data: string, textWidth: number) => {
    setSignatureDataUrl(data);
    setSignatureWidth(textWidth);
  };

  const signaturePreviewComponent = (
    <BaseCanvas
      id="signature-canvas"
      text={input}
      fontFamily={SIGNATURE_FONT}
      fontSize={SIGNATURE_FONT_SIZE}
      onDataChange={handleSignatureDataChanged}
    />
  );

  return { signaturePreviewComponent, signatureDataUrl, signatureWidth };
};
