import React from 'react';
import { useDispatch } from 'react-redux';
import { SignaturePageImageData } from 'src/store/signaturePage/types';
import {
  SetupSignaturePageImageAction,
  SetupSignaturePageHeightImageAction,
} from 'src/store/signaturePage/actions';
import { SignaturePageImage } from 'src/components/Signature/SignaturePageImage';

interface SignaturePageImageContainerProps {
  pageImage: SignaturePageImageData;
}

export const SignaturePageImageContainer: React.FC<
  SignaturePageImageContainerProps
> = ({ pageImage }) => {
  const dispatch = useDispatch();

  const pageImgElement = React.useRef<HTMLDivElement>(null);

  let imageYPos = 0;

  React.useEffect(() => {
    const positionTimer = setInterval(() => {
      if (pageImgElement.current) {
        const y = pageImgElement.current.offsetTop;
        if (y !== imageYPos) {
          imageYPos = y;
          dispatch(SetupSignaturePageHeightImageAction(pageImage.key, y));
        }
      }
    }, 500);

    return () => {
      clearInterval(positionTimer);
    };
  }, []);

  const handlePageImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement>,
  ) => {
    const { offsetTop: y, offsetHeight } = event.currentTarget;
    const pageData = {
      ...pageImage,
      loaded: true,
      element: { y, offsetHeight },
    };
    dispatch(SetupSignaturePageImageAction(pageData));
  };

  return (
    <SignaturePageImage
      ref={pageImgElement}
      pageImage={pageImage}
      handlePageImageLoad={handlePageImageLoad}
    />
  );
};
