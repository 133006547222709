import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SearchIcon = (props: SvgIconProps) => (
  <SvgIcon width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.45 8.4C9.47775 8.421 9.50475 8.44425 9.53025 8.46975L11.7803 10.7198C11.8519 10.7889 11.909 10.8717 11.9483 10.9632C11.9876 11.0547 12.0083 11.1531 12.0092 11.2527C12.0101 11.3523 11.9911 11.451 11.9534 11.5432C11.9157 11.6354 11.86 11.7191 11.7895 11.7895C11.7191 11.86 11.6354 11.9157 11.5432 11.9534C11.451 11.9911 11.3523 12.0101 11.2527 12.0092C11.1531 12.0083 11.0547 11.9876 10.9632 11.9483C10.8717 11.909 10.7889 11.8519 10.7198 11.7803L8.46975 9.53025C8.44465 9.50517 8.42134 9.47836 8.4 9.45C7.33763 10.2468 6.01279 10.6114 4.69234 10.4703C3.37189 10.3292 2.15395 9.69303 1.28387 8.68981C0.413786 7.6866 -0.0437926 6.39093 0.003306 5.0638C0.0504046 3.73668 0.598681 2.4767 1.53769 1.53769C2.4767 0.598681 3.73668 0.0504046 5.0638 0.003306C6.39093 -0.0437926 7.6866 0.413786 8.68981 1.28387C9.69303 2.15395 10.3292 3.37189 10.4703 4.69234C10.6114 6.01279 10.2468 7.33763 9.45 8.4ZM5.25 9C6.24456 9 7.19839 8.60491 7.90165 7.90165C8.60491 7.19839 9 6.24456 9 5.25C9 4.25544 8.60491 3.30161 7.90165 2.59835C7.19839 1.89509 6.24456 1.5 5.25 1.5C4.25544 1.5 3.30161 1.89509 2.59835 2.59835C1.89509 3.30161 1.5 4.25544 1.5 5.25C1.5 6.24456 1.89509 7.19839 2.59835 7.90165C3.30161 8.60491 4.25544 9 5.25 9Z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
