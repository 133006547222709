import React, { FC } from 'react';
import { DescriptionTooltip } from 'src/components/Tooltip/DescriptionTooltip';

interface TooltipWrapperProps {
  children: React.ReactNode;
  title?: React.ReactNode;
}

export const TooltipWrapper: FC<TooltipWrapperProps> = ({ children, title }) =>
  title ? (
    <DescriptionTooltip
      disableFocusListener
      enterTouchDelay={100}
      title={title}
      interactive
    >
      <div>{children}</div>
    </DescriptionTooltip>
  ) : (
    <>{children}</>
  );
