import loadable from '@loadable/component';
import {
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { PDFSkeleton } from 'src/components/Skeleton/PDFSkeleton';
import { RIGHT_SIDEBAR_WIDTH } from 'src/constants';
import { useAppSelector } from 'src/hooks/useStore';
import { clamp } from 'src/utils/common_utils';

const PdfViewer = loadable(() => import('src/components/UI/PDFViewer'));

const DESKTOP_MAX_PDF_PAGE_WIDTH = 600;
const DESKTOP_MIN_PDF_PAGE_WIDTH = 480;

const useStyles = makeStyles(() =>
  createStyles({
    notificationPdfLoader: {
      width: (props: { width: number }) => props.width,
      margin: '0 auto',
      height: 900,
    },
  }),
);
export const NotificationPdfViewer: React.FC<{
  pdfUrl: string;
  previewContainerRef: React.RefObject<HTMLDivElement>;
}> = ({ pdfUrl, previewContainerRef }) => {
  const [pdfPageWidth, setPdfPageWidth] = React.useState<number>(0);
  const classes = useStyles({ width: pdfPageWidth });
  const isTimelineShowing = useAppSelector(
    (state) => state.ui.rightSideBar.isOpen,
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  // this function handles getting the dynamically sized pdf page width.
  const setDynamicallyPdfPageWidth = () => {
    if (!previewContainerRef.current) return;
    let pageWidth = 0;
    // if the timeline is showing, we want to subtract the width of the timeline
    const rightSpacing =
      isTimelineShowing && !isMobile ? RIGHT_SIDEBAR_WIDTH : 0;

    // calculate width using the bounding rectangle of the node
    // https://github.com/wojtekmaj/react-pdf/issues/752
    const pdfContainerNodeWith =
      previewContainerRef.current.getBoundingClientRect().width;

    // we want to keep the pdf page width to a max of 800px and min of 380px
    // this way it does not get too big on large screens and too small on smaller desktop screens.
    pageWidth = clamp(
      pdfContainerNodeWith - rightSpacing,
      DESKTOP_MIN_PDF_PAGE_WIDTH,
      DESKTOP_MAX_PDF_PAGE_WIDTH,
    );

    // if the device is mobile, take the full width of the screen
    // amd keep a 20px margin on each side
    if (isMobile) {
      pageWidth = pdfContainerNodeWith - 40;
    }

    setPdfPageWidth(pageWidth);
  };

  React.useEffect(() => {
    setDynamicallyPdfPageWidth();
    window.addEventListener('resize', setDynamicallyPdfPageWidth);
    return () => {
      window.removeEventListener('resize', setDynamicallyPdfPageWidth);
    };
  }, [isTimelineShowing, isMobile]);

  return (
    <PdfViewer
      customLoader={<PDFSkeleton className={classes.notificationPdfLoader} />}
      pdfPageWidth={pdfPageWidth}
      pdfUrl={pdfUrl}
    />
  );
};
