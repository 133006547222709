import React from 'react';
import CopyToClipboard from 'src/components/CopyToClipboard';
import Button from 'src/components/Button';
import { useCustomDomains } from 'src/hooks/useCustomDomains';

const isCopyAction = (action: string) => action.includes('pageAction=copy');

type ButtonProps = React.ComponentProps<typeof Button>;

interface ResourceActionButtonProps {
  actionLink: string;
  actionLabel: string;
  buttonProps: ButtonProps;
}

export const ResourceActionButton: React.FC<ResourceActionButtonProps> = ({
  actionLink,
  actionLabel,
  buttonProps,
}) => {
  const { basePortalDomain } = useCustomDomains();
  const pathTo = actionLink.split('&pathTo=').at(1);

  /**
   * This function handles copy action
   * it check if the pathTo is present in the actionLink
   * if it is present it will copy the base domain url
   * and concat the pathTo to it.
   * @param copyFn - copy function from CopyToClipboard
   */
  const handleCopy = (copyFn: (content: string) => void) => {
    if (pathTo && basePortalDomain) {
      copyFn(basePortalDomain);
    }
  };
  return isCopyAction(actionLink || '') ? (
    <CopyToClipboard>
      {({ copy }) => (
        <Button {...buttonProps} onClick={() => handleCopy(copy)}>
          {actionLabel}
        </Button>
      )}
    </CopyToClipboard>
  ) : (
    <Button {...buttonProps}>{actionLabel}</Button>
  );
};
