import React, { forwardRef } from 'react';
import { Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import { drawerWidth } from 'src/components/Sidebar/DrawerSection';
import { NotAllowedPage } from 'src/components/UI/NotAllowedPage';
import { NetworkErrorPage } from 'src/components/UI/Pages/NetworkErrorPage';
import { RootState } from 'src/store';
import { LoadingPage } from 'src/components/UI/Pages/LoadingPage';
import { SettingsPageContent } from 'src/components/Settings/SettingsPageContent';
import { useGetPortalConfigQuery } from 'src/services/api';
import { RouteContext } from 'src/context';
import { HomePageContent } from 'src/components/HomePage';
import { useAppSelector } from 'src/hooks/useStore';
import { shallowEqual } from 'react-redux';

const ONE_SECOND_INTERVAL = 1000;
export interface PageContentStyleProps {
  noSpace?: boolean;
  fullMobileWidth?: boolean;
  maxWidth?: string;
  noToolbar?: boolean;
}

export interface PageContentProps extends PageContentStyleProps {
  children?: React.ReactNode;
  allowed?: boolean;
  fullPage?: boolean;
  isSettingsPage?: boolean;
}
const getLeftPadding = (
  props: PageContentStyleProps,
  theme: Theme,
  isSmScreen: boolean,
) => {
  const additionalPadding = !isSmScreen ? drawerWidth : 0;
  return `${additionalPadding + (props.noSpace ? 0 : theme.spacing(4.5))}px`;
};

const usePageContentStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      overflow: 'auto',
      boxSizing: 'border-box',
      [theme.breakpoints.down('xs')]: {
        padding: (props: PageContentStyleProps) =>
          props.noSpace ? 0 : theme.spacing(0, 2),
      },
      [theme.breakpoints.up('sm')]: {
        height: '100%',
        padding: (props: PageContentStyleProps) =>
          props.noSpace ? 0 : theme.spacing(0, 4.5),
        paddingLeft: (props: PageContentStyleProps) =>
          getLeftPadding(props, theme, true),
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: (props: PageContentStyleProps) =>
          getLeftPadding(props, theme, false),
      },
    },
    noSpaceContainer: {
      [theme.breakpoints.down('xs')]: {
        margin: '0 auto',
      },
    },
    noToolbar: (props: PageContentStyleProps) => ({
      paddingTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        paddingTop: props.noSpace ? 0 : theme.spacing(2),
      },
    }),
    pageContentBody: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: '100%',
        padding: 'initial',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '100%',
      },
      width: '100%',
      height: '100%',
      display: 'flex',
      margin: 'initial',
      flexDirection: 'column',
    },
    fullPage: {
      padding: 0,
      width: '100%',
    },
  }),
);

export const PageContent = forwardRef<HTMLDivElement, PageContentProps>(
  (
    {
      children,
      allowed = true,
      noToolbar = false,
      fullPage = false,
      isSettingsPage = false,
      ...styleProps
    },
    ref,
  ) => {
    // all hookes should be defined before any return to avoid:
    // Error: Rendered more hooks than previous render
    const handleScroll = (event: Event) => {
      if (event instanceof Event) {
        const element = event.target as HTMLElement;
        if (element.classList && !element.classList?.contains('on-scrollbar')) {
          element.classList.add('on-scrollbar');
          setTimeout(() => {
            element.classList.remove('on-scrollbar');
          }, ONE_SECOND_INTERVAL);
        }
      }
    };
    /**
     * Add event listener to handle scroll event
     * We want to render scrollbar only when user is scrolling
     * After 1 second of no scrolling, we will remove the scrollbar
     */
    React.useEffect(() => {
      window.addEventListener('scroll', handleScroll, true);

      return () => {
        window.removeEventListener('scroll', handleScroll, true);
      };
    }, []);

    const { isLoading: isPortalConfigurationLoading } =
      useGetPortalConfigQuery(); // Check whether portal configuration is loaded
    const { loadingError, permissions } = useAppSelector(
      (state: RootState) => ({
        loadingError: state.user.loadingError,
        permissions: state.user.data?.permissions,
      }),
      shallowEqual,
    );
    const { pathname } = React.useContext(RouteContext);
    const isClientUser = useAppSelector((state) => state.user.isClient);
    const classes = usePageContentStyles({
      ...styleProps,
      noToolbar,
    });
    const redirectToHomePage =
      (pathname === '/' || pathname.startsWith('/portal/')) && !isClientUser;

    let PageOverrideComponent;
    if (loadingError) {
      PageOverrideComponent = NetworkErrorPage;
    } else if (isPortalConfigurationLoading || !permissions) {
      PageOverrideComponent = LoadingPage;
    } else if (!allowed) {
      PageOverrideComponent = NotAllowedPage;
    }

    const noSpaceContainerProps = {
      className: classNames(classes.root, classes.noSpaceContainer, {
        [classes.fullPage]: noToolbar,
      }),
      width: 1,
      ref,
    };

    if (PageOverrideComponent) {
      return (
        <Box {...noSpaceContainerProps} display="flex">
          <PageOverrideComponent />
        </Box>
      );
    }

    if (isSettingsPage) {
      return <SettingsPageContent>{children}</SettingsPageContent>;
    }

    if (fullPage) {
      return (
        <div className={classes.fullPage} ref={ref}>
          {children}
        </div>
      );
    }

    if (styleProps.noSpace) {
      return <Box {...noSpaceContainerProps}>{children}</Box>;
    }
    return (
      <Grid
        container
        className={classNames(classes.root, {
          [classes.noToolbar]: noToolbar,
        })}
        ref={ref}
      >
        <Grid className={classes.pageContentBody} item>
          {redirectToHomePage ? <HomePageContent /> : children}
        </Grid>
      </Grid>
    );
  },
);
