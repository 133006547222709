import React from 'react';
import { StepperPageContext } from 'src/context';
import Layout from 'src/components/Layout/Layout';
import { StepperPageHeader } from 'src/components/Layout/StepperLayout/StepperPageHeader';
import {
  NextButtonTooltipOptions,
  Step,
  StepperSubmitAction,
} from 'src/components/Layout/StepperLayout/stepperTypes';

interface Props {
  title: string;
  steps: Step[];
  onCancel: () => void;
}
export const StepperPageLayout: React.FC<Props> = ({
  children,
  title,
  steps: pageSteps,
  onCancel,
}) => {
  const [steps, setSteps] = React.useState<Array<Step>>(pageSteps || []);
  const [pageTitle, setPageTitle] = React.useState<string>(title || '');
  const [nextButtonTooltipOptions, setNextButtonTooltipOptions] =
    React.useState<NextButtonTooltipOptions | null>(null);

  const [showStepper, setShowStepper] = React.useState<boolean>(true);
  const activeStepIndex = React.useMemo(
    () => steps.findIndex((step) => step.active),
    [steps],
  );
  const activeStep = React.useMemo(
    () => steps[activeStepIndex],
    [steps, activeStepIndex],
  );

  const [submitActions, setSubmitActions] = React.useState<
    Array<StepperSubmitAction>
  >([]);

  const [loading, setLoading] = React.useState<boolean>(false);

  const [asyncNextHandler, setAsyncNextHandler] = React.useState<
    (() => void) | null
  >(null);

  // update steps array to set the given step as active
  const updateStepAsActive = (updatedStep: Step) => {
    const updatedSteps = steps.map((step) => {
      if (step.label !== updatedStep.label) {
        return {
          ...step,
          active: false,
        };
      }
      return {
        ...step,
        active: true,
      };
    });

    setSteps(updatedSteps);
  };

  /**
   * This function handles moving to the next step.
   */
  const onNext = async () => {
    if (asyncNextHandler) {
      setLoading(true);
      await asyncNextHandler();
      setLoading(false);
    }
    const nextStep = steps[activeStepIndex + 1] || null;

    if (nextStep) {
      updateStepAsActive(nextStep);
    }
  };

  /**
   * This function handles moving to the previous step.
   */
  const onBack = () => {
    const prevStep = steps[activeStepIndex - 1] || null;
    if (prevStep) {
      updateStepAsActive(prevStep);
    }
  };

  /**
   * This function is called when the active step
   * is completed.
   * To mark current step as incomplete, pass false as the argument.
   */
  const onComplete = (complete?: boolean) => {
    const updatedSteps = steps.map((step) => {
      if (step.label === activeStep.label) {
        return {
          ...step,
          complete: complete ?? true,
        };
      }
      return step;
    });

    setSteps(updatedSteps);
  };

  const stepperContextValue = React.useMemo(
    () => ({
      pageTitle,
      setPageTitle,
      setSteps,
      steps,
      onBack,
      onNext,
      activeStep,
      activeStepIndex,
      onComplete,
      nextButtonTooltipOptions,
      setNextButtonTooltipOptions,
      asyncNextHandler,
      setAsyncNextHandler,
      loading,
      setLoading,
      submitActions,
      setSubmitActions,
      showStepper,
      setShowStepper,
    }),
    [
      pageTitle,
      steps,
      onBack,
      onNext,
      activeStep,
      activeStepIndex,
      nextButtonTooltipOptions,
      asyncNextHandler,
      loading,
      submitActions,
      showStepper,
    ],
  );

  return (
    <StepperPageContext.Provider value={stepperContextValue}>
      <Layout headerComponent={<StepperPageHeader onCancel={onCancel} />}>
        {children}
      </Layout>
    </StepperPageContext.Provider>
  );
};
