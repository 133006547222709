import {
  Avatar,
  IconButton,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
import { PortalConfigContext } from 'src/context';
import { BackIcon } from 'src/components/Icons';
import BaseTypography from 'src/components/Text/BaseTypography';

type Props = {
  onBackClick: () => void;
  title?: string;
  logoClassName?: string; // class to override logo styles
  backIconClassName?: string; // class to override back button styles
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
  },
  logoContainer: {
    width: '40px',
    height: '40px',
    marginRight: theme.spacing(2.25),
    marginLeft: theme.spacing(2.25),
    backgroundColor: 'transparent',
  },
  iconButton: {
    padding: 0,
  },
  logo: {
    width: '100%',
  },
}));

export const BrandedBackButton = ({
  onBackClick,
  title,
  logoClassName = '',
  backIconClassName = '',
}: Props) => {
  const classes = useStyles();
  const portalConfig = React.useContext(PortalConfigContext);
  const theme = useTheme();
  return (
    <div className={classes.root}>
      <IconButton
        onClick={onBackClick}
        className={classNames(classes.iconButton, backIconClassName)}
      >
        <BackIcon
          style={{ color: theme.palette.primary.main }}
          data-testid="back-button"
        />
      </IconButton>
      <Avatar
        variant="rounded"
        className={classNames(classes.logoContainer, logoClassName)}
      >
        <img
          className={classes.logo}
          src={portalConfig?.assets?.logo?.iconImageUrl}
          alt="Logo"
        />
      </Avatar>
      <BaseTypography fontType="18Medium">
        {title || portalConfig?.name}
      </BaseTypography>
    </div>
  );
};
