import React from 'react';
import {
  createStyles,
  Theme,
  makeStyles,
  Radio,
  FormControlLabel,
} from '@material-ui/core';

interface AccountTypeSelectProps {
  value: string;
  label: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    radioInput: {
      marginRight: theme.spacing(1),
      '& span': {
        color: theme.palette.primary.main,
      },
    },
  }),
);

const AccountTypeSelect: React.FC<AccountTypeSelectProps> = ({
  value,
  label,
}) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.root}
      value={value}
      control={
        <Radio
          classes={{
            root: classes.radioInput,
          }}
          size="small"
          color="primary"
        />
      }
      label={label}
    />
  );
};

export default AccountTypeSelect;
