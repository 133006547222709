import {
  formatPhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { MapFieldTypeToDefaultProps } from 'src/components/CustomFieldsMenu';
import PhoneNumberInput from 'src/components/Inputs/PhoneNumberInput';
import { MultiSelect } from 'src/components/MultiSelect';
import { BaseTextField, NumberTextField } from 'src/components/TextField';
import { EmailValidationRegex, URLValidationRegex } from 'src/constants';
import { CustomFieldType } from 'src/store/clients/types';

// this function determines which input text component to use
// as quick editor.
export const getFieldInputComponent = (fieldType: CustomFieldType) => {
  switch (fieldType) {
    case 'phoneNumber':
      return PhoneNumberInput;
    case 'number':
      return NumberTextField;
    case 'multiSelect':
      return MultiSelect;
    default:
      return BaseTextField;
  }
};

/**
 * This function is used to format field values.
 * @param fieldType: represents the field type
 * @param fieldValue: represents the field value
 * @returns
 */
export const formatFieldValue = (
  fieldType: CustomFieldType,
  fieldValue: string,
) => {
  if (fieldType === 'phoneNumber') {
    return formatPhoneNumber(fieldValue);
  }

  return fieldValue;
};

/**
 * This function checks if input value is
 * valid or not based on the field type.
 * @param fieldType: custom field type (text, url, email, address)
 * @param val: current input type
 */
export const getFieldValidationByType = (
  fieldType: CustomFieldType,
  val: string,
) => {
  // when input is empty don't show validation text
  if (!val) return '';

  if (fieldType === 'email') {
    const emailRegex = new RegExp(EmailValidationRegex);
    if (!val.toString().match(emailRegex)) {
      return MapFieldTypeToDefaultProps.email.validation?.invalidText || '';
    }
  }

  if (fieldType === 'url') {
    const urlRegex = new RegExp(URLValidationRegex);
    if (!val.toString().match(urlRegex)) {
      return MapFieldTypeToDefaultProps.url.validation?.invalidText || '';
    }
  }

  if (fieldType === 'phoneNumber') {
    if (!isValidPhoneNumber(val)) {
      return (
        MapFieldTypeToDefaultProps.phoneNumber.validation?.invalidText || ''
      );
    }
  }

  return '';
};
