import React from 'react';
import { Logger } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { NotificationBadge } from 'src/components/UI/Badges';
import { RootState } from 'src/store';
import { removeDuplicates } from 'src/utils/array';
import { isUnreadNotification } from 'src/utils/NotificationsUtils';

const logger = new Logger('FilesNotificationsBadge');

export const FilesNotificationBadge = () => {
  const fileNotifications = useSelector(
    (state: RootState) => state.notifications.fileNotifications,
  );

  const esigRequestNotifications = useSelector(
    (state: RootState) => state.notifications.esigRequestNotifications,
  );

  const esigRequestCompleteNotifications = useSelector(
    (state: RootState) => state.notifications.esigRequestCompleteNotifications,
  );

  const [filesNotificationsCounter, setFilesNotificationsCounter] =
    React.useState(0);

  /**
   * This method calculates for each notification
   * group its total unread notification, since files
   * and e-sign unread notifications are calculated differently.
   * Files unread notifications count is the sum of file-upload notified channel.
   * E-sign notifications count is the sum of esign-requests/signature complete actions
   * received at channel.
   */
  const calculateFilesNotificationsCounter = () => {
    logger.debug('Counting primary sidebar file notifications');
    logger.debug('Total file notifications:', fileNotifications.length);
    const unreadFileUploadNotifications = fileNotifications.filter((notif) =>
      isUnreadNotification(notif),
    );
    logger.debug('Unread notifications:', unreadFileUploadNotifications.length);
    const fileUploadNotifiedChannelsIds = removeDuplicates(
      unreadFileUploadNotifications.map((notif) => notif.fields.channelId),
    );
    logger.debug(
      'Unique Channel Ids without file upload notifications:',
      fileUploadNotifiedChannelsIds,
    );

    const unreadEsigRequestCompleteNotifications =
      esigRequestCompleteNotifications.filter((notif) =>
        isUnreadNotification(notif),
      );

    const totalFileUploadNotificationsCount =
      fileUploadNotifiedChannelsIds.length;
    // If contracts are enabled then esign request notification is to be shown with contracts and not files
    const totalEsignRequestNotificationsCount = 0;
    const totalEsignRequestCompleteNotificationsCount =
      unreadEsigRequestCompleteNotifications.length;

    const totalFilesNotificationsCount =
      totalFileUploadNotificationsCount +
      totalEsignRequestNotificationsCount +
      totalEsignRequestCompleteNotificationsCount;

    setFilesNotificationsCounter(totalFilesNotificationsCount);
  };
  React.useEffect(() => {
    calculateFilesNotificationsCounter();
  }, [
    fileNotifications,
    esigRequestNotifications,
    esigRequestCompleteNotifications,
  ]);

  return (
    <NotificationBadge
      showZero={false}
      isSidebar
      content={filesNotificationsCounter}
    />
  );
};
