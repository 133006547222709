import { combineReducers } from '@reduxjs/toolkit';
import { entityImportReducer } from 'src/store/entityImporter/slice';
import users from 'src/store/users/reducers';
import user from 'src/store/user/reducers';
import clients from 'src/store/clients/reducers';
import ui from 'src/store/ui/reducers';
import runtime from 'src/store/runtime/reducers';
import data from 'src/store/data/reducers';
import settings from 'src/store/settings/reducers';
import files from 'src/store/files/reducers';
import messages from 'src/store/messages/reducers';
import payments from 'src/store/payments/reducers';
import notifications from 'src/store/notifications/reducers';
import signaturePage from 'src/store/signaturePage/reducers';
import dashboard from 'src/store/dashboard/reducers';
import { shortcutsReducer } from 'src/store/shortcuts/slice';

import tags from 'src/store/tags/reducers';
import knowledgeBase from 'src/store/knowledgeBase/reducers';
import tableProperties from 'src/store/tableProperties/reducers';
import { appAPI } from 'src/services/api';
import { usersApi } from 'src/services/api/usersApi';
import { authReducer } from 'src/store/auth/slice';

export const rootReducer = combineReducers({
  clients,
  users,
  user,
  ui,
  runtime,
  data,
  settings,
  files,
  messages,
  payments,
  notifications,
  signaturePage,
  dashboard,
  shortcuts: shortcutsReducer,
  entityImports: entityImportReducer,
  tags,
  knowledgeBase,
  tableProperties,
  auth: authReducer,
  [appAPI.reducerPath]: appAPI.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
});
