import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  Breadcrumbs,
  Theme,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { ForwardIcon } from 'src/components/Icons';
import { RootState } from 'src/store/reduxTypes';
import useNavigate from 'src/hooks/useNavigate';
import { BaseMenu, Action } from 'src/components/Dropdowns';
import { saveUploadedFiles } from 'src/store/files/actions';
import { useProgressUpload } from 'src/hooks/useProgressUpload';
import S3Utils from 'src/utils/S3Utils';
import { FileSaveModel } from 'src/store/files/types';
import * as Colors from 'src/theme/colors';
import {
  FilesBreadcrumbChip,
  ChipTemplate,
} from 'src/components/Files/FilesBreadcrumbChip';
import { AgGridContext } from 'src/components/AgGrid';

interface FilesBreadcrumbsProps {
  firstNodeLabel: string;
  baseURL: string;
  filePathParts: string[];
  selectedChannelId: string;
  hasToolbar: boolean;
  isSmallWidth: boolean;
  selectedFilePath: string | undefined;
  className?: string;
  showRootChip?: boolean;
  lastChipClassname?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3.125),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
      },
    },
    folderChip: {
      color: Colors.GraySmall,
      background: 'none',
      textOverflow: 'ellipse',
      maxWidth: 150,
      '&:hover': {
        backgroundColor: Colors.DividersAndCardBorders,
      },
    },
    folderChipDragOver: {
      backgroundColor: Colors.DividersAndCardBorders,
    },
    collapsedMenuChip: {
      background: 'none',
      height: '16px',
      width: '16px',
    },
    breadcrumbs: {
      height: 32,
      borderRadius: 64,
      backgroundColor: Colors.NonHoverBackground,
      width: 'fit-content',
      padding: theme.spacing(0, 1.5),
    },
  }),
);

export const FilesBreadcrumbs: React.FC<FilesBreadcrumbsProps> = ({
  firstNodeLabel,
  baseURL,
  filePathParts,
  selectedChannelId,
  hasToolbar,
  isSmallWidth,
  selectedFilePath,
  className = '',
  showRootChip = false, // show breadcrumb for files that are in root directory
  lastChipClassname = '',
}) => {
  const classes = useStyles();

  const { gridApi } = React.useContext(AgGridContext);
  const dispatch = useDispatch();
  const fileSettings = useSelector(
    (state: RootState) => state.settings.fileSettings,
  );
  const isClient = useSelector((state: RootState) => state.user.isClient);
  const isFolderUpdateDisabled =
    isClient && Boolean(fileSettings?.clientFolderUpdateDisabled);
  const { setUploadProgress, startUploadFiles, exitUploadFiles } =
    useProgressUpload();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleCollapsedChipsMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleCollapsedChipsMenuClose = () => {
    setAnchorEl(null);
  };

  const { navigate } = useNavigate();
  const chips: ChipTemplate[] = [
    {
      label: firstNodeLabel,
      path: '',
      onClick: () => {
        navigate(baseURL);
      },
      fileDragActionsEnabled: true,
    },
  ];
  const nestedPathActions: Action[] = [];
  const handleSaveUploadedFiles = (filesPaths: FileSaveModel[]) => {
    if (selectedChannelId) {
      dispatch(saveUploadedFiles(filesPaths, selectedChannelId));
    }
  };
  const handleFilesUpload = useCallback(
    (targetPath = '') =>
      async (acceptedFiles: Array<any>) => {
        const filesPaths = await S3Utils.uploadFilesToS3({
          acceptedFiles,
          targetPath,
          startUploadFiles,
          exitUploadFiles,
          setUploadProgress,
          ignoreFolders: isFolderUpdateDisabled,
        });
        handleSaveUploadedFiles(filesPaths);
      },
    [selectedChannelId, isFolderUpdateDisabled],
  );

  const nestedMenuSize = filePathParts.length - 2;
  if (nestedMenuSize > 0) {
    const nestedPaths = filePathParts.slice(0, nestedMenuSize);
    chips.push({
      path: '',
      label: '...',
      onClick: handleCollapsedChipsMenu,
      fileDragActionsEnabled: false,
    });
    nestedPaths.forEach((p, idx) => {
      nestedPathActions.push({
        name: p,
        onClick: () =>
          navigate(
            `${baseURL}&selectedFilePath=${encodeURIComponent(
              filePathParts.slice(0, idx + 1).join('/'),
            )}`,
          ),
      });
    });
    filePathParts.slice(nestedMenuSize).forEach((part, idx) => {
      const path = filePathParts.slice(0, idx + nestedMenuSize + 1).join('/');
      chips.push({
        label: part,
        path,
        onClick: () => {
          navigate(`${baseURL}&selectedFilePath=${encodeURIComponent(path)}`);
        },
        fileDragActionsEnabled: true,
      });
    });
  } else {
    filePathParts.forEach((part, idx) => {
      const path = filePathParts.slice(0, idx + 1).join('/');
      chips.push({
        path,
        label: part,
        onClick: () => {
          navigate(`${baseURL}&selectedFilePath=${encodeURIComponent(path)}`);
        },
        fileDragActionsEnabled: true,
      });
    });
  }

  const [chipRefs, setChipRefs] = React.useState<Array<HTMLDivElement>>([]);

  const handleChipRefSet = useCallback(
    (node: HTMLDivElement | null, idx: number) => {
      if (node !== null && chipRefs[idx] !== node) {
        // if a valid html node representing a chip is passed in, we set it in the Array of chipRefs at the provided index
        chipRefs[idx] = node;
        setChipRefs([...chipRefs]);
      }
    },
    [],
  );

  const handleDropActive = (idx: number) => {
    const activeDropzoneChip = chipRefs[idx];
    if (activeDropzoneChip)
      activeDropzoneChip.classList.add(classes.folderChipDragOver);
  };
  const handleDropInactive = (idx: number) => {
    const activeDropzoneChip = chipRefs[idx];
    if (activeDropzoneChip)
      activeDropzoneChip.classList.remove(classes.folderChipDragOver);
  };
  if (isSmallWidth || (!showRootChip && !selectedFilePath)) return null;

  return (
    <div
      className={classNames({
        [classes.root]: hasToolbar,
      })}
    >
      <Breadcrumbs
        classes={{
          ol: classNames(classes.breadcrumbs, {
            [className]: className,
          }),
        }}
        separator={<ForwardIcon style={{ fontSize: 9 }} />}
      >
        {chips.map((template, index) => (
          <FilesBreadcrumbChip
            dispatch={dispatch}
            key={template.path}
            gridApi={gridApi}
            handleDropActive={handleDropActive}
            handleDropInactive={handleDropInactive}
            handleFilesUpload={handleFilesUpload}
            chipIndex={index}
            chipTemplate={template}
            chipRefs={chipRefs}
            handleChipRefSet={handleChipRefSet}
            isClient={isClient}
            fileSettings={fileSettings}
            chipsLength={chips.length}
            lastChipClassname={lastChipClassname}
          />
        ))}
      </Breadcrumbs>
      <BaseMenu
        actions={nestedPathActions}
        menuProps={{
          MenuListProps: {
            style: {
              maxWidth: '250px',
            },
          },
          id: 'filebreadcrumb-menu',
          anchorEl,
          getContentAnchorEl: null,
          keepMounted: true,
          open,
          onClose: handleCollapsedChipsMenuClose,
          anchorOrigin: {
            vertical: 20,
            horizontal: 0,
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      />
    </div>
  );
};
