import React from 'react';
import { Theme, makeStyles, Box, InputBase } from '@material-ui/core';
import { debounce } from 'lodash';
import { typography13MediumStyle } from 'src/components/Text';
import * as Colors from 'src/theme/colors';
import { SearchIcon } from 'src/components/Icons';
import { useKeyboardShortcuts } from 'src/hooks/useKeyboardShortcuts';

const height = 28;

const useStyles = makeStyles((theme: Theme) => ({
  searchIcon: {
    height: 12,
    width: 12,
    marginRight: theme.spacing(1),
    color: Colors.GraySmall,
  },
  searchInput: {
    ...typography13MediumStyle,
    height: `${height}px`,
    color: Colors.GraySmall,
    textAlign: 'center',
  },
  inputInput: {
    padding: 0,
    transition: theme.transitions.create('width'),
    '&::placeholder': {
      ...typography13MediumStyle,
      color: Colors.GraySmall,
      opacity: 1,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-2px',
      width: '100px',
    },
  },
  searchWrapper: {
    border: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

interface SearchInputProps {
  handleChange: (value: string) => void;
  htmlId?: string;
  searchEntity?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  handleChange,
  htmlId = 'search-elements',
  searchEntity,
}) => {
  const classes = useStyles();
  const searchInputRef = React.useRef<HTMLInputElement | null>(null);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.target) {
      return handleChange(event.target.value);
    }
    return handleChange('');
  };

  const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const debouncedSearch = debounce(() => handleSearch(event), 500);
    debouncedSearch();
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  useKeyboardShortcuts([
    {
      id: 'search-shortcut',
      name: 'Search',
      shortcut: ['/'],
      perform: () => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      },
    },
  ]);

  /**
   * Handle escape key press event on search input.
   * @param event
   */
  const handleEscape = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      if (searchInputRef.current) {
        // un-focus input
        searchInputRef.current.blur();
      }
    }
  };

  return (
    <Box display="flex">
      <InputBase
        inputRef={searchInputRef}
        startAdornment={<SearchIcon className={classes.searchIcon} />}
        classes={{ root: classes.searchInput, input: classes.inputInput }}
        placeholder={searchEntity ? `Search ${searchEntity}` : 'Search...'}
        onChange={handleKeyChange}
        id={htmlId}
        autoComplete="off"
        onKeyDown={handleEscape}
      />
    </Box>
  );
};

export default SearchInput;
