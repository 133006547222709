import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CreditCardIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" {...props}>
    <g clip-path="url(#clip0_7420_8859)">
      <path
        d="M2.22222 3.96429C1.91667 3.96429 1.66667 4.22143 1.66667 4.53571V15.9643C1.66667 16.2786 1.91667 16.5357 2.22222 16.5357H17.7778C18.0833 16.5357 18.3333 16.2786 18.3333 15.9643V4.53571C18.3333 4.22143 18.0833 3.96429 17.7778 3.96429H2.22222ZM0 4.53571C0 3.275 0.996528 2.25 2.22222 2.25H17.7778C19.0035 2.25 20 3.275 20 4.53571V15.9643C20 17.225 19.0035 18.25 17.7778 18.25H2.22222C0.996528 18.25 0 17.225 0 15.9643V4.53571ZM3.33333 13.9643C3.33333 13.4893 3.70486 13.1071 4.16667 13.1071H5.83333C6.29514 13.1071 6.66667 13.4893 6.66667 13.9643C6.66667 14.4393 6.29514 14.8214 5.83333 14.8214H4.16667C3.70486 14.8214 3.33333 14.4393 3.33333 13.9643ZM7.77778 13.9643C7.77778 13.4893 8.14931 13.1071 8.61111 13.1071H12.5C12.9618 13.1071 13.3333 13.4893 13.3333 13.9643C13.3333 14.4393 12.9618 14.8214 12.5 14.8214H8.61111C8.14931 14.8214 7.77778 14.4393 7.77778 13.9643ZM3.33333 11.1071C3.33333 10.6321 3.70486 10.25 4.16667 10.25H15.8333C16.2951 10.25 16.6667 10.6321 16.6667 11.1071C16.6667 11.5821 16.2951 11.9643 15.8333 11.9643H4.16667C3.70486 11.9643 3.33333 11.5821 3.33333 11.1071ZM13.0556 5.67857H15.8333C16.2951 5.67857 16.6667 6.06071 16.6667 6.53571V8.25C16.6667 8.725 16.2951 9.10714 15.8333 9.10714H13.0556C12.5938 9.10714 12.2222 8.725 12.2222 8.25V6.53571C12.2222 6.06071 12.5938 5.67857 13.0556 5.67857Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_7420_8859">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
