import * as React from 'react';
import { hasInProductNotification } from 'src/components/Inbox';
import { Client } from 'src/constants/dataTypes';
import { InboxNotificationDetailsResponse } from 'src/entities/Notifications';
import { useAppSelector } from 'src/hooks/useStore';

/**
 * This hook is used to get the total read and unread notifications count.
 * @param notifications - all loaded notifications
 * @param clients
 * @param companyMap
 * @returns the total read and unread notifications count
 **/
export const useNotificationsCounts = (
  notifications: Array<InboxNotificationDetailsResponse>,
  clients: Array<Client>,
  companyMap: Record<string, Record<string, string>>,
) => {
  const isClient = useAppSelector((state) => state.user.isClient);
  // get total read and unread notifications count
  const { unreadNotificationCount, readNotificationCount } =
    React.useMemo(() => {
      return getInboxNotificationsCount(
        notifications,
        clients,
        companyMap,
        isClient,
      );
    }, [notifications]);

  return { unreadNotificationCount, readNotificationCount };
};

/**
 * getInboxNotificationsCount will return the unread/read notifications count while
 * only taking into consideration notifications for active clients/companies
 */
const getInboxNotificationsCount = (
  notifications: Array<InboxNotificationDetailsResponse>,
  clients: Array<Client>,
  companyMap: Record<string, Record<string, string>>,
  isClient: boolean,
) => {
  const clientsMap: Record<string, Client> = {};
  clients.forEach((cu) => {
    clientsMap[cu.id] = cu;
  });
  const filteredNotifications = notifications?.filter((notification) => {
    const { fields } = notification;
    // For backwards compatibility we also check the fields attribute
    const sender =
      clientsMap[notification.senderId || fields?.senderUserId || ''];
    const notificationCompanyId =
      sender?.fields.companyId || notification.senderId || '';
    const senderCompanyName = companyMap[notificationCompanyId]?.name || '';
    const isCompanyUser = !!senderCompanyName;
    if ((!sender && !isCompanyUser) || sender?.entityStatus === 'DELETED') {
      return false; // means we shouldn't take notification into count consideration
    }

    return !isClient ? hasInProductNotification(notification) : true;
  });
  const totalUnreadNotifications = filteredNotifications.reduce(
    (prev, curr) => {
      if (!curr.isRead) return prev + 1;
      return prev;
    },
    0,
  );
  return {
    unreadNotificationCount: totalUnreadNotifications,
    readNotificationCount:
      filteredNotifications?.length - totalUnreadNotifications,
  };
};
