import React from 'react';
import {
  useMediaQuery,
  useTheme,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { SettingsSidebarBackToggle } from 'src/components/Sidebar/SettingsSidebarBackToggle';
import BaseTypography from 'src/components/Text/BaseTypography';

const useStyles = makeStyles(() =>
  createStyles({
    settingsHeaderTitleWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);
export const SettingsPageHeaderTitle: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return isSmScreen ? (
    <div className={classes.settingsHeaderTitleWrapper}>
      <SettingsSidebarBackToggle />

      <BaseTypography fontType="15Medium">Settings</BaseTypography>
    </div>
  ) : null;
};
