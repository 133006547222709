import React, {
  MouseEventHandler,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  createStyles,
  makeStyles,
  useTheme,
  useMediaQuery,
  Grid,
  Theme,
} from '@material-ui/core';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  useDrop,
  DropTargetMonitor,
  DragObjectWithType,
  XYCoord,
} from 'react-dnd';
import classNames from 'classnames';
import { ESIGNATURE_PAGE_MARGIN } from 'src/constants';
import { SignatureDraggableComponent } from 'src/components/Signature/SignatureDraggableComponent';
import { SignaturePageImageContainer } from 'src/components/Signature/SignaturePageImageContainer';
import { RootState } from 'src/store/reduxTypes';
import { useSignatureRequestComponent } from 'src/components/Signature/SignatureSidebar/useSignatureRequestComponent';
import {
  RemoveActiveComponent,
  RemoveSelectedComponent,
  SetActiveComponent,
  ToggleResetComponentsReference,
  UpdateComponentsPlacement,
} from 'src/store/signaturePage/actions';
import { SignaturePlaceholderComponent } from 'src/components/Signature/SignaturePlaceholderComponent';
import * as Colors from 'src/theme/colors';
import { ClientSignatureIndicatorPanel } from 'src/components/Signature/ClientSignatureIndicatorPanel';
import { useWindowSize } from 'src/hooks/useWindowResize';
import { AddSignatureDrawer } from 'src/components/Signature/AddSignatureDrawer';
import {
  ESignatureFlowStatus,
  SignaturePageComponent,
} from 'src/store/signaturePage/types';
import SignatureConnectingLine from 'src/components/Signature/SignatureConnectingLine';
import { DRAG_ITEM_TYPE } from './SignatureComponents/SignatureSidebarButton';
import { PDFSkeleton } from 'src/components/Skeleton/PDFSkeleton';
import { ContractBuilderContext } from 'src/context/contractBuilderContext';
import { filterSignatureComponentsByCreationMethod } from 'src/utils/ContractUtils';

export const signaturePageContentWidth = 680;

type StyleProps = {
  isClient: boolean;
  isSmallScreen: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles<string, StyleProps>({
    root: {
      width: `${signaturePageContentWidth}px`,
      margin: (props) => (props.isClient ? '0px' : 'auto'),
    },
    signatureFilesContainer: {
      position: 'relative',
      // Make sure the signature pdf width  is not wider than the ideal width of 680px
      // On small screens, the width is 100vw to take the full width of the screen
      width: `clamp(0px, 100vw, ${signaturePageContentWidth}px)`,
    },
    fileItem: {
      width: '100%',
      pointerEvents: 'none',
      marginBottom: ESIGNATURE_PAGE_MARGIN,
      '& >img': {
        width: '100%',
      },
    },
    dragContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    documentContainer: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      border: `1px solid ${Colors.NonHoverBorder}`,
    },
    activeDropArea: {},
    hoverDropArea: {},
    signatureFlowContainer: {
      borderRight: `1px solid ${Colors.DividersAndCardBorders}`,
      display: 'flex',
      justifyContent: 'center',
      position: 'fixed',
      width: '100%',
    },
    pdfContainer: {
      paddingTop: theme.spacing(12.5),
      marginLeft: 'auto',
      marginBottom: theme.spacing(16),
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
    },
    contractContainer: {
      paddingTop: 0,
    },
  }),
);

interface SignaturePageContentProps {
  onSavePlaceholderElementReference: (
    element: HTMLButtonElement | null,
  ) => void;
  onPageSave: () => void;
  isEditFlow: boolean; // prop is true for contract template edit flow
}

export const SignaturePageContent: React.FC<SignaturePageContentProps> =
  React.memo(
    ({
      onSavePlaceholderElementReference,
      onPageSave,
      isEditFlow,
      children,
    }) => {
      const { isOneOffContract, pageScalerFactor, setPageScalerFactor } =
        useContext(ContractBuilderContext);
      const clientSignaturePanelRef = React.useRef<HTMLDivElement>(null);
      const theme = useTheme();
      const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
      const dispatch = useDispatch();

      const dropAreaRef = React.useRef<HTMLDivElement>(null);
      const [isResetReferences, setResetReferences] = React.useState(false);
      const { width } = useWindowSize(signaturePageContentWidth);

      const { handleRequestReceiverInfo } = useSignatureRequestComponent();

      const {
        pageComponents,
        pageImages,
        pagePlaceholderComponents,
        resetComponentsReference,
        isClient,
        initialPageComponents,
        signatureFlowStatus,
        completeRenderPageImages,
        pageLoading,
      } = useSelector(
        (state: RootState) => ({
          pageComponents: state.signaturePage.pageComponents || [],
          initialPageComponents: state.signaturePage.initialPageComponents,
          pageImages: state.signaturePage.pageImages || [],
          pagePlaceholderComponents:
            state.signaturePage.pagePlaceholderComponents || [],
          resetComponentsReference:
            state.signaturePage.resetComponentsReference,
          isClient: state.user.isClient,
          signatureFlowStatus: state.signaturePage.signatureFlowStatus,
          completeRenderPageImages:
            state.signaturePage.completeRenderPageImages,
          pageLoading: state.signaturePage.loading,
        }),
        shallowEqual,
      );
      const classes = useStyles({
        isClient,
        isSmallScreen,
      });

      /**
       * This useEffect is used to calculate the page scaler factor between the current pdf width and ideal pdf width.
       * We will use this ratio to update the dimensions and coords of the signature page components when the page width is less than the ideal pdf width.
       * For ideal pdf width, the ratio will be 1.
       */
      useEffect(() => {
        setPageScalerFactor(
          signaturePageContentWidth /
            (dropAreaRef.current?.clientWidth || signaturePageContentWidth),
        );
      }, [width]);

      /**
       * This useEffect is used to update the dimensions and coords of the signature page components to adjust them according to the current pdf width.
       * The database is storing the dimensions and coords of the signature page components according to the ideal pdf width.
       * So in order to get dimesions and coords according to the current pdf width, we need to divide the dimensions and coords by the page scaler factor.
       */
      React.useEffect(() => {
        const initialPageComponentsMap: Record<string, SignaturePageComponent> =
          initialPageComponents?.reduce((acc, component) => {
            acc[component.key] = component;
            return acc;
          }, {} as Record<string, SignaturePageComponent>);

        const updatedPageComponents = pageComponents.map((component) => {
          const updatedComponent = {
            ...component,
            xPosition:
              initialPageComponentsMap[component.key].xPosition /
              pageScalerFactor,
            yPosition:
              initialPageComponentsMap[component.key].yPosition /
              pageScalerFactor,
            width:
              initialPageComponentsMap[component.key].width / pageScalerFactor,
            height:
              initialPageComponentsMap[component.key].height / pageScalerFactor,
          };
          return updatedComponent;
        });

        dispatch(UpdateComponentsPlacement(updatedPageComponents));
      }, [pageScalerFactor]);

      // monitor.getSourceClientOffset() returns the top-left offset of the dragged item relative to DnDProvider
      // this function calculates the position of the signature button relative to the drop area
      const getCorrectDroppedOffsetValue = (finalPosition: XYCoord | null) => {
        if (!finalPosition) return undefined;
        // get the container (view port) position by react ref...
        const dropTargetPosition = dropAreaRef.current?.getBoundingClientRect();
        const { y: finalY, x: finalX } = finalPosition;

        // calculate the correct position removing the viewport position.
        const newYposition = finalY - (dropTargetPosition?.top ?? 0);

        const newXposition = finalX - (dropTargetPosition?.left ?? 0);

        return {
          x: newXposition,
          y: newYposition,
        };
      };

      const [{ canDrop, isOver }, drop] = useDrop({
        accept: DRAG_ITEM_TYPE,
        drop: (_: DragObjectWithType, monitor: DropTargetMonitor) => {
          const position = getCorrectDroppedOffsetValue(
            monitor.getSourceClientOffset(),
          );
          return { name: 'Esign Document', position };
        },
        collect: (monitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
      });

      // signature component click handler when client wants to edit signature value
      const requestReceiverInfoHandler = (
        component: SignaturePageComponent,
      ) => {
        // Only want to show active client form on FILL_SIGNATURE step
        if (signatureFlowStatus !== ESignatureFlowStatus.FILL_SIGNATURE) return;

        // making sure previous active component is removed
        dispatch(RemoveActiveComponent());
        dispatch(SetActiveComponent(component.key));
        handleRequestReceiverInfo(component);
      };

      const classeNames = [classes.signatureFilesContainer];
      const isActive = canDrop && isOver;
      if (isActive) {
        classeNames.push(classes.activeDropArea);
      } else if (canDrop) {
        classeNames.push(classes.hoverDropArea);
      }

      const setElementReference = () => {
        dispatch(ToggleResetComponentsReference());
      };
      const drawerRef = useRef<HTMLDivElement>(null);

      useEffect(() => {
        if (resetComponentsReference) {
          setResetReferences(true);
        } else {
          setResetReferences(false);
        }
      }, [resetComponentsReference]);

      const onPageClick: MouseEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation();

        // due to `e.stopPropagation` when click on pdf we are not able to listen for SignatureDraggableItemV2 clickaway listener
        // so handling SignatureDraggableItemV2 click away listener for internal admin here when clicked on pdf
        if (!isClient) {
          dispatch(RemoveSelectedComponent());
        }

        if (signatureFlowStatus !== ESignatureFlowStatus.FILL_SIGNATURE) return;
        // remove the activeComponent key to close the bottom drawer fo client form
        // only on small screens
        // for client only
        if (isClient && isSmallScreen) {
          dispatch(RemoveActiveComponent());
        }
      };

      const areAllContractPDFPagesLoaded =
        (pageLoading || pageImages.length > 0) && !completeRenderPageImages;

      // For the create template flow:
      // Filters the page components based on the contract creation method so that only relevant components are shown.
      // If it's an edit flow or contract submission flow, returns all page components.
      // If it's not a one-off contract, filters out variable inputs and non-empty autofill inputs.
      const filteredPageComponents = useMemo(() => {
        const isContractSubmitFlow = isClient;

        return isEditFlow || isContractSubmitFlow
          ? pageComponents
          : filterSignatureComponentsByCreationMethod(
              pageComponents,
              !isOneOffContract,
            );
      }, [pageComponents, isOneOffContract, isEditFlow, isClient]);

      return (
        <Grid container>
          {/** No left panel for mobile placement */}
          {!isSmallScreen && isClient && (
            <Grid
              ref={clientSignaturePanelRef}
              item
              md={4}
              lg={5}
              className={classes.signatureFlowContainer}
            >
              <ClientSignatureIndicatorPanel onPageSave={onPageSave} />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            style={{ paddingBottom: drawerRef.current?.offsetHeight || 0 }} // PDF viewer needs padding bottom to display components on bottom correctly
            // PDF area needs to take 680px width for md breakpoint
            md={!isSmallScreen && isClient ? 8 : 12}
            lg={!isSmallScreen && isClient ? 7 : 12}
            container
            justifyContent="center"
            alignItems="center"
            className={classNames(classes.pdfContainer, {
              [classes.contractContainer]: !isClient,
            })}
          >
            <div
              ref={drop}
              className={classes.root}
              onClick={onPageClick}
              role="button"
              tabIndex={0}
            >
              <div
                id="pdf-container"
                ref={dropAreaRef}
                className={classeNames.join(' ')}
              >
                {areAllContractPDFPagesLoaded && <PDFSkeleton />}
                {pageImages?.length > 0 &&
                  pageImages.map((pageImage) => (
                    <SignaturePageImageContainer
                      key={pageImage.key}
                      pageImage={pageImage}
                    />
                  ))}
                <div className={classes.dragContainer}>
                  {completeRenderPageImages &&
                    filteredPageComponents.map((component) => (
                      <SignatureDraggableComponent
                        key={component.key}
                        component={component}
                        requestComponentClicked={requestReceiverInfoHandler}
                        setElementReference={setElementReference}
                        refreshElementsReference={isResetReferences}
                      />
                    ))}
                  {pagePlaceholderComponents?.map((component) => (
                    <SignaturePlaceholderComponent
                      key={component.key}
                      component={component}
                      setPlaceholderElement={onSavePlaceholderElementReference}
                    />
                  ))}
                  {isClient && (
                    <SignatureConnectingLine
                      clientSignaturePanelWidth={
                        clientSignaturePanelRef.current?.clientWidth || 0
                      }
                    />
                  )}
                </div>
              </div>
              {isSmallScreen && isClient && (
                <AddSignatureDrawer>
                  <div ref={drawerRef}>{children} </div>
                </AddSignatureDrawer>
              )}
            </div>
          </Grid>
        </Grid>
      );
    },
  );
