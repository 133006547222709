import React from 'react';
import {
  IconButton,
  InputAdornment,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import {
  BaseTextField,
  BaseTextFieldProps,
} from 'src/components/TextField/BaseTextField';
import { InvisibleIcon, VisibleIcon } from 'src/components/Icons';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 14,
      },
    },
    iconButton: {
      padding: 0,
    },
  }),
);
export const PasswordField = (props: BaseTextFieldProps) => {
  const classes = useStyles();
  const [showPassword, togglePasswordVisibility] = React.useReducer(
    (value: boolean) => !value,
    false,
  );

  return (
    <BaseTextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      className={classes.root}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              className={classes.iconButton}
              aria-label="toggle password visibility"
              onClick={() => togglePasswordVisibility()}
            >
              {showPassword ? (
                <InvisibleIcon style={{ fontSize: 20 }} />
              ) : (
                <VisibleIcon style={{ fontSize: 20 }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
