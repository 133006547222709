import React from 'react';
import * as Yup from 'yup';
import {
  FormControl,
  Box,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { v4 } from 'uuid';
import { FormBodyAPI } from 'src/constants/fieldsTypes';
import BaseTypography from 'src/components/Text/BaseTypography';
import { BaseTextField } from 'src/components/TextField';
import { SignatureComponentType } from 'src/constants';

const getSubmitValues = async () => ({
  key: v4(),
});

export const useClientInputLabelForm = ({
  isRequired,
}: {
  isRequired: boolean;
}) => {
  const validationScheme = isRequired
    ? Yup.object().shape({
        label: Yup.string().trim().required('Label is required'),
      })
    : Yup.object().shape({
        label: Yup.string().trim(),
      });

  const FormRenderer: React.FC<FormBodyAPI> = ({
    touched,
    errors,
    values,
    setFieldValue,
    handleChange,
    handleBlur,
  }) => (
    <Box>
      <FormControl fullWidth>
        <BaseTypography fontType="13Medium">Label</BaseTypography>
        <BaseTextField
          name="label"
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched && errors ? touched.label && errors.label : ''}
          variant="outlined"
          error={Boolean(touched.label && errors.label)}
          inputProps={{ 'data-testid': 'label-input' }}
          autoFocus
        />
      </FormControl>
      {/* Optional checkbox for text signature inputs */}
      {values.componentType === SignatureComponentType.REQUEST_TEXT && (
        <Box mt={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.isOptional}
                name="isOptional"
                onChange={() => setFieldValue('isOptional', !values.isOptional)}
                color="primary"
                data-testid="isOptional-checkbox"
              />
            }
            label="Make field optional"
            id="make-field-optional-checkbox"
          />
        </Box>
      )}
    </Box>
  );

  return { validationScheme, getSubmitValues, FormRenderer };
};
