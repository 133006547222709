import React, { useContext } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core';
import { Action } from 'kbar';
import { RouteContext } from 'src/context';
import * as Constants from 'src/constants';
import { SidebarListItem } from 'src/components/Sidebar/SidebarListItem';
import { CollapseItem } from 'src/components/Sidebar/CollapseItem';
import { MainSettingsIcon } from 'src/components/Icons';
import history from 'src/history';
import {
  appNavItemIdPrefix,
  useSidebarItems,
} from 'src/components/Sidebar/useSidebarItems';
import {
  toggleHelpCenterModalAction,
  togglePrimarySidebarMobile,
} from 'src/store/ui/actions';
import {
  PERM_MOD_SETTINGS_MAIN_PG,
  GENERAL_SETTINGS_PAGE,
  PROFILE_PAGE,
  HELP_CENTER_PAGE,
} from 'src/constants';
import { hasPermission } from 'src/utils/UserUtils';
import { useSettingsPageRedirection } from 'src/components/Settings/useSettingsPageRedirection';
import useNavigate from 'src/hooks/useNavigate';
import { useKeyboardShortcuts } from 'src/hooks/useKeyboardShortcuts';
import RowDivider from 'src/components/RowDivider';
import { usePlanStatus } from 'src/hooks/usePlanStatusHook';
import { BlackHeadings, NonHoverBorder } from 'src/theme/colors';
import { ClientExperienceNavItem } from 'src/components/Sidebar/ClientExperienceNavItem';
import { UrlUtils } from 'src/utils';
import { useUserNotification } from 'src/hooks/useUserNotification';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    nested: {
      padding: theme.spacing(0.5, 0, 0.5, 3),
    },
    sidebarItemDivider: {
      padding: theme.spacing(0, 1),
      '& hr': {
        backgroundColor: NonHoverBorder,
      },
    },
    menuList: {
      padding: theme.spacing(1),
      paddingBottom: 0,
      minWidth: 170,
      outline: 'none',
    },
    copyText: {
      wordBreak: 'break-all',
      color: BlackHeadings,
    },
  }),
);
export interface NavSection {
  htmlId: string;
  title: string;
  navItems: Array<NavItem>;
  canAccess: boolean;
  icon?: (props: SvgIconProps) => JSX.Element;
}

export function isNavItem(item: any): item is NavItem {
  return Boolean(item) && 'htmlId' in item && 'label' in item && 'path' in item;
}

export interface NavItem {
  htmlId: string;
  label: string;
  path: string;
  isExternalLink?: boolean;
  icon?: (props: SvgIconProps) => JSX.Element;
  canAccess?: boolean;
  subItems?: Array<NavItem>;
  shouldCollapse?: boolean;
  selected?: boolean;
  children?: React.ReactNode;
  shortcut?: string;
  tooltipTitle?: React.ReactNode;
  disabled?: boolean;
  priority?: number;
  isNonCoreApp?: boolean;
}

type NavOptions = {
  path: string;
  isExternalLink?: boolean;
  isCollapseItem?: boolean;
  isNonCoreApp?: boolean;
};
const SidebarNav: React.FC = () => {
  const classes = useStyles();
  useUserNotification();
  const { pathname } = useContext(RouteContext);
  const { navigate } = useNavigate();
  const isClient = useAppSelector((state) => state.user.isClient);
  const dispatch = useAppDispatch();
  const { isTrialing } = usePlanStatus();
  const permissions = useAppSelector((state) => state.user.data?.permissions);
  const hasMainSettingsPermission = hasPermission(
    PERM_MOD_SETTINGS_MAIN_PG,
    permissions,
  );
  const settingsLandingPage =
    !isClient && hasMainSettingsPermission
      ? GENERAL_SETTINGS_PAGE
      : PROFILE_PAGE;
  const { goToSettings } = useSettingsPageRedirection(
    true,
    settingsLandingPage.path,
  );
  const isPrimarySidebarOpenOnMobile = useAppSelector(
    (state) => state.ui.primarySideBarMobile.isOpen,
  );

  const getNavHandler = (navOptions: NavOptions) => () => {
    const { path, isExternalLink, isCollapseItem, isNonCoreApp } = navOptions;
    // when sidebar item has external link, open it in new tab
    const fullPath = UrlUtils.GetFullUrl(path);
    if (isExternalLink) {
      window.open(fullPath, '_blank');
      return;
    }

    // otherwise, navigate to path
    navigate(path, { forceReload: isNonCoreApp || false });

    // after navigating to path, close primary sidebar on mobile
    if (isPrimarySidebarOpenOnMobile) {
      // if it's a collapse item, do not close primary sidebar
      if (isCollapseItem) return;
      dispatch(togglePrimarySidebarMobile({ isOpen: false }));
    }
  };

  const mainSidebarSections = useSidebarItems('main');
  const settingsSidebarSections = useSidebarItems('settings');

  const isSettingsPage = pathname.includes('settings');

  const sidebarSections = React.useMemo(
    () => (isSettingsPage ? settingsSidebarSections : mainSidebarSections),
    [pathname, settingsSidebarSections, mainSidebarSections],
  );

  // Determine the first accessible nav item for redirection
  // Our sidebar is divied into three sections Home, Apps and Preferences
  // Home is not accessible for client, so we're skipping it
  const firstAccessibleNavItem = React.useMemo(
    () =>
      (isClient
        ? mainSidebarSections.at(1)
        : mainSidebarSections?.at(0)
      )?.navItems.find((item) => item.canAccess),
    [mainSidebarSections, isClient],
  );

  /**
   * This holds the main sidebar command bar actions (shortcuts)
   */
  const mainSidebarCommandBarActions: Action[] = React.useMemo(() => {
    // For Sidebar 2.0, we're adding customization, module mangement and settings to commandbar.
    // For this reason, we're looping over all items in main sidebar.
    const navItems = mainSidebarSections
      .map((x) => {
        const items = x.navItems;
        if (items.some((i) => i.subItems)) {
          return [...items, ...items.flatMap((y) => y.subItems)]; // Invoices, Subscription, Customization and Module Management are sub items
        }
        return items;
      })
      .flat(Infinity) // Flattening the array so all items are rooted to base level
      .filter(
        (x) =>
          isNavItem(x) && Boolean(x.canAccess) && x.htmlId.includes('sidebar'),
      );

    return [
      ...(navItems as NavItem[]).map(({ htmlId, label, path, disabled }) => ({
        section: 'Navigation',
        id: htmlId,
        name: `Go to ${label}`,
        disabled,
        perform: () => (!disabled ? navigate(path) : null), // Disabled items can not be navigated to.
      })),
      // Adding sidebar section manually because it's not part of main sidebar nav items
      {
        section: 'Navigation',
        id: 'internal-settings',
        name: 'Go to Settings',
        perform: () => goToSettings(),
      },
    ];
  }, [mainSidebarSections, hasMainSettingsPermission]);

  // register shortcuts actions for main sidebar.
  useKeyboardShortcuts(mainSidebarCommandBarActions);

  /**
   * this use effect will redirect the to the first available sidebar section
   * for the user. Internal user will go to get started if available
   * otherwise user is redirect to first sidebar nav item
   */
  React.useEffect(() => {
    if (pathname === '/') {
      // Client doesn't have access to CRM and Home (for now).
      // First accessible section for client would have to come from sidebarSections[1]
      // Or else redirection to first nav item won't be possible

      if (firstAccessibleNavItem) {
        // Determine the appropriate redirect path based on the first accessible navigation item.
        // If the first accessible item is an external link, instead of redirecting directly to the external link,
        // we redirect to the apps page using the app ID. This page provides information about the app,
        // and the user can then choose to visit the external link from there.
        const redirectPath = firstAccessibleNavItem.isExternalLink
          ? `/apps?id=${firstAccessibleNavItem.htmlId.replace(
              appNavItemIdPrefix,
              '',
            )}`
          : firstAccessibleNavItem.path;

        // Avoid redirecting to the root path again
        if (redirectPath !== '/') {
          history.push(redirectPath);
        }
      } else {
        console.error('No accessible sidebar section found for redirection');
      }
    }
  }, [firstAccessibleNavItem]);

  /**
   * This method allow to check if collapse item
   * should remain open or not. The logic is
   * to check if pathname root node is the
   * same as collapse childs path root node.
   * If so, the collapse should remains open,
   * otherwise it can collapse.
   * @param item
   */
  const getCollapseItemState = (item: NavItem) => {
    if (!item.subItems) return false;
    const pathRouteNode = pathname.split('/').at(1);

    return item.subItems.some(
      (subItem) => subItem.path.split('/')[1] === pathRouteNode,
    );
  };

  const accessibleSections = sidebarSections.filter((s) => s.canAccess);

  const isHelpCenterModalOpen = useAppSelector(
    (state) => state.ui.helpCenterModal.isOpen,
  );

  const openHelpCenterModal = () => {
    dispatch(toggleHelpCenterModalAction(true));
  };
  const showDivider = (index: number) =>
    index !== sidebarSections.length - 1 && // Divider not to be displayed for last section
    !isSettingsPage && // Settings page do not have dividers
    !isClient && // Client do not have multiple section, hence no divider
    sidebarSections[index + 1]?.canAccess; // There's next item, so we can display divider

  return (
    <div className={classes.root}>
      <div>
        {sidebarSections.map((section, index) => (
          <React.Fragment key={section.htmlId}>
            {section.canAccess && (
              <>
                {/* Displaying section title and action */}
                {accessibleSections.length > 1 && section.title && (
                  <SidebarListItem
                    htmlId={section.htmlId}
                    sectionTitle={section.title}
                  >
                    {section.icon}
                  </SidebarListItem>
                )}

                {/* Displaying section nav items */}
                {section.navItems.map(
                  (navItem) =>
                    navItem.canAccess &&
                    (navItem.subItems ? (
                      <CollapseItem
                        key={navItem.htmlId}
                        htmlId={navItem.htmlId}
                        label={navItem.label}
                        show={
                          navItem.shouldCollapse ||
                          getCollapseItemState(navItem)
                        }
                        IconElement={navItem.icon}
                        handleClick={getNavHandler({
                          path: navItem.path,
                          isCollapseItem: true,
                        })}
                        disabled={navItem.disabled}
                        tooltipTitle={navItem.tooltipTitle}
                      >
                        <div className={classes.nested}>
                          {navItem.subItems.map((subNavItem) =>
                            subNavItem.canAccess ? (
                              <SidebarListItem
                                key={subNavItem.htmlId}
                                htmlId={subNavItem.htmlId}
                                label={subNavItem.label}
                                selected={
                                  subNavItem?.selected ||
                                  pathname.includes(subNavItem.path)
                                }
                                handleClick={getNavHandler({
                                  path: subNavItem.path,
                                  isNonCoreApp: subNavItem.isNonCoreApp,
                                })}
                              />
                            ) : null,
                          )}
                        </div>
                      </CollapseItem>
                    ) : (
                      <SidebarListItem
                        key={navItem.htmlId}
                        htmlId={navItem.htmlId}
                        label={navItem.label}
                        tooltipTitle={navItem.tooltipTitle}
                        disabled={navItem.disabled}
                        selected={
                          navItem?.selected || pathname === navItem.path
                        }
                        handleClick={getNavHandler({
                          path: navItem.path,
                          isExternalLink: navItem.isExternalLink,
                          isNonCoreApp: navItem.isNonCoreApp,
                        })}
                        IconElement={navItem.icon}
                      >
                        {navItem.children}
                      </SidebarListItem>
                    )),
                )}
              </>
            )}
            {showDivider(index) && (
              <div className={classes.sidebarItemDivider}>
                <RowDivider mt={1} mb={1} />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>

      {!isSettingsPage && (
        <div>
          {!isClient && !isTrialing && (
            <SidebarListItem
              htmlId="sidebar-referrals"
              label="Referrals"
              selected={pathname === Constants.REFERRAL_PAGE_V2.path}
              handleClick={getNavHandler({
                path: Constants.REFERRAL_PAGE_V2.path,
              })}
              IconElement={Constants.REFERRAL_PAGE_V2.icon}
            />
          )}
          {!isClient && (
            <SidebarListItem
              htmlId="sidebar-help-center"
              label={HELP_CENTER_PAGE.label}
              selected={isHelpCenterModalOpen}
              handleClick={openHelpCenterModal}
              IconElement={Constants.HELP_CENTER_PAGE.icon}
            />
          )}

          <SidebarListItem
            htmlId="sidebar-settings"
            label="Settings"
            selected={pathname === Constants.PROFILE_PAGE.path}
            handleClick={() => {
              goToSettings();
            }}
            IconElement={MainSettingsIcon}
          />
          {!isClient && <ClientExperienceNavItem />}
        </div>
      )}
    </div>
  );
};

export default SidebarNav;
