import { PORTAL_DOMAINS, docsURL } from 'src/constants/linkConsts';

export const GET_STARTED_VIDEO_URL = `${PORTAL_DOMAINS.copilot}/university`;
export const API_DOCS_URL = `${docsURL}/reference/`;
export const JOIN_SLACK_COMMUNITY_URL = 'https://copilot.com/community';
export const PORTAL_UPDATES_URL = `${PORTAL_DOMAINS.copilot}/updates/`;
export const ENTERPRISE_INTRO_URL =
  'https://calendly.com/adamportal/enterprise-introduction';
export const COPILOT_GUIDE_URL = `${PORTAL_DOMAINS.copilot}/guide`;

export const SUPPORT_EMAIL = 'support@copilot.com';
export const SALES_EMAIL = 'sales@copilot.com';
export const FIND_AN_EXPERT_URL = 'https://www.copilot.com/experts';
export const REQUEST_AN_APP_URL =
  'https://copilotplatforms.typeform.com/to/yfp8IhME';
