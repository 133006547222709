import * as React from 'react';
import { ChannelType } from 'src/components/Channels/useChannelForm';
import {
  MessagesNotificationBadge,
  FilesNotificationBadge,
  FormsNotificationsBadge,
  InvoiceNotificationsBadge,
  InboxNotificationBadge,
  ContractNotificationsBadge,
} from '.';
import { AppNotificationBadge } from 'src/components/Sidebar/NotificationBadges/AppNotificationBadge';

export const useNotificationsBadge = (
  entityType: ChannelType | 'customApp',
  appId?: string,
) => {
  switch (entityType) {
    case 'messaging':
      return <MessagesNotificationBadge />;
    case 'files':
      return <FilesNotificationBadge />;
    case 'forms':
      return <FormsNotificationsBadge />;
    case 'contracts':
      return <ContractNotificationsBadge />;
    case 'billing':
      return <InvoiceNotificationsBadge />;
    case 'inbox':
      return <InboxNotificationBadge />;
    case 'customApp':
      return appId ? <AppNotificationBadge appId={appId} /> : null;
    default:
      return null;
  }
};
