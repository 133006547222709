import React from 'react';
import {
  createStyles,
  LinearProgress,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { GrayThinBorder } from 'src/theme/borders';
import {
  CloseIcon,
  SuccessIcon,
  ErrorIcon,
  AlertFilledIcon,
  UploadIcon,
} from 'src/components/Icons';
import { RootState } from 'src/store';
import { AlertVariant } from 'src/store/ui/types';
import {
  DividersAndCardBorders,
  GraySmall,
  BlackHeadings,
  WebLink,
  red,
  green,
  yellow,
  white,
} from 'src/theme/colors';
import BaseTypography from 'src/components/Text/BaseTypography';
import Button from 'src/components/Button';

const SNACKBAR_WIDTH = 384;

interface StyleProps {
  variant: AlertVariant;
}

/**
 * This method returns theme color of alert
 * depending on the severity type.
 */
const getAlertColor = (variant: AlertVariant, theme: Theme) => {
  switch (variant) {
    case AlertVariant.ERROR:
      return red;
    case AlertVariant.SUCCESS:
      return green;
    case AlertVariant.PROGRESS:
      return theme.palette.primary.main; // progress snackbar color scheme is based on primary color
    case AlertVariant.WARNING:
      return yellow;
    default:
      return '';
  }
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles<string, StyleProps>({
    root: {
      position: 'relative',
      padding: '2px 12px 2px 0',
      [theme.breakpoints.up('sm')]: {
        width: `${SNACKBAR_WIDTH}px`,
      },
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 16px)',
      },
      boxSizing: 'border-box',
      border: GrayThinBorder,
      borderLeft: 'none',
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      backgroundColor: 'white',
    },
    closeIcon: {
      height: '8px',
      width: '8px',
      color: GraySmall,
    },
    title: {
      // capitalize only the first word
      '&:first-letter ': {
        textTransform: 'capitalize',
      },
      color: BlackHeadings,
    },
    subTitle: {
      color: GraySmall,
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    alertIcon: {
      margin: 'auto 16px',
      '& svg': {
        height: '18px',
        width: '18px',
      },
    },
    message: {
      flex: 1,
    },
    progressBar: {
      backgroundColor: theme.palette.primary.main,
    },
    progressBarContainer: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: DividersAndCardBorders,
      flex: 1,
      maxWidth: '274px', // set directly to allow for progress value to change
    },
    alertBodyRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    alertActionButton: {
      minWidth: 'auto',
      color: WebLink,
    },
    alertLeftBorder: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      height: '100%',
      width: 2,
      backgroundColor: (props) => getAlertColor(props.variant, theme),
    },
    warningIcon: {
      color: `${yellow} !important`,
    },
    progressIcon: {
      height: 18,
      width: 18,
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      '& svg': {
        height: 10,
        color: `${white} !important`,
      },
      '& path': {
        strokeWidth: 2,
      },
    },
  }),
);

interface AlertSnackbarProps {
  variant: AlertVariant;
  alertMessage: string;
  onClose?: () => void;
  onAction?: () => void;
  actionLabel?: string;
}

export const AlertSnackbar: React.FC<AlertSnackbarProps> = ({
  variant,
  alertMessage,
  onClose,
  onAction,
  actionLabel,
}) => {
  const classes = useStyles({ variant });
  const progressValue = useSelector(
    (state: RootState) => state.ui.progressValue,
  );
  const isProgress = variant === AlertVariant.PROGRESS;

  const ProgessAlertIcon = React.memo(() => (
    <div className={classes.progressIcon}>
      <UploadIcon />
    </div>
  ));

  /**
   * This method render snackbar icon
   * depending on the severity type.
   */
  const getAlertIcon = () => {
    switch (variant) {
      case AlertVariant.ERROR:
        return <ErrorIcon />;
      case AlertVariant.SUCCESS:
        return <SuccessIcon />;
      case AlertVariant.PROGRESS:
        return <ProgessAlertIcon />;
      case AlertVariant.WARNING:
        return <AlertFilledIcon className={classes.warningIcon} />;
      default:
        return null;
    }
  };

  return (
    <Alert
      classes={{
        root: classes.root,
        icon: classes.alertIcon,
        message: classes.message,
      }}
      icon={getAlertIcon()}
    >
      <div className={classes.alertLeftBorder} />
      <div className={classes.alertBodyRow}>
        <BaseTypography fontType="13Medium" className={classes.title}>
          {isProgress || onAction ? alertMessage : variant}
        </BaseTypography>
        {onClose && (
          <CloseIcon className={classes.closeIcon} onClick={onClose} />
        )}
      </div>
      {isProgress && (
        <div className={classes.alertBodyRow}>
          <LinearProgress
            classes={{
              root: classes.progressBarContainer,
              bar: classes.progressBar,
            }}
            variant="determinate"
            value={progressValue}
          />
          <BaseTypography fontType="12Regular">{progressValue}%</BaseTypography>
        </div>
      )}

      {/* show alert message body only when it is a message alert snackbar () */}
      {alertMessage && !isProgress && !onAction && (
        <BaseTypography className={classes.subTitle}>
          {alertMessage}
        </BaseTypography>
      )}

      {onAction && (
        <Button
          htmlId="snackbar-action"
          onClick={onAction}
          variant="text"
          color="primary"
          className={classes.alertActionButton}
        >
          {actionLabel}
        </Button>
      )}
    </Alert>
  );
};
