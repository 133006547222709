import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const TrayFullOutline = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      d="M15 3.125H5C3.98438 3.125 3.32031 3.67188 3.125 4.6875L1.875 10.625V15C1.87644 15.4968 2.07445 15.9729 2.42577 16.3242C2.77709 16.6756 3.25316 16.8736 3.75 16.875H16.25C16.7468 16.8736 17.2229 16.6756 17.5742 16.3242C17.9256 15.9729 18.1236 15.4968 18.125 15V10.625L16.875 4.6875C16.6797 3.63281 15.9766 3.125 15 3.125Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M1.875 10.625H7.5"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12.5 10.625H18.125"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M7.5 10.625C7.5 11.288 7.76339 11.9239 8.23223 12.3928C8.70107 12.8616 9.33696 13.125 10 13.125C10.663 13.125 11.2989 12.8616 11.7678 12.3928C12.2366 11.9239 12.5 11.288 12.5 10.625"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M5.625 5.625H14.375"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M5 8.125H15"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
