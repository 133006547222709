import React from 'react';
import {
  Box,
  Divider,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
  Tooltip,
} from '@material-ui/core';
import classNames from 'classnames';
import { Action } from 'src/components/Dropdowns';
import * as Colors from 'src/theme/colors';
import Button from 'src/components/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    separator: {
      margin: theme.spacing(0, 2),
      height: 22,
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 1.5),
      },
    },
    tableActionBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1.5),
      },
    },
    actionBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    menuItemActive: {
      backgroundColor: Colors.HoverNonBorderBackground,
    },
    actionsWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    actionButton: {
      minWidth: 28,
    },
    delete: {
      color: Colors.red,
    },
  }),
);

interface PageToolbarActionProps {
  actions: Action[];
  createButton?: React.ReactNode;
  onActionButtonAnchorElSet?: (element: HTMLElement | null) => void;
}

export const PageToolbarAction: React.FC<Prettify<PageToolbarActionProps>> = ({
  actions,
  children,
  createButton,
  onActionButtonAnchorElSet,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box className={classes.actionBox} width="180">
      <Box className={classes.actionBox}>
        {children}
        {actions.length > 0 && (
          <Box className={classes.tableActionBox}>
            {actions.map((toolbarAction) => (
              <Tooltip
                key={toolbarAction.key}
                title={
                  toolbarAction.tooltipLabel ? toolbarAction.tooltipLabel : ''
                }
                disableHoverListener={!toolbarAction.isDisabled}
                placement="left"
              >
                <div>
                  <Button
                    key={toolbarAction.key}
                    variant="contained"
                    htmlId="btn-change-visibility"
                    color="secondary"
                    onClick={(event) => {
                      if (onActionButtonAnchorElSet) {
                        onActionButtonAnchorElSet(event.currentTarget); // event.currentTarget is the button element
                      }
                      toolbarAction.onClick(event);
                    }}
                    disabled={toolbarAction.isDisabled}
                    className={classNames(classes.actionButton, {
                      [classes.delete]: toolbarAction.isDelete,
                    })}
                  >
                    <div className={classes.actionsWrapper}>
                      {toolbarAction.icon}
                      {!isMobile && toolbarAction.name}
                    </div>
                  </Button>
                </div>
              </Tooltip>
            ))}
          </Box>
        )}
        {createButton && actions.length > 0 && (
          <Divider orientation="vertical" className={classes.separator} />
        )}
      </Box>
      {createButton}
    </Box>
  );
};
