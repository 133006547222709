export const GET_STARTED_REMOVE_CONFIRMATION =
  'If you’re ready to go you can permanently remove the Get started page. If you need any assistance in the future, shoot over an email to our team at';

export const ACCOUNT_SETTINGS_PAGE_DESCRIPTION = 'Manage your user account';
export const PROFILE_INFO_DESCRIPTION_TITLE = 'Personal profile';
export const ACCOUNT_SETTINGS_PAGE_SECURITY = 'Security';
export const GENERAL_SETTINGS_PAGE_DESCRIPTION =
  'Manage your general Copilot settings';
export const CUSTOMIZATION_SETTINGS_PAGE_DESCRIPTION =
  'Personalize your portal';
export const DOMAINS_SETTINGS_PAGE_DESCRIPTION =
  'Manage custom domains for your portal and email notifications';
export const TEAMS_SETTINGS_PAGE_DESCRIPTION = 'Manage your team';
export const API_SETTINGS_PAGE_DESCRIPTION =
  'Manage your API keys and Webhooks';
export const APP_SETTINGS_PAGE_DESCRIPTION = 'Manage your integrations';
export const PAYMENTS_SETTINGS_PAGE_DESCRIPTION = 'Manage your payment methods';
export const MODULE_MANAGEMENT_SETTINGS_PAGE_DESCRIPTION =
  'Control which modules are enabled in your portal';
export const MODULE_MANAGEMENT_DESCRIPTION =
  'Specify which modules and extensions are enabled in your portal and update their names, icons, and sort order for the client experience.';
export const FILE_SETTINGS_PAGE_DESCRIPTION = 'Manage Files preferences';
export const NOTIFICATIONS_SETTINGS_PAGE_DESCRIPTION =
  'Manage your notification preferences';
export const MESSAGING_SETTINGS_PAGE_DESCRIPTION = 'Manage messaging settings';
export const BILLING_SETTINGS_PAGE_DESCRIPTION =
  'Connect your bank so you can get paid';
export const PUBLIC_WEBSITE_SETTINGS_PAGE_DESCRIPTION =
  'Manage marketing website settings';

export const PORTAL_INFO_DESCRIPTION_TITLE = 'Brand name';
export const PORTAL_INFO_DESCRIPTION_TEXT = [
  'Your brand name is the name your customers use to refer to you. It doesn’t need to be your legal business name.',
];

export const PORTAL_IMPROVED_IMAGE_DESCRIPTION_TITLE = 'Image assets';
export const PORTAL_IMAGE_SECTION_DESCRIPTION_TEXT = [
  'Your icon and login images are used in various places to customize the experience for you and your clients',
];

export const PORTAL_COLOR_SECTION_DESCRIPTION_TITLE = 'Primary color';
export const PORTAL_COLOR_SECTION_DESCRIPTION_TEXT = [
  'The primary color sets the color scheme of your portal. We automatically use variants of your primary colors for forms, tables, and buttons to maintain consistency.',
];

export const ADVANCED_BRANDING_COLOR_SECTION_DESCRIPTION_TITLE = 'Brand colors';
export const ADVANCED_BRANDING_COLOR_SECTION_DESCRIPTION_TEXT = [
  'Customize the colors in your portal. Note that these colors only affect your portal and not the internal user experience. The accent is used for buttons, tags, and other UI elements. ',
];

export const PORTAL_CLIENT_BRANDING_SECTION_TITLE = 'Client branding';
export const PORTAL_CLIENT_BRANDING_SECTION_DESCRIPTION_TEXT = [
  'If you turn this on, clients with associated companies will see their company name and icon in the sidebar instead of yours. This option is disabled if you have companies turned off.',
];

export const PORTAL_ACCESS_SECTION_DESCRIPTION_TITLE = 'Portal access';

export const PORTAL_META_SECTION_DESCRIPTION_TITLE = 'Website meta information';
export const PORTAL_META_SECTION_DESCRIPTION_TEXT = [
  'Title and description help you define how your portal shows up on search engine.The company social media links that you fill out, will appear as social icons in the website footer.',
];

export const ALLOW_CLIENT_SIGNUP_DESCRIPTION_TITLE =
  'Allow client user direct sign up';
export const ALLOW_CLIENT_SIGNUP_DESCRIPTION_TEXT = [
  'When this is turned on, clients can create account themselves on your portal sign-up page.',
];

export const ALLOW_CLIENT_INVITE_DESCRIPTION_TITLE =
  'Allow client users to invite team members';

export const ALLOW_CLIENT_INVITE_DESCRIPTION_TEXT = [
  'When this is enabled, clients can invite users to their team on the Settings/Team page. Clients created like this are automatically assigned to the same company.',
];

export const ENABLE_SEND_CLIENT_EMAILS_TITLE =
  'Send clients email notifications in uninvited status';

export const ENABLE_SEND_CLIENT_EMAILS_DESCRIPTION =
  'When enabled, clients will be notified when you take actions (e.g. sending an invoice/contract or sharing a file) regardless of their invited status';

export const ENABLE_COMPANIES_DESCRIPTION_TITLE = 'Enable companies';
export const ENABLE_COMPANIES_DESCRIPTION_TEXT = [
  'When enabled, a company field shows on the Clients Page and you can group clients by company. You can also create company-level channels (for Messaging, Files, and Partner Apps) and assign invoices and subscriptions to companies.',
];

export const ENABLE_GOOGLE_SIGN_IN_TITLE = 'Sign in with Google';
export const ENABLE_GOOGLE_SIGN_IN_DESCRIPTION_TEXT = [
  "Allow client's to login through Google's SSO feature.",
];

export const DEFAULT_CHANNEL_FOR_CLIENTS_TITLE =
  'Default channels for clients with companies';
export const DEFAULT_CHANNEL_FOR_CLIENTS_TEXT = [
  'When a client is created with an associated company, specify if the channels that are created (for Messaging and Files) are individual client channels or company channels. Note that with company channels, any client associated to the company has access.',
];

export const ABOUT_US_HEADER_CONTENT_DESCRIPTION_TITLE = 'Header';
export const ABOUT_US_HEADER_CONTENT_DESCRIPTION_TEXT = [
  'Select the background image, title and subtitle that you wish to use for the Home Page Header.',
];

export const ABOUT_US_SERVICE_DESCRIPTION_TITLE = 'Services';
export const ABOUT_US_SERVICE_DESCRIPTION_TEXT = [
  'Tell customers about the services you offer with a short title and a description.',
];

export const INTRODUCTION_DESCRIPTION_TITLE = 'Introduction';
export const INTRODUCTION_DESCRIPTION_TEXT = [
  'Set the introduction paragraph for your services.',
];

export const BUSINESS_INFORMATION_DESCRIPTION_TITLE = 'Business information';
export const BUSINESS_INFORMATION_DESCRIPTION_TEXT = [
  'Optionally make your business’ service days and hours, and address available to customers.',
];

export const CONTACT_DETAILS_DESCRIPTION_TITLE = 'Contact details';
export const CONTACT_DETAILS_DESCRIPTION_TEXT = [
  'Optionally make your contact information available to customers who want to reach you.',
];

export const CONTACT_US_HEADER_DESCRIPTION_TITLE = 'Header';
export const CONTACT_US_HEADER_DESCRIPTION_TEXT = [
  'Select the background image, title and subtitle that you wish to use for the Home Page Header.',
];

export const BANKING_SETTINGS_DESCRIPTION_TITLE = 'Connect your bank';
export const BANKING_SETTINGS_DESCRIPTION_TEXT = [
  'We use Stripe as our payments processing partner.',
];

export const BANKING_DEFAULTS_DESCRIPTION_TITLE = 'Defaults';
export const BANKING_DEFAULTS_DESCRIPTION_TEXT = [
  'Set your defaults to save time',
];

export const BILLING_SETTINGS_DESCRIPTION_TITLE = 'Payment method';
export const BILLING_SETTINGS_DESCRIPTION_TEXT = ['Setup your payment method'];

export const INVOICES_SETTINGS_DESCRIPTION_TITLE = 'Invoices';
export const INVOICES_SETTINGS_DESCRIPTION_TEXT = ['Descriptions'];

export const APPOINTMENT_SCHEDULING_SETTINGS_DESCRIPTION_TITLE =
  'Appointment scheduling';
export const APPOINTMENT_SCHEDULING_SETTINGS_DESCRIPTION_TEXT = [
  'Enabling appointment scheduling will show a “Schedule call” button on your website where users can schedule time to speak with you at the link you provide.',
];

export const PORTAL_BADGE_SETTING_DESCRIPTION_TITLE =
  'Powered by Copilot badge';
export const PORTAL_BADGE_SETTING_DESCRIPTION_TEXT = [
  'The Powered by Copilot badge shows on the sign in and sign up pages of your portal.',
];

export const SOCIAL_MEDIA_LINKS_DESCRIPTION_TITLE = 'Social Media Links';
export const SOCIAL_MEDIA_LINKS_DESCRIPTION_TEXT = [
  'The social media profiles will show as icons in the footer of your website.',
];

export const BASIC_INFORMATION_DESCRIPTION_TITLE = 'Company information';
export const BASIC_INFORMATION_DESCRIPTION_TEXT = [
  'Setup your company information.',
];

export const DEFAULT_LEAD_DESCRIPTION_TITLE = 'Default lead';
export const DEFAULT_LEAD_DESCRIPTION_TEXT = [
  'When clients are invited, the internal user who does the invite is assigned as the lead. However, in some cases another lead needs to be chosen, including (1) when a client user signs up directly and (2) when an internal user who is a lead is deleted.',
];
export const DEFAULT_VISIBILITY_DESCRIPTION_TITLE = 'Default assignees';
export const DEFAULT_VISIBILITY_DESCRIPTION_TEXT = [
  'When a new client user is created, the internal users selected below are set as assignees. Admins are always assignees.',
];
export const ANALYTICS_SECTION_DESCRIPTION_TITLE = 'Google Analytics';
export const ANALYTICS_SECTION_DESCRIPTION_TEXT = [
  'Get powerful insights about the use of your portal',
];

export const MESSAGING_PREFERENCES_TITLE = 'Preferences';
export const MESSAGING_PREFERENCES_TEXT = ['Personalize the Messages App.'];

export const MESSAGING_SETTINGS_DESCRIPTION_TITLE = 'Automatic welcome message';
export const MESSAGING_SETTINGS_DESCRIPTION_TEXT = [
  'Message that is sent to all new sign ups to your portal.',
];

export const MODULE_MANAGEMENT_DESCRIPTION_TITLE = 'Control center';
export const MODULE_MANAGEMENT_DESCRIPTION_TEXT = [];

export const SECURITY_SECTION_DESCRIPTION_TITLE = 'Security';
export const SECURITY_SECTION_DESCRIPTION_TEXT = [
  'Configure authentication preferences and personal privacy settings.',
];

export const RESET_SECTION_DESCRIPTION_TITLE = 'Password';
export const RESET_SECTION_DESCRIPTION_TEXT = [
  'We recommend changing passwords every 3 months.',
];

export const MFA_SETUP_TITLE = 'Two-factor authentication setup';
export const MFA_SETUP_DESCRIPTION =
  'Scan the code into your authenticator application, then proceed to the next step.';

export const SETUP_MFA_DESCRIPTION_TITLE = 'Enable two-factor authentication';
export const SETUP_MFA_DESCRIPTION_TEXT = [
  "We'll ask for a verification code everytime you log in.",
];

export const LOGOUT_SECTION_DESCRIPTION_TITLE = 'Log out';
export const LOGOUT_SECTION_DESCRIPTION_TEXT = [
  'You will be logged out of this session and will have to log back in.',
];

export const PORTAL_URL_REQUIRED_ERROR = 'Portal URL is required.';
export const PORTAL_URL_MUST_BE_MORE_THAN_4 =
  'Your portal URL must be > 4 characters.';
export const PORTAL_URL_MUST_BE_LESS_THAN_30 =
  'Your portal URL must be < 30 characters.';

export const SIGNOUT_URL_TITLE = 'Sign-out destination';
export const SIGNOUT_URL_DESCRIPTION =
  'This is the location clients are linked to when they sign out of your portal. Most companies link to their marketing website.';

export const NOTIFICATIONS_ASSIGNMENT_PREFERENCE_SECTION_DESCRIPTION_TITLE =
  'Assignment preference';
export const NOTIFICATIONS_ASSIGNMENT_PREFERENCE_SECTION_DESCRIPTION_TEXT = [
  'Choose whether you receive notifications when you are assigned to a client, or only if you are lead.',
];

export const PRODUCT_NOTIFICATION_TITLE = 'Product notifications';
export const PRODUCT_NOTIFICATION_TEXT = [
  'Receive product notifications in your portal.',
];

export const RECEIVE_EMAIL_NOTIFICATION_TITLE = 'Email';
export const RECEIVE_EMAIL_NOTIFICATION_TEXT = ['Receive email notifications.'];

export const EMAIL_DIGEST_NOTIFICATION_TITLE = 'Email digest';
export const EMAIL_DIGEST_NOTIFICATION_TEXT = [
  'Receive a daily or weekly email that summarizes activity in your portal. The digest is skipped if there has not been any activity.',
];

export const NOTIFICATION_METHODS_SECTION_DESCRIPTION_TITLE =
  'Notification methods';

export const SWITCH_TO_NEW_PLAN = `I’d like to switch to one of the new pricing plans.`;

export const API_UPGRADE_CALLOUT = {
  TITLE: 'API',
  DESCRIPTION: 'Manage your API and webhooks effectively',
};

export const CLIENT_FONTFAMILY_BRANDING_DESCRIPTION_TITLE = 'Font';
export const CLIENT_FONTFAMILY_BRANDING_DESCRIPTION_TEXT = [
  'By default we use “Inter”, but you can switch to a different font below. Note that this font only affects your portal and not the internal user experience',
];

export const CLIENT_META_DATA_DESCRIPTION_TITLE = 'Meta data';
export const CLIENT_META_DATA_DESCRIPTION_TEXT = [
  'The meta title and description are surfaced in search engine results, link previews, and browser tab names.',
];
