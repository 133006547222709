import React, { useContext, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { CONTRACTS_PAGE, ERROR_CONSTS } from 'src/constants';
import history from 'src/history';
import { useContractBuilderAndSignerForm } from 'src/hooks/useContractBuilderAndSigner';
import { FormPage } from 'src/components/Page/FormPage';
import {
  BreadCrumbBase,
  BreadCrumbItem,
} from 'src/components/UI/BreadCrumbBase';
import {
  ContractBuilderContext,
  ContractFile,
} from 'src/context/contractBuilderContext';
import {
  ChangeEsignFlowStateAction,
  ResetESignToInitialValues,
  SetActiveComponent,
  StartSavingComponents,
  StopSavingComponents,
  UpdateClientSignatureFlowStatus,
  loadContractPages,
  setPageComponents,
} from 'src/store/signaturePage/actions';
import {
  filterSignatureComponentsByCreationMethod,
  getComponentNameByComponentType,
  getComponentTypeUsingContractType,
  getContractInputValueUsingComponentType,
  getPageComponentsRelativeToPage,
  hasValidClientContractComponents,
  htmlToBase64Image,
} from 'src/utils/ContractUtils';
import { alertSnackbar, openAuthModal } from 'src/store/ui/actions';
import {
  AddContractTemplateInput,
  ApiTags,
  FieldType,
  InputType,
  appAPI,
  useAddContractTemplateMutation,
  useGetContractTemplatesQuery,
  useGetContractsQuery,
  useShareContractTemplateMutation,
  useSignContractMutation,
  useUpdateContractTemplateMutation,
} from 'src/services/api';
import { ContractPageTabs } from './ContractsTablePage';
import { Contract, ContractField } from 'src/entities/Contract';
import { RouteContext } from 'src/context';
import {
  ESignatureFlowStatus,
  SignaturePageComponent,
} from 'src/store/signaturePage/types';
import { scrollToComponent } from '../Signature/ClientSignatureIndicatorPanel';
import { useSignatureRequestComponent } from '../Signature/SignatureSidebar/useSignatureRequestComponent';
import { ContractDetailsPageView } from './ContractDetailsPage';
import { AuthModal } from '../Auth';
import { AUTH_STATES } from 'src/constants/authConsts';
import { usePageTitle } from 'src/hooks/usePageTitle';
import { useGetContractInputValue } from 'src/hooks/useGetContractInputValue';
import { loadClientCustomFields } from 'src/store/clients/actions';
import { GetCurrentUserFromState } from 'src/store/storeUtils';
import { useAppSelector } from 'src/hooks/useStore';
import { notify } from 'src/clients/ApiService';

export interface ContractTemplateForm {
  title: string;
  fileKey: string;
  recipientId?: string;
}

type ContractSubmitPageQueryParams = {
  contractId: string;
  templateId: string;
};

/**
 * This component represents the contract management form, which allows internal user to create
 * contract template and client to sign the contract.
 *
 * @returns {JSX.Element} The JSX element rendering the contract management and signing form.
 */
export const ContractBuilderAndSignerForm = () => {
  const dispatch = useDispatch();

  const {
    isClient,
    pageComponents,
    userId,
    signatureFlowStatus,
    isFormPageChanged,
    pageImages,
    companies,
    customFields,
    currentUser,
    isClientCustomFieldsLoading,
    isClientCustomFieldsLoaded,
  } = useAppSelector(
    (state) => ({
      isClient: state.user.isClient,
      pageComponents: state.signaturePage.pageComponents,
      userId: state.user.id,
      signatureFlowStatus: state.signaturePage.signatureFlowStatus,
      users: state.users.users,
      isFormPageChanged: state.ui.isFormPageChanged,
      pageImages: state.signaturePage.pageImages,
      companies: state.clients.activeCompanies,
      customFields: state.clients.clientCustomFields?.additionalFields,
      currentUser: GetCurrentUserFromState(state),
      isClientCustomFieldsLoading: state.clients.isClientCustomFieldsLoading,
      isClientCustomFieldsLoaded: state.clients.isClientCustomFieldsLoaded,
    }),
    shallowEqual,
  );

  const { handleRequestReceiverInfo } = useSignatureRequestComponent();

  const { query } = useContext(RouteContext);
  const { contractId, templateId } = query as ContractSubmitPageQueryParams;
  const { pageTitle } = usePageTitle(CONTRACTS_PAGE.label, 'contracts');

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Indicates if the client has filled all the signature fields and is on the end signature screen on mobile devices
  const isClientOnEndSignatureOnMobile =
    isClient &&
    isSmallScreen &&
    signatureFlowStatus === ESignatureFlowStatus.END_SIGNAURE;

  const { data: contracts } = useGetContractsQuery(
    {
      recipientId: userId,
    },
    {
      skip: !isClient,
    },
  );

  const contractIdToContractMap = useMemo(
    () =>
      contracts?.reduce((acc, contract) => {
        acc[contract.id] = contract;
        return acc;
      }, {} as Record<string, Contract>),
    [contracts],
  );

  const { data: templates } = useGetContractTemplatesQuery();

  const [addContractTemplate] = useAddContractTemplateMutation();
  const [updateContractTemplate] = useUpdateContractTemplateMutation();
  const [signContract] = useSignContractMutation();
  const [createContract] = useShareContractTemplateMutation();

  const [isOneOffContract, setIsOneOffContract] = useState(false);
  const [pageScalerFactor, setPageScalerFactor] = useState(1);

  // State to hold information about the uploaded PDF file:
  // - 'fileKey' - The key to access the file from S3.
  // - 'pageFileKeys' - An array of keys for page images saved in S3.
  const [contractPdfInfo, setContractPdfInfo] = useState<ContractFile>({
    fileKey: '',
    pageFileKeys: [],
    identityId: '',
  });

  const [isFontLoaded, setIsFontLoaded] = useState(false);

  // trying to load fonts like we do in customFonts.css created a race condition where
  // the viewer would load before the font was loaded, causing the viewer to show up
  // incorrectly. This useEffect will ensure the font is loaded before use later in the
  // contract template builder.
  useEffect(() => {
    if (!isFontLoaded) {
      const font = new FontFace(
        'Cedarville Cursive',
        'url(https://fonts.gstatic.com/s/cedarvillecursive/v17/yYL00g_a2veiudhUmxjo5VKkoqA-B_nuIrpw4cNOTw.woff2)',
      );
      document.fonts.add(font);
      font.load().then(() => {
        setIsFontLoaded(true);
      });
    }
  }, []);

  // State to hold a reference to the placeholder element.
  // This element is used as an anchor for positioning signature popups.
  const [placeholderElement, setPlaceholderElement] =
    useState<HTMLButtonElement | null>(null);

  const [contractFormValues, setContractFormValues] = useState<{
    title: string;
    fileKey: string;
    pageComponents: SignaturePageComponent[];
  }>({
    title: '',
    fileKey: '',
    pageComponents: [],
  });

  const navigateToContractsPage = (tab: ContractPageTabs) =>
    history.push(`${CONTRACTS_PAGE.path}?view=${tab}`);

  const navigateToContractDetailsPage = (id: string) =>
    history.push(
      `${CONTRACTS_PAGE.path}/details?templateId=${id}&view=${ContractDetailsPageView.SubmissionRequests}`,
    );

  /**
   * Handles the save action for creating a contract template.
   * Redirects to the contracts table page upon successful template creation.
   * @param {ContractTemplateForm} values - The form values containing template information.
   */
  const handleCreateContractTemplate = async (values: ContractTemplateForm) => {
    const filteredPageComponents = filterSignatureComponentsByCreationMethod(
      pageComponents,
      !isOneOffContract,
    );

    const areAllComponentsAssignedToPage = filteredPageComponents.every(
      (comp) => Boolean(comp.page),
    );

    if (!areAllComponentsAssignedToPage) {
      dispatch(
        alertSnackbar({
          errorMessage: ERROR_CONSTS.SignatureComponentMissingPage,
        }),
      );
      return;
    }

    // Check if there is at least one valid client signature input to create a template
    if (!hasValidClientContractComponents(filteredPageComponents)) {
      dispatch(
        alertSnackbar({
          errorMessage: ERROR_CONSTS.AddComponentForClient,
        }),
      );
      return;
    }

    const contractFieldPromises: Array<Promise<ContractField>> =
      filteredPageComponents.map(async (p) => ({
        id: p.key,
        label: p.label,
        // TODO: remove these nullish coallesing operators when we fix the types
        page: p.page ?? 0,
        type: p.fieldType!,
        inputType: p.inputType,
        isOptional: Boolean(p.isOptional),
        value: getContractInputValueUsingComponentType(p),
        autoFillField: p.autoFillField,
        // The bounds are stored relative to the current pdf page size.
        // We need to convert them to ideal pdf page size before saving them to the database.
        // This is done by multiplying the bounds with the page scaler factor.
        // For ideal case i.e when the pdf width is 680px the page scaler factor is 1.
        // So it will not affect the bounds in that case.
        bounds: {
          x: p.xPosition * pageScalerFactor,
          y: p.yPosition * pageScalerFactor,
          height: p.height * pageScalerFactor,
          width: p.width * pageScalerFactor,
          pageWidth: p.pageWidth ?? 0,
          pageHeight: p.pageHeight ?? 0,
        },
      }));

    const allContractFields = await Promise.all(contractFieldPromises);

    // Prepare the request body for creating the contract template
    const requestBody: AddContractTemplateInput = {
      name: values.title,
      fileKey: contractPdfInfo.fileKey,
      identityId: contractPdfInfo.identityId,
      fields: allContractFields,
    };

    if (isOneOffContract) {
      if (values.recipientId) {
        const response = await createContract({
          ...requestBody,
          recipientId: values.recipientId,
          creationMode: 'oneOff',
        })
          .unwrap()
          .catch((e) => e.data);

        if ('error' in response) {
          notify({
            status: 'error',
            errorMessage: 'Something went wrong while sending the contract',
            dispatch,
          });
          return;
        }
        notify({
          status: 'success',
          successMessage: 'Contract shared successfully.',
          dispatch,
        });
        navigateToContractsPage(ContractPageTabs.Submissions);
        dispatch(appAPI.util.invalidateTags([ApiTags.contracts]));
      }
    } else {
      const result = await addContractTemplate(requestBody);

      if ('data' in result) {
        navigateToContractDetailsPage(result.data.id);
      }
    }
  };

  const handleUpdateContractTemplate = async (values: ContractTemplateForm) => {
    const areAllComponentsAssignedToPage = pageComponents.every((comp) =>
      Boolean(comp.page),
    );

    if (!areAllComponentsAssignedToPage) {
      dispatch(
        alertSnackbar({
          errorMessage: ERROR_CONSTS.SignatureComponentMissingPage,
        }),
      );
      return;
    }
    // Check if there is at least one valid client signature input to create a template
    if (!hasValidClientContractComponents(pageComponents)) {
      dispatch(
        alertSnackbar({
          errorMessage: ERROR_CONSTS.AddComponentForClient,
        }),
      );
      return;
    }

    const selectedTemplate = templates?.find((t) => t.id === templateId);

    if (!selectedTemplate) return;

    const contractFieldPromises: Array<Promise<ContractField>> =
      pageComponents.map(async (p) => ({
        id: p.key,
        label: p.label,
        page: p.page ?? 0,
        type: p.fieldType!,
        inputType: p.inputType,
        isOptional: Boolean(p.isOptional),
        value: getContractInputValueUsingComponentType(p),
        autoFillField: p.autoFillField,
        bounds: {
          x: p.xPosition * pageScalerFactor,
          y: p.yPosition * pageScalerFactor,
          height: p.height * pageScalerFactor,
          width: p.width * pageScalerFactor,
          pageWidth: p.pageWidth ?? 0,
          pageHeight: p.pageHeight ?? 0,
        },
      }));

    const allContractFields = await Promise.all(contractFieldPromises);

    const requestBody = {
      id: templateId,
      body: {
        ...selectedTemplate,
        name: values.title,
        fields: allContractFields,
      },
    };

    const updateContractReponse = await updateContractTemplate(requestBody);
    if ('data' in updateContractReponse) {
      const { data } = updateContractReponse;
      setContractFormValues({
        title: data.name,
        fileKey: data.fileKey,
        pageComponents,
      });
    }
  };

  /**
   * This method initiates the signing flow for clients on mobile devices
   */
  const startSigningFlowOnMobile = () => {
    dispatch(ChangeEsignFlowStateAction({ startedEsignFlow: true }));
    dispatch(
      UpdateClientSignatureFlowStatus(ESignatureFlowStatus.FILL_SIGNATURE),
    );
    const clientComponents = pageComponents.filter((c) =>
      c.componentType.startsWith('request'),
    );

    // Select the first client input for signing
    if (clientComponents.length > 0) {
      const firstClientComponent = clientComponents.at(0);
      if (firstClientComponent) {
        dispatch(SetActiveComponent(firstClientComponent.key));
        handleRequestReceiverInfo(firstClientComponent);
        scrollToComponent(firstClientComponent.key);
      }
    }
  };

  /**
   * If the e-signature flow status is START_SIGNATURE, initiates the signing flow for clients on mobile devices.
   * If the e-signature flow status is END_SIGNATURE, handles the completion of client signature request.
   */
  const handleClientAction = async () => {
    if (signatureFlowStatus === ESignatureFlowStatus.START_SIGNATURE) {
      startSigningFlowOnMobile();
      return;
    }
    if (signatureFlowStatus === ESignatureFlowStatus.END_SIGNAURE) {
      dispatch(StartSavingComponents());

      const initialPageComponentsMap = contractFormValues.pageComponents.reduce(
        (acc, component) => {
          acc[component.key] = component;
          return acc;
        },
        {} as Record<string, SignaturePageComponent>,
      );

      // Restore the adjusted dimensions and position of the page components
      // for the mobile sign contract flow back to their original state
      // before sending the values to the API.
      const components = pageComponents.map((component) => ({
        ...component,
        xPosition: initialPageComponentsMap[component.key]?.xPosition,
        yPosition: initialPageComponentsMap[component.key]?.yPosition,
        width: initialPageComponentsMap[component.key]?.width,
        height: initialPageComponentsMap[component.key]?.height,
      }));

      // Signature components are placed relative to the entire PDF, but
      // while generating the PDF in the backend, we require the y position of the
      // component relative to the page it is placed on.
      const componentsRelativeToPdfPage = await getPageComponentsRelativeToPage(
        pageImages,
        components,
      );
      const selectedContract = contracts?.find((c) => c.id === contractId);
      if (!selectedContract) return;

      let allContractFields: ContractField[] = [];
      if (componentsRelativeToPdfPage.length > 20) {
        // break off the components into chunks of 20 to stop the webapp from crashing due to overextended resource use.
        const toChunk = componentsRelativeToPdfPage;
        while (toChunk.length > 0) {
          const chunk = toChunk.splice(0, 20);
          const chunkPromises = mapComponentsToPromises(chunk);
          const fields = await Promise.all(chunkPromises);
          allContractFields.push(...fields);
        }
      } else {
        const contractFieldPromises = mapComponentsToPromises(
          componentsRelativeToPdfPage,
        );
        allContractFields = await Promise.all(contractFieldPromises);
      }

      const requestBody = {
        id: selectedContract.id,
        body: {
          ...selectedContract,
          fields: allContractFields,
        },
      };

      await signContract(requestBody);
      dispatch(StopSavingComponents());
    }
  };

  const mapComponentsToPromises = (
    components: SignaturePageComponent[],
  ): Array<Promise<ContractField>> => {
    return components.map(async (p) => ({
      id: p.key,
      label: p.label,
      page: p.page ?? 0,
      type: p.fieldType!,
      inputType: p.inputType,
      isOptional: Boolean(p.isOptional),
      value: getContractInputValueUsingComponentType(p),
      // In the final step, where the client signs the contract,
      // we convert all the signature component HTML to Base64 images.
      // This is done to ensure that the generated PDF has signature component
      // images with the same dimensions as in the preview.
      encodedImgValue: p.encodedImgValue || (await htmlToBase64Image(p)),
      autoFillField: p.autoFillField,
      bounds: {
        x: p.xPosition,
        y: p.yPosition,
        height: p.height,
        width: p.width,
        pageWidth: p.pageWidth ?? 0,
        pageHeight: p.pageHeight ?? 0,
      },
    }));
  };

  const handleSave = async (values: ContractTemplateForm) => {
    if (!isClient) {
      if (templateId) {
        await handleUpdateContractTemplate(values);
        return;
      }
      await handleCreateContractTemplate(values);
      return;
    }

    await handleClientAction();
  };

  const onCancel = () => {
    // Edit contract template
    if (templateId) {
      dispatch(setPageComponents(contractFormValues.pageComponents));
      return;
    }

    if (isClientOnEndSignatureOnMobile) {
      startSigningFlowOnMobile();
      return;
    }

    // Create contract template
    const redirectionPath = `${CONTRACTS_PAGE.path}?view=${ContractPageTabs.Templates}`;
    if (isFormPageChanged) {
      dispatch(
        openAuthModal(AUTH_STATES.CONFIRM_PAGE_CHANGE_MODAL, redirectionPath),
      );
      return;
    }

    history.push(redirectionPath);
  };

  React.useEffect(() => {
    if (!isClientCustomFieldsLoading && !isClientCustomFieldsLoaded) {
      dispatch(loadClientCustomFields());
    }
  }, [isClientCustomFieldsLoading, isClientCustomFieldsLoaded]);

  const selectedContract = contractIdToContractMap
    ? contractIdToContractMap[contractId]
    : undefined;

  const getInitialSignatureInputValue = useGetContractInputValue(
    selectedContract,
    currentUser,
    companies,
    customFields,
  );

  /**
   * For client users, this effect retrieves contract info based on the contractId
   * available in the query parameters. It then loads the contract page images using the
   * "pageKeys" and "identityId" from the selected contract data.
   */
  useEffect(() => {
    if (selectedContract) {
      const formattedContractFields: Array<SignaturePageComponent> = (
        selectedContract.fields || []
      )
        .map((field: ContractField) => {
          const value = getInitialSignatureInputValue(field);

          // Remove AutoFill inputs for which the client doesn't have a value
          if (!value && field.inputType === InputType.AutoFill) return null;

          return {
            fieldType: field.type,
            inputType: field.inputType,
            autoFillField: field.autoFillField,
            xPosition: field.bounds.x,
            yPosition: field.bounds.y,
            label: field.label,
            receiverUserId: selectedContract.recipientId,
            height: field.bounds.height,
            width: field.bounds.width,
            isAgree: false,
            key: field.id,
            resizedHeight: field.bounds.resizedHeight,
            resizedWidth: field.bounds.resizedWidth,
            value,
            page: field.page,
            componentType: getComponentTypeUsingContractType(
              field,
              field.inputType === InputType.Client,
            ),
            name: getComponentNameByComponentType(field),
            isOptional: field.isOptional,
            // For image based component the text inputed by user to generate
            // signature image is stored in the "value" key
            imageTextValue:
              field.type === FieldType.Signature ||
              field.type === FieldType.Initials
                ? field.value
                : '',
            encodedImgValue: field.encodedImgValue,
          };
        })
        .filter(Boolean); // Filter out null values

      setContractFormValues({
        title: selectedContract.name,
        fileKey: selectedContract.fileUrl,
        pageComponents: formattedContractFields,
      });
      setContractPdfInfo({
        pageFileKeys: selectedContract.pageKeys,
        fileKey: selectedContract.fileUrl,
        identityId: selectedContract.identityId,
      });
    }
  }, [selectedContract, customFields]);

  /**
   * For internal users, this effect retrieves contract info based on the templateId
   * available in the query parameters. It then loads the contract page images using the
   * "pageKeys" and "identityId" from the selected template data.
   */
  useEffect(() => {
    if (!templateId) return;
    const selectedTemplate = templates?.find((t) => t.id === templateId);
    if (selectedTemplate) {
      const formattedContractFields: SignaturePageComponent[] = (
        selectedTemplate.fields || []
      ).map((field: ContractField) => ({
        fieldType: field.type,
        inputType: field.inputType,
        autoFillField: field.autoFillField,
        name: getComponentNameByComponentType(field),
        xPosition: field.bounds.x,
        yPosition: field.bounds.y,
        label: field.label,
        height: field.bounds.height,
        width: field.bounds.width,
        isAgree: false,
        key: field.id,
        resizedHeight: field.bounds.resizedHeight,
        resizedWidth: field.bounds.resizedWidth,
        value:
          field.type === FieldType.Signature ||
          field.type === FieldType.Initials
            ? field.encodedImgValue
            : field.value,
        page: field.page,
        componentType: getComponentTypeUsingContractType(
          field,
          field.inputType !== InputType.Fixed,
        ),
        isOptional: field.isOptional,
        // For image based component the text inputed by user to generate
        // signature image is stored in the "value" key
        imageTextValue:
          field.type === FieldType.Signature ||
          field.type === FieldType.Initials
            ? field.value
            : '',
      }));

      setContractFormValues({
        title: selectedTemplate.name,
        fileKey: selectedTemplate.fileKey,
        pageComponents: formattedContractFields,
      });
      setContractPdfInfo({
        pageFileKeys: selectedTemplate.pageKeys,
        fileKey: selectedTemplate.fileKey,
        identityId: selectedTemplate.identityId,
      });
    }
  }, [templateId, templates]);

  /* When the user is redirected to other pages (for example, when the contract creation flow is canceled or completed),
   * we want the signaturePage reducer to reset to its initial state.
   * This ensures that when they return to create a new contract, they begin with a clean slate.
   */
  useEffect(() => {
    if (contractPdfInfo?.pageFileKeys?.length) {
      dispatch(
        loadContractPages(contractPdfInfo, contractFormValues.pageComponents),
      );
    }

    return () => {
      dispatch(ResetESignToInitialValues());
    };
  }, [contractPdfInfo]);

  // Define the states and methods to be passed down using the `ContractBuilderContext`.
  const contextValue: ContractBuilderContext = useMemo(
    () => ({
      placeholderElement,
      contractPdfInfo,
      isOneOffContract,
      pageScalerFactor,
      setPlaceholderElement,
      setContractPdfInfo,
      setIsOneOffContract,
      setPageScalerFactor,
    }),
    [placeholderElement, contractPdfInfo, isOneOffContract, pageScalerFactor],
  );

  const breadcrumbItems: BreadCrumbItem[] = useMemo(() => {
    const selectedTemplate = templates?.find((t) => t.id === templateId);

    return [
      {
        label: pageTitle,
        path: CONTRACTS_PAGE.path,
      },
      ...(selectedTemplate?.name
        ? [
            {
              label: selectedTemplate?.name,
            },
          ]
        : [
            {
              label: 'New contract',
            },
          ]),
    ];
  }, []);
  const getSubmitActionLabel = React.useMemo(() => {
    if (!isClient) {
      if (templateId) return 'Save';
      return isOneOffContract ? 'Send' : 'Create template';
    }
    if (signatureFlowStatus === ESignatureFlowStatus.START_SIGNATURE)
      return 'Start';
    return 'Finish and send';
  }, [signatureFlowStatus, templateId, isOneOffContract]);

  const getCancelActionLabel = React.useMemo(() => {
    if (isClientOnEndSignatureOnMobile) {
      return 'Back to edit';
    }

    return 'Cancel';
  }, [isClientOnEndSignatureOnMobile, signatureFlowStatus, templateId]);

  const isSubmitActionButtonDisabled = useMemo(() => {
    // Disable the submit button during the client sign flow if the signature flow has been initiated
    if (isClient) {
      return signatureFlowStatus === ESignatureFlowStatus.FILL_SIGNATURE;
    }

    // Disable the save button for editing contract templates when there are no changes in the form.
    return Boolean(templateId) && !isFormPageChanged;
  }, [signatureFlowStatus, templateId, isFormPageChanged, isClient]);

  const useContractBuilderSignerForm = () =>
    useContractBuilderAndSignerForm({ onPageSave: handleClientAction });

  return (
    <ContractBuilderContext.Provider value={contextValue}>
      <AuthModal />
      <FormPage
        useFormHook={useContractBuilderSignerForm}
        headerVariant="bottomBorder"
        BreadcrumbsComponent={
          !isClient ? <BreadCrumbBase items={breadcrumbItems} /> : undefined
        }
        resetFormOnCancel={!isClient && Boolean(templateId)} // we only want to reset form on edit flow
        cancelLabel={getCancelActionLabel}
        actionLabel={getSubmitActionLabel}
        handleCancel={onCancel}
        shouldShowPageAction
        handleSave={handleSave}
        initialFormValue={contractFormValues}
        hideFormToolbar={isClient && !isSmallScreen}
        title="eSignature request"
        // The cancel button will be hidden only for clients on mobile screen
        hideCancelAction={
          isClient &&
          isSmallScreen &&
          signatureFlowStatus !== ESignatureFlowStatus.END_SIGNAURE
        }
        // Disable the cancel button during  edit template flow
        // when there are no changes in the form values
        cancelDisabled={!isClient && Boolean(templateId) && !isFormPageChanged}
        showBackButton={isClient && isSmallScreen}
        onBackButtonClick={() => {
          navigateToContractsPage(ContractPageTabs.Templates);
        }}
        disableSubmit={isSubmitActionButtonDisabled}
      />
    </ContractBuilderContext.Provider>
  );
};
