import React, { ComponentProps, useCallback } from 'react';
import {
  Avatar,
  Typography,
  ClickAwayListener,
  AvatarTypeMap,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { getPublicImageURL } from 'src/utils/ImageUtils';
import BaseTypography from 'src/components/Text/BaseTypography';
import { AvatarContentRenderer } from 'src/components/User/AvatarContentRenderer';

interface ColumnAvatarProps {
  avatarUrl?: string;
  name?: string;
  description?: string | number | React.ReactNode;
  fallbackColor?: string;
}
export type AvatarSizeType =
  | 'mini'
  | '16small'
  | '18small'
  | 'xxxsmall'
  | '25small'
  | 'xsmall'
  | '28small'
  | '40Small'
  | 'small'
  | 'medium'
  | 'medium-large'
  | 'large'
  | 'regularLarge'
  | 'regular';

const AVATAR_SPACING_MAP: Partial<Record<AvatarSizeType, string>> = {
  xxxsmall: 'ml-2',
  '28small': 'ml-2',
  '16small': 'ml-1',
};

const getAvatarSize = (avatarSize: AvatarSizeType) => {
  if (avatarSize === 'medium') {
    return 'h-[64px] w-[64px] text-[28px]';
  }
  if (avatarSize === 'medium-large') {
    return 'h-[50px] w-[50px] text-[28px]';
  }
  if (avatarSize === 'large') {
    return 'h-[120px] w-[120px] text-[45px]';
  }
  if (avatarSize === 'small') {
    return 'h-[40px] w-[40px]';
  }

  if (avatarSize === 'xsmall') {
    return 'h-[32px] w-[32px]';
  }

  if (avatarSize === '25small') {
    return 'h-[25px] w-[25px]';
  }

  if (avatarSize === 'xxxsmall') {
    return 'h-[22.5px] w-[22.5px]';
  }

  if (avatarSize === 'mini') {
    return 'h-[20px] w-[20px]';
  }
  if (avatarSize === '16small') {
    return 'h-[16px] w-[16px]';
  }

  if (avatarSize === '18small') {
    return 'h-[18px] w-[18px]';
  }

  if (avatarSize === 'regularLarge') {
    return 'h-[35px] w-[35px]';
  }

  if (avatarSize === '28small') {
    return 'h-[28px] w-[28px]';
  }

  if (avatarSize === '40Small') {
    return 'h-[40px] w-[40px]';
  }

  return 'h-[30px] w-[30px]';
};

type PrimaryTextVariant =
  | ComponentProps<typeof Typography>['variant']
  | 'tableMain';
type SecondaryTextVariant =
  | ComponentProps<typeof Typography>['variant']
  | 'tableSubtitle';

interface UserAvatarProps extends ColumnAvatarProps {
  type?: 'row' | 'image' | 'uploader';
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  showName?: boolean;
  showTime?: boolean;
  showAvatar?: boolean;
  avatarSize?: AvatarSizeType;
  append?: string;
  fallbackTypographyVariant?: 'body1' | 'h3';
  primaryTextVariant?: PrimaryTextVariant;
  secondaryTextVariant?: SecondaryTextVariant;
  shape?: AvatarTypeMap['props']['variant'];
  initialLetters?: string;
  avatarStyles?: React.CSSProperties;
  isLoading?: boolean;
  isActive?: boolean;
}

const UnMemoizedUserAvatar: React.FC<UserAvatarProps> = ({
  type = 'row',
  showName = false,
  showAvatar = true,
  avatarSize = 'regular',
  shape = 'rounded',
  avatarUrl,
  name = '',
  initialLetters = '',
  description = '',
  fallbackColor = '',
  append = '',
  primaryTextVariant,
  secondaryTextVariant,
  avatarStyles,
  isLoading = false,
}) => {
  const [isFocused, setFocusedStatus] = React.useState(false);

  const wrapSkeleton = (skeletonType: string, returnValue: any) => {
    const skeleton =
      skeletonType === 'avatar' ? (
        <Skeleton variant="circle" className={getAvatarSize(avatarSize)} />
      ) : (
        <Skeleton variant="text" />
      );
    if (isLoading) {
      return skeleton;
    }
    return returnValue;
  };

  const getAvatarSrc = () => {
    if (avatarUrl !== undefined && avatarUrl !== null) {
      const size = avatarSize === 'large' ? 150 : 64;
      return getPublicImageURL(avatarUrl, {
        resize: {
          width: size,
          height: size,
        },
      });
    }

    return '';
  };

  const getCustomAvatarShape = useCallback(() => {
    if (shape) {
      return { variant: shape };
    }

    return null;
  }, [shape]);

  const getStyles = React.useCallback((): React.CSSProperties => {
    return {
      backgroundColor: getAvatarSrc() ? '#fff' : fallbackColor, // first user color passed in prop, if not present fallback to current user color

      opacity: showAvatar ? 1 : 0,
      ...avatarStyles,
    };
  }, [avatarStyles, fallbackColor, getAvatarSrc, showAvatar]);

  const AvatarElement = wrapSkeleton(
    'avatar',
    <Avatar
      alt="User"
      src={getAvatarSrc()}
      className={getAvatarSize(avatarSize)}
      {...getCustomAvatarShape()}
      style={getStyles()}
    >
      <AvatarContentRenderer
        avatarSize={avatarSize}
        initialLetters={initialLetters}
        name={name}
      />
    </Avatar>,
  );

  const removeActiveState = () => {
    setFocusedStatus(false);
  };

  return (
    <ClickAwayListener onClickAway={removeActiveState} disableReactTree>
      <div
        data-testid="user-avatar"
        className={isFocused ? 'bg-none-hover-background' : ''}
      >
        {type === 'uploader' && AvatarElement}
        {type !== 'uploader' && (
          <div className="flex items-center">
            {AvatarElement}
            {type !== 'image' && (
              <div className={AVATAR_SPACING_MAP[avatarSize] ?? 'ml-4'}>
                <div className="text-left">
                  {/** Todo(@Monfernape): Bad variants */}
                  {primaryTextVariant === 'tableMain' ? (
                    <BaseTypography fontType="12Medium">
                      {name + append}
                    </BaseTypography>
                  ) : (
                    <Typography hidden={!showName} variant={primaryTextVariant}>
                      {name + append}
                    </Typography>
                  )}
                  {secondaryTextVariant === 'tableSubtitle' ? (
                    <BaseTypography fontType="12Regular">
                      {description}
                    </BaseTypography>
                  ) : (
                    <Typography variant={secondaryTextVariant}>
                      {description}
                    </Typography>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

const UserAvatar = React.memo(UnMemoizedUserAvatar);

export default UserAvatar;
