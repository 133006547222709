import { Theme } from '@material-ui/core';
import { typography13MediumStyle } from 'src/components/Text';

export default {};

export const overrideChip = (theme: Theme) => {
  if (theme.overrides && theme.overrides.MuiChip) {
    // eslint-disable-next-line no-param-reassign
    theme.overrides.MuiChip = {
      root: {
        height: 20,
        borderRadius: theme.shape.borderRadius,
        border: 'none',
        padding: 0,
        '& > span:first-of-type': {
          padding: '0px 5px',
          ...typography13MediumStyle,
          // chip label line height should not exceed
          // the root wrapper height.
          lineHeight: '20px',
        },
      },
      colorPrimary: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
    };
  }
};
