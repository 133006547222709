import React from 'react';
import { makeStyles } from '@material-ui/core';
import { CONTRACTS_PAGE } from 'src/constants';
import history from 'src/history';
import { BrandedBackButton } from 'src/components/UI/BrandedBackButton';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(7),
    paddingLeft: theme.spacing(4),
    position: 'fixed',
  },
}));

export const ClientSignatureHeader = () => {
  const navigateBack = () => history.push(CONTRACTS_PAGE.path);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <BrandedBackButton onBackClick={navigateBack} />
    </div>
  );
};
