import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarKey, useSnackbar } from 'notistack';
import { AlertVariant } from 'src/store/ui/types';
import { RootState } from 'src/store';
import { AlertSnackbar } from 'src/components/AlertSnackbar/AlertSnackbar';
import { clearAlertMessage } from 'src/store/ui/actions';
import { useTrackAppVersion } from 'src/hooks/useTrackAppVersion';

const Notifier = () => {
  const dispatch = useDispatch();
  const alertMessage = useSelector((state: RootState) => state.ui.alertMessage);
  const alertMessageType = useSelector(
    (state: RootState) => state.ui.alertMessageType,
  );
  const isProgressComplete = useSelector(
    (state: RootState) => state.ui.isProgressComplete,
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useTrackAppVersion(enqueueSnackbar);

  /**
   * when snackbar is closed, clear the alert message
   * this will avoid snackbar from showing up again on re-render or useEffect change
   */
  const handleCloseRequest = () => {
    dispatch(clearAlertMessage());
  };

  const snackbarContent = React.useCallback(
    (key: SnackbarKey) =>
      alertMessageType ? (
        <div key={key}>
          <AlertSnackbar
            onClose={() => closeSnackbar(key)}
            alertMessage={alertMessage}
            variant={alertMessageType}
          />
        </div>
      ) : null,
    [alertMessage, alertMessageType],
  );

  React.useEffect(() => {
    let timer = 0;
    if (isProgressComplete) {
      timer = window.setTimeout(() => {
        dispatch(clearAlertMessage());
        closeSnackbar('uploadProgress');
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [isProgressComplete]);

  React.useEffect(() => {
    if (alertMessageType) {
      const enqueueDate = new Date().getTime();
      // if an error snackbar is about to be queued, close any existing progress snackbar
      if (alertMessageType === AlertVariant.ERROR) {
        closeSnackbar('uploadProgress');
      }
      enqueueSnackbar(alertMessage, {
        persist: alertMessageType === AlertVariant.PROGRESS,
        key:
          alertMessageType === AlertVariant.PROGRESS
            ? 'uploadProgress'
            : `alert-${alertMessageType}-${enqueueDate}`,
        content: snackbarContent,
        onClose: handleCloseRequest,
      });
    }
  }, [alertMessage, alertMessageType, enqueueSnackbar]);

  return null;
};

export default Notifier;
