import React from 'react';
import { useKBar, VisualState } from 'kbar';
import { InputBase, makeStyles, Theme, createStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { DividersAndCardBorders, GraySmall, white } from 'src/theme/colors';
import { typography15RegularStyle } from 'src/components/Text';
import {
  ShortcutActionIdsToMenuTitleMap,
  ShortcutActionIdsToMenuTitleMapType,
} from 'src/constants/shortcutConsts';
import { CommandbarMenuTitle } from 'src/components/CommandBar/CommandbarMenuTitle';
import { CLIENT_DETAILS_PAGE } from 'src/constants';
import { RouteContext } from 'src/context';
import { RootState } from 'src/store';

export const KBAR_LISTBOX = 'kbar-listbox';
export const getListboxItemId = (id: number) => `kbar-listbox-item-${id}`;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${DividersAndCardBorders}`,
      padding: theme.spacing(2.5, 2.5, 1.5),
    },
    input: {
      background: white,
      padding: 0,
      width: 600,
      '& input': {
        ...typography15RegularStyle,
        borderRadius: theme.shape.borderRadius,
        color: GraySmall,
        '&::placeholder': {
          color: GraySmall,
        },
      },
    },
  }),
);
export const CommandBarSearch: React.FC<
  React.InputHTMLAttributes<HTMLInputElement> & { defaultPlaceholder?: string }
> = ({ defaultPlaceholder, onChange, onKeyDown }) => {
  const classes = useStyles();
  const {
    query,
    search,
    actions,
    currentRootActionId,
    activeIndex,
    showing,
    options,
  } = useKBar((state) => ({
    search: state.searchQuery,
    currentRootActionId: state.currentRootActionId,
    actions: state.actions,
    activeIndex: state.activeIndex,
    showing: state.visualState === VisualState.showing,
  }));

  const ownRef = React.useRef<HTMLInputElement>(null);
  const { pathname, query: pathQuery } = React.useContext(RouteContext);
  const activeClients = useSelector(
    (state: RootState) => state.clients.activeClients,
  );
  React.useEffect(() => {
    query.setSearch('');
    if (ownRef.current) {
      ownRef.current.focus();
    }
    return () => query.setSearch('');
  }, [currentRootActionId, query]);

  const placeholder = React.useMemo((): string => {
    const defaultText = defaultPlaceholder || 'Type a command or search…';
    // when parent action is open client show the corresponding input placeholder
    if (currentRootActionId === 'open-client') {
      return 'Type to find clients or companies...';
    }

    if (currentRootActionId === 'create-entity-command') {
      return 'Specify what you want to create...';
    }

    return currentRootActionId
      ? actions[currentRootActionId].name
      : defaultText;
  }, [actions, currentRootActionId, defaultPlaceholder]);

  const handleChange = React.useCallback(
    (event) => {
      onChange?.(event);
      query.setSearch(event.target.value);
      options?.callbacks?.onQueryChange?.(event.target.value);
    },
    [query, onChange, options],
  );

  const handleKeyDown = React.useCallback(
    (event) => {
      onKeyDown?.(event);
      if (currentRootActionId && !search && event.key === 'Backspace') {
        const { parent } = actions[currentRootActionId];
        query.setCurrentRootAction(parent);
      }
    },
    [query, onKeyDown, currentRootActionId, search, actions],
  );

  // map root command action id to title
  const menuTitle = React.useMemo(() => {
    const isSubMenu = Boolean(currentRootActionId);

    // when page is client details and it is not a sub action menu, show the client name.
    // Note: when `currentRootActionId` is truthy, that means that the current menu
    // is open from a parent command action, then it is a sub menu. Otherwise, it is a root menu.
    if (pathname === CLIENT_DETAILS_PAGE.path && !isSubMenu) {
      const viewedClient = activeClients.find(
        (c) => c.id === (pathQuery as any).clientUserId,
      );
      return viewedClient
        ? `${viewedClient.fields.givenName} ${viewedClient.fields.familyName}`
        : '';
    }

    return ShortcutActionIdsToMenuTitleMap[
      currentRootActionId as keyof ShortcutActionIdsToMenuTitleMapType
    ];
  }, [currentRootActionId, activeClients]);

  return (
    <div className={classes.wrapper}>
      {menuTitle && <CommandbarMenuTitle menuTitle={menuTitle} />}
      <InputBase
        className={classes.input}
        fullWidth
        ref={ownRef}
        autoFocus
        autoComplete="off"
        role="combobox"
        spellCheck="false"
        aria-expanded={showing}
        aria-controls={KBAR_LISTBOX}
        aria-activedescendant={getListboxItemId(activeIndex)}
        value={search}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};
