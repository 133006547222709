export const MODULE_MANAGEMENT_LIST = {
  contracts: {
    activeLabel:
      'Turning off the contracts module hides the module in the sidebar for internal and client users.',
    inActiveLabel:
      'Turning on the contracts module lets internal users and client users communicate in an integrated experience.',
    learnMoreLink: 'https://www.copilot.com/guide/copilot-contracts-app',
  },
  messages: {
    activeLabel:
      'Turning off the messages module hides the module in the sidebar for internal and client users.',
    inActiveLabel:
      'Turning on the messages module lets internal users and client users communicate in an integrated experience.',
    learnMoreLink: 'https://www.copilot.com/guide/copilot-messages-app',
  },
  files: {
    activeLabel:
      'Turning off the files module hides it in the sidebar for internal and client users.',
    inActiveLabel:
      'Turning on the files module lets internal and client users securely share files.',
    learnMoreLink: 'https://www.copilot.com/guide/copilot-files-app',
  },
  forms: {
    activeLabel:
      'Turning off the forms module hides it in the sidebar for internal and client users. When disabled, internal users cannot create forms and client users cannot submit them.',
    inActiveLabel:
      'Turning on the forms module lets internal users create intake forms and lets client users answer them.',
    learnMoreLink: 'https://www.copilot.com/guide/copilot-forms-app',
  },
  payments: {
    activeLabel:
      'Turning off the billing module hides it in the sidebar for internal and client users. When disabled, internal users cannot create invoices and subscriptions, and client users cannot make payments.',
    inActiveLabel:
      'Turning on the billing module lets internal users create invoices and subscriptions, and lets client users make payments with credit card and ACH.',
    learnMoreLink: 'https://www.copilot.com/guide/copilot-billing-app',
  },
  knowledgeBase: {
    activeLabel:
      'Turning off the knowledge base module hides it in the sidebar for internal and client users.',
    inActiveLabel:
      'Turning on the knowledge base module lets internal users create knowledge base articles that are viewable by client users.',
    learnMoreLink: 'https://www.copilot.com/guide/copilot-helpdesk-app',
  },
  landings: {
    activeLabel:
      'Turning off the public website requires you to connect your existing marketing website.',
    inActiveLabel:
      'Turning on the public website lets you design a public-facing website directly in your portal. This is the page that is accessible by any visitor with no log-in required.',
    learnMoreLink: '',
  },
};

export const EXTENSIONS_LEARN_MORE_LINK =
  'https://www.copilot.com/guide/intro-to-apps';
export const EXTENSION_SETUP_LINK =
  'https://www.copilot.com/guide/intro-to-apps';
export const CUSTOM_APP_SETUP_LINK =
  'https://www.copilot.com/guide/custom-apps';
export type ModuleManagementList = typeof MODULE_MANAGEMENT_LIST;

export type ModuleManagementListKeys = keyof ModuleManagementList;

export const LANDING_TOOLTIP_WITH_DOMAIN =
  'You cannot change the public website after your domain is setup. Delete your custom domain before making further changes';

export const renameDisabledModules = ['payments'];

export const CORE_MODULES_LIST_TEXTS = {
  messages:
    'Message existing clients directly from your portal and greet new clients with automatic welcome messages.',
  files:
    'Securely share files. Replaces ShareFile, Dropbox, Google Drive, and DocuSign.',
  forms:
    'Send intake forms and surveys to clients to make client onboarding seamless. Replaces TypeForm and Google Forms.',
  payments:
    'Create invoices & subscriptions and give clients an easy way to pay with credit card and ACH. Replaces Bill.com and QuickBooks.',
  knowledgeBase:
    'Create a knowledge base with text, images, and videos to help clients find answers to common questions.',
};

export const localExtensionDescription =
  'With Local Extensions, you can embed a different view for each client. Configure this module in the Extension Modules page.';
export const globalExtensionDescription =
  'With Global Extension, you can embed a view and have it be accessible by all of your clients. Configure this module in the Extension modules page.';
export const authenticatedExtensionDescription =
  'With Authenticated Extensions, you can embed a url to a web application that control what content is shown based on the authenticated user.';

export const addCustomExtension = 'a custom App';

export enum AppPresets {
  PartnerApp = 'partner-app',
  CustomApp = 'custom',
}
