import React from 'react';
import MuiButton, { overrideButton } from 'src/theme/overrides/MuiButton';
import MuiButtonGroup, {
  overrideButtonGroup,
} from 'src/theme/overrides/MuiButtonGroup';
import MuiCheckbox, { overrideCheckbox } from 'src/theme/overrides/MuiCheckbox';
import MuiIconButton, {
  overrideIconButton,
} from 'src/theme/overrides/MuiIconButton';
import MuiInputBase, {
  overrideInputBase,
} from 'src/theme/overrides/MuiInputBase';
import MuiAutocomplete from 'src/theme/overrides/MuiAutocomplete';
import MuiCssBaseline from 'src/theme/overrides/MuiCssBaseline';
import MuiDivider from 'src/theme/overrides/MuiDivider';
import MuiFormControl from 'src/theme/overrides/MuiFormControl';
import MuiFormHelperText from 'src/theme/overrides/MuiFormHelperText';
import MuiList from 'src/theme/overrides/MuiList';
import MuiListItem from 'src/theme/overrides/MuiListItem';
import MuiMenu from 'src/theme/overrides/MuiMenu';
import MuiMenuItem from 'src/theme/overrides/MuiMenuItem';
import MuiSelect from 'src/theme/overrides/MuiSelect';
import MuiSwitch from 'src/theme/overrides/MuiSwitch';
import MuiTextField from 'src/theme/overrides/TextField';
import MuiTooltip from 'src/theme/overrides/MuiTooltip';
import MuiTypography from 'src/theme/overrides/MuiTypography';
import MuiChip, { overrideChip } from 'src/theme/overrides/MuiChip';
import MuiRadio, { overrideRadio } from 'src/theme/overrides/MuiRadio';
import { CheckboxIcon, CheckboxCheckedIcon } from 'src/components/Icons';
import MuiAlert from 'src/theme/overrides/MuiAlert';
import MuiFormControlLabel, {
  overrideFormControlLabel,
} from 'src/theme/overrides/MuiFormControlLabel';

export default {
  MuiList,
  MuiListItem,
  MuiAutocomplete,
  MuiTypography,
  MuiTextField,
  MuiTooltip,
  MuiInputBase,
  MuiIconButton,
  MuiMenu,
  MuiMenuItem,
  MuiSwitch,
  MuiFormHelperText,
  MuiButton,
  MuiButtonGroup,
  MuiCheckbox,
  MuiCssBaseline,
  MuiDivider,
  MuiSelect,
  MuiFormControl,
  MuiChip,
  MuiAlert,
  MuiFormControlLabel,
  MuiRadio,
};

export const ComponentsOptions = {
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiCheckbox: {
    checkedIcon: <CheckboxCheckedIcon style={{ fontSize: 16 }} />,
    icon: <CheckboxIcon style={{ fontSize: 16 }} />,
  },
};

export const AdditionalOverrides = [
  overrideInputBase,
  overrideIconButton,
  overrideButton,
  overrideButtonGroup,
  overrideCheckbox,
  overrideChip,
  overrideFormControlLabel,
  overrideRadio,
];
