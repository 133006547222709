import React, { useMemo } from 'react';
import BaseTypography from 'src/components/Text/BaseTypography';
import { useResizeObserver, Rect } from 'src/hooks/useResizeObserver';
import type { ChartData } from 'src/types/analytics';

// This is not exact, I kind of just tweaked it
// until things looked right.
const LABEL_WIDTH = 150;

function getDate(date: Date): string {
  return date.toLocaleDateString(undefined, {
    month: 'short',
    day: '2-digit',
  });
}

function getAxisLabels(data: ChartData[], rect: Rect): string[] {
  if (!rect.width) {
    return [];
  }

  const numLabels = Math.floor(rect.width / LABEL_WIDTH);
  const firstDatum = data.at(0);
  const lastDatum = data[data.length - 1];
  const first = firstDatum ? getDate(firstDatum.date) : undefined;
  const last = lastDatum ? getDate(lastDatum.date) : undefined;

  if (!first || !last) {
    return [];
  }

  // Never go lower than 2, even if there's no space. Practically
  // speaking, this should never happen but it allows the code
  // to be cleaner if we handle it as a special case.
  if (numLabels < 2) {
    return [first, last];
  }

  const step = Math.floor(data.length / (numLabels - 1));
  const labels = [
    first,
    ...data
      .slice(1, -1)
      .filter((_, index) => (index + 1) % step === 0)
      .map((item) => getDate(item.date)),
    last,
  ];
  return labels;
}

export function XAxisLabel({ data }: { data: ChartData[] }) {
  const { ref: sizeRef, rect } = useResizeObserver<HTMLDivElement>();
  const labels = useMemo(() => getAxisLabels(data, rect), [data, rect]);
  return (
    <div
      ref={sizeRef}
      className={[
        'w-full',
        'h-5',
        'text-gray-500',
        'overflow-visible',
        'flex',
        'flex-row',
        'justify-between',
      ].join(' ')}
    >
      {labels.map((label) => (
        <div key={label} className="text-gray-500">
          <BaseTypography fontType="13Regular">{label}</BaseTypography>
        </div>
      ))}
    </div>
  );
}
