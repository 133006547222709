import { NewCustomFieldOption } from 'src/components/CustomFieldsMenu/CustomFieldsMenu';

// note: key values should remain empty because they will be filled by API endpoint logic
// these key values on custom fields serve as easily-readable, camel-cased alternatives to id values (UUIDs)
export const CUSTOM_FIELDS: Array<NewCustomFieldOption> = [
  {
    key: '',
    name: 'Phone number',
    type: 'phoneNumber',
  },

  {
    key: '',
    name: 'Email',
    type: 'email',
  },

  {
    key: '',
    name: 'URL',
    type: 'url',
  },
  {
    key: '',
    name: 'Text',
    type: 'text',
  },
  {
    key: '',
    name: 'Number',
    type: 'number',
  },
  {
    key: '',
    name: 'Address',
    type: 'address',
  },
  {
    key: '',
    name: 'Tags',
    type: 'multiSelect',
    options: [],
  },
];
