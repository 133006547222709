import React from 'react';
import {
  MenuItem,
  IconButton,
  Menu,
  makeStyles,
  Theme,
  createStyles,
  Tooltip,
  MenuProps,
} from '@material-ui/core';
import { PlusIcon } from 'src/components/Icons';
import { red, BlackHeadings } from 'src/theme/colors';
import BaseTypography from 'src/components/Text/BaseTypography';

import RowDivider from 'src/components/RowDivider';
import {
  regularMenuMinWidth,
  wideMenuMinWidth,
  skinnyMenuMinWidth,
  extraWideMenuMinWidth,
} from 'src/theme/dimensions';
import { BaseActionsMenuProps } from 'src/constants';
import { Action } from 'src/components/Dropdowns/BaseActionsMenu';
import { BaseMenuInput } from 'src/components/Dropdowns/BaseMenuInput';
import { ActionMenuItem } from 'src/components/Dropdowns/ActionMenuItem';

export interface MenuAction {
  key: string;
  name: string;
  onClick?: (params?: any) => void;
  icon?: React.ReactElement;
  value?: string;
  isDelete?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    skinny: {
      minWidth: skinnyMenuMinWidth,
      marginTop: theme.spacing(0.5),
    },
    regular: {
      minWidth: regularMenuMinWidth,
      marginTop: theme.spacing(0.5),
    },
    wide: {
      minWidth: wideMenuMinWidth,
      marginTop: theme.spacing(0.5),
    },
    extraWide: {
      minWidth: extraWideMenuMinWidth,
      marginTop: theme.spacing(0.5),
    },
    menuTitle: {
      color: BlackHeadings,
      '&:hover': {
        background: 'white',
      },
    },
    addActionItem: {
      color: BlackHeadings,
    },
    customFieldEdit: {
      width: 120,
    },
    delete: {
      color: red,
      '& svg': {
        color: red,
      },
    },
    icon: {
      color: BlackHeadings,
      padding: 0,
      marginRight: theme.spacing(2),
      '& svg': {
        fontSize: 14,
      },
    },
  }),
);

export const BaseMenu: React.FC<BaseActionsMenuProps> = ({
  variant = 'regular',
  menuTitle,
  menuInputProps,
  addOptionLabel,
  onAddOption,
  headerActions,
  actions,
  menuProps,
  showTitleDivider = true,
  preventClickEventPropagation,
}) => {
  const classes = useStyles();

  const onClickMenuItem = (
    event: React.MouseEvent<HTMLLIElement>,
    action: Action,
  ) => {
    // Prevent the event propagation if parent element get affected by subsequent DOM child click.
    if (preventClickEventPropagation) {
      event.stopPropagation();
    }
    // Event propagation allowed to enable click on parent component and thus removing active state from parent component
    // This passes the id of the menu item so that it can be used in the menu item action handler.
    // e.g. Delete company menu action needs to know what the companyId to delete is.
    action.onClick({
      ...event,
      currentTarget: {
        ...event.currentTarget,
        id: menuProps?.id || '',
      },
    });
    if (menuProps?.onClose) {
      menuProps.onClose(event, 'backdropClick');
    }
  };

  if (!actions) {
    return <div />;
  }

  return (
    <div>
      <Menu
        {...(menuProps as MenuProps)}
        classes={{ paper: classes[variant as keyof typeof classes] }}
      >
        {menuInputProps && <BaseMenuInput {...menuInputProps} />}

        {menuTitle && (
          <div>
            <MenuItem classes={{ root: classes.menuTitle }}>
              <BaseTypography fontType="15Regular">{menuTitle}</BaseTypography>
            </MenuItem>
            {actions.length > 0 && showTitleDivider && (
              <RowDivider mt={0.5} mb={0.5} />
            )}
          </div>
        )}

        {headerActions && headerActions.length > 0 && (
          <div>
            {headerActions.map((action) => (
              <div key={action.key || action.name}>
                {action.tooltipLabel && action.isDisabled ? (
                  <Tooltip title={action.tooltipLabel} placement="left">
                    <div>
                      <ActionMenuItem
                        action={action}
                        onClick={onClickMenuItem}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <ActionMenuItem action={action} onClick={onClickMenuItem} />
                )}
              </div>
            ))}
            <RowDivider mt={0.5} mb={0.5} />
          </div>
        )}
        {actions.map((action) => (
          <div key={action.key || action.name}>
            {action.tooltipLabel && action.isDisabled ? (
              <Tooltip title={action.tooltipLabel} placement="left">
                <div>
                  <ActionMenuItem action={action} onClick={onClickMenuItem} />
                  {action.hasDivider && <RowDivider mt={0.5} mb={0.5} />}
                </div>
              </Tooltip>
            ) : (
              <ActionMenuItem action={action} onClick={onClickMenuItem} />
            )}
            {action.hasDivider &&
              !(action.tooltipLabel && action.isDisabled) && (
                <RowDivider mt={0.5} mb={0.5} />
              )}
          </div>
        ))}
        {addOptionLabel && (
          <div>
            <RowDivider mt={0.5} mb={0.5} />
            <MenuItem
              classes={{ root: classes.addActionItem }}
              onClick={onAddOption}
            >
              <IconButton className={classes.icon}>
                <PlusIcon />
              </IconButton>
              <BaseTypography>{addOptionLabel}</BaseTypography>
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  );
};
