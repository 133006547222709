import { AxiosError, AxiosResponse } from 'axios';
import { Auth } from 'aws-amplify';
import { Dispatch } from 'redux';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ApiMethods, ApiTags, amplifyBaseQuery } from '.';
import { SetUsersAction, setInternalUsers } from 'src/store/users/actions';
import { UPDATE_USER, UPDATE_USER_PERMISSIONS } from 'src/store/user/types';
import {
  setClientsAction,
  setCompaniesAction,
} from 'src/store/clients/actions';
import { setFileChannelsAction } from 'src/store/files/actions';
import { setResourcesAction } from 'src/store/data/actions';
import { setExtensionItemsAction } from 'src/store/dashboard/actions';
import { setNotificationsAction } from 'src/store/notifications/actions';
import { DefaultCRMColumnSettings, User, UsersAPIName } from 'src/constants';
import { getUserRef } from 'src/utils/UserUtils';
import { setUserLoadingErrorAction } from 'src/store/user/actions';
import {
  loadPortalSettingsAction,
  loadSettingsSuccess,
  setMessageSettingsAction,
} from 'src/store/settings/actions';
import {
  setCrmTablePropertiesFieldsAction,
  setCrmTablePropertiesIdAction,
} from 'src/store/tableProperties/actions';
import { SetPaymentInfo } from 'src/store/payments/actions';
import UsersClient, { UserGetData } from 'src/clients/UsersClient';
import { AppViewMode } from 'src/constants/uiConsts';
import Stripe from 'stripe';

type GetClientUsersOptions = {
  ignoreAccess?: boolean;
};

/**
 * When a query fails, this function handles the error and dispatches the appropriate action.
 * @param err: the error object
 * @param dispatch: the dispatch function
 */
const handleQueryError = (err: AxiosError, dispatch: Dispatch) => {
  if (err.response || err.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    dispatch(setUserLoadingErrorAction('Request failed'));
    console.error('failed to load user', err.response);
  } else {
    // Something happened in setting up the request that triggered an Error
    dispatch(setUserLoadingErrorAction('Connection error'));
    console.error('connection failed loading user', err);
  }
  throw err;
};

export const usersApi = createApi({
  baseQuery: amplifyBaseQuery({ apiName: UsersAPIName }),
  reducerPath: UsersAPIName,
  tagTypes: Object.values(ApiTags),
  endpoints: (build) => ({
    getUsers: build.query<
      Partial<UserGetData>,
      { userId: string; appViewMode: AppViewMode }
    >({
      queryFn: async ({ userId, appViewMode }) => {
        // get internal users first since they are needed to lookup
        // the current user assigned clients.
        const internalUsers =
          appViewMode === AppViewMode.INTERNAL
            ? await UsersClient.listUsers()
            : undefined;
        // users list should be incrementally loaded since it can be very large.
        const usersResponse = await UsersClient.loadPaginatedUserDataItems(
          userId,
          {
            includeArchived: true,
            dataType: 'users',
          },
          'users',
          [],
        );
        return { data: { ...usersResponse.data, internalUsers } };
      },
      providesTags: [ApiTags.users],
      async onQueryStarted(
        { appViewMode, userId },
        { queryFulfilled, dispatch },
      ) {
        let usersResponse;
        try {
          usersResponse = await queryFulfilled;
        } catch (error) {
          handleQueryError(error as AxiosError, dispatch);
        }
        if (!usersResponse?.data) return;
        const isClient = appViewMode === AppViewMode.CLIENT;
        const { users, internalUsers } = usersResponse.data;
        // set internal users in the store before setting clients store
        // in order to filter clients by assigned internal users.
        if (internalUsers) dispatch(setInternalUsers(internalUsers));
        if (!users) return;

        if (isClient) {
          dispatch(SetUsersAction(users));
        } else {
          dispatch(
            setClientsAction({
              clients: users,
              isClient,
              userId,
            }),
          );
        }
      },
    }),
    getCompanies: build.query<UserGetData, string>({
      queryFn: async (userId) => {
        // load companies
        const companies = await UsersClient.loadPaginatedUserDataItems(
          userId,
          {
            includeArchived: true,
            dataType: 'companies',
          },
          'companies',
          [],
        );
        return { data: { ...companies.data } };
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        let companiesResponse;
        try {
          companiesResponse = await queryFulfilled;
        } catch (error) {
          handleQueryError(error as AxiosError, dispatch);
        }
        if (!companiesResponse?.data) return;
        dispatch(setCompaniesAction(companiesResponse.data.companies || []));
      },
      providesTags: [ApiTags.companies],
    }),
    getUserFileChannels: build.query<UserGetData, string>({
      queryFn: async (userId) => {
        // load file channels
        const fileChannels = await UsersClient.loadPaginatedUserDataItems(
          userId,
          {
            includeArchived: true,
            dataType: 'fileChannels',
          },
          'fileChannels',
          [],
        );
        return { data: { ...fileChannels.data } };
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        let fileChannelsResponse;
        try {
          fileChannelsResponse = await queryFulfilled;
        } catch (error) {
          handleQueryError(error as AxiosError, dispatch);
        }

        if (!fileChannelsResponse?.data) return;
        dispatch(
          setFileChannelsAction(fileChannelsResponse.data.fileChannels || []),
        );
      },
      providesTags: [ApiTags.fileChannels],
    }),
    getUserNotifications: build.query<UserGetData, string>({
      query: (userId) => ({
        path: `/v1/users/anyType/${userId}/data`,
        method: ApiMethods.get,
        options: {
          queryStringParameters: {
            dataType: 'notifications',
            includeArchived: true,
          },
        },
      }),
      transformResponse: (response: AxiosResponse) => response.data,
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        let notificationsResponse;
        try {
          notificationsResponse = await queryFulfilled;
        } catch (error) {
          handleQueryError(error as AxiosError, dispatch);
        }
        if (!notificationsResponse?.data) return;
        dispatch(
          setNotificationsAction(notificationsResponse.data.notifications),
        );
      },
    }),
    getOtherUserData: build.query<UserGetData, { cognitoUserId: string }>({
      query: ({ cognitoUserId }) => ({
        path: `/v1/users/anyType/${cognitoUserId}/data`,
        method: ApiMethods.get,
        options: {
          queryStringParameters: {
            includeArchived: true,
            excludeTypes: [
              'users',
              'notifications',
              'companies',
              'fileChannels',
            ],
          },
        },
      }),
      transformResponse: (response: AxiosResponse) => response.data,
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        let otherUserDataResponse;
        try {
          otherUserDataResponse = await queryFulfilled;
        } catch (error) {
          handleQueryError(error as AxiosError, dispatch);
        }
        if (!otherUserDataResponse?.data) return;
        const {
          resources,
          extensionItems,
          portalSettings,
          messageSettings,
          crmTableProperties,
          internalUsers,
          isClientUser,
          ...otherData
        } = otherUserDataResponse.data;
        dispatch(setInternalUsers(internalUsers || []));
        dispatch(setResourcesAction(resources));
        dispatch(setExtensionItemsAction(extensionItems));

        // update user redux state with cognito user attributes
        const authUser = await Auth.currentAuthenticatedUser();
        const dbUserId = otherData.userId;
        dispatch({
          type: UPDATE_USER,
          user: {
            isClient: isClientUser || false,
            attributes: authUser?.attributes || {}, // fallback to empty attributes for unAuth cognito user
            ref: getUserRef(dbUserId as string, internalUsers || []),
          },
          userId: authUser?.getUsername() || '',
          data: otherData,
        });

        if (portalSettings) {
          dispatch(loadPortalSettingsAction([portalSettings]));
        }

        if (messageSettings) {
          dispatch(setMessageSettingsAction([messageSettings]));
        }

        if (crmTableProperties && crmTableProperties.structFields) {
          dispatch(
            setCrmTablePropertiesFieldsAction(crmTableProperties.structFields),
          );
          dispatch(setCrmTablePropertiesIdAction(crmTableProperties.id));
        } else {
          dispatch(setCrmTablePropertiesFieldsAction(DefaultCRMColumnSettings));
        }
      },
      providesTags: [ApiTags.otherUserData],
    }),
    getUserPermissionData: build.query<UserGetData, string>({
      query: (cognitoUserId) => ({
        path: `/v1/users/anyType/${cognitoUserId}/data`,
        method: ApiMethods.get,
        options: {
          queryStringParameters: {
            includeArchived: true,
            dataType: 'permissions',
          },
        },
      }),
      transformResponse: (response: AxiosResponse) => response.data,
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        let userPermissionsDataResponse;
        try {
          userPermissionsDataResponse = await queryFulfilled;
        } catch (error) {
          handleQueryError(error as AxiosError, dispatch);
        }
        if (!userPermissionsDataResponse?.data) return;
        const { permissions } = userPermissionsDataResponse.data;
        dispatch({
          type: UPDATE_USER_PERMISSIONS,
          permissions,
        });
      },
      providesTags: [ApiTags.userPermissions],
    }),
    getUserSettings: build.query<any, string>({
      query: (userId) => ({
        path: `/users/${userId}/settings`,
        method: ApiMethods.get,
        options: {
          queryStringParameters: {},
        },
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const settingsResponse = await queryFulfilled;
        if (!settingsResponse.data) return;
        const { paymentInfo, ...newSettings } = settingsResponse.data;
        dispatch(SetPaymentInfo(paymentInfo));
        dispatch(loadSettingsSuccess(newSettings));
      },
      providesTags: [ApiTags.settings],
    }),
    getClientUsers: build.query<Array<User>, GetClientUsersOptions>({
      query: (opts) => ({
        path: `/v1/users/clients`,
        method: ApiMethods.get,
        options: {
          queryStringParameters: opts,
        },
      }),
      providesTags: [ApiTags.clientUsers],
    }),
    getStripeCustomer: build.query<
      Stripe.Customer,
      { clientId?: string; companyId?: string }
    >({
      query: (input) => ({
        path: `/users/customer`,
        method: ApiMethods.get,
        options: {
          queryStringParameters: input,
        },
      }),
      providesTags: [ApiTags.stripeCustomer],
    }),
    getCustomerSession: build.query<string, void>({
      query: () => ({
        path: `/customers/session`,
        method: ApiMethods.get,
        options: {},
      }),
    }),
    getCustomerPaymentMethods: build.query<Stripe.PaymentMethod[], string>({
      query: (customerId: string) => ({
        path: `/customers/${customerId}/paymentMethods`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.paymentMethods],
      transformResponse: (response: Stripe.PaymentMethod[]) => response || [],
    }),
    deletePaymentMethod: build.mutation<
      void,
      { paymentMethodId: string; clientId?: string; companyId?: string }
    >({
      query: (input) => ({
        path: `/paymentMethods/${input.paymentMethodId}`,
        method: ApiMethods.del,
        options: {
          queryStringParameters: {
            clientId: input.clientId,
            companyId: input.companyId,
          },
        },
      }),
      invalidatesTags: [ApiTags.paymentMethods, ApiTags.stripeCustomer],
    }),
    setDefaultPaymentMethod: build.mutation<
      void,
      { clientId?: string; companyId?: string; defaultPaymentMethod: string }
    >({
      query: (params) => ({
        path: `/users/defaultPaymentMethod`,
        method: ApiMethods.put,
        options: {
          body: {
            defaultPaymentMethod: params.defaultPaymentMethod,
          },
          queryStringParameters: {
            clientId: params.clientId,
            companyId: params.companyId,
          },
        },
      }),
      invalidatesTags: [ApiTags.stripeCustomer],
    }),
  }),
});

export const {
  useLazyGetCompaniesQuery,
  useLazyGetUsersQuery,
  useLazyGetUserNotificationsQuery,
  useLazyGetUserFileChannelsQuery,
  useLazyGetOtherUserDataQuery,
  useLazyGetUserSettingsQuery,
  useGetClientUsersQuery,
  useGetUserPermissionDataQuery,
  useGetStripeCustomerQuery,
  useGetCustomerSessionQuery,
  useGetCustomerPaymentMethodsQuery,
  useSetDefaultPaymentMethodMutation,
  useDeletePaymentMethodMutation,
} = usersApi;
