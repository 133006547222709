import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  CSSProperties,
} from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { FiPlus as Plus } from 'react-icons/fi';
import { HTMLInputEvent } from 'src/constants/dataTypes';
import Button from 'src/components/Button';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      display: 'none',
    },
    uploadButtonWrapper: {
      display: 'flex',
    },
    uploadButton: {
      '&.MuiButton-root.MuiButton-containedPrimary': {
        height: '40px',
      },
    },
    hidden: {
      position: 'absolute',
      top: -1000,
      zIndex: -100,
    },
  }),
);

interface UploadButtonProps {
  handleChange: (e: HTMLInputEvent | any) => void;
  isUploading?: boolean;
  htmlId: string;
  label: string;
  styles?: CSSProperties;
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  hasStartIcon?: boolean;
  hidden?: boolean;
  startIcon?: React.ReactElement;
}

export type UploadButtonHandle = {
  clickUploadButton: (directoryUpload?: boolean) => void;
  inputFileRef?: HTMLInputElement & { directory: boolean };
};

export const UploadButton = forwardRef<UploadButtonHandle, UploadButtonProps>(
  (props, ref) => {
    const classes = useStyles();
    const {
      handleChange,
      isUploading,
      htmlId,
      label,
      styles,
      color = 'primary',
      hasStartIcon = true,
      variant = 'contained',
      hidden = false,
      startIcon,
    } = props;

    const inputFile = useRef<HTMLInputElement & { directory: boolean }>(null);

    const triggerInputFileClick = () => {
      if (inputFile && inputFile.current) {
        inputFile.current.click();
      }
    };

    const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(event);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    };

    useImperativeHandle(ref, () => ({
      clickUploadButton(uploadOnlyDirectory = false) {
        if (inputFile.current) {
          inputFile.current.webkitdirectory = uploadOnlyDirectory;
          inputFile.current.directory = uploadOnlyDirectory;
        }
        triggerInputFileClick();
      },
      inputFileRef: inputFile.current || undefined,
    }));

    const uploadButtonClick = () => {
      triggerInputFileClick();
    };

    return (
      <Box className={hidden ? classes.hidden : ''} width="fit-content">
        <input
          className={classes.input}
          id="icon-button-file"
          type="file"
          multiple
          ref={inputFile}
          onChange={handleChangeFile}
        />
        <Button
          htmlId={htmlId}
          variant={variant}
          aria-label="upload picture"
          component="span"
          color={color}
          isLoading={isUploading}
          startIcon={startIcon || (hasStartIcon && <Plus />)}
          style={styles}
          disableElevation
          progressColor="inherit"
          onClick={uploadButtonClick}
        >
          {label}
        </Button>
      </Box>
    );
  },
);
