import {
  CustomTheme,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  SIGNATURE_COMPONENT_DEFAULT_HEIGHT,
  SIGNATURE_COMPONENT_DEFAULT_WIDTH,
} from 'src/constants';
import { RootState } from 'src/store';
import { SignaturePageComponent } from 'src/store/signaturePage/types';
import { NonHoverBorder } from 'src/theme/colors';
import { signaturePageContentWidth } from 'src/components/Signature/SignaturePageContent';
import { SCROLLBAR_SIZE } from 'src/components/Layout';

type Props = {
  clientSignaturePanelWidth: number;
};

const useStyles = makeStyles((theme: CustomTheme) => ({
  desktopLine: {
    position: 'absolute',
    height: 2,
    backgroundImage: `linear-gradient(to right, ${NonHoverBorder} 2px, rgba(255, 255, 255, 0) 0%)`,
    backgroundPosition: 'top',
    backgroundSize: '6px 2px', // backgroud image 6px wide and 2px tall
    backgroundRepeat: 'repeat-x',
    zIndex: theme.zIndex.contractSignatureComponent - 1, // Ensure that the connecting line is always positioned behind the signature components
  },
  mobileLine: {
    position: 'absolute',
    width: '2px',
    height: '100%',
    backgroundImage: `linear-gradient(${NonHoverBorder} 2px, rgba(255, 255, 255, 0) 0%)`,
    backgroundPosition: 'right',
    backgroundSize: '2px 6px', // backgroud image 2px wide and 6px tall
    backgroundRepeat: 'repeat-y',
    zIndex: theme.zIndex.contractSignatureComponent - 1, // Ensure that the connecting line is always positioned behind the signature components
  },
}));

const SignatureConnectingLine = ({ clientSignaturePanelWidth }: Props) => {
  const classes = useStyles();
  const [activeComponent, setActiveComponent] = React.useState<
    SignaturePageComponent | undefined
  >(undefined);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { activeComponentKey, pageComponents } = useSelector(
    (state: RootState) => ({
      activeComponentKey: state.signaturePage.activeComponentKey,
      pageComponents: state.signaturePage.pageComponents,
    }),
  );

  // find active component using active key
  const getActiveComponent = () => {
    const component = pageComponents?.find((c) => c.key === activeComponentKey);

    // If component width and height is not available fallback to default width and height
    if (component) {
      const stagedComponent = { ...component };
      if (!stagedComponent.width) {
        stagedComponent.width = SIGNATURE_COMPONENT_DEFAULT_WIDTH;
      }

      if (!stagedComponent?.height) {
        stagedComponent.height = SIGNATURE_COMPONENT_DEFAULT_HEIGHT;
      }
      setActiveComponent(stagedComponent);
      return;
    }

    setActiveComponent(component);
  };

  React.useEffect(() => {
    getActiveComponent();
  }, [activeComponentKey]);

  // Calculating the space between the PDF area and the left sidebar in the client view.
  // To calculate this, we subtract the left sidebar width, PDF area, and scrollbar width from the viewport width.
  // This results in the left and right spacing around the PDF. Dividing by 2 gives us the left spacing.
  const spaceBetweenSidebarAndPDF =
    (window.innerWidth -
      (clientSignaturePanelWidth + signaturePageContentWidth)) /
    2;
  // To start our dotted line from right side of left sidebar
  // Since dotted line is positioned relative to PDF area
  const desktopLeft = -(spaceBetweenSidebarAndPDF - SCROLLBAR_SIZE);

  const desktopWidth =
    (activeComponent?.xPosition ?? 0) +
    (spaceBetweenSidebarAndPDF - SCROLLBAR_SIZE);

  if (!activeComponent) return null;

  if (isSmallScreen) {
    return (
      <div
        data-testid="signature-connect-line"
        className={classes.mobileLine}
        style={{
          left: activeComponent.xPosition + activeComponent.width / 2,
          top: activeComponent.yPosition + activeComponent.height,
        }}
      />
    );
  }

  return (
    <div
      data-testid="signature-connect-line"
      className={classes.desktopLine}
      style={{
        left: desktopLeft,
        top: activeComponent.yPosition + activeComponent.height / 2,
        width: desktopWidth,
      }}
    />
  );
};

export default SignatureConnectingLine;
