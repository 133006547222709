import React from 'react';
import {
  Toolbar,
  Grid,
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core';
import NavbarContent, {
  NavbarContentProps,
} from 'src/components/Navbar/NavbarContent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolBar: {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 4.5, 0, 4.5),
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 2),
      },
    },

    toolBarPadding: {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(
          0,
          4.5,
          0,
          0, // removing toolbar left padding so that it's child element can stretch to full width
        ),
      },
    },
  }),
);

const Navbar: React.FC<NavbarContentProps> = ({
  title,
  user,
  onOpenSidebarMobile,
  showSearchbar,
  BreadCrumbComponent,
  showBreadCrumb,
  showNavbarAuthActions,
  headerContent,
}) => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.toolBar}>
      <Grid container>
        {headerContent || (
          <NavbarContent
            title={title}
            user={user}
            onOpenSidebarMobile={onOpenSidebarMobile}
            showSearchbar={showSearchbar}
            BreadCrumbComponent={BreadCrumbComponent}
            showBreadCrumb={showBreadCrumb}
            showNavbarAuthActions={showNavbarAuthActions}
          />
        )}
      </Grid>
    </Toolbar>
  );
};

export default Navbar;
