import { TaskCodes } from 'src/components/HomePage/OnboardingTasksSection/constants';
import {
  APP_SETUP_PAGE,
  CRM_CLIENTS_PAGE,
  CUSTOMIZATION_PAGE,
  DOMAIN_PAGE,
  PAYMENTS_PAGE,
} from 'src/constants';
import { ensureUnreachable } from 'src/utils/common_utils';

// represents Task detail info which is show when a task is made active on onboarding setup
export interface TaskDetailInfo {
  title: string;
  description: string;
  buttonLabel: string;
  illustrationName: string;
  learnMoreLink: string;
  routePath: string;
}

// Takes the task code and enrich the details for the given task
// enrichment includes having description text, illustration, learn more link and redirect path for the action button
export function MapTaskCodeToTaskDetails(taskCode: TaskCodes): TaskDetailInfo {
  switch (taskCode) {
    case TaskCodes.TaskCodeCustomization:
      return {
        title: 'Customize your portal',
        description:
          'Edit the look and feel of your portal to create a branded experience for your clients.',
        buttonLabel: 'Start customizing',
        learnMoreLink:
          'https://www.copilot.com/guide/customization-and-setup#basic-customizations',
        illustrationName: 'customize_your_portal',
        routePath: CUSTOMIZATION_PAGE.path,
      };
    case TaskCodes.TaskCodeFirstClient:
      return {
        title: 'Create a test client',
        description:
          'For your first client, invite yourself to familiarize yourself with the client experience.',
        buttonLabel: `Create client`,
        learnMoreLink: 'https://www.copilot.com/guide/test-run',
        illustrationName: 'create_your_first_client',
        routePath: `${CRM_CLIENTS_PAGE.path}?openCreateModal=true`,
      };
    case TaskCodes.TaskCodeFirstApp:
      return {
        title: 'Configure your apps',
        description:
          'Add apps like Calendly, Airtable, ClickUp, Jotform, Google Data Studio, Notion, and 100s of others.',
        buttonLabel: 'Add an app',
        learnMoreLink: 'https://www.copilot.com/guide/intro-to-apps',
        illustrationName: 'add_your_first_app',
        routePath: `${APP_SETUP_PAGE.path}/new`,
      };
    case TaskCodes.TaskCodeCustomDomain:
      return {
        title: 'Connect custom domain',
        description:
          'Switch to a custom domain to make your portal accessible on a subdomain on your own website.',
        buttonLabel: 'Connect your domain',
        learnMoreLink: 'https://www.copilot.com/guide/custom-domains',
        illustrationName: 'connect_your_custom_domain',
        routePath: DOMAIN_PAGE.path,
      };
    case TaskCodes.TaskCodeSetupWithPayments:
      return {
        title: 'Get setup with Payments',
        description:
          'Connect your bank or Stripe account so that you can start invoicing clients and get paid.',
        buttonLabel: 'Add a payout account',
        learnMoreLink: 'https://www.copilot.com/guide/copilot-billing-app',
        illustrationName: 'get_setup_with_payments',
        routePath: PAYMENTS_PAGE.path,
      };
    default:
      ensureUnreachable(taskCode);
  }
}
