import React from 'react';
import { makeStyles, createStyles, Theme, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import MemoBaseTypography from 'src/components/Text/BaseTypography';
import {
  BaseTextField,
  BaseTextFieldProps,
} from 'src/components/TextField/BaseTextField';
import {
  DividersAndCardBorders,
  GraySmall,
  HoverBorder,
  redDark,
} from 'src/theme/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputContainer: {
      marginBottom: 16,
    },
    sharpInput: {
      // customize the input height and padding
      '&.MuiTextField-root .MuiOutlinedInput-root': {
        borderRadius: 0, // sharp input has no border radius
      },
      '& .MuiInputBase-input': {
        '&::placeholder': {
          color: GraySmall,
          opacity: 1,
        },
        '&:focus': {
          // hide placeholder on focus
          '&::placeholder': {
            opacity: 0,
          },
        },
        // input text color disabled state
        '&:disabled': {
          color: HoverBorder,
          // fix issue with safari browser where disabled input text was not visible
          WebkitTextFillColor: HoverBorder,
          '&::placeholder': {
            color: HoverBorder,
            opacity: 1,
          },
        },
      },
      // input border disabled state
      '& .Mui-disabled': {
        '& fieldset': {
          borderColor: `${DividersAndCardBorders} !important`,
        },
      },
      zIndex: 2, // the input should be above the label element
    },
    '@keyframes showLabel': {
      '0%': {
        transform: 'translateY(100%)',
      },
      '50%': {
        transform: 'translateY(50%)',
      },
      '100%': {
        transform: 'translateY(0)',
        opacity: 1,
      },
    },
    '@keyframes hideLabel': {
      '0%': {
        transform: 'translateY(0)',
      },
      '50%': {
        transform: 'translateY(50%)',
      },
      '100%': {
        transform: 'translateY(100%)',
        opacity: 0,
      },
    },
    inputLabel: {
      zIndex: 1,
      background: theme.palette.primary.main,
      width: 'fit-content',
      color: 'white',
      padding: theme.spacing(0, 0.5),
      // input label should be hidden
      // initially and shows on focus
      opacity: 0,
    },
    // this animation class applies when the input is focused
    animateShowLabel: {
      animation: `$showLabel 300ms ${theme.transitions.easing.easeInOut}`,
      animationFillMode: 'forwards',
    },
    // this animation class applies when the input is blurred
    animateHideLabel: {
      animation: `$hideLabel 300ms ${theme.transitions.easing.easeInOut}`,
      animationFillMode: 'forwards',
    },
    // when input has invalid value, its placeholder should be red
    redPlaceholder: {
      '& .MuiInputBase-input': {
        '&::placeholder': {
          color: redDark,
          opacity: 1,
        },
      },
    },
    selectPopover: {
      borderRadius: 0,
    },
    selectPlaceholder: {
      '& .MuiSelect-root': {
        color: GraySmall,
      },
    },
  }),
);

const SELECT_PLACEHOLDER = 'placeholder-item';
/**
 * This component is a TextField with sharp border.
 * It controls an animated label that appears when the input is focused.
 * @returns {React.FC<BaseTextFieldProps>}
 */
export const SharpOutlinedTextField = ({
  label,
  className,
  children,
  ...otherProps
}: BaseTextFieldProps) => {
  const classes = useStyles();
  const [showLabel, setShowLabel] = React.useState(false);

  const isSelectDropdown = otherProps.select;
  const getInputValue = () => {
    const { value } = otherProps;
    if (isSelectDropdown) {
      return value || SELECT_PLACEHOLDER;
    }
    return otherProps.value;
  };
  const inputHasError = Boolean(otherProps.error);

  const handleBlur: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setShowLabel(false);
    const { onBlur } = otherProps;
    if (onBlur) onBlur(e);
  };

  const handleFocus: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setShowLabel(true);
    const { onFocus } = otherProps;
    if (onFocus) onFocus(e);
  };

  return (
    <div className={classes.inputContainer}>
      {/* When input has error then its label should not show */}
      <MemoBaseTypography
        className={clsx(classes.inputLabel, {
          [classes.animateShowLabel]: showLabel,
          [classes.animateHideLabel]: !showLabel || inputHasError,
        })}
        fontType="10Medium"
      >
        {label}
      </MemoBaseTypography>
      <BaseTextField
        variant="outlined"
        className={clsx(classes.sharpInput, className, {
          [classes.selectPlaceholder]:
            isSelectDropdown && getInputValue() === SELECT_PLACEHOLDER, // select input's placeholder style
        })}
        {...otherProps}
        SelectProps={{
          MenuProps: {
            PopoverClasses: {
              paper: classes.selectPopover,
            },
          },
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        helperText={null}
        value={getInputValue()}
      >
        {/**
         * Mui textfield select component does not support showing placeholder text
         * This is a workaround to show the placeholder text. To do so, we add a placeholder item
         * that will be selected when the value is undefined. To not show the placeholder item
         * in the dropdown list we set the display to none.
         * More dicussion here: https://copilotplatforms.slack.com/archives/C04EVQ5LJ80/p1722447909080489
         */}
        {isSelectDropdown && !showLabel && (
          <MenuItem
            value="placeholder-item"
            style={{
              display: 'none',
            }}
          >
            {label}
          </MenuItem>
        )}
        {children}
      </BaseTextField>
    </div>
  );
};
