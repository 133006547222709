import React, { useEffect } from 'react';
import { CheckIcon, ForwardIcon, AlertFilledIcon } from 'src/components/Icons';
import AlertModal from 'src/components/Modals/AlertModal';
import * as Constants from 'src/constants';
import useNavigate from 'src/hooks/useNavigate';

const CompletedActionKeys = {
  SubscriptionSetup: 'subscriptionSetup',
  PaymentSetup: 'paymentSetup',
  AppConnectSuccess: 'appConnectSuccess',
  AppConnectFailure: 'appConnectFailure',
};

interface CompletedActionOption {
  bodyText: string;
  isFailure?: boolean;
  path?: any;
}

const CompletedActionOptions: Record<string, CompletedActionOption> = {
  [CompletedActionKeys.SubscriptionSetup]: {
    bodyText: Constants.SUBSCRIPTION_ACTIVATED,
    path: Constants.HOME_PAGE,
  },
  [CompletedActionKeys.PaymentSetup]: {
    bodyText: Constants.PAYMENT_METHOD_UPDATED,
  },
  [CompletedActionKeys.AppConnectSuccess]: {
    bodyText: Constants.APP_CONNECT_SUCCESS,
  },
  [CompletedActionKeys.AppConnectFailure]: {
    bodyText: Constants.APP_CONNECT_FAIL,
    isFailure: true,
    path: Constants.APPS_PAGE,
  },
};

interface ModalState {
  isOpen: boolean;
  bodyText: string;
  title: string;
  isFailure?: boolean;
  path?: any;
  dialogContentProps: { id: string };
}

const initialModalSate: ModalState = {
  isOpen: false,
  bodyText: '',
  title: '',
  dialogContentProps: { id: '' },
};

const CompleteExternalActionModal = () => {
  const { navigate } = useNavigate();
  const [modalData, setModalData] =
    React.useState<ModalState>(initialModalSate);

  /**
   * Wait for route change and search the completedAction query
   * if completedAction query found than show the alert Modal
   *
   * Why here we are not using the RouteContext:
   * If user is inside files module with selected some channel.
   * In this case the route will be: host/files?channelId=us-west-2_82RKYmV8C/7202177f-bab3-4239-bc5e-0c06b29799e1/none
   * So this route will be with a query. And after the user If clicks to settings->plans and change the plan,
   * he will be redirected to the checkout. After finishing with checkout he will be redirected to the url above
   * with concatenated ?completedAction=subscriptionSetup and the whole link
   * will be: host/files?channelId=us-west-2_82RKYmV8C/7202177f-bab3-4239-bc5e-0c06b29799e1/none?completedAction=subscriptionSetup.
   * When RouteContext will be searching for the queries,
   * it will return only: channelId: us-west-2_82RKYmV8C/7202177f-bab3-4239-bc5e-0c06b29799e1/none?completedAction=subscriptionSetup
   * because our concatenated ?completedAction=subscriptionSetup will be simple string
   */
  useEffect(() => {
    if (window.location && window.location.href) {
      Object.entries(CompletedActionOptions).forEach(
        ([completedAction, actionOptions]) => {
          const actionModalData: ModalState = {
            isOpen: true,
            bodyText: '',
            title: 'Success!',
            isFailure: false,
            dialogContentProps: { id: `dialog-${completedAction}` },
          };
          let location = '';
          if (window.location.href.indexOf(completedAction) !== -1) {
            if (completedAction === CompletedActionKeys.SubscriptionSetup) {
              console.info('subscription setup complete');
            }
            actionModalData.bodyText = actionOptions.bodyText;
            actionModalData.isFailure = actionOptions.isFailure;
            if (actionOptions.isFailure) {
              actionModalData.title = 'Something went wrong';
            }
            actionModalData.path = actionOptions.path;
            setModalData(actionModalData);
            location = window.location.pathname.replace(completedAction, '');
            navigate(location);
          }
        },
      );
    }
  }, [window.location]);

  /**
   * handle modal success click to close modal and navigate to new page
   * if required
   */
  const handleSuccessAction = () => {
    setModalData(initialModalSate);
    if (modalData.path) {
      navigate(modalData.path.path);
    }
  };

  return (
    <AlertModal
      title={modalData.title}
      open={modalData.isOpen}
      onSuccess={handleSuccessAction}
      successButtonLabel={`Continue to ${
        modalData.path ? modalData.path.label : 'my Portal'
      }`}
      Icon={modalData.isFailure ? AlertFilledIcon : CheckIcon}
      successButtonEndIcon={<ForwardIcon />}
      contentWrapperProps={modalData.dialogContentProps}
    >
      {modalData.bodyText}
    </AlertModal>
  );
};

export default CompleteExternalActionModal;
