import React from 'react';
import { DomainVerificationStatus } from 'src/constants';
import { useGetDomainsQuery, useGetEmailDomainsQuery } from 'src/services/api';

export const useCustomDomains = () => {
  const { data: domains = [] } = useGetDomainsQuery();
  const { data: emailDomains = [] } = useGetEmailDomainsQuery();

  const isCustomDomainConnected = React.useMemo(() => {
    const anyCustomEmailDomain = emailDomains.find(
      (d) =>
        d.id !== 'default' &&
        d.emailDomainMetadata?.emailDomainStatus ===
          DomainVerificationStatus.verified,
    );

    const anyCustomDomain = domains.find(
      (d) =>
        d.id !== 'default' &&
        d.domainMetadata?.status === DomainVerificationStatus.verified,
    );

    return !!anyCustomDomain || !!anyCustomEmailDomain;
  }, [domains, emailDomains]);

  const basePortalDomain = React.useMemo(() => {
    if (!domains) return null;
    if (domains.length === 0) return null;

    const portalDomain = domains.find(
      (d) =>
        // find the domain that is verified
        d.domainMetadata?.status === DomainVerificationStatus.verified &&
        // if custom domain is connected, then find the domain that is not default
        // if custom domain is not connected, then find the domain that is default
        (isCustomDomainConnected ? d.id !== 'default' : d.id === 'default'),
    );
    if (!portalDomain) return null;

    return `${portalDomain.domainMetadata?.subdomain}.${portalDomain.domainMetadata?.domain}`;
  }, [domains]);

  const numDomainsConnected = React.useMemo(() => {
    return (
      domains.filter(
        (d) =>
          d.id !== 'default' &&
          d.domainMetadata?.status === DomainVerificationStatus.verified,
      ).length +
      emailDomains.filter(
        (d) =>
          d.id !== 'default' &&
          d.emailDomainMetadata?.emailDomainStatus ===
            DomainVerificationStatus.verified,
      ).length
    );
  }, [domains, emailDomains]);

  return {
    domains,
    emailDomains,
    isCustomDomainConnected,
    basePortalDomain,
    numDomainsConnected,
  };
};
