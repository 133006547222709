import React from 'react';
import { IconButton, Typography, makeStyles } from '@material-ui/core';
import clsx from 'classnames';
import { useFileIcon } from '../../hooks/useFileIcon';
import { RegularCardBox } from '../Cards/RegularCardBox';
import { getHumanReadableFileSize } from '../Files/helpers';
import { CloseIcon } from '../Icons';
import { typography13MediumStyle } from '../Text';
import BaseTypography from '../Text/BaseTypography';
import {
  BlackHeadings,
  HoverBackground,
  HoverBorder,
  NonHoverBackground,
  NonHoverBorder,
} from '../../theme/colors';
import { DownloadAttachmentIcon } from 'src/components/Icons/DownloadAttachmentIon';

const useStyles = makeStyles(() => ({
  fileItemCard: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '10px 31px 10px 16px',
    gap: '12px',
    maxWidth: 176,
    maxHeight: 62,
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
      background: HoverBackground,
    },
  },
  iconContainer: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 7,
    right: 7,
    padding: 0,
    '&:hover': {
      backgroundColor: NonHoverBackground,
    },
  },
  closeIcon: {
    color: BlackHeadings,
    fontSize: 6,
    visibility: 'hidden',
  },
  fileItemTextContainer: {
    overflow: 'hidden',
  },

  cardContainer: {
    position: 'relative',
    border: `1px solid ${NonHoverBorder}`,
    borderRadius: 5,
    '&:hover': {
      borderColor: HoverBorder,
      '& $closeIcon': {
        visibility: 'visible',
      },
    },
  },
  downloadIconContainer: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '48%',
    right: 8,
    padding: 0,
    '&:hover': {
      backgroundColor: NonHoverBackground,
    },
  },
}));

export type FileInputMetaData = {
  name: string;
  size: number;
  extension: string;
  readOnly: boolean;
  fileKey?: string;
};

interface FileInputItemProps extends FileInputMetaData {
  onClick?: () => void;
  onRemove: () => void;
  className?: string;
  onDownload?: () => void;
}

/**
 * This component is responsible for rendering the dropped/input files.
 * It renders the file name, file size and icons (file meta data)
 * @param name: string - file name
 * @param size: number - file size
 * @param extension: string - file extension: used to get the file icon to be rendered.
 * @returns {JSX.Element} the file input card item
 */
export const FileInputUploadItem = ({
  name,
  size,
  extension,
  onClick,
  onRemove,
  readOnly,
  fileKey,
  className = '',
  onDownload,
}: FileInputItemProps) => {
  const { getIcon } = useFileIcon();
  const classes = useStyles();
  return (
    <div className={classes.cardContainer} key={fileKey}>
      <RegularCardBox
        onClick={onClick}
        className={clsx(classes.fileItemCard, className)}
      >
        {getIcon(extension)}
        <div className={classes.fileItemTextContainer}>
          <Typography
            style={{
              ...typography13MediumStyle,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {name}
          </Typography>
          <BaseTypography fontType="12Regular">
            {getHumanReadableFileSize(size, true)}
          </BaseTypography>
        </div>
      </RegularCardBox>
      <div>
        {!readOnly && (
          <IconButton className={classes.iconContainer} onClick={onRemove}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        )}
        {readOnly && (
          <IconButton
            className={classes.downloadIconContainer}
            onClick={onDownload}
          >
            <DownloadAttachmentIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};
