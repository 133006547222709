import React from 'react';
import { useSelector } from 'react-redux';
import {
  FiEye as PreviewIcon,
  FiDownload as DownloadIcon,
} from 'react-icons/fi';
import { compact } from 'lodash';
import { FilesActionsRendererProps } from 'src/components/AgGrid/Renderer/types';
import { BaseActionsMenuButton } from 'src/components/Dropdowns';
import {
  FILE_TYPE,
  FILES_ACTION,
  FOLDER_FILE_KEY,
  FILE_ACTION_DISABLE_TOOLTIP_LABEL,
} from 'src/constants';
import { TrashIcon, EditIcon, CopyIcon } from 'src/components/Icons';
import { FileUtils } from 'src/utils';
import { RootState } from 'src/store/reduxTypes';
import { FileResponse, FilesTableActionInput } from 'src/store/files/types';
import {
  getExtensionFromFileKey,
  getFileNameFromFileKey,
} from 'src/components/Files/helpers';

const getFilesInFolder = (fileData: any, files: Array<FileResponse>) =>
  FileUtils.getAllFilesFromPath({
    files,
    sourcePath: fileData.filePath.join('/'),
    fileType: fileData.type,
    fileKey: '',
    ignoreLinks: true,
  }).filter((f) => f.fields.fileKey !== FOLDER_FILE_KEY);

/**
 * This function handle saving new custom field value
 * @param data row data for this table row, used to determine if this is a file or a folder
 * @param files from the redux state, a list of all files
 * @param filesTableAction function to trigger a download
 */
const getDownloadFunction = (
  fileData: any,
  files: Array<FileResponse>,
  filesTableAction: (data: FilesTableActionInput) => void | null,
) => {
  if (fileData.type === FILE_TYPE.FOLDER) {
    // using the path of the folder we want to find all FILE_METADATA objects nested inside this folder
    const formattedFileDataInFolder = getFilesInFolder(fileData, files)
      // map them into objects containing key, path, and identityID which the API will use to create a zip file
      .map((f) => ({
        key: f.fields.fileKey,
        name: `${
          f.fields.fileName || getFileNameFromFileKey(f.fields.fileKey)
        }${getExtensionFromFileKey(f.fields.fileKey)}`,
        path: f.fields.path
          ? f.fields.path
              .split('/')
              .slice(fileData.filePath.length) // slice path of the file based on path depth of the targeted folder for download
              .join('/') // if path is present on the file object, create an altered path param omitting the first (parent) directory which API will already have
          : '',
        identityID: f.identityId,
      }));
    // return a func that will call the files table action with parameters that will trigger the .zip file download flow
    return formattedFileDataInFolder.length > 0
      ? () =>
          filesTableAction({
            actionType: FILES_ACTION.DOWNLOAD,
            fileData,
            fileBundleData: formattedFileDataInFolder,
            bundleName: fileData.fileName,
          })
      : null;
  }
  return !fileData.fileUrl
    ? () => filesTableAction({ actionType: FILES_ACTION.DOWNLOAD, fileData })
    : null;
};
export const FilesActionsRenderer: React.FC<FilesActionsRendererProps> = ({
  isClient,
  fileSettings,
  data,
  removeFile,
  renameFile,
  copyFile,
  filesTableAction,
  onActionMenuClick,
}) => {
  const allFiles = useSelector((state: RootState) => state.files.items);
  const fileType = data.type;

  const downloadFunction = getDownloadFunction(
    data,
    allFiles,
    filesTableAction,
  );

  const downloadFile = React.useCallback(downloadFunction || (() => null), [
    allFiles,
  ]);
  const isEmptyFolder =
    fileType === FILE_TYPE.FOLDER &&
    getFilesInFolder(data, allFiles).length === 0;

  const previewFileActionLabel = data.fileUrl
    ? FILES_ACTION.VIEW
    : FILES_ACTION.PREVIEW;

  const previewFile =
    fileType !== FILE_TYPE.FOLDER
      ? () =>
          filesTableAction({
            actionType: previewFileActionLabel,
            fileData: data,
            fileLinkURLs: data.fileUrl ? [data.fileUrl] : undefined,
          })
      : null;

  const isDownloadAvailable = downloadFunction !== null;

  const fileOrLinkSuffix = isDownloadAvailable ? 'file' : 'link';
  const itemLabel = data.type === FILE_TYPE.FILE ? fileOrLinkSuffix : 'folder';
  const viewLabel = isDownloadAvailable ? `Preview ${itemLabel}` : 'Open link';
  const previewFileAction = previewFile
    ? [
        {
          name: viewLabel,
          icon: <PreviewIcon />,
          onClick: previewFile,
        },
      ]
    : [];

  const downloadFileAction =
    isDownloadAvailable || itemLabel === 'folder'
      ? [
          {
            name: `Download`,
            icon: <DownloadIcon />,
            onClick: downloadFile,
            isDisabled: isEmptyFolder,
            tooltipLabel: isEmptyFolder
              ? 'Folder with no contents cannot be downloaded.'
              : '',
          },
        ]
      : [];

  const handleRemoveFileClicked = () => {
    removeFile(data);
  };

  const handleRenameFileClicked = () => {
    renameFile(data);
  };

  const handleCopyFileClicked = () => {
    copyFile(data);
  };

  const deleteSettingDisabled = React.useMemo(() => {
    // base state is that delete is disabled if file delete is disabled
    let isDeleteDisabled = Boolean(fileSettings?.clientFileDeleteDisabled);

    // this can be overridden if folder update disable is turned off and fileType is folder
    // ...but only if this folder is empty. To delete a non-empty folder the user needs file and folder update ability
    if (fileType === FILE_TYPE.FOLDER) {
      isDeleteDisabled =
        Boolean(fileSettings?.clientFolderUpdateDisabled) ||
        (!isEmptyFolder && Boolean(fileSettings?.clientFileDeleteDisabled));
    }

    return isDeleteDisabled;
  }, [fileSettings, isEmptyFolder]);

  const actions = [
    ...downloadFileAction,
    ...previewFileAction,
    {
      name: `Rename ${itemLabel}`,
      onClick: handleRenameFileClicked,
      icon: <EditIcon />,
      isDisabled:
        isClient &&
        fileType === FILE_TYPE.FOLDER &&
        Boolean(fileSettings?.clientFolderUpdateDisabled),
      tooltipLabel: FILE_ACTION_DISABLE_TOOLTIP_LABEL,
    },
    {
      name: `Copy ${itemLabel}`,
      icon: <CopyIcon />,
      onClick: handleCopyFileClicked,
      isDisabled: isClient || itemLabel === 'link',
      tooltipLabel: FILE_ACTION_DISABLE_TOOLTIP_LABEL,
    },
    {
      name: `Delete ${itemLabel}`,
      onClick: handleRemoveFileClicked,
      icon: <TrashIcon />,
      isDelete: true,
      isDisabled: isClient && deleteSettingDisabled,
      tooltipLabel: FILE_ACTION_DISABLE_TOOLTIP_LABEL,
    },
  ];

  return (
    <BaseActionsMenuButton
      onActionMenuClick={onActionMenuClick}
      actions={compact(actions)}
    />
  );
};

export default FilesActionsRenderer;
