import { appAPI, ApiTags, ApiError, ApiMethods } from '.';
import { notify } from 'src/clients/ApiService';
import {
  FormFields,
  FormNotificationResponse,
  FormResponse,
  FormResponseStatus,
  FormTemplate,
  FormVisibility,
} from 'src/components/FormsV2/formsTypes';
import { NOTIFICATIONS_STATUSES } from 'src/constants';
import { FormCompleteNotification } from 'src/store/notifications/types';
import { ensureApiError } from 'src/utils/Errors';

export const formsApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    getForms: build.query<FormTemplate[], void>({
      query: () => ({
        path: `/forms`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.forms],
      // to prevent the null forms payload from being set to forms cache state,
      // we transform the response to an empty array
      transformResponse: (response: FormTemplate[]) => response || [],
    }),
    getFormById: build.query<FormTemplate, string>({
      query: (formId) => ({
        path: `/forms/${formId}`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.form_detail],
    }),
    updateForm: build.mutation<FormTemplate, FormTemplate>({
      query: (form) => ({
        path: `/forms/${form.id}`,
        method: ApiMethods.put,
        options: {
          body: {
            ...form,
          },
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const update = await queryFulfilled;

          dispatch(
            formsApi.util.updateQueryData('getForms', undefined, (draft) => {
              const index = draft.findIndex(
                (form) => form.id === update.data.id,
              );
              draft.splice(index, 1, update.data as unknown as FormTemplate);
            }),
          );
          notify({
            status: 'success',
            successMessage: 'Form updated successfully.',
            dispatch,
          });
        } catch (error) {
          const e = ensureApiError(error);

          const errorMessage = e.message;
          notify({
            status: 'error',
            errorMessage: errorMessage || 'Error occurs while creating form.',
            dispatch,
          });
        }
      },
      invalidatesTags: [ApiTags.form_detail],
    }),
    createForm: build.mutation<FormTemplate, { fields: FormFields }>({
      query: (data) => ({
        path: '/forms',
        method: ApiMethods.post,
        options: {
          body: {
            ...data,
          },
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(
            formsApi.util.updateQueryData('getForms', undefined, (draft) => {
              // update the forms template state with the
              // api response data. The reason that we don't
              // use the data from the queryFulfilled is because
              // it doesn't contain all properties like 'owner'.
              if (result.data.id) {
                // insert the new form at the beginning of the array
                draft.unshift(result.data);

                notify({
                  status: 'success',
                  successMessage: 'Form created successfully.',
                  dispatch,
                });
              }
            }),
          );
        } catch (err) {
          const error = err as ApiError;
          const errorMessage = error?.error?.data?.message;
          notify({
            status: 'error',
            errorMessage: errorMessage || 'Error occurs while creating form.',
            dispatch,
          });
        }
      },
    }),
    getFormResponses: build.query<FormResponse[], void>({
      query: () => ({
        path: `/form-responses`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.form_responses],
      // to prevent the null forms payload from being set to forms cache state,
      // we transform the response to an empty array
      transformResponse: (response: FormResponse[]) => response || [],
    }),
    getResponsesByFormId: build.query<FormResponse[], string>({
      query: (formId) => ({
        path: `/form-responses?formId=${formId}`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.form_responses],
      // to prevent the null forms payload from being set to forms cache state,
      // we transform the response to an empty array
      transformResponse: (response: FormResponse[]) => response || [],
    }),
    getResponsesByClientId: build.query<FormResponse[], string>({
      query: (clientId) => ({
        path: `/form-responses?userId=${clientId}`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.form_responses],
      // to prevent the null forms payload from being set to forms cache state,
      // we transform the response to an empty array
      transformResponse: (response: FormResponse[]) => response || [],
    }),
    cancelFormResponseRequest: build.mutation<void, string>({
      query: (responseId) => ({
        path: `/form-responses/${responseId}`,
        method: ApiMethods.del,
        options: {},
      }),
      invalidatesTags: [ApiTags.form_responses],
    }),
    // this mutation is used to submit form response by client user
    saveFormResponse: build.mutation<FormResponse, Partial<FormResponse>>({
      query: (data) => ({
        path: `/form-responses/${data.ref}`,
        method: ApiMethods.put,
        options: {
          body: {
            ...data,
          },
        },
      }),
      async onQueryStarted(
        { ref: responseRef, fields },
        { dispatch, queryFulfilled },
      ) {
        const previousStatus = fields?.status;
        try {
          const result = await queryFulfilled;
          // when form is submitted successfully, we need to update form responses cache
          if (result?.data.id) {
            notify({
              status: 'success',
              successMessage: 'Form response has been saved successfully.',
              dispatch,
            });

            dispatch(
              formsApi.util.updateQueryData(
                'getFormResponses',
                undefined,
                (draft) => {
                  // Six different scenarios:
                  // CASE-I: visibility is set to requestedClients and allowMultipleSubmissions is false, Replace the response;
                  // CASE-II: visibility is set to requestedClients and allowMultipleSubmissions is true, Replace the response;
                  // CASE-III: visibility is set to allClients and allowMultipleSubmissions is false, Replace the response;
                  // CASE-IV: visibility is set to allClients and allowMultipleSubmissions is true, Push the response;
                  // CASE-V: visibility is set to allClients and allowMultipleSubmissions is true but response has always open variant (indicated by previousStatus being PENDING), Replace the response;
                  // CASE-VI: visibility is set to allClients and allowMultipleSubmissions is false but response has open variant (indicated by previousStatus being PENDING), Push the response;
                  if (
                    result.data.fields.allowMultipleSubmissions &&
                    result.data.fields.visibility ===
                      FormVisibility.AllClients &&
                    previousStatus !== FormResponseStatus.Pending
                  ) {
                    draft.push(result.data);
                  } else {
                    // when form type is not always on, we need to update the response
                    const filteredDraft = draft.filter(
                      (response: FormResponse) => response.ref !== responseRef,
                    );
                    // clear the draft state and push the updated response
                    draft.splice(0, draft.length, ...filteredDraft);
                    draft.push(result.data);
                  }
                },
              ),
            );
          }
        } catch (err) {
          const error = err as ApiError;
          const errorMessage = error?.error?.data?.message;
          notify({
            status: 'error',
            errorMessage: errorMessage || 'Error occurs while submitting form.',
            dispatch,
          });
        }
      },
      // invalidatesTags: [ApiTags.form_responses], // after saving form response, we need to invalidate form responses cache to
    }),
    shareForm: build.mutation<void, { formId: string; clientIds: string[] }>({
      query: (data) => ({
        path: '/form-responses',
        method: ApiMethods.post,
        options: {
          body: {
            ...data,
          },
        },
      }),
      invalidatesTags: [ApiTags.form_responses],
    }),
    deleteForm: build.mutation<void, string>({
      query: (formId) => ({
        path: `/forms/${formId}`,
        method: ApiMethods.del,
        options: {},
      }),
      async onQueryStarted(formId, { dispatch, queryFulfilled }) {
        // optimistically filter out the deleted form template object from the cache.
        const patch = dispatch(
          formsApi.util.updateQueryData('getForms', undefined, (draft) => {
            const index = draft.findIndex((form) => form.id === formId);
            draft.splice(index, 1);
          }),
        );

        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Form has been deleted successfully.',
            dispatch,
          });
        } catch (err) {
          const error = err as ApiError;
          const errorMessage = error?.error?.data?.message;
          notify({
            status: 'error',
            errorMessage: errorMessage || 'Error occurs while deleting form.',
            dispatch,
          });
          // if there is an error, we need to revert the state
          patch.undo();
        }
      },
    }),
    markFormNotificationsRead: build.mutation<
      FormNotificationResponse,
      FormCompleteNotification[]
    >({
      query: (data) => ({
        path: `/entities/NOTIFY_FORM_COMPLETE?updateState=${NOTIFICATIONS_STATUSES.READ}`,
        method: ApiMethods.put,
        options: {
          body: {
            data,
          },
        },
      }),
      invalidatesTags: [ApiTags.form_responses],
    }),
  }),
});

export const {
  useGetFormsQuery,
  useGetFormResponsesQuery,
  useCreateFormMutation,
  useShareFormMutation,
  useSaveFormResponseMutation,
  useCancelFormResponseRequestMutation,
  useGetResponsesByFormIdQuery,
  useDeleteFormMutation,
  useGetFormByIdQuery,
  useUpdateFormMutation,
  useGetResponsesByClientIdQuery,
  useMarkFormNotificationsReadMutation,
} = formsApi;
