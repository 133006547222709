/* eslint-disable react/no-array-index-key */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import * as Colors from 'src/theme/colors';
import BaseTypography, { FontType } from 'src/components/Text/BaseTypography';

export interface DescriptionSectionTitleInlinedComponentProps {
  titleInlinedComponent?: React.ReactNode;
}

interface BaseDescriptionProps
  extends DescriptionSectionTitleInlinedComponentProps {
  title: React.ReactNode;
  titleInlinedComponent?: React.ReactNode;
  titleFontType?: FontType;
  descriptions?: string[] | React.ReactNode[];
}

const useStyles = makeStyles(() => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  descriptionText: {
    color: Colors.GraySmall,
  },
}));

const Description: React.FC<BaseDescriptionProps> = ({
  title,
  titleInlinedComponent,
  descriptions,
  titleFontType = '18Medium',
}) => {
  const classes = useStyles();
  return (
    <div data-testid="description-text-wrapper">
      <div className={classes.titleWrapper}>
        <BaseTypography fontType={titleFontType}>{title}</BaseTypography>
        {titleInlinedComponent}
      </div>
      {descriptions && descriptions.length > 0
        ? descriptions.map((description) =>
            typeof description === 'string' ? (
              <div key={description}>
                <BaseTypography className={classes.descriptionText}>
                  {description}
                </BaseTypography>
              </div>
            ) : (
              description
            ),
          )
        : null}
    </div>
  );
};

export const BaseDescription = React.memo(Description);
