import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { IconWrapper } from 'src/components/AgGrid';
import BaseTypography from 'src/components/Text/BaseTypography';
import { NotificationTableRowData } from 'src/components/Inbox/InboxContent';

interface NotificationTableNameRendererProps {
  data: NotificationTableRowData;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
);

export const NotificationTableNameRenderer = (
  props: NotificationTableNameRendererProps,
) => {
  const {
    data: { name, fileExtension },
  } = props;
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {fileExtension && (
        <div className={classes.icon}>
          <IconWrapper extension={fileExtension} />
        </div>
      )}
      <BaseTypography fontType="13Regular">{name}</BaseTypography>
    </div>
  );
};
