import React from 'react';
import BaseTypography from 'src/components/Text/BaseTypography';
import { parseMessageToHTML } from 'src/utils/UrlUtils';

type Props = {
  description: string;
  className?: string;
};

export const RichTextDescription = ({ description, className = '' }: Props) => {
  const parsedDescription = parseMessageToHTML(description);
  return (
    <BaseTypography
      fontType="13Regular"
      className={['text-gray-small', className].join(' ')}
    >
      <div
        className="[&>a]:text-web-link [&>a]:hover:text-black-heading [&>img]:max-w-[200px]"
        dangerouslySetInnerHTML={{ __html: parsedDescription }}
      />
    </BaseTypography>
  );
};
