import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Theme,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { ExpandLess } from '@material-ui/icons';
import { GraySmall, NonHoverBorder } from 'src/theme/colors';
import MemoBaseTypography from 'src/components/Text/BaseTypography';
import { InformationCircleIcon } from 'src/components/Icons';

interface ContractInputsContainerProps {
  summary: string;
  defaultExpanded: boolean;
  tooltip?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${NonHoverBorder}`,
    borderRadius: 0,
  },
  borderRadius: {
    '&:first-child, &:last-child': {
      borderRadius: 0,
    },
  },
  summaryRoot: {
    minHeight: 'auto',
    padding: theme.spacing(0, 2.5),
    '&.Mui-expanded': {
      minHeight: 'auto',
    },
  },
  summaryContent: {
    margin: theme.spacing(2.5, 0),
    '&.Mui-expanded': {
      marginBottom: theme.spacing(1),
    },
  },
  inputTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  tooltipIcon: {
    height: 12,
    width: 12,
    color: GraySmall,
  },
  expandIcon: {
    padding: 0,
    marginRight: 0,
    '&.Mui-expanded': {
      marginTop: theme.spacing(1),
    },
  },
}));

/**
 * Reusable component for contract template sidebar input sections accordion.
 *
 * @param {object} props - The component's props.
 * @param {string} props.summary - The title of the accordion section.
 * @param {string} props.tooltip - The description to display as a tooltip when hovered.
 * @param {string} props.defaultExpanded - When set to true the accordion will be exapanded by default
 * @returns {JSX.Element} The JSX element rendering the contract template sidebar input section accordion.
 */
export const ContractInputsContainer: React.FC<
  ContractInputsContainerProps
> = ({ children, summary, tooltip, defaultExpanded }) => {
  const classes = useStyles();
  return (
    <div>
      <Accordion
        elevation={0}
        classes={{ root: classes.root, rounded: classes.borderRadius }}
        defaultExpanded={defaultExpanded}
      >
        <AccordionSummary
          expandIcon={<ExpandLess />}
          classes={{
            content: classes.summaryContent,
            root: classes.summaryRoot,
            expandIcon: classes.expandIcon,
          }}
        >
          <div className={classes.inputTitle}>
            <MemoBaseTypography fontType="13Medium">
              {summary}
            </MemoBaseTypography>
            {tooltip && (
              <Tooltip title={tooltip}>
                <div>
                  <InformationCircleIcon className={classes.tooltipIcon} />
                </div>
              </Tooltip>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
};
