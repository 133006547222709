import React from 'react';
import {
  ModalWrapper,
  ModalWrapperProps,
} from 'src/components/Modals/ModalWrapper';
import BaseTypography from 'src/components/Text/BaseTypography';

export type ActionButtonModalPropType = {
  description?: string;
  onClose: () => void;
  title?: string;
  mainComponent?: React.ReactElement | string;
  footerComponent?: React.ElementType;
  showSpinner?: boolean;
  positiveAction?: boolean;
  positiveActionText?: string;
  cancelActionText?: string;
  onPositiveActionPerformed?: () => void;
  open: boolean;
};

const ActionButtonModal: React.FC<
  ModalWrapperProps & ActionButtonModalPropType
> = ({
  onClose,
  title = '',
  mainComponent = null,
  positiveAction = false,
  positiveActionText,
  cancelActionText = 'Cancel',
  onPositiveActionPerformed,
  ...modalProps
}) => (
  <ModalWrapper
    title={title}
    onClose={onClose}
    onSuccess={onPositiveActionPerformed}
    cancelButtonLabel={cancelActionText}
    successButtonLabel={positiveActionText}
    positiveAction={positiveAction}
    {...modalProps}
  >
    <BaseTypography>{mainComponent}</BaseTypography>
  </ModalWrapper>
);

export default ActionButtonModal;
