import React from 'react';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';
import {
  ImportProgressDataType,
  useGetImporterStatusQuery,
} from 'src/services/api';
import { setImportComplete } from 'src/store/entityImporter/slice';
import {
  alertSnackbar,
  setProgressValue,
  completeProgress,
} from 'src/store/ui/actions';
import { AlertVariant } from 'src/store/ui/types';
import { AlertSnackbar } from 'src/components/AlertSnackbar/AlertSnackbar';

const entityNameMap = {
  client_import: 'Clients',
};

export const useImportProgressNotifier = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const startedImporterId = useAppSelector((state) => state.entityImports.id);

  const dispatch = useAppDispatch();
  const importType = startedImporterId?.split('|')[1]; // get the entity name from the importer id which is in the format of entityName|importerId

  const entityName = entityNameMap[importType as keyof typeof entityNameMap];
  const { data: importProgressData } = useGetImporterStatusQuery(
    { importerId: startedImporterId || '' },
    { skip: !startedImporterId, pollingInterval: 2000 }, // poll importer progress status every 2 seconds
  );

  const successImportSnackbar = React.useCallback(
    (snackbarKey: string) => (
      <div key={snackbarKey}>
        <AlertSnackbar
          variant={AlertVariant.SUCCESS}
          onClose={() => closeSnackbar(snackbarKey)}
          alertMessage={`${entityName} import completed`}
          onAction={() => {
            // refresh the page to view the imported data
            window.location.reload();
          }}
          actionLabel={`Refresh to view ${entityName.toLowerCase()}`}
        />
      </div>
    ),
    [closeSnackbar],
  );

  /**
   * This function gets the progress data in order to update the progress snack bar value
   * @param progressData:
   */
  const getProgress = (progressData: ImportProgressDataType) => {
    if (!startedImporterId) return;
    const { Progress, Status } = progressData;

    switch (Status) {
      case 'started':
        dispatch(
          setProgressValue(Math.round((Progress.Done / Progress.Total) * 100)),
        );
        break;

      case 'failed':
        dispatch(completeProgress()); // clear the progress snack bar

        dispatch(
          alertSnackbar({
            errorMessage: 'Import runtime failed!',
          }),
        );
        dispatch(setImportComplete(true));
        break;

      case 'completed':
        dispatch(setProgressValue(100)); // TODO: remove this once the backend sends the correct progress value when the import is completed
        dispatch(setImportComplete(true)); // set the import as completed
        dispatch(completeProgress()); // clear the progress snack bar

        // show the success snackbar with an refresh action
        enqueueSnackbar(`${entityName} import completed`, {
          content: (snackbarKey: string) => successImportSnackbar(snackbarKey),
        });

        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    if (importProgressData) {
      getProgress(importProgressData);
    }
  }, [importProgressData]);
};
