import React from 'react';
import { createSelector } from '@reduxjs/toolkit';
import {
  FILE_ACTION_DISABLE_TOOLTIP_LABEL,
  NEW_FILE_OPTIONS_FOR_MAIN,
} from 'src/constants';
import { useAppSelector } from 'src/hooks/useStore';
import { RootState } from 'src/store';
import {
  FolderIcon,
  LinkIcon,
  PlusIcon,
  UploadIcon,
} from 'src/components/Icons';

const selectFileActionSettings = createSelector(
  (state: RootState) => state.settings.fileSettings,
  (state: RootState) => state.user.isClient,
  (fileSettings, isClient) => {
    const disableFolderCreate =
      isClient && Boolean(fileSettings?.clientFolderUpdateDisabled);
    const disableAddFile =
      isClient && Boolean(fileSettings?.clientFileAddDisabled);

    return {
      disableFolderCreate,
      disableAddFile,
    };
  },
);

export const useFileChannelActionButton = () => {
  const { disableAddFile, disableFolderCreate } = useAppSelector(
    selectFileActionSettings,
  );

  const headerOptions = React.useMemo(() => {
    return [
      {
        label: NEW_FILE_OPTIONS_FOR_MAIN.NEW_FOLDER,
        icon: <PlusIcon />,
        disabled: disableFolderCreate,
        tooltip: FILE_ACTION_DISABLE_TOOLTIP_LABEL,
      },
    ];
  }, [disableFolderCreate]);

  const mainOptions = React.useMemo(() => {
    return [
      {
        label: NEW_FILE_OPTIONS_FOR_MAIN.UPLOAD_FILE,
        icon: <UploadIcon />,
        disabled: disableAddFile,
        tooltip: FILE_ACTION_DISABLE_TOOLTIP_LABEL,
      },
      {
        label: NEW_FILE_OPTIONS_FOR_MAIN.UPLOAD_FOLDER,
        icon: <FolderIcon />,
        disabled: disableAddFile,
        tooltip: FILE_ACTION_DISABLE_TOOLTIP_LABEL,
      },
      {
        label: NEW_FILE_OPTIONS_FOR_MAIN.ADD_LINK,
        icon: <LinkIcon />,
        disabled: disableAddFile,
        tooltip: FILE_ACTION_DISABLE_TOOLTIP_LABEL,
      },
    ];
  }, [disableAddFile]);

  return { headerOptions, mainOptions };
};
