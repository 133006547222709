import { Theme } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { DATE_FORMAT } from 'src/constants';
import {
  InboxNotificationDetailTimelineEvent,
  NotificationEventType,
} from 'src/entities/Notifications';
import { DateUtils } from 'src/utils';
import { SuccessCheckmarkIcon } from '../Icons';
import { NotificationClipboardIcon } from '../Icons/ModuleIcons/NotificationClipboardIcon';
import { SendNotificationIcon } from '../Icons/SendNotificationIcon';
import { Timeline } from '../UI/Timeline';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
    },
  }),
);
export const NotificationTimeline: React.FC<{
  events: InboxNotificationDetailTimelineEvent[];
}> = ({ events }) => {
  const classes = useStyles();
  const renderIcon = (eventType: NotificationEventType) => {
    switch (eventType) {
      case 'forms.created':
      case 'form.created':
        return <NotificationClipboardIcon fill="#0E0E10" />;
      case 'forms.requested':
      case 'formResponse.requested':
      case 'invoice.requested':
      case 'esign.requested':
      case 'contract.requested':
      case 'autoChargePayment.requested':
        return <SendNotificationIcon fill="#0E0E10" />;
      case 'forms.completed':
      case 'formResponse.completed':
      case 'invoice.paid':
      case 'esign.completed':
      case 'contract.signed':
      case 'files.new':
      case 'files.created':
      case 'customApp.action':
        return (
          <SuccessCheckmarkIcon
            style={{
              fontSize: '20px',
            }}
          />
        );
      default:
        return <ErrorOutline />;
    }
  };
  return (
    <div className={classes.root}>
      <Timeline
        items={events.map((event) => ({
          // eventId should be unique for each event
          id: event.eventId,
          icon: renderIcon(event.eventType),
          title: event.label,
          // format date mm/dd/yyyy at hh:mm
          subTitle: `${DateUtils.formatDate(
            event.date,
            `${DATE_FORMAT} [at] hh:mm`,
          )}`,
        }))}
      />
    </div>
  );
};
