import React from 'react';
import Layout, { LayoutProps } from 'src/components/Layout';
import { SettingsPageHeaderTitle } from 'src/components/Settings/SettingsPageHeaderTitle';

export const SettingsLayout: React.FC<Partial<LayoutProps>> = ({
  children,
  ...others
}) => (
  <Layout
    title={<SettingsPageHeaderTitle />}
    showNavbarAuthActions={false}
    {...others}
  >
    {children}
  </Layout>
);
