import { Theme } from '@material-ui/core';
import * as Colors from 'src/theme/colors';
import * as Shadows from 'src/theme/shadows';
import ColorUtils from 'src/utils/ColorUtils';
import {
  typography13MediumStyle,
  typography15MediumStyle,
} from 'src/components/Text';

export default {};

export const overrideButton = (theme: Theme) => {
  if (theme.overrides && theme.overrides.MuiButton) {
    // eslint-disable-next-line no-param-reassign
    theme.overrides.MuiButton = {
      contained: {
        height: '28px',
        ...typography13MediumStyle,
        padding: theme.spacing(0.25, 1),
        boxShadow: Shadows.PrimaryButtonShadow,
        borderRadius: theme.shape.borderRadius,
        '& svg': {
          height: 12,
          width: 12,
        },
        '&.Mui-disabled': {
          color: Colors.LightGray,
          backgroundColor: Colors.white,
          border: `1px solid ${Colors.NonHoverBorder}`,
        },
      },
      sizeLarge: {
        height: '32px',
        ...typography15MediumStyle,
        padding: theme.spacing(0.5, 1.5),
        '& > svg': {
          height: 12,
          width: 12,
        },
      },
      containedPrimary: {
        color: Colors.white,
        border: `1px solid ${
          ColorUtils.GetColorDarknessShades(theme.palette.primary.main).dark
        }`,
        '&:hover': {
          boxShadow: Shadows.RegularButtonHoverShadow,
          backgroundColor: ColorUtils.GetColorDarknessShades(
            theme.palette.primary.main,
          ).midDark,
        },
      },
      containedSecondary: {
        border: `1px solid ${Colors.NonHoverBorder}`,
        color: Colors.BlackHeadings,
        backgroundColor: Colors.white,
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: Colors.HoverBackground,
          boxShadow: 'none',
          border: `1px solid ${Colors.HoverBorder}`,
        },
      },
      startIcon: {
        // using important to avoid negative margin
        marginLeft: '0 !important',
        marginRight: theme.spacing(1),
      },
      endIcon: {
        marginRight: '0 !important',
        marginLeft: theme.spacing(1),
      },
      iconSizeMedium: {
        // text button default icon size is medium
        // we should override it.
        '& > svg': {
          height: 12,
          width: 12,
        },
      },
      text: {
        backgroundColor: 'transparent',
        padding: 0,
      },
      textPrimary: {
        ...typography13MediumStyle,
        color: theme.palette.primary.main,
        '&:hover': {
          color: Colors.BlackHeadings,
          backgroundColor: 'transparent',
        },
      },
    };
  }
};
