import React from 'react';
import { Box, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { DESKTOP_APP_BAR_HEIGHT } from 'src/constants/uiConsts';
import { GraySmall, NonHoverBorder } from 'src/theme/colors';
import { Action, BaseActionsMenuButton } from 'src/components/Dropdowns';
import { BaseMenu } from 'src/components/Dropdowns/BaseMenu';
import BaseTypography from 'src/components/Text/BaseTypography';
import { BaseActionsMenuProps } from 'src/constants';

interface StyleProps {
  showBorder: boolean;
}
export interface RightSidebarHeaderProps {
  title: string;
  actions?: Action[];
  showBorder?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: DESKTOP_APP_BAR_HEIGHT,
      boxSizing: 'border-box',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: (props: StyleProps) =>
        props.showBorder ? `1px solid ${NonHoverBorder}` : 'none',
      marginBottom: theme.spacing(2),
    },
  }),
);

export const RightSidebarHeader: React.FC<RightSidebarHeaderProps> = ({
  title,
  actions,
  showBorder = false,
}) => {
  const classes = useStyles({ showBorder });
  const [actionMenuOptions, setActionMenuOptions] =
    React.useState<Partial<BaseActionsMenuProps> | null>(null);

  return (
    <>
      <Box className={classes.root}>
        <BaseTypography fontType="13Medium" style={{ color: GraySmall }}>
          {title}
        </BaseTypography>
        {actions && actions.length && actions.length > 0 && (
          <BaseActionsMenuButton
            onActionMenuClick={(menuOptions) => {
              setActionMenuOptions({
                ...menuOptions,
                menuProps: {
                  ...menuOptions.menuProps,
                  open: menuOptions.menuProps?.open ?? false,
                  onClose: () => setActionMenuOptions(null),
                },
              });
            }}
            actions={actions}
          />
        )}
      </Box>
      {actionMenuOptions && (
        <BaseMenu
          variant="skinny"
          actions={actionMenuOptions?.actions ?? []}
          menuProps={actionMenuOptions?.menuProps}
        />
      )}
    </>
  );
};
