import { useTheme, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { ModalWrapper } from 'src/components/Modals/index';

interface TableModalProps {
  open: boolean;
  setModalOpen: (arg: boolean) => void;
  title?: string;
  description?: string;
}

const TableModal: React.FC<TableModalProps> = ({
  open,
  setModalOpen,
  title,
  description,
  children,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <ModalWrapper
      fullScreen={isMobile}
      open={open}
      title={title}
      description={description}
      onClose={() => setModalOpen(false)}
      hideActions
      closeButton={isMobile}
      hideDividers
      denseContent
      width="616px"
      height="640px"
      modalType="table"
    >
      {children}
    </ModalWrapper>
  );
};

export default TableModal;
