import React from 'react';
import { Menu, MenuItem, makeStyles, Theme, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reduxTypes';
import BaseTypography from 'src/components/Text/BaseTypography';
import * as Colors from 'src/theme/colors';
import { CaretRight } from 'src/components/Icons';

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    padding: `0 ${theme.spacing(1.5)}px`,
    minHeight: 32,
  },
  menuItemLabel: {
    padding: `0 ${theme.spacing(1.5)}px`,
    color: Colors.BlackHeadings,
  },
  expandIcon: {
    fontSize: 8,
    color: Colors.LightGray,
  },
  menuItemIcon: {
    '& svg': {
      fontSize: 12,
    },
  },
  menuWrapper: {
    pointerEvents: 'all',
  },
}));

type Item = {
  id?: string;
  name?: string;
  icon?: React.ReactNode;
  children?: Item[];
  dispatchAction?: Function;
  navigate?: string;
};

export interface MenuItemTreeProps {
  menuItem: Item;
  onClick: (params: Record<any, any>) => void;
}

export const MenuItemTree = React.forwardRef<any, MenuItemTreeProps>(
  ({ menuItem, onClick }, ref) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isRightSidebarOpen = useSelector(
      (state: RootState) => state.ui.rightSideBar.isOpen,
    );
    const isSubMenuOpen = Boolean(anchorEl);
    const classes = useStyles();
    const {
      id,
      name,
      icon,
      children = [],
      dispatchAction,
      navigate,
    } = menuItem;
    const hasChildrenItems = children?.length || false;
    const isLeafNode = !hasChildrenItems;

    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<any>) => {
      event.stopPropagation();
      if (isLeafNode) {
        if (dispatchAction) {
          onClick({
            dispatchAction,
          });
        } else if (navigate) {
          onClick({
            navigateTo: navigate,
          });
        } else {
          onClick({ menuItemId: id });
        }
      }
    };

    return (
      <MenuItem
        ref={ref}
        disableRipple
        className={classes.menuItem}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleClose}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Box className={classes.menuItemIcon}>{icon}</Box>
            <BaseTypography className={classes.menuItemLabel}>
              {name}
            </BaseTypography>
          </Box>
          {hasChildrenItems && <CaretRight className={classes.expandIcon} />}
        </Box>
        {hasChildrenItems && (
          <Menu
            // "pointerEvents: none" to prevent invisible Popover wrapper div to capture mouse events
            style={{ pointerEvents: 'none' }}
            anchorEl={anchorEl}
            open={isSubMenuOpen}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'top',
              horizontal: isRightSidebarOpen ? 'right' : 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: isRightSidebarOpen ? 'left' : 'right',
            }}
            PaperProps={{
              elevation: 4,
            }}
            // reset pointer event here so that the menu items could receive mouse events
            classes={{ paper: classes.menuWrapper }}
          >
            {children.map((item) => (
              <MenuItemTree key={item.id} menuItem={item} onClick={onClick} />
            ))}
          </Menu>
        )}
      </MenuItem>
    );
  },
);
