import React from 'react';
import {
  createStyles,
  makeStyles,
  Drawer,
  InputAdornment,
  Theme,
} from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';
import BaseTypography from 'src/components/Text/BaseTypography';
import { BlackHeadings, GraySmall } from 'src/theme/colors';
import { useShortcutsSections } from 'src/components/ShortcutsDrawer/useShortcutsSections';
import {
  predefinedShortcutsIds,
  shortcutsDisplayNames,
} from 'src/components/ShortcutsDrawer/shortcutsSidebarConsts';
import { BaseTextField } from 'src/components/TextField';
import { QuestionIcon, SearchIcon } from 'src/components/Icons';
import { ShortcutSidebarSection } from 'src/components/ShortcutsDrawer/shortcutsDrawerTypes';
import { DescriptionTooltip } from 'src/components/Tooltip';
import { shortcutsSidebarOpen } from 'src/store/shortcuts/slice';
import ShortcutKey from 'src/components/ShortcutsDrawer/ShortcutKey';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      padding: theme.spacing(3),
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    drawerPaper: {
      width: 325,
      height: 'calc(100% - 24px)',
      borderRadius: 4,
    },
    drawerPaperRight: {
      right: 12,
      top: 12,
    },
    searchInputWrapper: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
    searchInput: {
      '& .MuiOutlinedInput-root .MuiInputBase-input': {
        paddingLeft: '0px !important',
      },
    },
    section: {
      marginTop: theme.spacing(3),
    },
    shortcutKeysWrapper: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
    listItem: {
      marginTop: theme.spacing(1.5),
      display: 'flex',
      justifyContent: 'space-between',
    },
    labelWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  }),
);

export const ShortcutsSidebar: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const registeredShortcuts = useAppSelector(
    (state) => state.shortcuts.registeredShortcuts,
  );
  const shortcutsSections = useShortcutsSections();

  const availableSections = React.useMemo(() => {
    const registeredSections: Array<ShortcutSidebarSection> =
      shortcutsSections.map((section) => {
        const stagedSectionShortcuts = section.shortcuts.filter(
          (sectionShortcut) => {
            const sectionShortcutsKeysLabel = (
              sectionShortcut.keys.length > 1
                ? sectionShortcut.keys.join('+')
                : sectionShortcut.keys[0]
            ).toLowerCase();
            return (
              registeredShortcuts[sectionShortcutsKeysLabel] ||
              predefinedShortcutsIds[sectionShortcut.id]
            );
          },
        );

        return { ...section, shortcuts: stagedSectionShortcuts };
      });
    return registeredSections;
  }, [shortcutsSections]);

  const [
    filteredAvailableShortcutsSections,
    setFilteredAvailableShortcutsSections,
  ] = React.useState<Array<ShortcutSidebarSection>>(availableSections);

  const sidebarOpen = useAppSelector((state) => state.shortcuts.sidebarOpen);

  const handleCloseShortcutsSidebar = () => {
    dispatch(shortcutsSidebarOpen(false));
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;

    const newSections = availableSections
      .map((section) => {
        const filteredShortcuts = section.shortcuts.filter((shortcut) =>
          shortcut.label.toLowerCase().includes(searchValue.toLowerCase()),
        );
        if (filteredShortcuts.length > 0) {
          return { ...section, shortcuts: filteredShortcuts };
        }
        return null;
      })
      .filter((section) => !!section) as Array<ShortcutSidebarSection>;

    setFilteredAvailableShortcutsSections(newSections);
  };

  /**
   * When shortcut sidebar is open, show all available shortcuts section
   */
  React.useEffect(() => {
    if (sidebarOpen) {
      setFilteredAvailableShortcutsSections(availableSections);
    }
  }, [sidebarOpen]);

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      onClose={handleCloseShortcutsSidebar}
      open={sidebarOpen}
      classes={{
        paper: classes.drawerPaper,
        paperAnchorRight: classes.drawerPaperRight,
      }}
    >
      <div className={classes.contentWrapper}>
        <div className={classes.titleWrapper}>
          <BaseTypography fontType="18Medium" style={{ color: BlackHeadings }}>
            Keyboard shortcuts
          </BaseTypography>
          <ShortcutKey>?</ShortcutKey>
        </div>

        <div className={classes.searchInputWrapper}>
          <BaseTextField
            className={classes.searchInput}
            fullWidth
            variant="outlined"
            autoFocus
            sizeVariant="skinny"
            onChange={handleSearch}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fontSize: 12, color: GraySmall }} />
                </InputAdornment>
              ),
            }}
          />
        </div>

        {filteredAvailableShortcutsSections.map((section) => (
          <div className={classes.section} key={section.id}>
            <BaseTypography
              fontType="13Medium"
              style={{ color: BlackHeadings }}
            >
              {section.sectionName}
            </BaseTypography>

            {section.shortcuts.map((shortcut) => (
              <div className={classes.listItem} key={shortcut.id}>
                <div className={classes.labelWrapper}>
                  <BaseTypography
                    fontType="13Medium"
                    style={{ color: GraySmall }}
                  >
                    {shortcut.label}
                  </BaseTypography>
                  {shortcut.tooltipText && (
                    <DescriptionTooltip
                      title={
                        <BaseTypography
                          fontType="12Medium"
                          style={{ color: GraySmall }}
                        >
                          {shortcut.tooltipText}
                        </BaseTypography>
                      }
                    >
                      <div>
                        <QuestionIcon
                          style={{ fontSize: '14px', marginLeft: 4 }}
                        />
                      </div>
                    </DescriptionTooltip>
                  )}
                </div>
                <div className={classes.shortcutKeysWrapper}>
                  {shortcut.keys?.map((key, index) => (
                    <React.Fragment key={key}>
                      <ShortcutKey>
                        <BaseTypography
                          fontType="12Regular"
                          style={{ color: BlackHeadings, whiteSpace: 'nowrap' }}
                        >
                          {shortcutsDisplayNames[key] || key}
                        </BaseTypography>
                      </ShortcutKey>
                      {index === 0 &&
                        shortcut.keys.length > 1 &&
                        section.shortcutsSeparator && (
                          <BaseTypography fontType="12Regular">
                            {section?.shortcutsSeparator}
                          </BaseTypography>
                        )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Drawer>
  );
};
