import { ModuleSettingsItem } from 'src/constants';
import { FORMS_PAGE } from 'src/constants/pathConsts';
import { ChannelType } from 'src/components/Channels/useChannelForm';

export const useGetChannelModalTexts = (
  channelType: ChannelType,
  moduleSettings: ModuleSettingsItem[] | undefined,
) => {
  let title = '';
  let positiveAction = 'Create';

  switch (channelType) {
    case 'files':
    case 'fileChannel': {
      const fileModuleSettings = (moduleSettings || []).find(
        (m) => m.id === 'files',
      );
      title = `${fileModuleSettings?.label || 'File'} channels`;
      break;
    }
    case 'messaging':
      title = 'Conversations';
      positiveAction = 'Create';
      break;
    case 'forms':
      title = FORMS_PAGE.label;
      positiveAction = 'Share';
      break;
    default:
      break;
  }

  return { title, positiveAction };
};
