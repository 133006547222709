import React from 'react';
import { useGetInboxNotificationsQuery } from 'src/services/api/inboxApi';

import { NotificationBadge } from 'src/components/UI/Badges';
import { useNotificationsCounts } from 'src/hooks/useNotificationsCount';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

export const InboxNotificationBadge = () => {
  const { data: inboxNotifications } = useGetInboxNotificationsQuery();
  const cus = useSelector((state: RootState) => state.clients.clients);
  const ius = useSelector((state: RootState) => state.users.internalUsers);
  const companyDataMap = useSelector(
    (state: RootState) => state.clients.companyDataMap,
  );
  const { unreadNotificationCount } = useNotificationsCounts(
    inboxNotifications || [],
    [...cus, ...ius],
    companyDataMap,
  );

  return (
    <NotificationBadge
      showZero={false}
      isSidebar
      content={unreadNotificationCount}
    />
  );
};
