import React from 'react';
import { useGetInboxNotificationsQuery } from 'src/services/api/inboxApi';
import { NotificationBadge } from 'src/components/UI/Badges';
import { useAppSelector } from 'src/hooks/useStore';

/**
 * AppNotificationBadge component
 * This component renders the notification badge for an installed app/extension/embed.
 * When the notifications api is used to create a notification with an api key
 * associated with an appId, that notification will be displayed with the associated app.
 */
export const AppNotificationBadge = ({ appId }: { appId: string }) => {
  const isClient = useAppSelector((state) => state.user.isClient);
  // ignore the query if the user is not a client, because the
  // app notification badge is only relevant to clients. IUs see
  // their notifications in the inbox.
  const { data: notifications } = useGetInboxNotificationsQuery(undefined, {
    skip: !isClient,
  });

  if (!isClient) {
    // similar to comment above, IUs see notifications
    // in in inbox so AppNotificationBadge should not
    // be rendered for them.
    return null;
  }

  // filter notifications by appId
  const appNotificationCount =
    notifications?.filter(
      (notification) =>
        notification.event === 'customApp.action' &&
        notification.appId === appId &&
        !notification.isRead,
    ).length || 0;

  return (
    <NotificationBadge
      showZero={false}
      isSidebar
      content={appNotificationCount}
    />
  );
};
