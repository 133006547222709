import React from 'react';
import { makeStyles } from '@material-ui/core';
import * as Colors from 'src/theme/colors';
import BaseTypography from 'src/components/Text/BaseTypography';
import { LoaderProps, Loader } from 'src/components/Loading';

interface LoadingPageProps {
  message?: string;
  subTitle?: string;
  height?: number;
  htmlID?: string;
}

interface StyleProps {
  height: number;
  fullHeight?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: (props: StyleProps) =>
      props.height ? 'space-between' : 'center',
    alignItems: 'center',
    height: (props: StyleProps) => props.height || '100%',
  },
  subTitleText: {
    color: Colors.GraySmall,
  },
}));

export const LoadingPage: React.FC<LoadingPageProps & LoaderProps> = ({
  message = '',
  subTitle = '',
  color,
  height = 0,
  htmlID = 'loading-page',
}) => {
  const classes = useStyles({ height });
  return (
    <div id={htmlID} className={classes.root}>
      {message && (
        <BaseTypography fontType="18Medium" align="center">
          {message}
        </BaseTypography>
      )}
      <Loader color={color} />
      {subTitle && (
        <BaseTypography
          className={classes.subTitleText}
          fontType="18Medium"
          align="center"
        >
          {subTitle}
        </BaseTypography>
      )}
      {/* Add empty typography support space-between to center loader to page, only needed when subTitle is not present */}
      {message && !subTitle && (
        <BaseTypography fontType="18Medium" align="center" />
      )}
    </div>
  );
};
