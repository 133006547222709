import React from 'react';
import Layout, { LayoutProps } from 'src/components/Layout/Layout';

export const ChannelsPageLayout: React.FC<LayoutProps> = ({
  children,
  ...others
}) => {
  return (
    <Layout disableGutters hasNavbar={false} {...others}>
      {children}
    </Layout>
  );
};
