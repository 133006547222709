import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  createStyles,
  makeStyles,
  IconButton,
  Box,
  Theme,
} from '@material-ui/core';
import { FiEdit2 as EditIcon } from 'react-icons/fi';
import { PortalConfigContext } from 'src/context';
import { RootState } from 'src/store/reduxTypes';
import { UserState as User } from 'src/store/user/types';
import { S3Utils, FileUtils } from 'src/utils';
import { UploadResponse } from 'src/utils/S3Utils';
import { ModalWrapper } from 'src/components/Modals';
import { ImageCropper } from 'src/components/ImageCropper/ImageCropper';
import { alertSnackbar } from 'src/store/ui/actions';
import { useProgressUpload } from 'src/hooks/useProgressUpload';
import { ModalUnderlay } from 'src/theme/colors';
import { UserAvatar } from '.';
import { AvatarSizeType } from './UserAvatar';
import { ensureApiError } from 'src/utils/Errors';

interface UserAvatarUploaderProps {
  htmlId: string;
  avatarUrl: string;
  handleChange: (location: string) => void;
  uploadIconSize: number;
  avatarSize?: AvatarSizeType;
  shape?: Shape;
  fallbackColor?: string;
  name?: string;
  initialLetters?: string;
}

type Shape = 'circle' | 'square';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'none',
    },
    uploadOverlayWrapper: {
      position: 'absolute',
      display: 'flex',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      borderRadius: (props: { shape: Shape }) =>
        props.shape === 'circle' ? '50%' : theme.shape.borderRadius,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: ModalUnderlay,
      opacity: 0,
      '&:hover': {
        opacity: 1,
      },
    },
  }),
);

const ProfilePictureDir = 'profile_pictures';
const UserAvatarUploader: React.FC<UserAvatarUploaderProps> = ({
  uploadIconSize,
  handleChange,
  avatarSize,
  shape,
  avatarUrl,
  name,
  fallbackColor,
  htmlId,
  initialLetters,
}) => {
  const classes = useStyles({ shape: shape ?? 'circle' });
  const portalConfig = useContext(PortalConfigContext);
  const dispatch = useDispatch();

  const user = useSelector<RootState, User>((state) => state.user);

  const { id: userId } = user;

  const [openImageCropper, setOpenImageCropper] = useState<boolean>(false);
  const [isAvatarUploading, setIsAvatarUploading] = useState<boolean>(false);
  const [chosenImage, setChosenImage] = useState<any>();
  const [croppedImage, setCroppedImage] = useState<any>();

  const { setUploadProgress, startUploadFiles } = useProgressUpload();

  const onchange = async (e: any) => {
    const [file] = e.target.files;
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        if (event && event.target) {
          setChosenImage({ ...file, preview: event.target.result });
          setOpenImageCropper(true);
        }
      };
    }
  };

  const uploadProfileImage = async (file: any) => {
    startUploadFiles();
    const res: UploadResponse = await S3Utils.uploadPortalFile(
      file,
      `${
        portalConfig.portalHeader
      }/images/${ProfilePictureDir}/${userId}/${croppedImage.split('/').pop()}`,
      (fileLoaded: number, fileTotalSize: number) => {
        setUploadProgress({
          totalSize: fileTotalSize,
          loaded: [fileLoaded],
        });
      },
    );

    if (res && res.Location) {
      handleChange(res.Location);
    }
  };

  const upload = (
    <Box>
      <input
        accept="image/*"
        className={classes.input}
        id={htmlId}
        type="file"
        onChange={(e) => onchange(e)}
      />
      {/* eslint-disable jsx-a11y/label-has-associated-control */}
      <label htmlFor={htmlId}>
        <IconButton component="span" aria-label="upload picture">
          <EditIcon style={{ color: '#fff', fontSize: uploadIconSize }} />
        </IconButton>
      </label>
    </Box>
  );

  const imageCropperRenderer = () => (
    <ImageCropper
      aspect={1}
      onCropComplete={async (_, croppedAreaPixels) => {
        try {
          const croppedImg = await FileUtils.getCroppedImg(
            chosenImage.preview,
            croppedAreaPixels,
            0,
          );
          setCroppedImage(croppedImg);
        } catch (e) {
          console.warn(e);
        }
      }}
      image={chosenImage}
    />
  );

  const onCropSucceed = async () => {
    setIsAvatarUploading(true);
    try {
      const file = await fetch(croppedImage).then((r) => r.blob());
      await uploadProfileImage(file);
    } catch (err) {
      const { message } = ensureApiError(err);
      dispatch(alertSnackbar({ errorMessage: message }));
    }

    setIsAvatarUploading(false);
    setOpenImageCropper(false);
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-start">
        <Box position="relative">
          <UserAvatar
            type="uploader"
            avatarSize={avatarSize}
            shape={shape}
            initialLetters={initialLetters}
            avatarUrl={avatarUrl}
            name={name}
            isLoading={isAvatarUploading}
            fallbackColor={fallbackColor}
          />
          <Box className={classes.uploadOverlayWrapper}>{upload}</Box>
        </Box>
      </Box>
      <ModalWrapper
        noBorder
        title="Crop your image"
        onClose={() => {
          setOpenImageCropper(false);
        }}
        onSuccess={onCropSucceed}
        open={openImageCropper}
        isLoading={isAvatarUploading}
      >
        <Box height={400}>{openImageCropper && imageCropperRenderer()}</Box>
      </ModalWrapper>
    </>
  );
};

export default UserAvatarUploader;
