import * as Colors from 'src/theme/colors';
import { typography13RegularStyle } from 'src/components/Text';

export default {
  root: {
    ...typography13RegularStyle,
    width: 'fit-content',
    marginTop: 16,
    borderRadius: '5px',
    backgroundColor: Colors.HoverBackground,
    alignItems: 'center',
    padding: '2px 12px',
  },
  icon: {
    marginRight: 12,
    '& .MuiSvgIcon-root': {
      fontSize: 14,
      color: Colors.BlackHeadings,
    },
  },
  outlinedSuccess: {
    border: `1px solid ${Colors.HoverNonBorderBackground}`,
  },
};
