import React, { useEffect } from 'react';
import { AmplifyApiSettings } from 'src/context';
import useWebSocket from 'src/hooks/useWebSocket';

interface WebsocketInitializerProps {
  apiEndpoints: AmplifyApiSettings[];
  userId: string;
  userLoaded: boolean;
}
export const WebsocketInitializer: React.FC<WebsocketInitializerProps> = ({
  apiEndpoints,
  userId,
  userLoaded,
}) => {
  const { startWebsocket } = useWebSocket(apiEndpoints?.at(0) ?? null);

  // this replaces component did mount hook
  useEffect(() => {
    if (userId && userLoaded) {
      startWebsocket(userId);
    }
  }, [userId, userLoaded]);

  return null;
};
