import React, { Dispatch } from 'react';
import { Chip, makeStyles, createStyles } from '@material-ui/core';
import { GridApi, RowDragEndEvent } from '@ag-grid-community/core';
import classNames from 'classnames';
import { MoreActionsIcon } from 'src/components/Icons';
import { FileDropzone } from 'src/components/FileDropzone';
import { moveFolders } from 'src/store/files/actions';
import { FileUtils } from 'src/utils';

import * as Colors from 'src/theme/colors';
import { FileSettings } from 'src/constants';
import { typography13MediumStyle } from 'src/components/Text';

interface FilesBreadcrumbChipProps {
  gridApi: GridApi | null | undefined;
  handleDropActive: (idx: number) => void;
  handleDropInactive: (idk: number) => void;
  handleFilesUpload: (path?: any) => (files: Array<any>) => Promise<void>;
  chipIndex: number;
  chipTemplate: ChipTemplate;
  chipRefs: HTMLDivElement[];
  handleChipRefSet: (node: HTMLDivElement | null, idx: number) => void;
  dispatch: Dispatch<any> | undefined;
  fileSettings: FileSettings | undefined;
  isClient: boolean;
  chipsLength: number;
  lastChipClassname?: string;
}

export interface ChipTemplate {
  label: string;
  path?: string;
  fileDragActionsEnabled: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    folderChip: {
      color: Colors.GraySmall,
      background: 'none',
      textOverflow: 'ellipse',
      maxWidth: 150,
      height: '24px',
      '&> span:first-of-type': {
        ...typography13MediumStyle,
        color: Colors.GraySmall,
        padding: 0,
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '&> span:first-of-type': {
          color: Colors.BlackHeadings,
          ...typography13MediumStyle,
        },
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    collapsedMenuChip: {
      background: 'none',
      height: '16px',
      width: '16px',
    },
    lastChip: {
      '&> span:first-of-type': {
        color: Colors.BlackHeadings,
        ...typography13MediumStyle,
      },
    },
  }),
);

export const FilesBreadcrumbChip: React.FC<FilesBreadcrumbChipProps> = ({
  gridApi,
  handleDropActive,
  handleDropInactive,
  handleFilesUpload,
  chipIndex,
  chipTemplate,
  chipRefs,
  handleChipRefSet,
  dispatch,
  fileSettings,
  isClient,
  chipsLength,
  lastChipClassname = '',
}) => {
  const classes = useStyles();

  const isLastChip = chipsLength - 1 === chipIndex;

  const isMenuChip: boolean = chipTemplate.label === '...';

  const chipElement = (
    <Chip
      label={!isMenuChip ? chipTemplate.label : null}
      ref={(el: HTMLDivElement) => {
        if (typeof handleChipRefSet !== 'undefined')
          handleChipRefSet(el, chipIndex);
      }}
      onClick={chipTemplate.onClick}
      className={classNames({
        [classes.collapsedMenuChip]: isMenuChip,
        [classes.folderChip]: !isMenuChip,
        [lastChipClassname]: Boolean(lastChipClassname),
        [classes.lastChip]: isLastChip,
      })}
      icon={
        isMenuChip ? (
          <MoreActionsIcon
            style={{
              fontSize: 16,
            }}
            viewBox="0 0 22 20"
          />
        ) : undefined
      }
    />
  );
  const disableFileAdd =
    isClient && Boolean(fileSettings?.clientFileAddDisabled);
  const currentChipRef = chipRefs[chipIndex];
  if (!disableFileAdd && typeof gridApi !== 'undefined' && currentChipRef) {
    const dropZoneParams = {
      getContainer() {
        // get the element to be associated with this dropzone
        return currentChipRef;
      },
      onDragStop(params: RowDragEndEvent) {
        handleDropInactive(chipIndex);
        if (chipTemplate.fileDragActionsEnabled && dispatch) {
          dispatch(
            moveFolders(
              chipTemplate.path || '',
              params.node.data.path.join('/'),
              params.node.data.type,
              params.node.data.fileKey,
              FileUtils.getEntityName(params.node.data),
            ),
          );
        }
      },
      onDragEnter() {
        handleDropActive(chipIndex);
      },
      onDragLeave() {
        handleDropInactive(chipIndex);
      },
    };
    gridApi?.removeRowDropZone(dropZoneParams);
    gridApi?.addRowDropZone(dropZoneParams);
  }

  return (
    <FileDropzone
      key={`${chipTemplate.path}-dropzone`}
      noClick
      onDrop={(e) => {
        handleDropInactive(chipIndex);
        if (chipTemplate.fileDragActionsEnabled) {
          handleFilesUpload(chipTemplate.path)(e);
        }
      }}
      onDragEnter={() => {
        handleDropActive(chipIndex);
      }}
      onDragLeave={() => {
        handleDropInactive(chipIndex);
      }}
      disabled={isClient && Boolean(fileSettings?.clientFileAddDisabled)}
    >
      {chipElement}
    </FileDropzone>
  );
};
