import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SlackIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M5.11052 0C4.92729 0.000170138 4.74589 0.0364319 4.57669 0.106714C4.40747 0.176997 4.25377 0.279924 4.12434 0.409616C3.99491 0.539308 3.8923 0.693228 3.82237 0.862581C3.75243 1.03193 3.71654 1.2134 3.71674 1.39663C3.71657 1.57984 3.75249 1.76128 3.82244 1.9306C3.89239 2.09992 3.99501 2.25381 4.12443 2.38348C4.25386 2.51314 4.40756 2.61605 4.57675 2.68631C4.74594 2.75658 4.92732 2.79283 5.11052 2.793H6.50428V1.39663C6.50452 1.2134 6.46858 1.03193 6.3987 0.862581C6.32876 0.693228 6.22615 0.539308 6.09671 0.409616C5.96727 0.279924 5.81356 0.176997 5.64435 0.106714C5.47514 0.0364319 5.29374 0.000170138 5.11052 0ZM5.11052 3.72426H1.39378C1.21057 3.72443 1.0292 3.76068 0.860008 3.83095C0.690812 3.90122 0.537116 4.00412 0.407692 4.13378C0.278267 4.26345 0.17565 4.41734 0.105698 4.58666C0.0357466 4.75598 -0.000169443 4.93743 8.67998e-07 5.12063C-0.000203514 5.30385 0.0356874 5.48533 0.105624 5.65468C0.175561 5.82403 0.278172 5.97794 0.4076 6.10762C0.537028 6.23735 0.690737 6.34025 0.859944 6.41054C1.02916 6.48083 1.21055 6.51712 1.39378 6.51723H5.11052C5.29374 6.51712 5.47514 6.48083 5.64435 6.41054C5.81356 6.34025 5.96727 6.23735 6.09671 6.10762C6.22615 5.97794 6.32876 5.82403 6.3987 5.65468C6.46858 5.48533 6.50452 5.30385 6.50428 5.12063C6.50446 4.93743 6.46852 4.75598 6.39858 4.58666C6.32864 4.41734 6.22603 4.26345 6.09659 4.13378C5.96721 4.00412 5.81349 3.90122 5.64429 3.83095C5.4751 3.76068 5.29372 3.72443 5.11052 3.72426Z"
      fill="#36C5F0"
    />
    <path
      d="M13.9379 5.12063C13.9381 4.93743 13.9022 4.75598 13.8322 4.58666C13.7623 4.41734 13.6597 4.26345 13.5302 4.13378C13.4008 4.00412 13.2471 3.90122 13.0779 3.83095C12.9087 3.76068 12.7273 3.72443 12.5442 3.72426C12.361 3.72443 12.1796 3.76068 12.0104 3.83095C11.8412 3.90122 11.6875 4.00412 11.5581 4.13378C11.4286 4.26345 11.326 4.41734 11.2561 4.58666C11.1861 4.75598 11.1502 4.93743 11.1504 5.12063V6.51723H12.5442C12.7274 6.51712 12.9088 6.48083 13.078 6.41054C13.2472 6.34025 13.4009 6.23735 13.5303 6.10762C13.6597 5.97794 13.7624 5.82403 13.8323 5.65468C13.9022 5.48533 13.9381 5.30385 13.9379 5.12063ZM10.2212 5.12063V1.39663C10.2214 1.2134 10.1855 1.03193 10.1156 0.862581C10.0456 0.693227 9.94304 0.539308 9.8136 0.409616C9.68415 0.279924 9.53045 0.176997 9.36122 0.106714C9.19206 0.0364319 9.01064 0.000170138 8.82741 0C8.64419 0.000170138 8.46277 0.0364319 8.29361 0.106714C8.12438 0.176997 7.97067 0.279924 7.84123 0.409616C7.71179 0.539308 7.60918 0.693227 7.53924 0.862581C7.4693 1.03193 7.43342 1.2134 7.43366 1.39663V5.12063C7.43342 5.30385 7.4693 5.48533 7.53924 5.65468C7.60918 5.82403 7.71179 5.97794 7.84123 6.10762C7.97067 6.23735 8.12438 6.34025 8.29361 6.41054C8.46277 6.48083 8.64419 6.51712 8.82741 6.51723C9.01064 6.51712 9.19206 6.48083 9.36122 6.41054C9.53045 6.34025 9.68415 6.23735 9.8136 6.10762C9.94304 5.97794 10.0456 5.82403 10.1156 5.65468C10.1855 5.48533 10.2214 5.30385 10.2212 5.12063Z"
      fill="#2EB67D"
    />
    <path
      d="M8.82741 13.9665C9.01064 13.9663 9.19206 13.93 9.36122 13.8598C9.53045 13.7895 9.68415 13.6865 9.8136 13.5568C9.94304 13.4272 10.0456 13.2732 10.1156 13.1039C10.1855 12.9345 10.2214 12.7531 10.2212 12.5698C10.2213 12.3866 10.1855 12.2052 10.1155 12.0359C10.0455 11.8665 9.94292 11.7126 9.81348 11.583C9.6841 11.4533 9.53039 11.3504 9.36116 11.2801C9.192 11.2099 9.01064 11.1736 8.82741 11.1735H7.43366V12.5698C7.43342 12.7531 7.4693 12.9345 7.53924 13.1039C7.60918 13.2732 7.71179 13.4272 7.84123 13.5568C7.97067 13.6865 8.12438 13.7895 8.29361 13.8598C8.46277 13.93 8.64419 13.9663 8.82741 13.9665ZM8.82741 10.2422H12.5442C12.7273 10.242 12.9087 10.2058 13.0779 10.1355C13.2471 10.0652 13.4008 9.96233 13.5302 9.83266C13.6597 9.70298 13.7623 9.5491 13.8322 9.37982C13.9022 9.21048 13.9381 9.029 13.9379 8.84583C13.9381 8.66261 13.9022 8.48114 13.8323 8.31179C13.7624 8.14239 13.6597 7.98851 13.5303 7.85884C13.4009 7.7291 13.2472 7.6262 13.078 7.55591C12.9088 7.48562 12.7274 7.44934 12.5442 7.44922H8.82741C8.64419 7.44934 8.46277 7.48562 8.29361 7.55591C8.12438 7.6262 7.97067 7.7291 7.84123 7.85884C7.71179 7.98851 7.60918 8.14239 7.53924 8.31179C7.4693 8.48114 7.43342 8.66261 7.43366 8.84583C7.43348 9.029 7.46936 9.21048 7.53936 9.37982C7.6093 9.5491 7.71191 9.70298 7.84135 9.83266C7.97073 9.96233 8.12444 10.0652 8.29366 10.1355C8.46283 10.2058 8.64419 10.242 8.82741 10.2422Z"
      fill="#ECB22E"
    />
    <path
      d="M1.18131e-06 8.94349C-0.00016913 9.12672 0.0357469 9.30819 0.105699 9.47747C0.17565 9.64682 0.278268 9.8007 0.407692 9.93037C0.537116 10.06 0.690812 10.1629 0.860008 10.2332C1.0292 10.3035 1.21057 10.3397 1.39378 10.3399C1.57698 10.3397 1.75836 10.3035 1.92755 10.2332C2.09674 10.1629 2.25044 10.06 2.37987 9.93037C2.50929 9.8007 2.61191 9.64682 2.68186 9.47747C2.75181 9.30819 2.78773 9.12672 2.78756 8.94349V7.54688H1.39378C1.21053 7.54705 1.02911 7.58333 0.859886 7.65363C0.690661 7.72392 0.53694 7.82688 0.407508 7.95661C0.278077 8.08634 0.175471 8.24028 0.105549 8.40963C0.0356285 8.57903 -0.00023728 8.7605 1.18131e-06 8.94378V8.94349ZM3.71674 8.94349V12.6675C3.71654 12.8507 3.75243 13.0322 3.82237 13.2016C3.8923 13.3709 3.99491 13.5248 4.12434 13.6545C4.25377 13.7842 4.40747 13.8871 4.57669 13.9574C4.74589 14.0277 4.92729 14.064 5.11052 14.0642C5.29374 14.064 5.47514 14.0277 5.64435 13.9574C5.81356 13.8871 5.96727 13.7842 6.09671 13.6545C6.22615 13.5248 6.32876 13.3709 6.3987 13.2016C6.46858 13.0322 6.50452 12.8507 6.50428 12.6675V8.94378C6.50452 8.76056 6.46858 8.57908 6.3987 8.40974C6.32876 8.2404 6.22615 8.08646 6.09671 7.95678C5.96727 7.82705 5.81356 7.72415 5.64435 7.65386C5.47514 7.58357 5.29374 7.54734 5.11052 7.54717C4.92729 7.54734 4.74589 7.58357 4.57669 7.65386C4.40747 7.72415 4.25377 7.82705 4.12434 7.95678C3.99491 8.08646 3.8923 8.2404 3.82237 8.40974C3.75243 8.57908 3.71654 8.76056 3.71674 8.94378"
      fill="#E01E5A"
    />
  </SvgIcon>
);
