import React, { useContext } from 'react';
import { TrashIcon } from 'src/components/Icons';
import { BaseActionsMenuButton, Action } from 'src/components/Dropdowns';
import { BaseActionRenderProps } from 'src/components/AgGrid/Renderer/types';
import { RouteContext } from 'src/context';
import { useGetPricesByProductIdQuery } from 'src/services/api/productsApi';

export interface ProductPriceTableRowModal {
  id: string;
  amount: number;
  currency: string;
  type: string;
  intervalCount: number;
  interval: string;
  status: string;
  createdAt: string;
  isUsed: boolean;
}

type EntityName = 'price' | 'product';

export interface ProductPriceActionsRendererProps
  extends BaseActionRenderProps {
  data: ProductPriceTableRowModal;
  handleClickRemove: (data: ProductPriceTableRowModal) => void;
  entityName: EntityName;
}

export const PriceActionRenderer = ({
  data,
  handleClickRemove,
  onActionMenuClick,
  entityName,
}: ProductPriceActionsRendererProps) => {
  const { pathname } = useContext(RouteContext);
  const productId = pathname.split('/').pop();

  const { data: prices = [] } = useGetPricesByProductIdQuery(productId!, {
    skip: !productId,
  });
  const actions: Action[] = [
    {
      name: `Delete`,
      onClick: () => handleClickRemove(data),
      icon: <TrashIcon />,
      isDelete: true,
      isDisabled: prices?.length === 1 || data.isUsed,
      ...(data.isUsed && {
        tooltipLabel: `You can’t delete this ${entityName} because it has been used before (e.g. in invoice or subscription)`,
      }),
      ...(prices?.length === 1 && {
        tooltipLabel: `Each product must have at least one ${entityName}`,
      }),
    },
  ];

  return (
    <BaseActionsMenuButton
      onActionMenuClick={onActionMenuClick}
      actions={actions}
    />
  );
};
