import React from 'react';
import {
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
  KBarOptions,
} from 'kbar';
import { useDispatch } from 'react-redux';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { PortalConfigContext } from 'src/context';
import { shortcutsSidebarOpen } from 'src/store/shortcuts/slice';
import { CommandBarResult } from 'src/components/CommandBar/CommandBarResults';
import { CommandBarSearch } from 'src/components/CommandBar/CommandBarSearch';
import { white } from 'src/theme/colors';
import { CommandBarShadow } from 'src/theme/shadows';
import { handleSignOut } from 'src/utils/UserUtils';
import { useAppSelector } from 'src/hooks/useStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    kbarPositioner: {
      // command bar should be stacked on top of any other component.
      zIndex: theme.zIndex.modal + 1,
    },
    kbarAnimator: {
      backgroundColor: white,
      boxShadow: CommandBarShadow,
      borderRadius: theme.shape.borderRadius,
    },
  }),
);

const kbarOptions: KBarOptions = { disableScrollbarManagement: true };

export const CommandBar: React.FC = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const portalConfig = React.useContext(PortalConfigContext);
  const isTestUser = useAppSelector((state) => state.user.data?.isTestUser);
  const isClient = useAppSelector((state) => state.user.isClient);
  const actions = React.useMemo(
    () => [
      {
        id: 'shortcutSidebar',
        name: 'Open Shortcut Sidebar',
        shortcut: ['?'],
        perform: () => {
          dispatch(shortcutsSidebarOpen(true));
        },
      },
      {
        id: 'logout-account',
        name: 'Logout',
        section: 'Account',
        keywords: 'logout sign out',
        perform: () => {
          handleSignOut(portalConfig, Boolean(isTestUser));
        },
      },
    ],
    [portalConfig, isTestUser],
  );

  return (
    // disableScrollbarManagement: true prevents kbar to add an extra margin
    // to the body element when the command bar is open.
    <KBarProvider actions={actions} options={kbarOptions}>
      {!isClient && (
        <KBarPortal>
          <KBarPositioner className={classes.kbarPositioner}>
            <KBarAnimator className={classes.kbarAnimator}>
              <CommandBarSearch />
              <CommandBarResult />
            </KBarAnimator>
          </KBarPositioner>
        </KBarPortal>
      )}
      {children}
    </KBarProvider>
  );
};
