import { Button, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { toggleRightSidebar } from 'src/store/ui/actions';
import { HoverNonBorderBackground } from 'src/theme/colors';
import { SidebarIcon, SidebarOpenIcon } from 'src/components/Icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      minWidth: 'unset',
      padding: theme.spacing(1),
      border: 'none',
      backgroundColor: HoverNonBorderBackground,
      '& .MuiButton-startIcon': {
        margin: 0,
      },
      '& svg': {
        height: 16,
        width: 16,
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(0.875),
      },
    },
    iconButtonWithNoBackground: {
      backgroundColor: 'unset',
    },
  }),
);

interface RightSidebarToggleProps {
  customClass?: string;
}

export const RightSidebarToggle = ({
  customClass = '',
}: RightSidebarToggleProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isRightSidebarOpen = useSelector(
    (state: RootState) => state.ui.rightSideBar.isOpen,
  );

  return (
    <Button
      id="right-sidebar-toggle"
      variant="outlined"
      startIcon={isRightSidebarOpen ? <SidebarOpenIcon /> : <SidebarIcon />}
      className={clsx(classes.iconButton, {
        [classes.iconButtonWithNoBackground]: !isRightSidebarOpen,
        [customClass]: Boolean(customClass),
      })}
      onClick={() =>
        dispatch(toggleRightSidebar({ isOpen: !isRightSidebarOpen }))
      }
    />
  );
};
