import { exportFormResponses } from 'src/utils/FormsUtils';
import { useAppSelector } from './useStore';
import { useFormResponse } from 'src/components/FormsV2/useFormResponse';

export const useExportFormResponse = (formResponseOptions?: {
  formId: string;
  responseRefId: string;
}) => {
  // when we are on the form details table, the formId and responseRefId
  // are available in the query params. So in that case we don't need
  // to pass them in formResponseOptions.
  // In the other hand, we do have cases when the these prop are not
  // present in the url params, then we need a way to provide
  // them to the hook. So we have defined this optional
  // argument formResponseOptions
  const { respondedForms } = useFormResponse(formResponseOptions);

  const clients = useAppSelector((state) => state.clients.clients || []);
  /**
   * This handles exporting a single form response
   * from the forms responses tab table.
   * @param {object} responseRowData - the row data of the response to export
   * @returns {void}
   */
  const handleExportForm = (responseRowData: { ref: string }) => {
    const responseToExport = respondedForms.find(
      (response) => response.ref === responseRowData.ref,
    );

    if (!responseToExport) return;

    const { formName } = responseToExport.fields;
    // use responder name as part of file name
    const { givenName, familyName } =
      clients.find((c) => c.id === responseToExport.clientId)?.fields ?? {};
    const responderName = `${givenName} ${familyName}`;
    const fileNameParts = `${formName}_${responderName}`;
    exportFormResponses([responseToExport], fileNameParts, clients);
  };

  return { handleExportForm };
};
