import { IconButton, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';
import { DividersAndCardBorders } from 'src/theme/colors';
import { RegularCardShadow } from 'src/theme/shadows';
import { CloseIcon } from 'src/components/Icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: RegularCardShadow,
      position: 'relative', // needed to position child elements that have absolute position,
      border: `1px solid ${DividersAndCardBorders}`,
      borderRadius: theme.shape.borderRadius,
      '&:hover': {
        // show dismiss card button when card is hovered
        '& .dismissBtn': {
          display: 'block',
          [theme.breakpoints.down('xs')]: {
            display: 'none',
          },
        },
      },
    },

    dismissButton: {
      display: 'none',
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      height: 20,
      width: 20,
    },
  }),
);

interface Props {
  children: React.ReactNode;
  className?: string;
  onDismissClick?: () => void;
  onClick?: () => void;
}

export const RegularCardBox: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { children, className, onDismissClick, ...containerDivProps } = props;

  return (
    <div {...containerDivProps} className={clsx(classes.root, className)}>
      {children}

      {onDismissClick && (
        <IconButton
          size="small"
          edge="end"
          onClick={onDismissClick}
          color="secondary"
          className={`${classes.dismissButton} dismissBtn`}
        >
          <CloseIcon style={{ fontSize: 10 }} />
        </IconButton>
      )}
    </div>
  );
};
