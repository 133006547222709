import React, { useMemo } from 'react';

import { curveMonotoneX } from '@visx/curve';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { bisector, max, min } from '@visx/vendor/d3-array';
import { scaleLinear, scaleUtc } from '@visx/scale';
import { LinePath } from '@visx/shape';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';

import { YAxisLabel } from 'src/components/HomePage/AnalyticsSection/YAxisLabel';
import { XAxisLabel } from 'src/components/HomePage/AnalyticsSection/XAxisLabel';
import BaseTypography from 'src/components/Text/BaseTypography';
import { useResizeObserver } from 'src/hooks/useResizeObserver';
import type { ChartData } from 'src/types/analytics';

// Data accessors
const getX = (d: ChartData): Date => d.date;
const getY = (d: ChartData): number => d.total;

const isDateTuple = (tuple: [unknown, unknown]): tuple is [Date, Date] => {
  return tuple.every((d) => d instanceof Date);
};

const ensureDateTuple = (tuple: [unknown, unknown]): [Date, Date] => {
  if (!isDateTuple(tuple)) {
    throw new Error('Invalid date tuple');
  }
  return tuple;
};

function nextMultipleOfTen(num: number): number {
  return Math.ceil(num / 10) * 10;
}

function prevMultipleOfTen(num: number): number {
  return Math.floor(num / 10) * 10;
}

function getYAxisValues(data: ChartData[]) {
  const minVal = Math.min(...data.map((item) => item.total));
  const maxVal = Math.max(...data.map((item) => item.total));

  if (maxVal === 0 && minVal === 0) {
    return { max: 10, mid: 5, min: 0 };
  }

  if (maxVal === minVal) {
    return { max: maxVal * 2, mid: maxVal, min: 0 };
  }

  const maxMultipleOfTen = nextMultipleOfTen(maxVal);
  const minMultipleOfTen = prevMultipleOfTen(minVal);
  const midVal = (maxMultipleOfTen + minMultipleOfTen) / 2;
  return { max: maxMultipleOfTen, mid: midVal, min: minMultipleOfTen };
}

export function Chart({ data }: { data: ChartData[] }) {
  const {
    tooltipData,
    tooltipLeft,
    tooltipOpen,
    tooltipTop,
    showTooltip,
    hideTooltip,
  } = useTooltip<ChartData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });
  const { ref: sizeRef, rect } = useResizeObserver<HTMLDivElement>();
  const yAxisLabels = useMemo(() => getYAxisValues(data), [data]);
  const tooltipDate = tooltipData?.date.toLocaleDateString();

  const scale = useMemo(() => {
    const { width, height } = rect;
    const xScale = scaleUtc({
      domain: ensureDateTuple([min(data, getX) ?? 0, max(data, getX) ?? 0]),
      range: [0, width],
      round: true,
    });
    const yScale = scaleLinear({
      domain: [yAxisLabels.min, yAxisLabels.max],
      // The -2 is just visual nudging. There's probably a better way.
      range: [height - 2, 0],
      round: true,
    });
    return { x: xScale, y: yScale };
  }, [rect, data]);

  return (
    <div className="text-current h-35 w-full" ref={containerRef}>
      <div className="flex pb-5">
        <div className="w-6 pr-4 box-content">
          <YAxisLabel labels={yAxisLabels} />
        </div>
        <div className="w-full h-full" ref={sizeRef}>
          {scale.x && scale.y && (
            <svg
              className={[
                'w-full',
                'h-full',
                'border-y',
                'border-y-gray-300',
                'border-solid',
                'border-x-0',
                'overflow-visible',
              ].join(' ')}
            >
              <Group>
                {/* Center line */}
                <line
                  x1="0%"
                  x2="100%"
                  y1="50%"
                  y2="50%"
                  className="stroke-gray-300"
                />

                {/* The data */}
                <LinePath<ChartData>
                  // A monotone curve prevents the line from going below the x-axis.
                  curve={curveMonotoneX}
                  data={data}
                  shapeRendering="geometricPrecision"
                  stroke="currentColor"
                  strokeOpacity={1}
                  strokeWidth={1}
                  x={(d) => {
                    if (scale.x) {
                      return scale.x(getX(d));
                    } else {
                      return 0;
                    }
                  }}
                  y={(d) => {
                    if (scale.y) {
                      return scale.y(getY(d));
                    } else {
                      return 0;
                    }
                  }}
                />

                {/* Marker for the tooltip */}
                {tooltipOpen && tooltipLeft && tooltipTop && (
                  <>
                    <line
                      x1={tooltipLeft}
                      x2={tooltipLeft}
                      y1={0}
                      y2="100%"
                      className="stroke-gray-300"
                    />

                    <circle
                      className="transition"
                      cx={tooltipLeft}
                      cy={tooltipTop}
                      fill="currentColor"
                      pointerEvents="none"
                      r={4}
                      stroke="white"
                      strokeWidth={2}
                    />
                  </>
                )}

                {/* Invisible overlay for tooltip */}
                <rect
                  height="100%"
                  width="100%"
                  fill="transparent"
                  onMouseLeave={hideTooltip}
                  onMouseMove={(event) => {
                    const coords = localPoint(event);
                    if (!coords || !scale.x || !scale.y) return;
                    const bisectDate = bisector(getX).center;
                    const date = scale.x.invert(coords.x);
                    const index = bisectDate(data, date, 1);
                    const datum = data[index];
                    showTooltip({
                      tooltipData: datum,
                      tooltipLeft: scale.x(getX(datum)),
                      tooltipTop: scale.y(getY(datum)),
                    });
                  }}
                />
              </Group>
            </svg>
          )}
        </div>
      </div>

      <div className="pl-10">
        <XAxisLabel data={data} />
      </div>

      <TooltipInPortal
        left={tooltipLeft}
        top={tooltipTop}
        className={`transition ${
          tooltipOpen
            ? 'opacity-100 pointer-events-auto'
            : 'opacity-0 pointer-events-none'
        }`}
      >
        {tooltipData && (
          <div>
            <BaseTypography fontType="14Regular">{tooltipDate}</BaseTypography>
            <BaseTypography fontType="14Regular">
              Total: {Intl.NumberFormat('en-us').format(tooltipData.total)}
            </BaseTypography>
          </div>
        )}
      </TooltipInPortal>
    </div>
  );
}
