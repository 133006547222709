export const baseMarketingDomain = 'copilot.com';

export const docsURL = `https://docs.${baseMarketingDomain}`;

export const PORTAL_DOMAINS = {
  copilot: 'https://copilot.com',
};

export const ClientImportHelpCenterLink =
  'https://www.copilot.com/guide/client-import';
