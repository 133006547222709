import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import BaseTypography from 'src/components/Text/BaseTypography';
import { UserAvatar } from 'src/components/User';
import { BaseResultItem } from 'src/components/CommandBar/BaseResultItem';
import {
  ClientAction,
  RenderParams,
} from 'src/components/CommandBar/commandBarTypes';
import { BlackHeadings, GraySmall } from 'src/theme/colors';

interface UserResultItemProps extends RenderParams {
  item: RenderParams['item'] & ClientAction;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userNameText: {
      color: BlackHeadings,
    },
    userDetailsWrapper: {
      marginLeft: theme.spacing(2),
    },
    companyText: {
      color: GraySmall,
    },
  }),
);
/**
 * This component renders the user action result item
 */
export const UserResultItem: React.FC<UserResultItemProps> = ({
  item,
  active,
}) => {
  const classes = useStyles();
  const { companyName, fallbackColor, pictureUrl } = item.additionalData;
  return (
    <BaseResultItem active={active}>
      <UserAvatar
        avatarSize="regularLarge"
        type="image"
        avatarUrl={pictureUrl}
        name={item.name}
        fallbackColor={fallbackColor}
        shape="circle"
      />
      <div className={classes.userDetailsWrapper}>
        <BaseTypography
          data-testid="user-name"
          className={classes.userNameText}
          fontType="13Medium"
        >
          {item.name}
        </BaseTypography>
        {companyName && (
          <BaseTypography
            data-testid="company-name"
            className={classes.companyText}
            fontType="12Regular"
          >
            {companyName}
          </BaseTypography>
        )}
      </div>
    </BaseResultItem>
  );
};
