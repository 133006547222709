import { Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import {
  ContractDetailsPageView,
  ContractPageTabs,
} from 'src/components/Contracts';
import { InboxSidebarItemData } from 'src/components/Inbox/InboxSidebar';
import { NotificationContentBreadcrumbs } from 'src/components/Inbox/NotificationContentBreadcrumbs';
import {
  CONTRACTS_PAGE,
  CONTRACT_DETAILS_PAGE,
  CLIENT_APPS_PAGE,
  FILES_PAGE,
  FORMS_DETAIL_PAGE_V2,
  FORMS_PAGE,
  INVOICES_PAGE,
} from 'src/constants';
import { FlagsContext } from 'src/context';
import { InboxNotificationDetailsResponse } from 'src/entities/Notifications';
import { useGetContractsQuery } from 'src/services/api';
import { useGetInstallsQuery } from 'src/services/api/applicationsApi';
import { useGetFormByIdQuery } from 'src/services/api/formsApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: (props: { isFileNotification: boolean }) =>
        props.isFileNotification
          ? theme.spacing(2.5, 0, 1, 2.5)
          : theme.spacing(0),
    },
  }),
);

type Props = {
  selectedNotification: InboxSidebarItemData;
  selectedNotificationDetails: InboxNotificationDetailsResponse | undefined;
  children: React.ReactNode; // This is the content of the notification details (PDFViewer, FilesTable, FormResponse, Invoice)
};
export const NotificationDetailsContainer = ({
  selectedNotification,
  selectedNotificationDetails,
  children,
}: Props) => {
  const {
    resourceId,
    notificationEventType,
    channelId,
    avatarInfo,
    refId: responseRefId,
  } = selectedNotification || {};

  const { EnableInvoiceDetailsPage } = React.useContext(FlagsContext);

  const contractTemplateId = selectedNotification?.channelId;
  const isOneOffContract = !contractTemplateId;
  const { data: contracts } = useGetContractsQuery({
    contractTemplateId,
  });
  const { data: formTemplate } = useGetFormByIdQuery(resourceId as string, {
    skip: notificationEventType !== 'formResponse.completed',
  });
  const contract = contracts?.find((c) => c.id === resourceId);
  const invoiceNumber =
    selectedNotificationDetails?.additionalFields?.invoiceNumber ||
    selectedNotification?.resourceId;

  const invoiceId = selectedNotification?.refId;

  const classes = useStyles({
    isFileNotification:
      selectedNotification?.notificationEventType === 'files.new' ||
      selectedNotification?.notificationEventType === 'files.created',
  });

  const { data: { extensionsSettings } = {} } = useGetInstallsQuery();

  const items = useMemo(() => {
    switch (notificationEventType) {
      case 'formResponse.completed':
      case 'forms.completed':
        return [
          { label: 'Forms', path: `${FORMS_PAGE.path}` },
          {
            label: formTemplate?.fields.name || '',
            path: `${FORMS_DETAIL_PAGE_V2.path}?formId=${resourceId}&view=see_responses`,
          },
          {
            label: avatarInfo?.name || '',
            path: `${FORMS_PAGE.path}/submit?formId=${resourceId}&responseRefId=${responseRefId}`,
          },
        ];
      case 'invoice.paid':
        return [
          { label: 'Invoices', path: `${INVOICES_PAGE.path}` },
          {
            label: invoiceNumber,
            path: EnableInvoiceDetailsPage
              ? `${INVOICES_PAGE.path}/details?invoiceId=${invoiceId}`
              : '',
          },
        ];
      case 'files.new':
      case 'files.created':
        return [
          { label: 'Files', path: `${FILES_PAGE.path}` },
          {
            label: avatarInfo?.name || '',
            path: `${FILES_PAGE.path}?channelId=${channelId}`,
          },
        ];
      case 'esign.completed':
      case 'contract.signed':
        return [
          { label: 'Contracts', path: `${CONTRACTS_PAGE.path}` },
          {
            label: contract?.name || '',
            path: isOneOffContract
              ? `${CONTRACTS_PAGE.path}?view=${ContractPageTabs.Submissions}`
              : `${CONTRACT_DETAILS_PAGE.path}?templateId=${contractTemplateId}&view=${ContractDetailsPageView.SubmissionRequests}`,
          },
          {
            label: avatarInfo?.name || '',
            path: `${CONTRACTS_PAGE.path}/submission?contractId=${resourceId}`,
          },
        ];

      case 'customApp.action':
        if (selectedNotificationDetails && extensionsSettings) {
          const appName =
            extensionsSettings[selectedNotificationDetails?.resourceId].name;
          const queryParams = new URLSearchParams({
            id: selectedNotificationDetails?.resourceId,
          });
          const ctaQueryParams = new URLSearchParams({
            id: selectedNotificationDetails?.resourceId,
            ...(selectedNotificationDetails?.deliveryTargets?.inProduct
              ?.ctaParams ?? {}),
          });
          return [
            {
              label: appName,
              path: `${CLIENT_APPS_PAGE.path}?${queryParams.toString()}`,
            },
            {
              label: avatarInfo?.name || '',
              path: `${CLIENT_APPS_PAGE.path}?${ctaQueryParams.toString()}`,
            },
          ];
        }

        return [];

      default:
        return [];
    }
  }, [
    channelId,
    contract?.name,
    formTemplate?.fields.name,
    contractTemplateId,
    notificationEventType,
    resourceId,
    avatarInfo,
    extensionsSettings,
    EnableInvoiceDetailsPage,
  ]);

  return (
    <>
      <div className={classes.root}>
        <NotificationContentBreadcrumbs items={items} />
      </div>
      {children}
    </>
  );
};
