import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import BaseTypography from 'src/components/Text/BaseTypography';
import { Callout } from 'src/components/Callout/Callout';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingLeft: 0,
      border: 'none',
      display: 'flex',
      alignContent: 'flex-start',
      alignItems: 'center',

      '& svg': {
        margin: '0px !important',
      },
    },
  }),
);
/**
 * This component is used to display error callout
 * @returns {JSX.Element} Returns the error callout
 */
export const ErrorCallout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Callout variant="error" className={classes.root}>
      <BaseTypography fontType="13Regular">{children}</BaseTypography>
    </Callout>
  );
};
