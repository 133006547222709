import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DownloadAttachmentIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M14.3171 14.875C14.6193 14.875 14.8624 14.6421 14.8624 14.3527C14.8624 14.0632 14.6193 13.8304 14.3171 13.8304H6.6839C6.38175 13.8304 6.13867 14.0632 6.13867 14.3527C6.13867 14.6421 6.38175 14.875 6.6839 14.875H14.3171ZM10.103 11.9239C10.2052 12.0283 10.3506 12.0893 10.5005 12.0893C10.6505 12.0893 10.7936 12.0305 10.8981 11.9239L13.806 8.96406C14.0127 8.75296 14.0013 8.42433 13.7833 8.22628C13.5652 8.02824 13.2199 8.03912 13.0131 8.24805L11.0457 10.2503V8.43304V5.64732C11.0457 5.35787 10.8027 5.125 10.5005 5.125C10.1984 5.125 9.95528 5.35787 9.95528 5.64732V8.43304V10.2503L7.99018 8.25022C7.78345 8.03912 7.43814 8.03041 7.22005 8.22846C7.00195 8.42651 6.99059 8.75731 7.19733 8.96624L10.1052 11.9261L10.103 11.9239Z"
      fill="#6B6F76"
    />
  </SvgIcon>
);
