import React from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { MultiSelectOption } from 'src/components/Select/types';
import { BaseChip, TruncatedText } from 'src/components/UI';
import ColorUtils from 'src/utils/ColorUtils';
import BaseTypography from 'src/components/Text/BaseTypography';
import { LightGray } from 'src/theme/colors';

const useStyles = makeStyles(() =>
  createStyles({
    multiSelectTagsWrapper: {
      width: '200px',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      cursor: 'pointer',
    },
  }),
);

interface MultiSelectInputProps {
  selectedTags: Array<MultiSelectOption>;
  tagsLimit?: number;
}

export const MultiSelectInput: React.FC<MultiSelectInputProps> = ({
  selectedTags,
  tagsLimit = 3,
}) => {
  const classes = useStyles();

  return selectedTags ? (
    <Box className={classes.multiSelectTagsWrapper}>
      {selectedTags.map((optionValue, index) => {
        const showOption = tagsLimit ? index < tagsLimit : true;
        return (
          showOption && (
            <Box key={optionValue.id} px={0.5} py={0.5}>
              <BaseChip
                label={
                  <TruncatedText
                    styleProps={{
                      color: optionValue.color,
                    }}
                    text={optionValue.label}
                    maxChars={10}
                  />
                }
                style={{
                  backgroundColor: ColorUtils.GetColorDarknessShades(
                    optionValue.color,
                  ).light,
                  cursor: 'pointer',
                }}
              />
            </Box>
          )
        );
      })}
      {tagsLimit && (selectedTags?.length ?? 0) > tagsLimit && (
        <BaseTypography fontType="12Medium" style={{ color: LightGray }}>
          + {(selectedTags?.length ?? 0) - tagsLimit}
        </BaseTypography>
      )}
    </Box>
  ) : null;
};
