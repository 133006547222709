import * as Colors from 'src/theme/colors';
import { typography13MediumStyle } from 'src/components/Text';
import { GraySmall } from 'src/theme/colors';

export default {
  root: {
    '& label': {
      '&.MuiInputLabel-outlined': {
        color: Colors.BlackHeadings,
        transform: 'none',
        position: 'relative',
        ...typography13MediumStyle,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0,
      borderColor: Colors.NonHoverBorder,
      '& legend': {
        display: 'none',
      },
    },
    '& .MuiOutlinedInput-root': {
      background: Colors.white,
      borderRadius: '5px',
      color: Colors.BlackHeadings,
      '& .MuiInputBase-input': {
        padding: '0 12px',
        display: 'flex',
        alignItems: 'center',
      },
      '&:hover:not(.Mui-error):not(.Mui-focused) fieldset': {
        borderColor: Colors.HoverBorder,
      },
      '&.Mui-error fieldset': {
        borderColor: Colors.red,
      },
      '&.Mui-focused fieldset': {
        borderWidth: '1px',
      },
      '& .MuiAutocomplete-endAdornment': {
        top: 'inherit',
        '& > svg': {
          height: 15,
          width: 15,
          color: GraySmall,
        },
      },
      '&.MuiOutlinedInput-multiline': {
        padding: 0,
        '& textarea': {
          paddingTop: '16px',
          paddingBottom: '16px',
        },
      },
      '&:not(.MuiOutlinedInput-multiline)': {
        '&.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
          {
            padding: '0',
            paddingLeft: '6px',
          },
      },
      '&.Mui-disabled': {
        color: Colors.GraySmall,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: Colors.NonHoverBorder,
        },
      },
    },
  },
};
