import React from 'react';
import { useChannelPreviewData } from 'src/components/Channels/useChannelPreviewData';
import { ChannelType } from 'src/components/Channels/useChannelForm';
import { FormsNotificationsStatuses } from 'src/store/notifications/types';
import { ExtensionItemStatus } from 'src/store/dashboard/types';
import { FileChannel } from 'src/store/files/types';
import { ChannelListItem } from '../UI/ListItem/ChannelListItem';

export interface AvatarInfoType {
  name: string;
  imageUrl: string;
  fallbackColor?: string;
  isCompanyUser?: boolean;
}

export interface ChannelOptionData {
  companyID?: string;
  fileChannel?: FileChannel;
  extensionMemberIds?: string[];
  id?: string;
  clientId?: string;
  name: string;
  avatarInfo: AvatarInfoType[];
  initialLetters?: string;
  position?: number;
  status?: FormsNotificationsStatuses | ExtensionItemStatus;
  type?: string;
  updatedDate?: string;
}

export interface ChannelSidebarItemProps {
  channelType: ChannelType;
  isSelected: boolean;
  channelOption: ChannelOptionData;
  // TODO(refactor needed): the channel item data is available on the parent component
  // we don't need to pass it to the onSelect callback. We should remove it.
  onSelect: (channelData: ChannelOptionData) => void;
  isRootSelected?: boolean;
}

export const ChannelSidebarItem: React.FC<ChannelSidebarItemProps> = React.memo(
  ({ channelOption, onSelect, isSelected, channelType }) => {
    const { avatarInfo, name } = channelOption;

    const { getChannelSummary, getTotalUnreadNotificationsCount } =
      useChannelPreviewData(channelOption, channelType);

    const { time: channelSummaryTime, subTitle: channelSummarySubtitle } =
      getChannelSummary();
    const channelNotificationCounter = getTotalUnreadNotificationsCount();

    return (
      <ChannelListItem
        title={name}
        subTitle={channelSummarySubtitle}
        unread={Boolean(channelNotificationCounter)}
        timestamp={channelSummaryTime}
        onSelect={() => onSelect(channelOption)}
        key={`${channelType}-channel-${name}`}
        selected={isSelected}
        avatarVariant={
          channelOption.fileChannel?.fields.companyID ? 'rounded' : 'circle'
        }
        members={avatarInfo.map(
          ({ name: avatarName, imageUrl, fallbackColor }) => ({
            avatar: imageUrl,
            fallbackColor: fallbackColor || '',
            name: avatarName,
          }),
        )}
      />
    );
  },
);
