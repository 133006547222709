import React from 'react';
import { StepperPageContextType } from 'src/components/Layout/StepperLayout/stepperTypes';

export const StepperPageContext = React.createContext<StepperPageContextType>({
  pageTitle: null,
  setPageTitle: () => null,
  steps: [],
  setSteps: () => null,
  onBack: () => null,
  onNext: () => null,
  activeStep: null,
  activeStepIndex: 0,
  onComplete: () => null,
  nextButtonTooltipOptions: null,
  // the next button displays a tooltip on hover when the match step
  // has some required fields that are not mapped. To display the tooltip
  // this function is called with the tooltip options (title, description)
  setNextButtonTooltipOptions: () => null,
  setAsyncNextHandler: () => null,
  asyncNextHandler: null,
  loading: false,
  submitActions: [],
  setSubmitActions: () => null,
  showStepper: true,
  setShowStepper: () => null,
});
