export const internalCopilotDomainProd = 'copilot.com';
export const internalCopilotDomainStaging = 'copilot-staging.com';

// copilot app hostnames
export const copilotAppProdDomain = 'copilot.app';
export const copilotAppStagingDomain = 'copilot-staging.app';
export const copilotAppDomains = [
  copilotAppProdDomain,
  copilotAppStagingDomain,
];

// copilot hostnames
export const copilotDashboardHostnameProd = 'dashboard.copilot.com';
export const copilotDashboardHostnameStaging = 'dashboard.copilot-staging.com';
export const copilotInternalUserHostnames = [
  copilotDashboardHostnameProd,
  copilotDashboardHostnameStaging,
  'localhost',
];

export const prodClientUserDomain = 'copilot.app';
export const devClientUserDomain = 'copilot-staging.app';

export const copilotClientUserDomains = [
  prodClientUserDomain,
  devClientUserDomain,
  '127.0.0.1',
];

// these hostnames are allowed to load the internal user portal
export const allowedInternalUserPortalHostnames = [
  ...copilotInternalUserHostnames,
  'localhost',
  '127.0.0.1',
];

export const allowedOnboardingPathHostnames = [
  ...allowedInternalUserPortalHostnames,
];
