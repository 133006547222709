export const ImageCDNBucket = 'lightout-portal';
export const BaseCDNURL = 'https://d3pe7ukunnhedw.cloudfront.net';
export const BaseS3URL = 'https://lightout-portal.s3.us-west-2.amazonaws.com';
export const BaseS3AcceleratedURL =
  'https://lightout-portal.s3-accelerate.amazonaws.com';
export const BaseS3PublicURLs = [
  `${BaseS3URL}/public/`,
  `${BaseS3AcceleratedURL}/public/`,
];

interface ImageRequestOptionsType {
  grayscale?: boolean;
  resize?: {
    width?: number;
    height?: number;
  };
}

interface ImageRequestType {
  bucket: string;
  key: string;
  edits?: ImageRequestOptionsType;
}

export const getCDNImageURL = (
  key: string,
  options?: ImageRequestOptionsType,
) => {
  const imageRequestOptions: ImageRequestType = {
    bucket: ImageCDNBucket,
    key,
  };
  if (options) {
    imageRequestOptions.edits = options;
  }
  const imageRequest = JSON.stringify(imageRequestOptions);
  return `${BaseCDNURL}/${Buffer.from(imageRequest).toString('base64')}`;
};

export const getMediaKitImageURL = (
  fileName: string,
  options?: ImageRequestOptionsType,
) => getCDNImageURL(`public/images/mediakit/${fileName}`, options);

/**
 * For a given S3URL get the cdn optimized url if this a public url
 * otherwise return the original url
 * @param s3Url where the image can be accessed
 * @param options how to process image
 */
export const getPublicImageURL = (
  s3Url: string,
  options?: ImageRequestOptionsType,
) => {
  let urlParts: Array<string> = [];
  // if the input url starts with any of the base public urls then set
  // that as the url parts to get cdn image url
  BaseS3PublicURLs.forEach((basePublicURL) => {
    if (s3Url.startsWith(basePublicURL)) {
      urlParts = s3Url.split(basePublicURL);
    }
  });

  if (urlParts.length !== 2 || urlParts[0] !== '') {
    return s3Url;
  }

  const imagePath = decodeURIComponent(urlParts[1]);
  return getCDNImageURL(`public/${imagePath}`, options);
};
