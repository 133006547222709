import React, {
  MouseEventHandler,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  createStyles,
  makeStyles,
  Button,
  IconButton,
  useTheme,
  useMediaQuery,
  CustomTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import Draggable, {
  DraggableData,
  DraggableEvent,
  DraggableEventHandler,
} from 'react-draggable';
import { Resizable, ResizeCallback } from 're-resizable';
import textFit, { TextFitOption } from 'textfit';
import { Logger } from 'aws-amplify';
import { RootState } from 'src/store/reduxTypes';
import { SignaturePageComponent } from 'src/store/signaturePage/types';
import {
  SignatureComponentType,
  CONTRACT_COMPONENT_DEFAULT_HEIGHT,
  SIGNATURE_COMPONENT_DEFAULT_HEIGHT,
  CONTRACT_COMPONENT_DEFAULT_WIDTH,
  CONTRACT_COMPONENT_MINIMUM_WIDTH,
} from 'src/constants';
import { red, white, NonHoverBorder, BlackHeadings } from 'src/theme/colors';
import {
  SignatureDateIcon,
  SignatureIcon,
  SignatureInitialIcon,
  SignatureTextIcon,
  TrashIcon,
} from 'src/components/Icons';
import { RegularCardShadow } from 'src/theme/shadows';
import ColorUtils from 'src/utils/ColorUtils';
import { ISize } from 'src/components/Signature/SignatureDraggableComponent';
import { ensureUnreachable } from 'src/utils/common_utils';
import { convertTextToDataEncodedImage } from '../Canvas/canvas_helpers';
import {
  SIGNATURE_FONT,
  SIGNATURE_FONT_SIZE,
} from '../Canvas/canvas_constants';
import { isRequestComponent } from '../Contracts/contract_helpers';
import { parseMessageToHTML } from 'src/utils/UrlUtils';
import { zIndex } from 'src/theme/zindex';
import { ContractBuilderContext } from 'src/context/contractBuilderContext';

const logger = new Logger('SignatureDraggableItem');

type StyleProps = {
  pageScalerFactor: number; // Represents the ratio of width of current screen to signaturePageContentWidth
  isSmallScreen: boolean;
  isMounted: boolean;
  isClient: boolean;
  component: SignaturePageComponent;
};

const ICON_SIZE = 14;
const CONTRACT_DEFAULT_FONT_SIZE = 12;

const textFitConfig: TextFitOption = {
  minFontSize: 1,
  maxFontSize: CONTRACT_DEFAULT_FONT_SIZE,
  multiLine: true,
  alignVert: true,
};

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles<string, StyleProps>({
    root: {
      display: 'flex',
      position: 'absolute',
      cursor: 'default',
      zIndex: theme.zIndex.contractSignatureComponent,
    },
    selectedContractButton: {
      // Display the selected signature button above non-selected ones.
      // Ensures proper functionality, like delete and resize, in cases of overlap
      zIndex: theme.zIndex.contractSignatureComponent + 1,
    },
    closeIcon: {
      height: SIGNATURE_COMPONENT_DEFAULT_HEIGHT, // if close icon and button height is not same then we get minor shift in the component when placed on the PDF
      width: SIGNATURE_COMPONENT_DEFAULT_HEIGHT, // icon is square so width and height should be same
      backgroundColor: white,
      border: `1px solid ${NonHoverBorder}`,
      boxShadow: RegularCardShadow,
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: white,
      },
    },
    signatureImage: {
      maxHeight: '100%',
      maxWidth: '100%',
      objectFit: 'contain',
      width: 'auto',
      height: '100%',
    },
    eSigComponentButton: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      minWidth: CONTRACT_COMPONENT_MINIMUM_WIDTH,
      borderRadius: 0,
      textAlign: 'left',
      fontSize: CONTRACT_DEFAULT_FONT_SIZE,
      zIndex: theme.zIndex.contractSignatureComponent,
      '& .MuiButton-label': {
        height: '100%',
        justifyContent: 'flex-start', // override default button label alignment
      },
      '& .MuiButton-iconSizeMedium > svg': {
        width: (props) =>
          props.isMounted && props.isSmallScreen
            ? ICON_SIZE / props.pageScalerFactor
            : ICON_SIZE,
        height: (props) =>
          props.isMounted && props.isSmallScreen
            ? ICON_SIZE / props.pageScalerFactor
            : ICON_SIZE,
      },
    },
    eSigComponentValue: {
      color: BlackHeadings,
      backgroundColor: 'transparent',
    },
    eSigComponentPlaceholder: {
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.primary.main,
      backgroundColor: ColorUtils.GetColorDarknessShades(
        theme.palette.primary.main,
      ).light,
      '&:hover': {
        backgroundColor: ColorUtils.GetColorDarknessShades(
          theme.palette.primary.main,
        ).light,
      },
    },
    contractButtonIcon: {
      marginLeft: theme.spacing(1),
      marginRight: '6px',
    },
    autoFitText: {
      display: 'inline-block',
      height: '100%',
    },
    clientPlaceholderText: {
      display: 'inherit',
      alignItems: 'center',
    },
    clientContractPlaceholderText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: theme.spacing(1),
    },
    signatureComponent: {
      height: (props) => props.component.height,
      width: (props) => props.component.width,
      fontSize: (props) =>
        props.isSmallScreen
          ? `${CONTRACT_DEFAULT_FONT_SIZE / props.pageScalerFactor}px`
          : `${CONTRACT_DEFAULT_FONT_SIZE}px`,
    },
    signatureButtonHover: {
      '&.MuiButton-root:hover': {
        backgroundColor: (props) =>
          props.isClient ? 'transparent' : 'inherit',
      },
    },
    // class to overide default MUI button cursor ( pointer )
    signatureButtonCursor: {
      cursor: 'default',
    },
    startIcon: {
      margin: 0,
    },
  }),
);

export enum ContractDragItemType {
  Pending = 'pending',
  Edit = 'edit',
}

type PendingDraggableItemProps = {
  type: ContractDragItemType.Pending;
  component: SignaturePageComponent;
  disableDragging: boolean;
};

type ActiveDraggableItemProps = {
  type: ContractDragItemType.Edit;
  component: SignaturePageComponent;
  handleComponentMoved: (e: DraggableEvent, data: DraggableData) => void;
  handleComponentResized: (dimensions: ISize, componentId: string) => void;
  handleRemoveComponent: () => void;
  handleComponentClicked: MouseEventHandler<HTMLButtonElement>;
  isComponentSelected: boolean;
  disableDragging: boolean;
};

export type ContractDraggableItemProps =
  | PendingDraggableItemProps
  | ActiveDraggableItemProps;

export const ContractDraggableItem = React.forwardRef<
  HTMLButtonElement,
  ContractDraggableItemProps
>((props, draggableButtonRef) => {
  const { component, disableDragging } = props;
  const isComponentSelected =
    'isComponentSelected' in props && props.isComponentSelected;
  const draggableRef = React.useRef(null);

  const [isResizing, setIsResizing] = React.useState(false);
  // State to determine whether to lock the aspect ratio during resizing when the shift key is pressed
  const [lockAspectRatio, setLockAspectRatio] = useState(false);
  const [isDragging, setIsDragging] = React.useState(false);
  const [isMounted, toggleMountedState] = React.useReducer(
    (state) => !state,
    false,
  ); // textFit needs the component to be mounted to work

  const { pageScalerFactor } = useContext(ContractBuilderContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isClient = useSelector((state: RootState) => state.user.isClient);
  const activeComponentKey = useSelector(
    (state: RootState) => state.signaturePage.activeComponentKey,
  );

  const classes = useStyles({
    isSmallScreen,
    pageScalerFactor,
    isMounted,
    isClient,
    component,
  });
  const textRef = React.useRef(null);
  const [size, setSize] = React.useState<ISize>({
    height: component?.height ?? CONTRACT_COMPONENT_DEFAULT_HEIGHT,
    width: component?.width ?? CONTRACT_COMPONENT_DEFAULT_WIDTH,
  });

  const pdfContainer = document.getElementById(
    'pdf-container',
  ) as HTMLDivElement;

  useEffect(() => {
    toggleMountedState();
    return () => {
      toggleMountedState();
    };
  }, []);

  const renderContractComponentValue = useMemo(() => {
    const isImageComponent = [
      SignatureComponentType.REQUEST_SIGN,
      SignatureComponentType.REQUEST_INITIAL,
      SignatureComponentType.OWNER_SIGN,
      SignatureComponentType.OWNER_INITIAL,
    ].includes(component.componentType);

    if (!component.imageTextValue) {
      console.info('No image text value found for component', component);

      // migrated contracts will only have an encodedImgValue, so confirm we can parse that value and return here if necessary
      if (
        isImageComponent &&
        component.encodedImgValue?.startsWith('data:image')
      ) {
        return (
          <img
            src={component.encodedImgValue}
            alt="signatureImage"
            height={component.resizedHeight}
            width={component.resizedWidth}
            draggable={false}
            className={classes.signatureImage}
          />
        );
      }
    }

    const imgSrc = isImageComponent
      ? convertTextToDataEncodedImage(
          component.imageTextValue || '',
          SIGNATURE_FONT_SIZE,
          SIGNATURE_FONT,
        )
      : '';

    // If component width is too small to fit the text label
    // Don't show the text label
    if (
      !component.value &&
      component.width <= CONTRACT_COMPONENT_MINIMUM_WIDTH + 8
    ) {
      return null;
    }

    // For request components
    if (!component.value && isRequestComponent(component.componentType)) {
      return (
        <span
          className={classes.clientContractPlaceholderText}
          style={{ width: component.width - CONTRACT_COMPONENT_MINIMUM_WIDTH }}
        >
          {component.name || component.label}
        </span>
      );
    }

    if (isImageComponent && imgSrc) {
      return (
        <img
          src={imgSrc}
          alt="signatureImage"
          height={component.resizedHeight}
          width={component.resizedWidth}
          draggable={false}
          className={classes.signatureImage}
        />
      );
    }

    // For text-based components
    // If the input supports multiline content, like variable inputs,
    // it can contain HTML content. We need to parse the content to HTML.
    const parsedValue = parseMessageToHTML(component.value || '');

    return (
      <span
        ref={textRef}
        className={clsx(classes.autoFitText)}
        style={{ width: component.width }}
        dangerouslySetInnerHTML={{ __html: parsedValue }}
      />
    );
  }, [component, isSmallScreen, size, pageScalerFactor, isMounted, isClient]);

  // based on the component type render the signature icon
  // need to show icon only for client inputs
  const renderSignatureIcon = useMemo(() => {
    if (component.value) {
      return null;
    }

    const iconClasses = clsx(classes.contractButtonIcon);

    switch (component.componentType) {
      case SignatureComponentType.REQUEST_SIGN:
        return <SignatureIcon className={iconClasses} />;
      case SignatureComponentType.REQUEST_INITIAL:
        return <SignatureInitialIcon className={iconClasses} />;
      case SignatureComponentType.REQUEST_DATE:
        return <SignatureDateIcon className={iconClasses} />;
      case SignatureComponentType.REQUEST_TEXT:
        return <SignatureTextIcon className={iconClasses} />;
      case SignatureComponentType.OWNER_DATE:
      case SignatureComponentType.OWNER_INITIAL:
      case SignatureComponentType.OWNER_SIGN:
      case SignatureComponentType.OWNER_TEXT:
        return null;
      default:
        return ensureUnreachable(component.componentType);
    }
  }, [component.value, component.componentType, classes]);

  // calculate fontsize and fit text according to width available
  const calculateFontSize = () => {
    const config: TextFitOption = {
      minFontSize: 1,
      maxFontSize: isSmallScreen
        ? CONTRACT_DEFAULT_FONT_SIZE / pageScalerFactor
        : CONTRACT_DEFAULT_FONT_SIZE,
      multiLine: true,
      alignVert: true,
    };
    try {
      if (textRef.current) textFit(textRef.current, config);
    } catch (e) {
      logger.error(e, 'Error while calculating field');
    }
  };

  // Fit text to container on intial load, when the component value is changed and
  React.useEffect(() => {
    if (!isMounted) return;
    calculateFontSize();
  }, [isMounted, textRef.current, component, pageScalerFactor, size]);

  React.useEffect(() => {
    if (size.height === component.height && size.width === component.width) {
      return;
    }

    setSize({
      height: component?.height, // default height: ;
      width: component?.width, // default width: ;
    });
  }, [component, size]);

  // DraggableEventHandler is used to update the component position
  // If false is returned, the component is not dragged
  const handleDisableDrag: DraggableEventHandler = () => false;

  const handleResizeStop: ResizeCallback = (_, __, ref) => {
    const dimensions = {
      height: ref.clientHeight,
      width: ref.clientWidth,
    };

    // if the component is resized then fit the text content to the new resized size
    if (textRef.current) textFit(textRef.current, textFitConfig);

    if ('handleComponentResized' in props) {
      props.handleComponentResized(dimensions, component.key);
    }
    setIsResizing(false);
  };

  const handleResize: ResizeCallback = (
    event,
    _ususedDirection,
    _unusedRef,
    d,
  ) => {
    // This will be true when shiftKey is pressed while resizing
    const shiftPressed = event.shiftKey;
    setLockAspectRatio(shiftPressed);

    setSize((prev) => ({
      height: prev.height + d.height,
      width: prev.width + d.width,
    }));
  };

  const handleDrag: DraggableEventHandler = (_, data) => {
    // if component is not moved from it's last position don't set dragging to true
    if (data.x === component.xPosition && data.y === component.yPosition)
      return;

    setIsDragging(true);
  };

  const handleDragStop = (e: DraggableEvent, data: DraggableData) => {
    // if component is not moved from it's last position don't update reducer state
    if (data.x === component.xPosition && data.y === component.yPosition)
      return;

    // When resizing e.target is null
    // And we don't want to run this function during resize
    if (e.target && 'handleComponentMoved' in props) {
      props.handleComponentMoved(e, data);
    }
    setIsDragging(false);
  };

  const handleRemoveComponent = () => {
    if ('handleRemoveComponent' in props) {
      props.handleRemoveComponent();
    }
  };

  const handleComponentClicked: MouseEventHandler<HTMLButtonElement> = (e) => {
    if ('handleComponentClicked' in props) {
      props.handleComponentClicked(e);
    }
  };

  const renderRemoveIcon = () => {
    // Don't show remove icon to client
    if (isClient) return null;

    // Don't show remove icon if component is not selected
    if (!isComponentSelected) return null;

    // Don't show remove icon if dragging, so that user can place signature component on the edge of page
    if (isDragging) return null;

    // Don't show remove icon if resizing
    if (isResizing) return null;

    return (
      <IconButton onClick={handleRemoveComponent} className={classes.closeIcon}>
        <TrashIcon
          data-testid="remove-button"
          style={{ fontSize: 16, color: red }}
        />
      </IconButton>
    );
  };

  // Indicator for current signing componenet only for client
  // if active componets key is same as component key show empty button with white background
  if (activeComponentKey === component.key && isClient)
    return (
      <Draggable
        defaultClassName={classes.root}
        defaultPosition={{ x: component.xPosition, y: component.yPosition }}
        disabled
      >
        <Button
          id={component.key}
          data-testid="active-signature-component"
          className={clsx(
            classes.eSigComponentButton,
            classes.eSigComponentClient,
            {
              [classes.signatureComponent]: isMounted,
            },
          )}
          style={{
            border: '1px solid',
          }}
        />
      </Draggable>
    );

  return (
    <Draggable
      bounds="parent"
      defaultClassName={clsx(classes.root, {
        [classes.selectedContractButton]: !isClient && isComponentSelected,
      })}
      onStop={handleDragStop}
      position={{ x: component.xPosition, y: component.yPosition }}
      disabled={disableDragging}
      ref={draggableRef}
      onDrag={isResizing ? handleDisableDrag : handleDrag} // is user is resizing then disable drag
    >
      <div>
        <Resizable
          lockAspectRatio={lockAspectRatio}
          onResizeStart={() => setIsResizing(true)}
          size={size}
          onResizeStop={handleResizeStop}
          minWidth={CONTRACT_COMPONENT_MINIMUM_WIDTH}
          minHeight={CONTRACT_COMPONENT_DEFAULT_HEIGHT / pageScalerFactor}
          enable={{
            top: false,
            left: false,
            right: false,
            bottom: false,
            bottomLeft: false,
            topLeft: false,
            topRight: false,
            bottomRight: !isClient && isComponentSelected,
          }}
          bounds={pdfContainer}
          onResize={handleResize}
          // styles for the sqaure resize handler
          handleStyles={{
            bottomRight: {
              border: '1px solid',
              height: 8,
              width: 8,
              right: -4,
              bottom: -4,
              zIndex: zIndex.contractSignatureComponent,
              backgroundColor: white,
            },
          }}
        >
          <Button
            id={component.key}
            onClick={handleComponentClicked}
            ref={draggableButtonRef}
            classes={{
              startIcon: classes.startIcon,
            }}
            className={clsx(classes.eSigComponentButton, {
              [classes.signatureComponent]: isMounted && isClient,
              [classes.eSigComponentValue]: component.value,
              [classes.eSigComponentPlaceholder]:
                !component.value && !component.imageTextValue,
              [classes.signatureButtonCursor]:
                isClient && component.componentType.includes('owner'), // setting cursor to default for internal user components ( client view only )
              [classes.signatureButtonHover]:
                isClient && component.componentType.includes('owner'),
            })}
            style={{
              border:
                // For image-based components, the text content is saved in the "imageTextValue" key
                // For text-based components, the text content is saved in the "value" key
                // If there is content (either image or text), set border to 'none', otherwise, set it to '1px solid'
                isClient && (component.value || component.imageTextValue)
                  ? 'none'
                  : '1px solid ',
            }}
            startIcon={renderSignatureIcon}
          >
            {renderContractComponentValue}
          </Button>
        </Resizable>
        {renderRemoveIcon()}
      </div>
    </Draggable>
  );
});
