import React from 'react';
import Stripe from 'stripe';
import { Button, Link, makeStyles } from '@material-ui/core';
import BaseTypography from 'src/components/Text/BaseTypography';
import { PlansUtils } from 'src/utils';
import * as Colors from 'src/theme/colors';
import { usePlanInfo } from 'src/hooks/usePlanInfo';
import history from 'src/history';
import { PLAN_PAGE_CANCEL_START, SWITCH_TO_NEW_PLAN } from 'src/constants';
import { SALES_EMAIL } from 'src/constants/externalUrls';
import { useLazyGetOnboardingTaskStatusQuery } from 'src/services/api/improvedOnboardingApi';

const useStyles = makeStyles(() => ({
  contactLink: {
    color: Colors.BlackHeadings,
  },
  inlineButton: {
    verticalAlign: 'baseline',
  },
  cancelButton: {
    verticalAlign: 'baseline',
    minWidth: 0,
  },
}));

interface CurrentPlanProps {
  subscription?: Stripe.Subscription;
}

const CurrentPlan: React.FC<CurrentPlanProps> = ({ subscription }) => {
  const classes = useStyles();

  const [
    ,
    { data: onboardingTaskStatus, isLoading: isOnboardingTaskStatusLoading },
  ] = useLazyGetOnboardingTaskStatusQuery();

  // extract plan info from subscription
  const {
    planName,
    currentPeriodEndDate,
    cancelAtPeriodEnd,
    isLegacyEnterprisePlan,
    isLegacyPlan,
  } = usePlanInfo();

  /**
   * Return a component that contains a message for user to select a plan
   */
  const renderNoPlanMessage = () => (
    <BaseTypography
      data-testid="no-plan-message"
      style={{ color: Colors.GraySmall }}
    >
      Please select a plan to continue using your portal. Reach out to{' '}
      <Link
        href={`mailto:${SALES_EMAIL}?subject=Choose Plan`}
        TypographyClasses={{ colorPrimary: classes.contactLink }}
      >
        <BaseTypography fontType="13Medium" component="span">
          {SALES_EMAIL}
        </BaseTypography>
      </Link>{' '}
      for help choosing a plan.
    </BaseTypography>
  );

  // if there is no active subscription then render noPlanMessage
  if (!subscription) {
    return renderNoPlanMessage();
  }

  const mainSubscriptionItem = subscription.items?.data[0];

  // if no plan is found for subscription then render noPlanMessage
  if (!mainSubscriptionItem) {
    return renderNoPlanMessage();
  }

  if (
    PlansUtils.isTrialPlan(mainSubscriptionItem.price) &&
    !isOnboardingTaskStatusLoading
  ) {
    return (
      <BaseTypography style={{ color: Colors.GraySmall }} component="span">
        {PlansUtils.getTrialDaysRemainingText({
          subscription,
          onboardingTaskStatus,
        })}
      </BaseTypography>
    );
  }

  const openEnterpriseSupport = () => {
    const actionLink = `pageAction=contactSupport&body=${encodeURIComponent(
      SWITCH_TO_NEW_PLAN,
    )}`;
    history.replace({
      search: actionLink,
    });
  };

  const navigateToCancellationFlow = () =>
    history.push(PLAN_PAGE_CANCEL_START.path);

  const cancellationMessage = () => {
    return (
      <>
        Your plan is scheduled to be canceled on{' '}
        {currentPeriodEndDate && (
          <BaseTypography fontType="13Medium" component="span">
            {currentPeriodEndDate}
            {'. '}
          </BaseTypography>
        )}{' '}
        You will not be charged again.
      </>
    );
  };

  const constructPlanMessages = () => {
    if (
      isLegacyEnterprisePlan ||
      PlansUtils.isEnterprisePlan(mainSubscriptionItem.price)
    ) {
      // return 'You are currently on an enterprise plan. To make changes, please contact your account manager.';
      return (
        <span data-testid="enterprise-plan">
          You're on an enterprise plan with custom features and pricing. To
          discuss changes to your plan,{' '}
          <Button
            color="primary"
            onClick={openEnterpriseSupport}
            className={classes.inlineButton}
            component="span"
          >
            contact us
          </Button>
        </span>
      );
    }

    if (cancelAtPeriodEnd) {
      return cancellationMessage();
    }

    if (isLegacyPlan) {
      return (
        <span data-testid="old-portal-plan">
          You’re on an early access plan that is no longer available to new
          customers. To learn about our new pricing plans,{' '}
          <Button
            color="primary"
            onClick={openEnterpriseSupport}
            className={classes.inlineButton}
            component="span"
          >
            contact us
          </Button>
          . Note that if you{' '}
          <Button
            color="primary"
            onClick={navigateToCancellationFlow}
            className={classes.cancelButton}
            component="span"
          >
            cancel your subscription
          </Button>
          , you’ll lose access to early access pricing permanently.
        </span>
      );
    }

    return (
      <>
        You are currently on the{' '}
        <BaseTypography fontType="13Medium" component="span">
          {planName}
        </BaseTypography>
        , renewing on{' '}
        {currentPeriodEndDate && (
          <BaseTypography fontType="13Medium" component="span">
            {currentPeriodEndDate}
            {'. '}
          </BaseTypography>
        )}
      </>
    );
  };

  return (
    <BaseTypography style={{ color: Colors.GraySmall }}>
      {constructPlanMessages()}
    </BaseTypography>
  );
};

export default CurrentPlan;
