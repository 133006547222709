import {
  BillingSettings,
  ContractSettings,
  ExtensionsSettings,
  FileSettings,
  WebhookConfigSet,
} from 'src/constants/dataTypes';
import * as SettingTypes from 'src/store/settings/types';
import { AppThunkAction } from 'src/store/reduxTypes';
import UsersClient from 'src/clients/UsersClient';
import { SetPaymentInfo } from 'src/store/payments/actions';
import { alertSnackbar } from 'src/store/ui/actions';
import {
  WebhookForm,
  SettingsClient,
  UpdateWebhookConfigSetInput,
} from 'src/clients/SettingsClient';
import {
  CallApiWithNotification,
  ApiWithNotificationResponseType,
} from 'src/clients/ApiService';
import {
  CrmSettings,
  CrmTableSettings,
  ERROR_CONSTS,
  MessageSettings,
  PortalSettings,
  ModuleSettingsItem,
} from 'src/constants';
import {
  LOAD_PORTAL_SETTINGS,
  REMOVE_PORTAL_SETTINGS,
  LOAD_MESSAGE_SETTINGS,
  REMOVE_MESSAGE_SETTINGS,
} from 'src/store/settings/types';
import { BatchResult } from 'src/utils/ApiUtils';
import { ModuleFormValuesType } from 'src/components/Settings/AppSetupPage/SetupApp/appSetupTypes';
import { getUpdatedModuleSettings } from 'src/utils/ModuleSettingsUtils';
import { DEFAULT_APPS } from 'src/hooks/useApps';
import { applicationSettingsApi } from 'src/services/api/applicationsApi';

export const LoadSettingsStartAction = () => ({
  type: SettingTypes.LOAD_SETTINGS_START,
});

export const LoadSettingsErrorAction = (message: string) => ({
  type: SettingTypes.LOAD_SETTINGS_ERROR,
  error: message,
});

export const loadSettingsSuccess = (
  newSettings: SettingTypes.NewSettingsResponse,
) => ({
  type: SettingTypes.LOAD_SETTINGS_SUCCESS,
  data: newSettings,
});

export const loadPortalSettingsAction = (items: PortalSettings[]) => ({
  type: LOAD_PORTAL_SETTINGS,
  data: items,
});

export const removePortalSettingsAction = (items: PortalSettings[]) => ({
  type: REMOVE_PORTAL_SETTINGS,
  data: items,
});

export const setMessageSettingsAction = (items: MessageSettings[]) => ({
  type: LOAD_MESSAGE_SETTINGS,
  data: items,
});

export const removeMessageSettingsAction = (items: MessageSettings[]) => ({
  type: REMOVE_MESSAGE_SETTINGS,
  data: items,
});

export const LoadSettings =
  (userId?: string): AppThunkAction =>
  async (dispatch, getState) => {
    let loadSettingsUserID = userId || '';
    if (!userId) {
      const { user } = getState();
      loadSettingsUserID = user.id;
    }
    dispatch(LoadSettingsStartAction());

    try {
      const response = await UsersClient.GetSettings(loadSettingsUserID);
      const { paymentInfo, ...newSettings } = response;
      dispatch(SetPaymentInfo(paymentInfo));
      dispatch(loadSettingsSuccess(newSettings));
    } catch (err) {
      const error = err as { data?: { message: string } };
      const message = error.data
        ? error.data.message
        : 'Unexpected error loading settings';
      dispatch(LoadSettingsErrorAction(message));
    }
  };

export const ClearSettings: () => SettingTypes.ClearSettingsAction = () => ({
  type: SettingTypes.CLEAR_SETTINGS,
});

export const clearSettingChanges = () => ({
  type: SettingTypes.CLEAR_SETTINGS_CHANGES,
});

export const updateSettingsStartAction = () => ({
  type: SettingTypes.UPDATE_SETTINGS_REQUEST,
});

export const updateSettingsFailedAction = (error: string) => ({
  type: SettingTypes.UPDATE_SETTINGS_FAILURE,
  error,
});

export const updateSettingsSucceededAction = () => ({
  type: SettingTypes.UPDATE_SETTINGS_SUCCESS,
});

export const updateCrmSettings =
  (crmSettings: CrmSettings): AppThunkAction =>
  async (dispatch, getState) => {
    let changedParam = '';
    let updateErrorMessage = '';
    const existingCrmSettings = getState().settings.crmSettings;
    // to setup snackbars messages, we need to check which crm settings param
    // has changed. To do that, we compare the setting param value with it existing
    // value in the redux store, if they're equal then that param doesn't changed
    // otherwise it does.
    if (
      existingCrmSettings?.defaultAssigneeIDs !== crmSettings.defaultAssigneeIDs
    ) {
      changedParam = 'Default assignees';
      updateErrorMessage = ERROR_CONSTS.UpdatingDefaultAssigneesFailed;
    }

    if (
      existingCrmSettings?.defaultLeadUserID !== crmSettings.defaultLeadUserID
    ) {
      changedParam = 'Default lead';
      updateErrorMessage = ERROR_CONSTS.UpdatingDefaultLeadFailed;
    }

    function success(successResponse: PortalSettings) {
      return {
        type: SettingTypes.UPDATE_CRM_SETTINGS_SUCCESS,
        payload: successResponse.fields.crmSettings,
      };
    }

    function failure(error: string) {
      return { type: SettingTypes.UPDATE_CRM_SETTINGS_FAILURE, error };
    }

    dispatch(updateSettingsStartAction());

    const result = await CallApiWithNotification({
      executeFunction: SettingsClient.updateCrmSettings,
      params: crmSettings,
      successMessage: `${changedParam} changed`,
      errorMessage: `${updateErrorMessage}`,
      dispatch,
    });

    if (result.status) {
      dispatch(success(result.data));
    } else {
      dispatch(failure(result.data));
    }
  };

export const updateCrmTableSettings =
  (tableSettings: CrmTableSettings): AppThunkAction =>
  async (dispatch) => {
    function success(payload: any) {
      return {
        type: SettingTypes.UPDATE_CRM_TABLE_SETTINGS_SUCCESS,
        payload,
      };
    }

    function failure(error: string) {
      return { type: SettingTypes.UPDATE_CRM_TABLE_SETTINGS_FAILURE, error };
    }

    dispatch(updateSettingsStartAction());
    const result = await CallApiWithNotification({
      executeFunction: SettingsClient.updateCrmTableSettings,
      params: tableSettings,
      successMessage: '',
      errorMessage: 'There was an error updating the table settings',
      dispatch,
    });

    if (result.status) {
      dispatch(success(tableSettings));
    } else {
      dispatch(failure(result.data));
    }
  };

export function saveModuleSettingsSuccessAction(payload: ModuleSettingsItem[]) {
  return {
    type: SettingTypes.UPDATE_MODULE_SETTINGS_SUCCESS,
    payload,
  };
}
/**
 * Method to update the modules settings
 * @param moduleSettings updated list of the modules
 * @param disableSuccessAlert when true don't show the success alert. This is done to avoid multiple alerts when modules are updated with other settings simultaneously
 */
export const updateModuleSettings =
  (
    modules: ModuleSettingsItem[],
    opts?: {
      disableSuccessAlert?: boolean;
      appId?: string;
    },
  ): AppThunkAction =>
  async (dispatch, getState) => {
    let updatedModules = modules;
    const { moduleSettings: oldModules } = getState().settings;
    const { disableSuccessAlert } = opts ?? {};
    const hasOnlyExtensionsModules = updatedModules.every(
      (module) => module.type === 'local' || module.type === 'global',
    );

    // If some modules aren't extensions, that means some of the default apps
    // are present in modulesSettings and this is an  opportune moment to make
    // sure we don't save a portion of default modules. If it's missing at this
    // point, it gets added as disabled.
    const moduleSettingsMap = new Map(
      updatedModules.map((module) => [module.id, module]),
    );
    const missingDefaultApps = !updatedModules?.length
      ? []
      : DEFAULT_APPS.filter((app) => !moduleSettingsMap.has(app.id)).map(
          (app) => ({
            id: app.id,
            label: app.title,
            icon: app.iconName,
            type: app.type,
            disabled: hasOnlyExtensionsModules ? false : true,
          }),
        );
    updatedModules = [...missingDefaultApps, ...updatedModules];

    function failure(error: string) {
      return {
        type: SettingTypes.UPDATE_MODULE_SETTINGS_FAILURE,
        error,
        payload: oldModules,
      };
    }

    // optimistic update modules
    dispatch(saveModuleSettingsSuccessAction(updatedModules));

    dispatch(updateSettingsStartAction());

    const result = await CallApiWithNotification<PortalSettings>({
      executeFunction: SettingsClient.updateModuleSettings,
      params: updatedModules,
      successMessage: !disableSuccessAlert
        ? `App settings have been changed`
        : '',
      errorMessage: 'There was a problem updating the app settings',
      dispatch,
    });

    if (result.data?.fields?.moduleSettings) {
      dispatch(
        saveModuleSettingsSuccessAction(result.data.fields.moduleSettings),
      );
      dispatch(
        applicationSettingsApi.util.updateQueryData(
          'getInstalls',
          undefined,
          (draft) => {
            draft.moduleSettings = result.data.fields.moduleSettings || [];
          },
        ),
      );
    }

    if (!result.status) {
      dispatch(failure(result.errorMessage));
    }
  };

export const updateFileSettings =
  ({
    fileSettings,
    currentFileModule,
  }: {
    fileSettings: FileSettings & ModuleSettingsItem;
    currentFileModule: ModuleFormValuesType | null;
  }): AppThunkAction =>
  async (dispatch, getState) => {
    const { moduleSettings } = getState().settings;

    // check if we need to call update module request
    const areModuleValuesUpdated =
      fileSettings?.icon !== currentFileModule?.icon || // icon change
      fileSettings?.label !== currentFileModule?.label || // label change
      currentFileModule?.disabled; // check if the module is disabled

    // update the file module specific settings
    if (areModuleValuesUpdated && currentFileModule) {
      const updatedModuleSettings = getUpdatedModuleSettings({
        moduleSettings,
        currentModule: currentFileModule,
        updatedModule: fileSettings,
      });
      await dispatch(
        updateModuleSettings(updatedModuleSettings, {
          disableSuccessAlert: true,
        }),
      );
    }

    // file settings update
    dispatch(updateSettingsStartAction());

    const result = await CallApiWithNotification({
      executeFunction: SettingsClient.updateFileSettings,
      params: fileSettings,
      successMessage: `File settings have been changed`,
      errorMessage: 'There was a problem updating the file settings',
      dispatch,
    });

    function success(payload: any) {
      return {
        type: SettingTypes.UPDATE_FILE_SETTINGS_SUCCESS,
        payload,
      };
    }

    function failure(error: string) {
      return { type: SettingTypes.UPDATE_FILE_SETTINGS_FAILURE, error };
    }

    if (result.status) {
      dispatch(success(result.data.fields.fileSettings));
    } else {
      dispatch(failure(result.data));
    }
  };

export const saveMessageSettings =
  ({
    messageSettings,
    currentMessagesModule,
  }: {
    messageSettings: Prettify<MessageSettings & ModuleSettingsItem>;
    currentMessagesModule: ModuleFormValuesType | null;
  }): AppThunkAction =>
  async (dispatch, getState) => {
    const { moduleSettings } = getState().settings;

    // check if we need to call update module request
    const areModuleValuesUpdated =
      messageSettings?.icon !== currentMessagesModule?.icon || // icon change
      messageSettings?.label !== currentMessagesModule?.label || // label change
      currentMessagesModule?.disabled; // check if the module is disabled

    // update the messages specific module settings
    if (areModuleValuesUpdated && currentMessagesModule) {
      const updatedModuleSettings = getUpdatedModuleSettings({
        moduleSettings,
        currentModule: currentMessagesModule,
        updatedModule: messageSettings,
      });
      await dispatch(
        updateModuleSettings(updatedModuleSettings, {
          disableSuccessAlert: true,
        }),
      );
    }

    dispatch(updateSettingsStartAction());
    const isNew = !messageSettings.id;
    const successMessage = `Message settings have been ${
      isNew ? 'created' : 'updated'
    }`;
    const result = await CallApiWithNotification<
      BatchResult<MessageSettings> | string
    >({
      executeFunction: isNew
        ? SettingsClient.createMessageSettings
        : SettingsClient.updateMessageSettings,
      params: messageSettings,
      successMessage,
      errorMessage: 'Creating message settings has failed.',
      dispatch,
    });

    if (result.status) {
      const {
        data: { updatedItems, createdItems },
      } = result as { data: BatchResult<MessageSettings> };
      const updatedSettings = isNew ? createdItems : updatedItems;
      dispatch(
        loadSettingsSuccess({
          messageSettings: updatedSettings?.at(0),
        } as SettingTypes.NewSettingsResponse),
      );
    } else {
      dispatch(updateSettingsFailedAction(result.data as string));
    }
  };

export const updateBillingSettings =
  ({
    billingSettings,
    currentBillingModule,
  }: {
    billingSettings: BillingSettings & ModuleSettingsItem;
    currentBillingModule: ModuleFormValuesType | null;
  }): AppThunkAction =>
  async (dispatch, getState) => {
    const { moduleSettings } = getState().settings;

    // check if we need to call update module request
    const areModuleValuesUpdated =
      billingSettings?.icon !== currentBillingModule?.icon || // icon change
      billingSettings?.label !== currentBillingModule?.label || // label change
      currentBillingModule?.disabled; // check if the module is disabled

    // update the module settings
    if (areModuleValuesUpdated && currentBillingModule) {
      const updatedModuleSettings = getUpdatedModuleSettings({
        moduleSettings,
        currentModule: currentBillingModule,
        updatedModule: billingSettings,
      });

      await dispatch(
        updateModuleSettings(updatedModuleSettings, {
          disableSuccessAlert: true,
        }),
      );
    }

    function success(payload: any) {
      return {
        type: SettingTypes.UPDATE_BILLING_SETTINGS_SUCCESS,
        payload,
      };
    }

    function failure(error: string) {
      return { type: SettingTypes.UPDATE_BILLING_SETTINGS_FAILURE, error };
    }

    dispatch(updateSettingsStartAction());

    // TODO: implement a generic util function to check
    // which param has been changed.
    const result = await CallApiWithNotification({
      executeFunction: SettingsClient.updateBillingSettings,
      params: billingSettings,
      successMessage: `Billing settings have been changed`,
      errorMessage: 'There was a problem updating the billing settings',
      dispatch,
    });

    if (result.status) {
      dispatch(success(result.data.fields.billingSettings));
    } else {
      dispatch(failure(result.data));
    }
  };

export const updateSettingsApiResultAction =
  (result: ApiWithNotificationResponseType<PortalSettings>): AppThunkAction =>
  async (dispatch) => {
    // if update settings action is successful then updated portal settings entity
    // is returned and should dispatch updating portal settings action
    if (result.status) {
      dispatch(loadPortalSettingsAction([result.data]));
      dispatch(updateSettingsSucceededAction());
    } else {
      dispatch(updateSettingsFailedAction(result.errorMessage));
    }
  };

/**
 * Initiate saving extension settings to portal settings and dispatch
 * actions to save response
 * @param extensionSettings new extension settings to save
 * @returns
 */
export const updateExtensionSettingsAction =
  (extensionSettings: ExtensionsSettings): AppThunkAction =>
  async (dispatch) => {
    dispatch(updateSettingsStartAction());

    const result = await CallApiWithNotification<PortalSettings>({
      executeFunction: SettingsClient.updateExtensionSettings,
      params: extensionSettings,
      successMessage: '',
      errorMessage: 'There was a problem updating the app settings',
      dispatch,
    });

    dispatch(updateSettingsApiResultAction(result));
    dispatch(
      applicationSettingsApi.util.updateQueryData(
        'getInstalls',
        undefined,
        (draft) => {
          draft.extensionsSettings =
            result.data.fields.extensionsSettings || {};
        },
      ),
    );
  };

/**
 * Call api to disconnect app in portal settings. The resulting
 * portal settings are updated in redux store to show updated state
 * @param appName which app settings to disconnect
 */
export const disconnectAppAction =
  (appName: string): AppThunkAction =>
  async (dispatch) => {
    dispatch(updateSettingsStartAction());

    const result = await CallApiWithNotification<PortalSettings>({
      executeFunction: SettingsClient.disconnectApp,
      params: appName,
      successMessage: `App has been disconnected`,
      errorMessage: 'There was a problem disconnecting the app',
      dispatch,
    });

    // if disconnect app is successful then updated portal settings entity
    // is returned and should dispatch updating portal settings action
    dispatch(updateSettingsApiResultAction(result));
  };

export const createWebhookConfig =
  (webhookConfigData: WebhookForm): AppThunkAction =>
  async (dispatch) => {
    function success(payload: WebhookConfigSet) {
      return {
        type: SettingTypes.ADD_WEBHOOK_CONFIG_SET_SUCCESS,
        payload,
      };
    }

    function failure(error: any) {
      return { type: SettingTypes.ADD_WEBHOOK_CONFIG_SET_FAILURE, error };
    }

    const result = await CallApiWithNotification<WebhookConfigSet>({
      executeFunction: SettingsClient.addWebhookConfigSet,
      params: webhookConfigData,
      successMessage: `Webhook configuration has been added`,
      errorMessage: 'There was a problem adding the webhook configuration',
      dispatch,
    });

    if (result.status) {
      dispatch(success(result.data));
    } else {
      dispatch(failure(result.data));
    }

    return result;
  };

export const listWebhookConfigs =
  (): // webhookConfigData: WebhookForm,
  AppThunkAction =>
  async (dispatch) => {
    function success(payload: WebhookConfigSet[]) {
      return {
        type: SettingTypes.GET_WEBHOOK_CONFIG_SETS_SUCCESS,
        payload,
      };
    }
    const result = await SettingsClient.getWebhookConfigSets();
    if (result) {
      dispatch(success(result));
    }
  };

export const deleteWebhookConfigSet =
  (id: string): AppThunkAction =>
  async (dispatch) => {
    function success(deletedID: string) {
      return {
        type: SettingTypes.DELETE_WEBHOOK_CONFIG_SET_SUCCESS,
        payload: deletedID,
      };
    }
    try {
      await SettingsClient.deleteWebhookConfigSet(id);
      // result will be null after a successful delete
      dispatch(success(id));
    } catch (ex) {
      dispatch(
        alertSnackbar({
          errorMessage: `Webhook configuration not deleted.`,
        }),
      );
    }
  };

export const updateWebhookConfigSet =
  (input: UpdateWebhookConfigSetInput): AppThunkAction =>
  async (dispatch) => {
    function success(updatedConfigSet: WebhookConfigSet) {
      return {
        type: SettingTypes.UPDATE_WEBHOOK_CONFIG_SET_SUCCESS,
        payload: updatedConfigSet,
      };
    }

    const result = await CallApiWithNotification<WebhookConfigSet>({
      executeFunction: SettingsClient.updateWebhookConfigSet,
      params: input,
      successMessage: `Webhook configuration has been updated`,
      errorMessage: 'There was a problem updating the webhook configuration',
      dispatch,
    });

    if (result.status) {
      dispatch(success(result.data));
    }
    return result;
  };

export const updateContractSettings =
  ({
    contractSettings,
    currentFileModule,
  }: {
    contractSettings: ContractSettings & ModuleSettingsItem;
    currentFileModule: ModuleFormValuesType | null;
  }): AppThunkAction =>
  async (dispatch, getState) => {
    const { moduleSettings } = getState().settings;

    // check if we need to call update module request
    const areModuleValuesUpdated =
      contractSettings?.icon !== currentFileModule?.icon || // icon change
      contractSettings?.label !== currentFileModule?.label || // label change
      currentFileModule?.disabled; // check if the module is disabled

    // update the contract module specific settings
    if (areModuleValuesUpdated && currentFileModule) {
      const updatedModuleSettings = getUpdatedModuleSettings({
        moduleSettings,
        currentModule: currentFileModule,
        updatedModule: contractSettings,
      });
      await dispatch(
        updateModuleSettings(updatedModuleSettings, {
          disableSuccessAlert: true,
        }),
      );
    }

    dispatch(updateSettingsStartAction());

    const result = await CallApiWithNotification({
      executeFunction: SettingsClient.updateContractSettings,
      params: contractSettings,
      successMessage: `Contract settings have been changed`,
      errorMessage: 'There was a problem updating the contract settings',
      dispatch,
    });

    function success(payload: any) {
      return {
        type: SettingTypes.UPDATE_CONTRACT_SETTINGS_SUCCESS,
        payload,
      };
    }

    function failure(error: string) {
      return { type: SettingTypes.UPDATE_CONTRACT_SETTINGS_FAILURE, error };
    }

    if (result.status) {
      dispatch(success(result.data.fields.contractSettings));
    } else {
      dispatch(failure(result.data));
    }
  };
