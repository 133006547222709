import React from 'react';
import GhostContentAPI, {
  PostOrPage,
  GhostContentAPIOptions,
} from '@tryghost/content-api';

const GhostAPIOptions: GhostContentAPIOptions = {
  url: 'https://copilot-updates.ghost.io',
  key: '4bb48fa6164088d5e50245b650',
  version: 'v5.0',
};

export const useLatestRelease = (skip = false): PostOrPage | null => {
  const [latestReleasePost, setLatestReleasePost] =
    React.useState<PostOrPage | null>(null);
  const api = new GhostContentAPI(GhostAPIOptions);

  const getLatestReleasePost = () =>
    api.posts
      .browse({
        limit: 1, // get latest post - by default order is asc
      })
      .then((results) => {
        setLatestReleasePost(results[0]);
      })
      .catch((err) => {
        console.error(err);
      });

  React.useEffect(() => {
    getLatestReleasePost();
  }, []);

  if (skip) return null;

  return latestReleasePost;
};
