import { NotificationSettingsFormValues } from 'src/components/notifications-settings/types';
import { appAPI } from '.';
import { ApiMethods, ApiTags } from 'src/services/api/appApi';
import { notify } from 'src/clients/ApiService';

const notificationsSettingsApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    getNotificationsSettings: build.query<NotificationSettingsFormValues, void>(
      {
        query: () => ({
          path: '/notification-settings',
          method: ApiMethods.get,
          options: {},
        }),
        providesTags: [ApiTags.notifications_settings],
      },
    ),
    updateNotificationsSettings: build.mutation<
      NotificationSettingsFormValues,
      unknown
    >({
      query: (body) => ({
        path: '/notification-settings',
        method: ApiMethods.post,
        options: {
          body,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          notificationsSettingsApi.util.updateQueryData(
            'getNotificationsSettings',
            undefined,
            () => {
              // Empty function, comment's here so eslint doesn't throw an error.
            },
          ),
        );
        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Notification preferences saved.',
            dispatch,
          });
        } catch (error) {
          patchResult.undo();
          notify({
            status: 'error',
            errorMessage: 'The notification settings cannot be updated.',
            error,
            dispatch,
          });
        }
      },
      invalidatesTags: [ApiTags.notifications_settings], // refetch get notification settings data
    }),
  }),
});

export const {
  useGetNotificationsSettingsQuery,
  useUpdateNotificationsSettingsMutation,
} = notificationsSettingsApi;
