import React, { useCallback, useContext } from 'react';
import { InboxPageContext } from 'src/context/inboxPageContext';
import {
  DeleteAllNotificationsType,
  useDeleteAllNotificationsMutation,
  useUpdateAllNotificationsMutation,
  useUpdateNotificationMutation,
} from 'src/services/api/inboxApi';
import { Action, BaseMenu } from '../Dropdowns';
import {
  TrashIcon,
  TrayFullOutline as MarkUnreadIcon,
  TrayOutlineIcon as MarkReadIcon,
} from '../Icons';
import Button from '../Button';
import { useInboxNotification } from './useInboxNotification';
/**
 * This component is used to show the inbox sidebar actions:
 * Mark all as read, Mark all as unread, Delete all notifications
 */
export const NotificationsActionDropdown: React.FC<{
  totalUnreadNotificationsCount: number;
  totalReadNotificationsCount: number;
}> = ({ totalUnreadNotificationsCount, totalReadNotificationsCount }) => {
  const { selectedNotification, setSelectedNotification } =
    useContext(InboxPageContext);

  const [viewButtonAnchor, setViewButtonAnchor] =
    React.useState<HTMLElement | null>(null);
  const [updateNotification] = useUpdateNotificationMutation();
  const [updateAllNotifications] = useUpdateAllNotificationsMutation();
  const { deleteNotification } = useInboxNotification();
  const [deleteAllNotifications] = useDeleteAllNotificationsMutation();

  const handleDeleteNotification = () => {
    deleteNotification();
  };
  const handleDeleteAllNotifications = (type: DeleteAllNotificationsType) => {
    deleteAllNotifications({ type });
    setSelectedNotification(null);
  };
  // handles updating the selected notification read status
  const handleUpdateSelectedNotificationStatus = (isRead: boolean) => {
    if (selectedNotification) {
      updateNotification({
        id: selectedNotification?.id || '',
        isRead,
      });
      setSelectedNotification({ ...selectedNotification, isRead });
    }
  };
  const handleUpdateAllNotifications = (isRead: boolean) => {
    updateAllNotifications({ isRead });
    if (selectedNotification) {
      setSelectedNotification({ ...selectedNotification, isRead });
    }
  };

  /**
   * This function setup the view button actions based on the scenarios
   * described in the figma design.
   * @link https://www.figma.com/file/QZOvjHYQLyIWWMuPXYqTc2/Main?type=design&node-id=40180-38418&mode=design&t=MDjRRazd6ONBRAJQ-0
   */
  const getViewDropdownActions = useCallback(() => {
    let actions: Array<Action> = [];
    // if no notification is selected and there are no notifications
    // actions menu should only show 'Delete all notifications'
    if (!selectedNotification) {
      if (totalUnreadNotificationsCount === 0) {
        actions = [
          {
            name: 'Delete all notifications',
            onClick: () =>
              handleDeleteAllNotifications(DeleteAllNotificationsType.ALL),
            isDelete: true,
            icon: <TrashIcon style={{ fontSize: 12 }} />,
          },
        ];

        return actions;
      }

      actions = [
        {
          name: 'Mark all notifications read',
          onClick: () => handleUpdateAllNotifications(true),
          icon: <MarkReadIcon style={{ fontSize: 12 }} />,
          hasDivider: true,
        },
        // if there are read notifications then surface 'Delete all read notifications'
        ...(totalReadNotificationsCount > 0
          ? [
              {
                name: 'Delete all read notifications',
                onClick: () =>
                  handleDeleteAllNotifications(DeleteAllNotificationsType.READ),
                isDelete: true,
                icon: <TrashIcon style={{ fontSize: 12 }} />,
              },
            ]
          : []),
        {
          name: 'Delete all notifications',
          onClick: () =>
            handleDeleteAllNotifications(DeleteAllNotificationsType.ALL),
          isDelete: true,
          icon: <TrashIcon style={{ fontSize: 12 }} />,
        },
      ];

      return actions;
    }

    // when selected notification is read and there is no unread notifications
    if (selectedNotification.isRead && totalUnreadNotificationsCount === 0) {
      actions = [
        {
          name: 'Mark unread',
          onClick: () => handleUpdateSelectedNotificationStatus(false),
          icon: <MarkUnreadIcon style={{ fontSize: 12 }} />,
          hasDivider: true,
        },
        {
          name: 'Delete notification',
          onClick: handleDeleteNotification,
          isDelete: true,
          icon: <TrashIcon style={{ fontSize: 12 }} />,
        },
        {
          name: 'Delete all notifications',
          onClick: () =>
            handleDeleteAllNotifications(DeleteAllNotificationsType.ALL),
          isDelete: true,
          icon: <TrashIcon style={{ fontSize: 12 }} />,
        },
      ];
      return actions;
    }

    // if selected notification is unread and all notifications are unread
    if (
      !selectedNotification.isRead &&
      totalUnreadNotificationsCount > 0 &&
      totalReadNotificationsCount === 0
    ) {
      actions = [
        {
          name: 'Mark read',
          onClick: () => handleUpdateSelectedNotificationStatus(true),
          icon: <MarkReadIcon style={{ fontSize: 12 }} />,
          hasDivider: true,
        },
        {
          name: 'Mark all notifications read',
          onClick: () => handleUpdateAllNotifications(true),
          icon: <MarkReadIcon style={{ fontSize: 12 }} />,
          hasDivider: true,
        },
        {
          name: 'Delete notification',
          onClick: handleDeleteNotification,
          isDelete: true,
          icon: <TrashIcon style={{ fontSize: 12 }} />,
        },
        {
          name: 'Delete all notifications',
          onClick: () =>
            handleDeleteAllNotifications(DeleteAllNotificationsType.ALL),
          isDelete: true,
          icon: <TrashIcon style={{ fontSize: 12 }} />,
        },
      ];
    }

    // when there are both unread and read notifications
    if (totalUnreadNotificationsCount > 0 && totalReadNotificationsCount > 0) {
      actions = selectedNotification.isRead
        ? [
            {
              name: 'Mark unread',
              onClick: () => handleUpdateSelectedNotificationStatus(false),
              icon: <MarkUnreadIcon style={{ fontSize: 12 }} />,
              hasDivider: true,
            },
            {
              name: 'Mark all notifications read',
              onClick: () => handleUpdateAllNotifications(true),
              icon: <MarkReadIcon style={{ fontSize: 12 }} />,
              hasDivider: true,
            },
            {
              name: 'Delete notification',
              onClick: handleDeleteNotification,
              isDelete: true,
              icon: <TrashIcon style={{ fontSize: 12 }} />,
            },
            {
              name: 'Delete all read notifications',
              onClick: () =>
                handleDeleteAllNotifications(DeleteAllNotificationsType.READ),
              isDelete: true,
              icon: <TrashIcon style={{ fontSize: 12 }} />,
            },
            {
              name: 'Delete all notifications',
              onClick: () =>
                handleDeleteAllNotifications(DeleteAllNotificationsType.ALL),
              isDelete: true,
              icon: <TrashIcon style={{ fontSize: 12 }} />,
            },
          ]
        : [
            {
              name: 'Mark read',
              onClick: () => handleUpdateSelectedNotificationStatus(true),
              icon: <MarkReadIcon style={{ fontSize: 12 }} />,
              hasDivider: true,
            },
            {
              name: 'Mark all notifications read',
              onClick: () => handleUpdateAllNotifications(true),
              icon: <MarkReadIcon style={{ fontSize: 12 }} />,
              hasDivider: true,
            },
            {
              name: 'Delete notification',
              onClick: handleDeleteNotification,
              isDelete: true,
              icon: <TrashIcon style={{ fontSize: 12 }} />,
            },
            {
              name: 'Delete all read notifications',
              onClick: () =>
                handleDeleteAllNotifications(DeleteAllNotificationsType.READ),
              isDelete: true,
              icon: <TrashIcon style={{ fontSize: 12 }} />,
            },
            {
              name: 'Delete all notifications',
              onClick: () =>
                handleDeleteAllNotifications(DeleteAllNotificationsType.ALL),
              isDelete: true,
              icon: <TrashIcon style={{ fontSize: 12 }} />,
            },
          ];
    }
    return actions;
  }, [
    totalUnreadNotificationsCount,
    totalReadNotificationsCount,
    selectedNotification,
  ]);

  return (
    <>
      <Button
        onClick={(e) => {
          setViewButtonAnchor(e.currentTarget);
        }}
        variant="contained"
        color="secondary"
        htmlId="view-notifications-actions-button"
        startIcon={<MarkReadIcon style={{ fontSize: 12 }} />}
      >
        Actions
      </Button>
      <BaseMenu
        key="view-notifications-actions-menu"
        menuProps={{
          open: Boolean(viewButtonAnchor),
          anchorEl: viewButtonAnchor,
          onClose: () => {
            setViewButtonAnchor(null);
          },
          getContentAnchorEl: null,
          keepMounted: true,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }}
        actions={getViewDropdownActions()}
      />
    </>
  );
};
