import React from 'react';
import {
  createStyles,
  makeStyles,
  Button,
  SvgIconProps,
} from '@material-ui/core';
import { useDrag, DragSourceMonitor } from 'react-dnd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getEmptyImage } from 'react-dnd-html5-backend';
import clsx from 'classnames';
import { RootState } from 'src/store';
import { ReorderIcon } from 'src/components/Icons';
import * as Colors from 'src/theme/colors';
import { ComponentCoordinatesType } from 'src/store/signaturePage/types';
import BaseTypography from 'src/components/Text/BaseTypography';
import { PortalConfigContext } from 'src/context';
import ColorUtils from 'src/utils/ColorUtils';
import { alertSnackbar } from 'src/store/ui/actions';
import {
  CONTRACT_COMPONENT_DEFAULT_HEIGHT,
  SIGNATURE_COMPONENT_DEFAULT_WIDTH,
} from 'src/constants';
import { signaturePageContentWidth } from 'src/components/Signature/SignaturePageContent';

export const DRAG_ITEM_TYPE = 'esignComponent';

const useStyles = makeStyles((theme) =>
  createStyles({
    signatureButtonIcon: {
      marginRight: theme.spacing(1),
    },
    // hovered over internal user signature button ( ESignature v2)
    internalButtonHover: {
      '&:hover': {
        borderColor: Colors.BlackHeadings,
        color: Colors.BlackHeadings,
        backgroundColor: ColorUtils.GetColorDarknessShades(Colors.BlackHeadings)
          .light,
      },
    },
    // hovered over client  signature button ( ESignature v2)
    clientButtonHover: {
      '&:hover': {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        backgroundColor: ColorUtils.GetColorDarknessShades(
          theme.palette.primary.main,
        ).light,
      },
    },
    reorderIcon: {
      color: Colors.NonHoverBorder,
    },
    draggingButton: {
      opacity: 0.4,
    },
    contractRoot: {
      height: '22px',
      padding: 0,
      justifyContent: 'start',
      minWidth: '136px',
      boxShadow: 'none',
      color: Colors.GraySmall,
      borderColor: 'transparent',
      transition: 'all 0.2s ease-in-out',
      paddingRight: theme.spacing(2),
      // hiding ReOrder icon by default
      '& .MuiButton-startIcon': {
        opacity: 0,
        width: 0,
        transition: 'all .5s ease-in-out',
        marginRight: '4px',
        color: Colors.BlackHeadings,
      },
      '& svg': {
        width: 'auto',
        height: '14px',
      },
      // showing ReOrder icon when signature button is hovered
      '&:hover .MuiButton-startIcon': {
        opacity: 1,
        width: '8px',
        marginLeft: '4px',
        marginRight: '4px',
      },
      '&:hover': {
        paddingLeft: '4px',
      },
    },
    contractButtonIcon: {
      marginRight: '4px',
      width: 'auto',
      height: '14px',
    },
    contractDragIcon: {
      '& svg': {
        height: '8px',
        width: '8px',
        marginLeft: 0,
      },
    },
  }),
);

interface SignatureSidebarButtonProps {
  label: string;
  onDrop: (coordinates: ComponentCoordinatesType) => void;
  // Adding two props to make it compatible with ESignature v2
  buttonIcon?: React.FC<SvgIconProps>; // icon to be displayed on the button( signature, date, initials, text)
  isClientSignatureButton?: boolean; // flag to indicate if the button is for client signature or internal user
  className?: string;
  disabled?: boolean;
}

export const SignatureSidebarButton: React.FC<SignatureSidebarButtonProps> = ({
  label,
  onDrop,
  buttonIcon: ButtonIcon,
  isClientSignatureButton = false,
  className = '',
  disabled = false,
  ...rest
}) => {
  const dispatch = useDispatch();
  const portalConfig = React.useContext(PortalConfigContext);
  const portalThemePrimaryColor = portalConfig.theme.palette.primary.main;
  const classes = useStyles();
  const {
    completeRenderPageImages,
    pageImageKeysField,
    isEsignPopoverOpen,
    pageImages,
  } = useSelector(
    (state: RootState) => ({
      completeRenderPageImages: state.signaturePage.completeRenderPageImages,
      pageImageKeysField: state.signaturePage.pageImageKeysField,
      isEsignPopoverOpen: state.signaturePage.isEsignPopoverOpen,
      pageImages: state.signaturePage.pageImages,
    }),
    shallowEqual,
  );

  const handleComponentDrop = (coordinates: ComponentCoordinatesType) => {
    const placement = { ...coordinates };
    const minimumXPositionRequiredToBeInsideDocument =
      signaturePageContentWidth - SIGNATURE_COMPONENT_DEFAULT_WIDTH;

    const lastPage = pageImages.find((p) => p.pageNumber === pageImages.length);

    // Calculate the maximum y position for a signature component
    // ensuring that the bottom of the signature component is within the page.
    const maxYPositionRequiredToBeInsideDocument = lastPage?.element
      ? lastPage.element.y +
        lastPage.element.offsetHeight -
        CONTRACT_COMPONENT_DEFAULT_HEIGHT
      : 0;

    // CASE-I: Item being placed over left of the document beyond constraints;
    if (coordinates.xPosition < 0) placement.xPosition = 0;
    // CASE-II: Item being placed over top of the document beyond constraints;
    if (coordinates.yPosition < 0) placement.yPosition = 0;
    // CASE-III: Item being placed over right of the document beyond constraints;
    // In order for a component to be completely inside the document from right end,
    // xPosition of the component needs to be less than (signaturePageContentWidth - SIGNATURE_COMPONENT_DEFAULT_WIDTH).
    // If it exceeds more than that, we gotta place it
    if (
      signaturePageContentWidth - placement.xPosition <
      SIGNATURE_COMPONENT_DEFAULT_WIDTH
    ) {
      placement.xPosition = minimumXPositionRequiredToBeInsideDocument;
    }
    // Case-IV: Item being placed below the document beyoud constraints;
    // If it exceeds more than that, we gotta place it
    if (
      maxYPositionRequiredToBeInsideDocument > 0 &&
      placement.yPosition > maxYPositionRequiredToBeInsideDocument
    ) {
      placement.yPosition = maxYPositionRequiredToBeInsideDocument;
    }

    onDrop(placement);
  };

  const [{ isDragging }, drag, preview] = useDrag({
    item: { name: label, type: DRAG_ITEM_TYPE, icon: ButtonIcon },
    end: (item: { name: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        const { x, y } = dropResult.position;
        const roundedCoordinates = {
          xPosition: Math.round(x),
          yPosition: Math.round(y),
        };
        handleComponentDrop({ ...roundedCoordinates });
      }
      // if we dont have drop result that means signature component is dropped on a non droppable area
      if (!dropResult) {
        dispatch(
          alertSnackbar({
            errorMessage: `${label} fields can only be placed on pages.`,
          }),
        );
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // hiding default browser drag preview
  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const isButtonDisabled =
    disabled ||
    !completeRenderPageImages ||
    pageImageKeysField.length < 1 ||
    isEsignPopoverOpen;

  const buttonClasses = clsx(classes.contractRoot, {
    [classes.draggingButton]: isDragging,
    [classes.clientButtonHover]: isClientSignatureButton,
    [classes.internalButtonHover]: !isClientSignatureButton,
    [className]: Boolean(className),
  });

  return (
    <Button
      ref={drag}
      className={buttonClasses}
      classes={{
        startIcon: classes.contractDragIcon,
      }}
      color="secondary"
      variant="contained"
      disabled={isButtonDisabled}
      startIcon={
        <ReorderIcon
          htmlColor={
            isClientSignatureButton
              ? portalThemePrimaryColor
              : Colors.BlackHeadings
          }
        />
      }
      {...rest}
      data-testid="signature-button"
    >
      {ButtonIcon ? (
        <ButtonIcon
          className={clsx(
            [classes.signatureButtonIcon],
            classes.contractButtonIcon,
          )}
        />
      ) : null}
      <BaseTypography fontType={'12Medium'}>{label}</BaseTypography>
    </Button>
  );
};
