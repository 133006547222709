import React from 'react';

import { useDispatch } from 'react-redux';
import { FiLink as LinkIcon } from 'react-icons/fi';
import { Box } from '@material-ui/core';
import { DropEvent } from 'react-dropzone-latest';
import { FileDropzone } from 'src/components/FileDropzone';
import {
  AviIcon,
  DocIcon,
  GenericIcon,
  GenericSetIcon,
  IsoIcon,
  JpgIcon,
  MovIcon,
  Mp3Icon,
  Mp4Icon,
  PdfIcon,
  PngIcon,
  SVGIcon,
  TxtIcon,
  ZipIcon,
  GifIcon,
  CsvIcon,
  XlxIcon,
} from 'src/components/Icons';
import BaseTypography from 'src/components/Text/BaseTypography';
import { FILE_TYPE } from 'src/constants/fileConsts';
import { useProgressUpload } from 'src/hooks/useProgressUpload';
import {
  saveUploadedFiles,
  updateFileDragging,
  updateHoveredRowNode,
} from 'src/store/files/actions';
import { FileCellRendererProps } from 'src/store/files/types';
import S3Utils from 'src/utils/S3Utils';
import { FileIconRenderer } from 'src/components/AgGrid/Renderer/FileIconRenderer';

const fileExtensionToIcon: {
  [key: string]: React.ComponentType<{ style: React.CSSProperties }>;
} = {
  avi: AviIcon,
  doc: DocIcon,
  docx: DocIcon,
  generic: GenericIcon,
  genericSet: GenericSetIcon,
  iso: IsoIcon,
  jpg: JpgIcon,
  jpeg: JpgIcon,
  mov: MovIcon,
  mp3: Mp3Icon,
  pdf: PdfIcon,
  png: PngIcon,
  txt: TxtIcon,
  zip: ZipIcon,
  svg: SVGIcon,
  link: LinkIcon,
  mp4: Mp4Icon,
  gif: GifIcon,
  csv: CsvIcon,
  xlx: XlxIcon,
  xlsx: XlxIcon,
  xls: XlxIcon,
};

interface IconWrapperProps {
  extension: string;
  size?: number;
}

export const IconWrapper = (props: IconWrapperProps) => {
  const { extension, size = 21 } = props;
  const Icon = fileExtensionToIcon[extension] || fileExtensionToIcon.generic;
  const iconProps = {
    style: {
      fontSize: size,
      fill: 'none',
    },
  };
  return <Icon {...iconProps} />;
};

export const FileCellRendererV2: React.FC<FileCellRendererProps> = ({
  data,
  value,
  channelId = '',
  selectedFilePath = '',
  isClient,
  fileSettings,
}) => {
  const isFolderUpdateDisabled =
    isClient && Boolean(fileSettings?.clientFolderUpdateDisabled);
  const dispatch = useDispatch();

  const { setUploadProgress, startUploadFiles, exitUploadFiles } =
    useProgressUpload();

  const { node: rowNode } = data;
  const fileType =
    data.fileKey === '__placeholder__' ? FILE_TYPE.FOLDER : FILE_TYPE.FILE;

  const handleFilesDrop = async (
    acceptedFiles: Array<any>,
    _: any,
    dropEvt: DropEvent,
  ) => {
    if (fileType !== FILE_TYPE.FOLDER) return;
    dispatch(updateFileDragging(false));
    dropEvt.stopPropagation();

    const targetPath = data.path
      ? data.path.join('/')
      : selectedFilePath?.split('/').join('/');
    const filesPaths = await S3Utils.uploadFilesToS3({
      acceptedFiles,
      targetPath,
      startUploadFiles,
      exitUploadFiles,
      setUploadProgress,
      ignoreFolders: isFolderUpdateDisabled,
    });
    console.info('saving filesPaths to channel', filesPaths, channelId);
    if (channelId) {
      dispatch(saveUploadedFiles(filesPaths, channelId));
    }
  };

  const handleFilesDragEnter = () => {
    if (fileType !== FILE_TYPE.FOLDER) return;
    dispatch(updateHoveredRowNode(rowNode));
  };

  const handleFileDragLeave = () => {
    if (fileType !== FILE_TYPE.FOLDER) return;
    dispatch(updateHoveredRowNode(null));
  };

  return (
    <FileDropzone
      noClick
      onDragEnter={handleFilesDragEnter}
      onDragLeave={handleFileDragLeave}
      onDrop={handleFilesDrop}
      disabled={isClient && Boolean(fileSettings?.clientFileAddDisabled)}
    >
      <Box
        ml="25px"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        zIndex={1}
        width="100%"
        height="100%"
      >
        <FileIconRenderer file={data} />

        <BaseTypography style={{ marginLeft: 10 }}>{value.name}</BaseTypography>
      </Box>
    </FileDropzone>
  );
};

export default FileCellRendererV2;
