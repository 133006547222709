import React from 'react';
import { Button, SvgIconProps } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import clsx from 'clsx';
import BaseTypography from 'src/components/Text/BaseTypography';
import * as Colors from 'src/theme/colors';
import { ReorderIcon } from 'src/components/Icons';
import { PortalConfigContext } from 'src/context';

type DragPreviewItemProps = {
  icon?: ((props: SvgIconProps<'svg', unknown>) => JSX.Element) | (() => null);
  name: string;
  canDrop: boolean | undefined;
  classes: ClassNameMap<string>;
};

export const DragPreviewItem = ({
  icon: SignatureIcon,
  canDrop,
  name,
  classes,
}: DragPreviewItemProps) => {
  const portalConfig = React.useContext(PortalConfigContext);
  const portalThemePrimaryColor = portalConfig.theme.palette.primary.main;
  return (
    <Button
      className={clsx(
        classes.signatureButton,
        classes.signatureButtonTransform,
        classes.contractButton,
        {
          [classes.invalidSignatureButton]: !canDrop,
        },
      )}
      color="secondary"
      variant="contained"
      startIcon={
        <ReorderIcon
          data-testid="reorder-icon"
          htmlColor={canDrop ? portalThemePrimaryColor : Colors.red}
          style={{ fontSize: 12 }}
        />
      }
    >
      {SignatureIcon && (
        <SignatureIcon
          style={{
            marginRight: 4,
            height: 14,
            width: 'auto',
          }}
        />
      )}
      <BaseTypography fontType={'12Medium'}>{name}</BaseTypography>
    </Button>
  );
};
