import {
  Automation,
  AutomationAction,
  AutomationRun,
  AutomationStep,
  AutomationTemplate,
  AutomationTrigger,
  TriggerCondition,
} from 'src/types/automations';
import { ApiMethods, ApiTags, appAPI } from '.';
import { notify } from 'src/clients/ApiService';
import { AppAPIName } from 'src/constants';
import { API } from 'src/utils/AmplifyApiUtils';

interface CreateAutomationRequest {
  name: string;
  status: 'published' | 'unpublished';
  trigger: AutomationTemplate['trigger'];
  steps: AutomationTemplate['steps'];
  triggerCondition: TriggerCondition | null;
  fromTemplate?: boolean;
}

export interface AutomationUpdateRequest {
  steps: AutomationStep[];
  triggerCondition?: TriggerCondition | null;
}

export interface GetAutomationsResponse {
  data: Array<Automation>;
  nextToken?: string;
}

export interface GetAutomationRunsResponse {
  data: AutomationRun[];
  nextToken?: string;
}

export type GetAutomationDetailResponse = AutomationRun;
export type UpdateAutomationResponse = Automation;

async function loadAllAutomationRuns(
  previousItems: AutomationRun[],
  nextToken: string | undefined,
  automationId: string | undefined,
): Promise<AutomationRun[]> {
  const endpointUrl = automationId
    ? `/v1/automations/runs?automationId=${automationId}`
    : `/v1/automations/runs`;
  const response: GetAutomationRunsResponse = await API.get(
    AppAPIName,
    endpointUrl,
    {
      queryStringParameters: {
        limit: 100,
        nextToken,
      },
    },
  );

  const responseItems = response?.data ?? [];
  const updatedItems = [...previousItems, ...responseItems];

  if (!response.nextToken) {
    return updatedItems.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  }

  return loadAllAutomationRuns(updatedItems, response.nextToken, automationId);
}

export const automationsApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    createAutomation: build.mutation<{}, CreateAutomationRequest>({
      query: (createRequest: CreateAutomationRequest) => ({
        path: `/v1/automations`,
        method: ApiMethods.post,
        options: {
          body: {
            ...createRequest,
          },
        },
      }),
      invalidatesTags: [ApiTags.automations],
    }),
    getAutomations: build.query<GetAutomationsResponse, void>({
      query: () => ({
        path: `/v1/automations?limit=500`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.automations],
    }),
    getAutomationsTriggers: build.query<AutomationTrigger[], void>({
      query: () => ({
        path: `/v1/automations/triggers`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.automationTriggers],
    }),
    getAutomationsActions: build.query<AutomationAction[], void>({
      query: () => ({
        path: `/v1/automations/actions`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.automationActions],
    }),
    getAutomationTemplates: build.query<AutomationTemplate[], void>({
      query: () => ({
        path: `/v1/automations/templates`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.automationTemplates],
    }),
    getAutomationById: build.query<Automation, { id: string }>({
      query: ({ id }) => ({
        path: `/v1/automations/${id}`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.automations],
    }),
    deleteAutomation: build.mutation<Automation, { id: string }>({
      query: ({ id }) => ({
        path: `/v1/automations/${id}`,
        method: ApiMethods.del,
        options: {
          queryStringParameters: {},
        },
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          automationsApi.util.updateQueryData(
            'getAutomations',
            undefined,
            (draft) =>
              (draft = {
                data: draft.data.filter((a) => a.id !== id),
              }),
          ),
        );
        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Automation has been deleted.',
            dispatch,
          });
        } catch (error) {
          patch.undo();
          notify({
            status: 'error',
            errorMessage: 'The automation could not be deleted.',
            error,
            dispatch,
          });
        }
      },
    }),
    changeStatus: build.mutation<
      Automation,
      { id: string; automationStatus: 'published' | 'unpublished' }
    >({
      query: ({ id, automationStatus }) => ({
        path: `/v1/automations/${id}`,
        method: ApiMethods.patch,
        options: {
          body: {
            status: automationStatus,
          },
        },
      }),
      async onQueryStarted(
        { id, automationStatus },
        { dispatch, queryFulfilled },
      ) {
        const patch = dispatch(
          automationsApi.util.updateQueryData(
            'getAutomations',
            undefined,
            (draft) =>
              (draft = {
                data: draft.data.map((a) => {
                  return {
                    ...a,
                    status: a.id === id ? automationStatus : a.status,
                  };
                }),
              }),
          ),
        );
        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: `Automation has been ${
              automationStatus === 'published' ? 'resumed' : 'paused'
            }.`,
            dispatch,
          });
        } catch (error) {
          patch.undo();
          notify({
            status: 'error',
            errorMessage: `The automation could not be ${
              automationStatus === 'published' ? 'resumed' : 'paused'
            }.`,
            error,
            dispatch,
          });
        }
      },
    }),
    updateAutomation: build.mutation<
      UpdateAutomationResponse,
      {
        id: string;
        updateRequest: AutomationUpdateRequest;
      }
    >({
      query: ({ id, updateRequest }) => ({
        path: `/v1/automations/${id}`,
        method: ApiMethods.patch,
        options: {
          body: updateRequest,
        },
      }),
      invalidatesTags: [ApiTags.automations],
    }),
    getAutomationRun: build.query<GetAutomationDetailResponse, { id: string }>({
      query: ({ id }) => ({
        path: `/v1/automations/runs/${id}`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.automations],
    }),
    getAllWorkspaceAutomationRuns: build.query<AutomationRun[], void>({
      queryFn: async () => {
        const response = await loadAllAutomationRuns([], undefined, undefined);
        return { data: response };
      },
      providesTags: [ApiTags.automations],
    }),
    getAutomationRuns: build.query<AutomationRun[], { id: string }>({
      queryFn: async ({ id }) => {
        const response = await loadAllAutomationRuns([], undefined, id);
        return { data: response };
      },
      providesTags: [ApiTags.automations],
    }),
    renameAutomation: build.mutation<Automation, { id: string; name: string }>({
      query: ({ id, name }) => ({
        path: `/v1/automations/${id}`,
        method: ApiMethods.patch,
        options: {
          body: {
            name,
          },
        },
      }),
      async onQueryStarted({ id, name }, { dispatch, queryFulfilled }) {
        const patch = dispatch(
          automationsApi.util.updateQueryData(
            'getAutomations',
            undefined,
            (draft) =>
              (draft = {
                data: draft.data.map((a) => {
                  return {
                    ...a,
                    name: a.id === id ? name : a.name,
                  };
                }),
              }),
          ),
        );
        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: `Automation has been renamed.`,
            dispatch,
          });
        } catch (error) {
          patch.undo();
          notify({
            status: 'error',
            errorMessage: `The automation could not be renamed.`,
            error,
            dispatch,
          });
        }
      },
    }),
  }),
});

export const {
  useCreateAutomationMutation,
  useGetAutomationsQuery,
  useDeleteAutomationMutation,
  useChangeStatusMutation,
  useGetAllWorkspaceAutomationRunsQuery,
  useGetAutomationRunQuery,
  useGetAutomationRunsQuery,
  useGetAutomationByIdQuery,
  useUpdateAutomationMutation,
  useGetAutomationTemplatesQuery,
  useGetAutomationsTriggersQuery,
  useRenameAutomationMutation,
  useGetAutomationsActionsQuery,
} = automationsApi;
