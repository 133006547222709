import React, { FC } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import { useRouteAccess } from 'src/routes/routeAccess';
import BaseTypography, {
  typography12MediumStyle,
} from 'src/components/Text/BaseTypography';
import * as Colors from 'src/theme/colors';
import { PLAN_PAGE } from 'src/constants';
import { useSettingsPageRedirection } from 'src/components/Settings/useSettingsPageRedirection';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      backgroundColor: Colors.white,
      textDecoration: 'underline',
      ...typography12MediumStyle,

      '&:hover': {
        backgroundColor: Colors.white,
        textDecoration: 'underline',
      },
    },
  }),
);

export const ExtensionTooltipTitle: FC = () => {
  const classes = useStyles();

  const routeAccess = useRouteAccess();

  const { goToSettings: goToPlansSettings } = useSettingsPageRedirection(
    true,
    PLAN_PAGE.path,
  );

  const onUpgradePlan = () => goToPlansSettings();

  return (
    <BaseTypography fontType="12Medium" style={{ color: Colors.BlackHeadings }}>
      {routeAccess.allowSettingsPlanPage ? (
        <>
          <span>To use apps </span>
          <Button
            variant="text"
            onClick={onUpgradePlan}
            className={classes.button}
          >
            upgrade your plan
          </Button>
        </>
      ) : (
        <span>To use apps, ask your account admin to upgrade your plan</span>
      )}
    </BaseTypography>
  );
};
