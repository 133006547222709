import { CoreIcon } from 'src/components/Icons';
import { AppPresets } from 'src/constants/moduleManagementConsts';

export const GENERAL_SETUP_APPS = [
  {
    name: 'Embeds and Links',
    description:
      "Don't see your app? Select this option to connect other products you already use as an embed or link.",
    Icon: CoreIcon,
    preset: AppPresets.PartnerApp,
  },
  {
    name: 'Custom Apps',
    description:
      'Custom Apps are embedded into Copilot via an iFrame and receive auth information about the current user.',
    Icon: CoreIcon,
    preset: AppPresets.CustomApp,
  },
];

export const APP_TOOLTIP_TITLE =
  'Apps are tailor-made for Copilot platform and typically come with rich functionality including notifications.';
export const EMBED_TOOLTIP_TITLE =
  'Copilot supports embedding any external application that doesn’t block embedding.';
export const INTERNAL_APP_TOOLTIP_TITLE =
  'Internal-facing apps are only visible to your internal team.';
export const CLIENT_APP_TOOLTIP_TITLE =
  'Client-facing apps are visible to clients as well as your internal team.';
