import React, { useMemo } from 'react';
import {
  Button,
  FormControl,
  Box,
  makeStyles,
  createStyles,
  Theme,
  IconButton,
} from '@material-ui/core';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { FormBodyAPI } from 'src/constants/fieldsTypes';
import {
  MultiCategorySelect,
  MultiCategorySelectProps,
} from 'src/components/Select/MultiCategorySelect';
import { RootState } from 'src/store';
import { PortalConfigContext } from 'src/context';
import { BlackHeadings, GraySmall, HoverBackground } from 'src/theme/colors';
import BaseTypography from 'src/components/Text/BaseTypography';
import { useCustomDomains } from 'src/hooks/useCustomDomains';
import CopyToClipboard from 'src/components/CopyToClipboard';
import { CopyIcon } from 'src/components/Icons';
import RowDivider from 'src/components/RowDivider';
import { UrlUtils } from 'src/utils';
import {
  useGetFormByIdQuery,
  useGetResponsesByFormIdQuery,
} from 'src/services/api/formsApi';
import { Callout } from 'src/components/Callout';
import {
  FormResponseStatus,
  FormVisibility,
} from 'src/components/FormsV2/formsTypes';

const validationScheme = Yup.object().shape({
  fields: Yup.object().shape({
    recipientIds: Yup.array()
      .of(Yup.string())
      .test(
        'clients-array',
        'Please select at least one client.',
        (value) => value.length > 0,
      ),
  }),
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    copyText: {
      wordBreak: 'break-all',
      color: BlackHeadings,
    },
    iconButton: {
      padding: 'unset',
    },
    copyIcon: {
      fontSize: 12,
    },
    clientLinkContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: HoverBackground,
      padding: theme.spacing(1.5),
    },
    linkTitle: {
      color: GraySmall,
      marginBottom: theme.spacing(1),
    },
    dividerTitle: {
      color: GraySmall,
    },
    callout: {
      marginBottom: theme.spacing(2),
      '& svg': {
        marginTop: theme.spacing(0.5),
      },
    },
  }),
);

export const useShareForm = (
  selectedFormId?: string,
  selectedFormRef?: string,
) => {
  const classes = useStyles();
  const { basePortalDomain } = useCustomDomains();
  const { data: selectedForm, isLoading: isLoadingForm } = useGetFormByIdQuery(
    selectedFormId as string,
  );
  const { data: formResponses = [], isLoading: isLoadingResponses } =
    useGetResponsesByFormIdQuery(selectedFormId as string);

  const clientUsers = useSelector(
    (state: RootState) => state.clients.activeClients,
  );

  const portalConfig = React.useContext(PortalConfigContext);
  const shareFormLink = UrlUtils.GetFullUrl(
    `${basePortalDomain}/sl/form/${selectedFormRef}`,
  );

  const FormRenderer: React.FC<FormBodyAPI> = ({
    errors,
    values,
    setFieldValue,
  }) => {
    const handleRecipientChange: MultiCategorySelectProps['onChange'] = (
      _,
      vals,
    ) => {
      if (!Array.isArray(vals)) return;

      const clientsIds = vals.map((val) => val.id);
      setFieldValue('fields.recipientIds', clientsIds);
    };
    const disableUserIds = useMemo(() => {
      const pendingResponses = formResponses.filter((response) => {
        const status = response?.fields?.status;
        return status === FormResponseStatus.Pending;
      });
      // disable form from being shared with users who are still pending submission
      return pendingResponses?.map((u) => u.clientId);
    }, [selectedForm, formResponses]);

    const selectAllClients = () => {
      const clientsWithFormNotShared = clientUsers
        .filter((c) => !disableUserIds.includes(c.id))
        .map((u) =>
          u.fields.givenName ? `${portalConfig.portalHeader}_${u.id}` : u.id,
        );
      setFieldValue('fields.recipientIds', clientsWithFormNotShared);
    };

    return (
      <>
        {selectedForm?.fields?.visibility ===
          FormVisibility.RequestedClients && (
          <Callout className={classes.callout}>
            The form link is only accessible to clients who have been sent the
            form.
          </Callout>
        )}
        <BaseTypography fontType="13Regular" className={classes.linkTitle}>
          Share form link with your client
        </BaseTypography>
        <div className={classes.clientLinkContainer}>
          <BaseTypography className={classes.copyText} fontType="12Regular">
            {shareFormLink}
          </BaseTypography>
          <CopyToClipboard>
            {({ copy }) => (
              <IconButton
                className={classes.iconButton}
                onClick={() => copy(shareFormLink)}
              >
                <CopyIcon className={classes.copyIcon} />
              </IconButton>
            )}
          </CopyToClipboard>
        </div>
        <RowDivider>
          <BaseTypography className={classes.dividerTitle}>OR</BaseTypography>
        </RowDivider>
        <FormControl fullWidth style={{ marginBottom: 0 }}>
          <MultiCategorySelect
            multiple
            includeInternalUsers={false}
            includeClientUsers
            optionToExclude={disableUserIds}
            filterResultsLimit={0}
            values={values.fields.recipientIds || []}
            id="clients-select"
            label="Select client(s)"
            onChange={handleRecipientChange}
            placeholder="Type to find clients"
            showPlaceholder
            disabled={
              values.fields.disabled || isLoadingForm || isLoadingResponses
            }
            showClientsInOtherCompanies
            helperText={errors.fields && errors.fields.recipientIds}
            error={!!(errors.fields && errors.fields.recipientIds)}
          />
        </FormControl>
        <Box mt={1.75} textAlign="right">
          <Button
            variant="contained"
            onClick={selectAllClients}
            color="secondary"
          >
            Select all clients
          </Button>
        </Box>
      </>
    );
  };

  return { validationScheme, FormRenderer };
};
