import React from 'react';
import {
  FiEdit as EditIcon,
  FiNavigation as NavigationIcon,
} from 'react-icons/fi';
import { TrashIcon, DetailsIcon } from 'src/components/Icons';
import { BaseActionsMenuButton, Action } from 'src/components/Dropdowns';
import { ClientActionsRendererProps } from 'src/components/AgGrid/Renderer/types';

export const ClientActionRenderer = ({
  data,
  handleClickRemove,
  handleClickEdit,
  handleClickInvite,
  handleClickViewDetails,
  entityName,
  onActionMenuClick,
}: ClientActionsRendererProps) => {
  const actions: Action[] = [
    {
      name: 'Edit info',
      icon: <EditIcon />,
      onClick: () => handleClickEdit(data),
    },
  ];
  if (data.status !== 'Active' && handleClickInvite) {
    actions.push({
      name: `${
        data.status === 'Invited' ? 'Resend invite' : 'Invite client'
      }...`,
      onClick: () => handleClickInvite(data),
      icon: <NavigationIcon />,
    });
  }
  if (handleClickViewDetails) {
    actions.unshift({
      name: 'View details',
      icon: <DetailsIcon />,
      onClick: () => handleClickViewDetails(data),
    });
  }
  actions.push({
    name: `Delete ${entityName || 'client'}`,
    onClick: () => handleClickRemove(data),
    icon: <TrashIcon />,
    isDelete: true,
  });

  return (
    <BaseActionsMenuButton
      onActionMenuClick={onActionMenuClick}
      actions={actions}
    />
  );
};
