import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  List,
  createStyles,
  Theme,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import SidebarNav from 'src/components/Sidebar/SidebarNav';
import DrawerSection, {
  drawerWidth,
} from 'src/components/Sidebar/DrawerSection';
import { RootState } from 'src/store';
import { RouteContext, SidebarThemeContext } from 'src/context';
import { SettingsSidebarBackToggle } from 'src/components/Sidebar/SettingsSidebarBackToggle';
import { BANNER_HEIGHT } from 'src/constants/uiConsts';
import { useSidebarTheme } from 'src/components/Sidebar/useSidebarTheme';
import { SidebarLogo } from 'src/components/Sidebar/SidebarLogo';

export { drawerWidth };

const SidebarSkeletonItems = [
  { id: 'crm' },
  { id: 'messaging' },
  { id: 'files' },
];
interface SidebarContainerStyleProps {
  showBanner: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sidebarHeader: {
      padding: theme.spacing(2, 1, 3.5, 1),
      width: 'auto',
    },
    sidebarItemSkeleton: {
      borderRadius: theme.shape.borderRadius,
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: theme.spacing(0.5),
      height: '100%',
      [theme.breakpoints.up('md')]: {
        marginBottom: (props: SidebarContainerStyleProps) =>
          props.showBanner ? `${BANNER_HEIGHT}px` : 0,
      },
    },
  }),
);

interface SidebarProps {
  openMobile: boolean;
  onMobileClose: () => void;
}

const SidebarContainer: React.FC<SidebarProps> = ({
  openMobile,
  onMobileClose,
}) => {
  const { sidebarTheme } = useSidebarTheme();
  const bannerOptions = useSelector(
    (state: RootState) => state.ui.bannerOptions,
  );
  const isSettingsLoaded = useSelector(
    (state: RootState) => state.settings.loaded,
  );
  const isExtensionsItemsLoaded = useSelector(
    (state: RootState) => state.dashboard.itemsLoaded,
  );

  const classes = useStyles({
    showBanner: Boolean(bannerOptions),
  });
  const { pathname } = useContext(RouteContext);
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // show sidebar skeleton if app state is not ready
  // or settings are not loaded
  // or extensions items are not loaded yet
  const showSidebarSkeleton = !isSettingsLoaded || !isExtensionsItemsLoaded;

  const isSettingsPage = pathname.includes('settings');
  return (
    <SidebarThemeContext.Provider value={sidebarTheme}>
      <DrawerSection onClose={onMobileClose} open={openMobile}>
        <Box className={classes.sidebarHeader}>
          {/* On main sidebar show portal logo */}
          <SidebarLogo isSettingsPage={isSettingsPage} />
          {/* On settings sidebar show back to portal button only on desktop */}
          {isSettingsPage && !isSmScreen && <SettingsSidebarBackToggle />}
        </Box>
        <List className={classes.list}>
          {showSidebarSkeleton ? (
            SidebarSkeletonItems.map(({ id }) => (
              <Box key={id} py={0.5} px={1}>
                <Skeleton variant="rect" width="100%" height={28} />
              </Box>
            ))
          ) : (
            <SidebarNav />
          )}
        </List>
      </DrawerSection>
    </SidebarThemeContext.Provider>
  );
};

export default SidebarContainer;
