import React, { FC, memo } from 'react';
import {
  AvatarProps,
  ListItem,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import Typography, {
  typography13RegularStyle,
} from 'src/components/Text/BaseTypography';
import {
  BlackHeadings,
  GraySmall,
  HoverBackground,
  NonHoverBorder,
} from 'src/theme/colors';
import { TruncatedText } from '../TruncatedText';
import classNames from 'classnames';
import { StatusIcon } from 'src/components/Icons/StatusIcon';
import { AvatarGroup, Avatar } from '../Avatars';
import { getInitials } from 'src/utils/UserUtils';
import { getTimeFromNow } from 'src/utils/DateUtils';
import { useNow } from 'src/hooks/useNow';
import BaseTypography from 'src/components/Text/BaseTypography';

const CHANNEL_ITEM_HEIGHT = 68;
const CHANNEL_ITEM_WIDTH = 360;
type ChannelMemberData = {
  name: string;
  avatar: string;
  fallbackColor: string;
};
interface ChannelListItemProps {
  title: string;
  subTitle: string;
  unread: boolean;
  onSelect: React.ComponentProps<typeof ListItem>['onSelect'];
  members?: ChannelMemberData[];
  avatarVariant?: AvatarProps['variant'];
  selected?: boolean;
  timestamp?: Date;
  // subTitleRenderer is a component that will be used to render the subtitle
  // text of the channel item. This is useful when we want to render the subtitle
  // along side some other component. For example, in the Inbox page we want to
  // show an avatar before the text. The allows the style of the text always be the same
  // in look and feel and the parent component can decide how to render anything
  // along side the text.
  subTitleRenderer?: React.FC;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: CHANNEL_ITEM_WIDTH,
      height: CHANNEL_ITEM_HEIGHT,
      padding: theme.spacing(1.5, 2.5),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${NonHoverBorder}`,
      // hover state on desktop
      [theme.breakpoints.up('sm')]: {
        '&:hover': {
          backgroundColor: HoverBackground,
          cursor: 'pointer',
        },
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    summarySlot: {
      display: 'flex',
      gap: theme.spacing(1.5),
      alignItems: 'center',
      width: '100%',
    },
    titleSlot: {
      display: 'flex',
      flexDirection: 'column',
      width: 245,
      maxWidth: 245,
      justifyContent: 'center',
    },
    time: {
      height: '100%',
      boxSizing: 'content-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: (props: Pick<ChannelListItemProps, 'unread'>) =>
        props.unread ? 'space-between' : 'flex-end',
    },
    selected: {
      [theme.breakpoints.up('sm')]: {
        backgroundColor: HoverBackground,
      },
    },
    status: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    truncatedContainer: {
      width: '100%',
      color: GraySmall,
    },
    truncatedText: {
      display: 'inline-block',
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      paddingRight: theme.spacing(2.75),
    },
  }),
);

const ChannelTimeStamp: FC<{ time: Date; unReadNotification: boolean }> = memo(
  ({ time, unReadNotification }) => {
    const { now: nowTime } = useNow();
    const humanizedTimeSince = getTimeFromNow(time, nowTime);

    return (
      <Typography
        textColor={GraySmall}
        fontType={unReadNotification ? '13Medium' : '13Regular'}
      >
        {humanizedTimeSince}
      </Typography>
    );
  },
);

export const DefaultSubTitleRenderer: FC = ({ children }) => <>{children}</>;

export const ChannelListItem: FC<ChannelListItemProps> = ({
  members,
  avatarVariant = 'rounded',
  title,
  subTitle,
  subTitleRenderer,
  unread,
  timestamp,
  onSelect,
  selected,
}) => {
  const classes = useStyles({ unread });
  const [member] = members || [];

  const SubTitleRenderer = subTitleRenderer ?? DefaultSubTitleRenderer;

  return (
    <ListItem
      component="div"
      className={classNames(classes.root, {
        [classes.selected]: selected,
      })}
      onClick={onSelect}
    >
      <div className={classes.summarySlot}>
        {members &&
          (members.length > 1 ? (
            <AvatarGroup>
              {members
                .slice(0, 3)
                .map(({ avatar: src, fallbackColor, name }) => (
                  <Avatar
                    src={src}
                    variant="circle"
                    fallbackColor={fallbackColor}
                    fallbackLetters={getInitials(name) || ''}
                  />
                ))}
            </AvatarGroup>
          ) : (
            <Avatar
              variant={avatarVariant}
              src={member?.avatar || ''}
              fallbackColor={member?.fallbackColor || ''}
              fallbackLetters={getInitials(member?.name) || ''}
            />
          ))}

        <div className={classes.titleSlot}>
          <TruncatedText
            text={title}
            styleProps={{
              ...typography13RegularStyle,
              color: BlackHeadings,
              fontWeight: unread ? 500 : 400,
            }}
          />
          <SubTitleRenderer>
            {subTitle && (
              <BaseTypography
                fontType="13Regular"
                className={classes.truncatedContainer}
              >
                <span className={classes.truncatedText}>{subTitle}</span>
              </BaseTypography>
            )}
          </SubTitleRenderer>
        </div>
      </div>

      <div className={classes.time}>
        <div className={classes.status}>
          {unread && (
            <StatusIcon
              style={{
                color: BlackHeadings,
                fontSize: 8,
              }}
            />
          )}
        </div>
        {timestamp && (
          <ChannelTimeStamp time={timestamp} unReadNotification={unread} />
        )}
      </div>
    </ListItem>
  );
};
