import { Auth } from 'aws-amplify';
import React from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import history from 'src/history';
import { TrackEvent } from 'src/utils/AnalyticsUtils';
import UsersClient from 'src/clients/UsersClient';
import { PortalConfigContext } from 'src/context';
import { isEmptyString } from 'src/utils/StringUtils';
import {
  SIGNED_IN_WITH_GOOGLE_USER_KEY,
  SIGNED_IN_WITH_PROXY_USER_KEY,
} from 'src/constants/authConsts';
import { logoutFromHostedUi } from 'src/utils/AuthUtils';
import axios from 'axios';

export const useLogout = () => {
  const portalConfig = React.useContext(PortalConfigContext);
  const logout = async (
    isTestUser: boolean,
    redirectUrl: string,
    isClient: boolean,
    allSessions = true,
  ) => {
    const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
    const cognitoUsername = currentUser.getUsername();

    const googleSignedInUser = window.localStorage.getItem(
      SIGNED_IN_WITH_GOOGLE_USER_KEY,
    );

    // if we are logging out a user, who was logged in through google
    // then we should clear the storage item for this user
    // this allows us to skip calling the hosted ui endpoint for the non federated users
    if (cognitoUsername === googleSignedInUser) {
      window.localStorage.removeItem(SIGNED_IN_WITH_GOOGLE_USER_KEY);
    }

    // When a client user signs in via google, the sessions are managed by the
    // unauth-router via auth.copilot.app
    //
    // This is done, since a client user can initiate sign in via google through different domains
    // We manage the redirects via the api at `auth.copilot.com`.
    // The cookies are set on this api domain which remebers the session and
    // redirects the user as required.
    //
    // If a client has multiple portals logged in via the same browser, they
    // effectively have multiple sessions being managed by the api.
    // When the logout is initiated for the client, the callback is received by the api
    // but there is no information about the session to log out of.
    //
    // To solve this we call the `/auth/google/initiate-logout?portalId=<>` api before logging out
    // This api sets a cookie
    // google-logout-init-for-portal with the value being the
    // portal id the user is logging out from
    //
    // Now when the logout is completed via the unauth-router's signout endpoint
    // the api can redirect the user to the correct redirectSignOutUrl based on the portalId
    // set here
    if (isClient && googleSignedInUser && !isEmptyString(googleSignedInUser)) {
      const redirectSignOutURL = portalConfig.AWS.Auth.oauth?.redirectSignOut;

      if (redirectSignOutURL) {
        // the init logout api uses the same api as the redirect signout api
        // the portalId is set in the query param
        const initLogoutApi = `${
          new URL(redirectSignOutURL).origin
        }/auth/google/initiate-logout?portalId=${portalConfig.portalHeader}`;
        await axios.get(initLogoutApi, { withCredentials: true });

        // for proxied users,
        // this signs out the custom auth session
        // In this case to clear the google session and complete signout,
        // this has to be followed by the
        // `logoutFromHostedUi` which takes the user to the logout endpoint
        //
        // for non proxied user, this will clear the google session
        await Auth.signOut();

        const proxiedUser = window.localStorage.getItem(
          SIGNED_IN_WITH_PROXY_USER_KEY,
        );

        // if a user was proxied in, that represents a valid session with google
        // to clear the google session , we need to log the user out from the
        // hosted ui
        if (proxiedUser && !isEmptyString(proxiedUser)) {
          window.localStorage.removeItem(SIGNED_IN_WITH_PROXY_USER_KEY);
          logoutFromHostedUi(portalConfig, redirectSignOutURL);
          return;
        }
      }
    } else {
      await Auth.signOut();
    }

    TrackEvent(`Signed Out`, { isTestUser });
    // clients should be redirected to the SignoutRedirectURL internal users have set,
    // whether that is within copilot domain or a marketing site of their choice
    if (isClient) {
      window.location.replace(`/auth/signout?next=${redirectUrl}`);
    } else {
      const endFc = allSessions
        ? UsersClient.endSessions
        : UsersClient.endCurrentSession;
      await endFc();

      // if there was a google sign in done, and we are trying to log out of all sessions
      // we need to call the hosted ui logout endpoint, to complete the login
      // this ensures that the next time, we try logging in with google an account selection
      // screen is shown
      if (
        allSessions &&
        googleSignedInUser &&
        !isEmptyString(googleSignedInUser)
      ) {
        // since all the user logged in with google will be signed out, we clear the
        // storage
        window.localStorage.removeItem(SIGNED_IN_WITH_GOOGLE_USER_KEY);

        // logs any google authed user out
        logoutFromHostedUi(portalConfig, window.location.origin + redirectUrl);
      }

      // if the user was proxied in, we need to clear the proxy session flag
      window.localStorage.removeItem(`proxy#${cognitoUsername}`);
      history.replace(redirectUrl);
    }
  };
  return logout;
};
