import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { FormBodyAPI } from 'src/constants/fieldsTypes';
import { BaseTextField } from 'src/components/TextField';
import { RootState } from 'src/store';

interface AddTextFormProps {
  label?: string;
}

export const useAddTextForm = ({ label }: AddTextFormProps) => {
  const isClient = useSelector((state: RootState) => state.user.isClient);

  const inputLabel = useMemo(() => {
    if (isClient) {
      if (label) {
        return `Add ${label.toLowerCase()}`;
      }

      return 'Add a text';
    }

    return 'Text';
  }, [isClient, label]);

  const validationScheme = Yup.object().shape({
    value: Yup.string().nullable().trim().required('Required'),
  });

  const getSubmitValues = async () => ({
    key: v4(),
  });

  const FormRenderer: React.FC<FormBodyAPI> = ({
    touched,
    errors,
    handleBlur,
    handleChange,
    values,
  }) => (
    <Box>
      <BaseTextField
        sizeVariant="medium"
        error={Boolean(touched.value && errors.value)}
        fullWidth
        helperText={(touched.value && errors.value) || null}
        name="value"
        label={inputLabel}
        onBlur={handleBlur}
        variant="outlined"
        onChange={handleChange}
        value={values.value}
        autoFocus
        autoComplete="new-password"
        style={{
          minHeight: 80,
        }}
      />
    </Box>
  );

  return { validationScheme, getSubmitValues, FormRenderer };
};
