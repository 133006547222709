import React from 'react';
import { SidebarThemeContext } from 'src/context';
import { white } from 'src/theme/colors';
import { DiamondIcon } from 'src/components/Icons';

/**
 *
 * @param props
 * @description Renders diamond icon that represent the advanced feature and is exclusive to higher plans.
 * @returns JSX
 */
export const UpgradeBadge = ({ show = false }) => {
  const { sidebarTextColor } = React.useContext(SidebarThemeContext);
  return show ? (
    <DiamondIcon
      data-testid="upgrade-icon"
      style={{ fontSize: 16, color: sidebarTextColor || white }}
    />
  ) : null;
};
