import * as React from 'react';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { Button, makeStyles, createStyles, Theme } from '@material-ui/core';
import { FormBodyAPI } from 'src/constants/fieldsTypes';
import BaseTypography from 'src/components/Text/BaseTypography';
import { LightGray } from 'src/theme/colors';
import { SignatureComponentType } from 'src/constants';

interface ClientFormProps {
  title: string;
  primaryButtonActionLabel: string;
  secondaryButtonActionLabel?: string;
  onPrimaryButtonClick: (values: any) => void;
  initialFormValue: any;
  onSecondaryButtonClick?: (values: any) => void;
  isOptional?: boolean;
}

export interface ClientSignatureFromActionProps {
  useFormHook: () => {
    validationScheme: Yup.ObjectSchema;
    getSubmitValues: (values?: any) => Promise<any>; // this method can be specified in the form to get any extra values that might be needed during submit api call
    FormRenderer: React.FC<FormBodyAPI & FormikHelpers<any>>;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    formContainer: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    secondaryButton: {
      marginLeft: theme.spacing(2),
    },
    optionalText: {
      color: LightGray,
    },
    formActions: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  }),
);

export const SignatureClientFormV2: React.FC<
  Partial<ClientFormProps> & ClientSignatureFromActionProps
> = React.memo(
  ({
    title,
    primaryButtonActionLabel,
    secondaryButtonActionLabel,
    initialFormValue,
    onPrimaryButtonClick,
    useFormHook,
    onSecondaryButtonClick,
    isOptional,
  }) => {
    const classes = useStyles();

    const { validationScheme, getSubmitValues, FormRenderer } = useFormHook();
    const [formState, setFormState] = React.useState({
      formValue: initialFormValue,
    });

    /**
     * Send all submit values to the saving method passes in props. This can either be an
     * redux action which will be dispatched or a callback function
     * that is not dispatched. This method also looks for extra
     *  submit values that might not be in the form and needed to be formatted differently.
     * @param values the input values of the form that will be submitted
     * @returns void
     */
    const handlePrimaryButtonClick = async (values: any) => {
      const extraValues = await getSubmitValues(values);
      const stagedValues = {
        ...values,
        fields: { ...values.fields, ...extraValues },
      };
      if (onPrimaryButtonClick) {
        onPrimaryButtonClick(stagedValues);
      }
    };

    const handleSecondaryButtonClick = async (values: any) => {
      const extraValues = await getSubmitValues(values);
      const stagedValues = {
        ...values,
        fields: { ...values.fields, ...extraValues },
      };
      if (onSecondaryButtonClick) {
        onSecondaryButtonClick(stagedValues);
      }
    };

    const isButtonDisabled = (
      values: any,
      isValid: boolean,
      isSubmitting: boolean,
    ) => {
      const { componentType, value, name } = values;
      if (isSubmitting) return true;
      // if form is not valid disable the button
      if (!isValid) return true;

      // for signature and text inputs we will have value saved in 'name' key
      // for other inputs we have value saved in 'value' key
      if (
        componentType === SignatureComponentType.REQUEST_SIGN ||
        componentType === SignatureComponentType.REQUEST_INITIAL
      ) {
        if (!name) return true;
      }

      if (
        componentType === SignatureComponentType.REQUEST_DATE ||
        componentType === SignatureComponentType.REQUEST_TEXT
      ) {
        if (!value) return true;
      }

      return false;
    };

    React.useMemo(() => {
      setFormState({
        ...formState,
        formValue: initialFormValue,
      });
    }, [initialFormValue]);

    return (
      <div className={classes.root}>
        <BaseTypography fontType="15Medium">
          {title}{' '}
          <span className={classes.optionalText}>
            {isOptional ? '(Optional)' : ''}
          </span>
        </BaseTypography>
        <Formik
          enableReinitialize
          initialValues={{ ...formState.formValue }}
          validationSchema={validationScheme}
          onSubmit={handlePrimaryButtonClick}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            isSubmitting,
            touched,
            values,
            initialValues,
            isValid,
            ...rest
          }) => (
            <>
              <div className={classes.formContainer}>
                {FormRenderer({
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  values,
                  initialValues,
                  isSubmitting,
                  ...rest,
                })}
              </div>
              <div className={classes.formActions}>
                <Button
                  variant="contained"
                  disabled={isButtonDisabled(values, isValid, isSubmitting)}
                  color="primary"
                  id="company-action"
                  onClick={() => handlePrimaryButtonClick(values)}
                >
                  {primaryButtonActionLabel}
                </Button>
                {secondaryButtonActionLabel && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleSecondaryButtonClick(values)}
                    disabled={
                      !isOptional
                        ? isButtonDisabled(values, isValid, isSubmitting)
                        : false
                    } // do not check validation for optional button
                    className={classes.secondaryButton}
                  >
                    {secondaryButtonActionLabel}
                  </Button>
                )}
              </div>
            </>
          )}
        </Formik>
      </div>
    );
  },
);
