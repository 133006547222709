import { API as AmplifyAPI, Auth } from 'aws-amplify';
import { AppViewMode, UnAuthAPIName } from 'src/constants';
import { getApiEndpointUrl } from 'src/mocks/baseUrls';
import { handleNativeHttpRequestFetcher } from 'src/utils/httpUtils';
import { UseUnAuthApp } from 'src/utils/AuthUtils';

// general type for init, copied from aws-amplify
type initType = {
  [key: string]: any;
};

/**
 * Setup init object for api call based on api name.
 * For api names that correspond to unauth endpoints, return existing init object.
 * For other api names (all auth) add auth header from current user token.
 * @param apiName name of api being called
 * @param init init object passed to api call
 * @returns modified init object with auth header if needed
 */
const getInitForApi = async (apiName: string, init: initType) => {
  const isUnauthRoute = UseUnAuthApp(window.location.pathname);
  // when it is client view mode, the portal header
  // information lives in the portal config and we dont need
  // to get it from the session info since we dont have the notion
  // of multiple sessions in client experience.

  // when it is internal view mode, the portal header information lives in
  // two places depending on the route.

  // 1. if the route is an un-auth route,we dont know which portal is being accessed
  // so use the portal header from the portal config which defines the unauth portal
  // header (workspace).

  // 2. if the route is an auth route, use the portal header from the session info
  // which defines the actual portal the user is accessing.
  const Portal =
    isUnauthRoute || window.App.portalConfig.viewMode === AppViewMode.CLIENT
      ? window.App.portalConfig.portalHeader
      : window.App.sessionData.currentPortalSession;

  if (apiName === UnAuthAPIName) {
    return {
      ...init,
      headers: {
        Portal,
      },
    };
  }

  // get current session from amplify auth (which does refresh)
  const currentSession = await Auth.currentSession();
  const idToken = currentSession.getIdToken().getJwtToken();

  const Authorization = `Bearer ${idToken}`;

  return {
    ...init,
    headers: {
      Authorization,
      Portal,
    },
  };
};

/**
 * This class is a wrapper around Amplify's API class.
 * It allows us to add additional headers to the request (e.g. Authorization).
 * based on a refreshed token.
 * It can also be used to mock Amplify's API class in tests.
 */
export class API {
  static async get(
    apiName: string,
    path: string,
    init: initType,
  ): Promise<any> {
    const requestInit = await getInitForApi(apiName, init);
    return process.env.JEST_WORKER_ID
      ? handleNativeHttpRequestFetcher(
          getApiEndpointUrl(path),
          'GET',
          undefined,
          undefined,
        )
      : AmplifyAPI.get(apiName, path, requestInit);
  }

  static async post(
    apiName: string,
    path: string,
    init: initType,
  ): Promise<any> {
    const requestInit = await getInitForApi(apiName, init);
    return process.env.JEST_WORKER_ID
      ? handleNativeHttpRequestFetcher(
          getApiEndpointUrl(path),
          'post',
          requestInit,
          undefined,
        )
      : AmplifyAPI.post(apiName, path, requestInit);
  }

  static async put(
    apiName: string,
    path: string,
    init: initType,
  ): Promise<any> {
    const requestInit = await getInitForApi(apiName, init);
    return process.env.JEST_WORKER_ID
      ? handleNativeHttpRequestFetcher(
          getApiEndpointUrl(path),
          'put',
          requestInit,
          undefined,
        )
      : AmplifyAPI.put(apiName, path, requestInit);
  }

  static async patch(
    apiName: string,
    path: string,
    init: initType,
  ): Promise<any> {
    const requestInit = await getInitForApi(apiName, init);
    return process.env.JEST_WORKER_ID
      ? handleNativeHttpRequestFetcher(
          getApiEndpointUrl(path),
          'patch',
          requestInit,
          undefined,
        )
      : AmplifyAPI.patch(apiName, path, requestInit);
  }

  static async del(
    apiName: string,
    path: string,
    init: initType,
  ): Promise<any> {
    const requestInit = await getInitForApi(apiName, init);

    return process.env.JEST_WORKER_ID
      ? handleNativeHttpRequestFetcher(
          getApiEndpointUrl(path),
          'del',
          requestInit,
          undefined,
        )
      : AmplifyAPI.del(apiName, path, requestInit);
  }
}
