export const AUTH_STATES = {
  SIGNED_IN: 'signedIn',
  SIGNED_UP: 'signedUp',
  SIGN_UP: 'signUp',
  SIGN_IN: 'signIn',
  CONFIRM_SIGN_UP: 'confirmSignUp',
  DESKTOP_ONLY_MODAL: 'modalRestricted',
  CONFIRM_PAGE_CHANGE_MODAL: 'confirmPageChange',
  FORGOT_PASSWORD: 'forgotPassword',
  CONFIRM_SIGN_IN: 'confirmSignIn',
  REQUIRE_NEW_PASSWORD: 'requireNewPassword',
  VERIFY_CONTACT: 'verifyContact',
} as const;

type AuthStateKeys = keyof typeof AUTH_STATES;
export type AuthStateTypes = (typeof AUTH_STATES)[AuthStateKeys];

export const GetAuthStateTitleMap = (name: string) => ({
  [AUTH_STATES.SIGN_IN]: 'Sign In',
  [AUTH_STATES.SIGN_UP]: `Sign up for ${name}`,
  [AUTH_STATES.DESKTOP_ONLY_MODAL]: 'Mobile support coming soon',
});

export const GetMobileRestrictedDescription = (name: string) =>
  `The ${name} Portal does not support all functionality on mobile devices yet. Please switch to a desktop device.`;

export const MFA_CODE_LENGTH = 6;

export const MFA_INPUT_TITLE = "Verify it's you";

export const MFA_INPUT_DESCRIPTION =
  'Please enter your code from your authenticator app.';

export const MFA_ISSUER_NAME = 'Copilot.com';

export const SESSION_EXPIRY_ERR_MSG =
  'Session expired. Please refresh and try logging in again.';

export const INVALID_CODE = 'Invalid code';

// key that represents a user logged in with google
// the value is the cognito username of the user
export const SIGNED_IN_WITH_GOOGLE_USER_KEY = 'signed_in_with_google';

// key that represents a user logged in via proxy
// the value is the email of the logged in user
export const SIGNED_IN_WITH_PROXY_USER_KEY = 'signed_in_with_proxy';

// key that saves the email for a user where the login fails
// since the callbacks can't contain wildcards, we use this to store the
// context for the error
export const SIGNED_IN_WITH_GOOGLE_FAILED_EMAIL_KEY =
  'signed_in_with_google_email';

// key that saves the next path set before the google login flow
export const REDIRECT_PATH_AFTER_GOOGLE_LOGIN = 'redirect_after_google_login';
