import React from 'react';
import {
  Button,
  makeStyles,
  createStyles,
  Theme,
  Divider,
} from '@material-ui/core';
import { BANNER_HEIGHT } from 'src/constants/uiConsts';
import { PLAN_PAGE } from 'src/constants';
import { ForwardIcon } from 'src/components/Icons';
import { BannerOptions } from 'src/store/ui/types';
import * as Colors from 'src/theme/colors';
import BaseTypography from 'src/components/Text/BaseTypography';
import { useSettingsPageRedirection } from 'src/components/Settings/useSettingsPageRedirection';
import { drawerWidth } from '../Sidebar/SidebarContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      minHeight: `${BANNER_HEIGHT}px`,
      height: `${BANNER_HEIGHT}px`,
      backgroundColor: Colors.CopilotDarkGreen,
      color: Colors.CopilotLightGreen,
      [theme.breakpoints.up('md')]: {
        marginLeft: `-${drawerWidth}px`,
      },
    },
    conatiner: {
      display: 'flex',
      alignItems: 'center',
    },
    bannerButton: {
      color: Colors.CopilotLightGreen,
      '&:hover': {
        color: Colors.white,
        backgroundColor: 'transparent',
      },
    },
    divider: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      height: 20,
      backgroundColor: Colors.CopilotLightGreen,
    },
  }),
);

export const Banner: React.FC<BannerOptions> = ({ message, buttonOptions }) => {
  const classes = useStyles();
  const { goToSettings: goToPlanSettingsPage } = useSettingsPageRedirection(
    true,
    PLAN_PAGE.path,
  );
  const handleClick = () => {
    if (buttonOptions) {
      goToPlanSettingsPage();
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.conatiner}>
        <BaseTypography fontType="13Medium">{message}</BaseTypography>
        <Divider
          orientation="vertical"
          variant="middle"
          className={classes.divider}
        />
        {buttonOptions ? (
          <Button
            variant="text"
            color="secondary"
            className={classes.bannerButton}
            onClick={handleClick}
            endIcon={<ForwardIcon />}
          >
            <BaseTypography fontType="13Medium">
              {buttonOptions.label}
            </BaseTypography>
          </Button>
        ) : null}
      </div>
    </div>
  );
};
