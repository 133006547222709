import {
  NOTIFICATIONS_SETTINGS_PAGE_DESCRIPTION,
  ACCOUNT_SETTINGS_PAGE_DESCRIPTION,
  BILLING_SETTINGS_PAGE_DESCRIPTION,
  CUSTOMIZATION_SETTINGS_PAGE_DESCRIPTION,
  DOMAINS_SETTINGS_PAGE_DESCRIPTION,
  GENERAL_SETTINGS_PAGE_DESCRIPTION,
  MESSAGING_SETTINGS_PAGE_DESCRIPTION,
  PAYMENTS_SETTINGS_PAGE_DESCRIPTION,
  TEAMS_SETTINGS_PAGE_DESCRIPTION,
  APP_SETTINGS_PAGE_DESCRIPTION,
  FILE_SETTINGS_PAGE_DESCRIPTION,
  API_SETTINGS_PAGE_DESCRIPTION,
  MODULE_MANAGEMENT_DESCRIPTION,
} from 'src/constants/stringConsts';
import { NotificationIcon } from 'src/components/Icons/NotificationIcon';
import { HomeIcon } from 'src/components/Icons/ModuleIcons/HomeIcon';
import { DEFAULT_REFERRAL_AMOUNT } from 'src/constants/referralConsts';
import { AUTH_STATES } from 'src/constants/authConsts';
import {
  AccountIcon,
  ClientsIcon,
  BillingIcon,
  FilesIcon,
  MessagesIcon,
  PaymentsIcon,
  TeamIcon,
  HardwareChipIcon,
  FormsIcon,
  BookOpenIcon,
  LinkIcon,
  PuzzleIcon,
  QuestionIcon,
  AppsIcon,
  GiftIcon,
  PortalModulesIcon,
  ExternalLinkIcon,
  CustomizationIcon,
  ContractIcon,
  MoreActionsIcon,
} from 'src/components/Icons';
import { PlanIcon } from 'src/components/Icons/Plan';
import { StatusIcon } from 'src/components/Icons/StatusIcon';
import { AutomationsIcon } from 'src/components/Icons/ModuleIcons/AutomationsIcon';

export const COMPANIES_PAGE = {
  path: '/companies',
  label: 'CRM',
  icon: ClientsIcon,
};

export const USERS_PAGE = {
  path: '/clients/users',
  label: 'Clients',
  icon: ClientsIcon,
};

export const INBOX_PAGE = {
  path: '/notifications',
  label: 'Notifications',
  icon: NotificationIcon,
};

export const CRM_CLIENTS_PAGE = {
  path: '/clients/users',
  label: 'CRM',
  icon: ClientsIcon,
};

export const CLIENTS_IMPORT_PAGE = {
  path: '/clients/import',
};

export const HOME_PAGE = {
  path: '/',
  label: 'Home',
  icon: HomeIcon,
};

export const CLIENT_APPS_PAGE = {
  path: '/apps',
  label: '',
};

export const CLIENT_DETAILS_PAGE = {
  path: '/clients/users/details',
  label: 'Client Users',
  icon: ClientsIcon,
};

export const EXPERIENCE_SETUP_PAGE = '/create-ad';

export const MARKETING_PAGE_URL = 'https://www.lightout.com';

export const EXPERIENCES_PAGE = '/feed';

export const PACKAGES_PAGE = '/packages';

export const LOGIN_PAGE = `/login?step=${AUTH_STATES.SIGN_IN}`;

export const REGISTER_PAGE = `/login?step=${AUTH_STATES.SIGN_UP}`;

export const REQUIRE_NEW_PASSWORD = `/login?step=${AUTH_STATES.REQUIRE_NEW_PASSWORD}`;

export const CONFIRM_REGISTER_PAGE = `/login?step=${AUTH_STATES.CONFIRM_SIGN_UP}`;

export const FORGOT_PASSWORD_PAGE = `/login?step=${AUTH_STATES.FORGOT_PASSWORD}`;

export const SETTINGS_PAGE = '/settings';

export const ARTICLE_PAGE = '/article';

export const INVOICE_PATH = '/inv';

export const ARTICLE_PATH = '/art';

export const SHORT_LINK_PATH = '/sl';

export const HELPDESK_PAGE = {
  path: '/helpdesk',
  label: 'Helpdesk',
  icon: BookOpenIcon,
};

export const FILES_PAGE = {
  path: '/files',
  label: 'Files',
  icon: FilesIcon,
};
export const ARTICLES_PAGE = {
  path: '/articles',
  label: 'Knowledge base',
  icon: BookOpenIcon,
};
export const PRODUCTS_PAGE = {
  path: '/products',
  label: 'Products',
  icon: PaymentsIcon,
  description: PAYMENTS_SETTINGS_PAGE_DESCRIPTION,
};
export const PRODUCT_DETAILS_PAGE = {
  path: '/products/details',
  label: 'Product',
};

export const PRODUCT_EDIT_PAGE = {
  path: '/products/edit',
  label: 'Create Product',
};

export const INVOICES_PAGE = {
  path: '/invoices',
  label: 'Invoices',
  icon: PaymentsIcon,
  description: PAYMENTS_SETTINGS_PAGE_DESCRIPTION,
};

export const SUBSCRIPTION_PAGE = {
  path: '/subscriptions',
  label: 'Subscriptions',
  icon: PaymentsIcon,
  description: PAYMENTS_SETTINGS_PAGE_DESCRIPTION,
};
export const MESSAGES_PAGE = {
  path: '/messaging',
  label: 'Messages',
  icon: MessagesIcon,
  description: MESSAGING_SETTINGS_PAGE_DESCRIPTION,
};

export const HELP_CENTER_PAGE = {
  path: '/help-center',
  label: 'Help Center',
  icon: QuestionIcon,
};

export const SETTINGS_PAGE_ROOT_PATH = '/settings';

export const CUSTOMIZATION_PAGE = {
  path: '/customization',
  label: 'Customization',
  description: CUSTOMIZATION_SETTINGS_PAGE_DESCRIPTION,
  icon: ExternalLinkIcon,
};

// app setup page is improved version of module management page
export const APP_SETUP_PAGE = {
  path: '/app-setup',
  label: 'App Setup',
  description: MODULE_MANAGEMENT_DESCRIPTION,
  icon: CustomizationIcon,
};

export const BILLING_PAGE = {
  path: '/settings/billing',
  label: 'Payment methods',
  icon: BillingIcon,
  description: PAYMENTS_SETTINGS_PAGE_DESCRIPTION,
};

export const SETUP_APP_PAGE_BASE_PATH = `${APP_SETUP_PAGE.path}/setup` as const;

export const FILES_SETTINGS_PAGE = {
  path: '/settings/files',
  label: 'Files',
  icon: FilesIcon,
  description: FILE_SETTINGS_PAGE_DESCRIPTION,
};

export const NOTIFICATIONS_SETTINGS_PAGE = {
  path: '/settings/notifications',
  label: 'Notifications',
  icon: NotificationIcon,
  description: NOTIFICATIONS_SETTINGS_PAGE_DESCRIPTION,
};

export const MESSAGING_PAGE = {
  path: '/settings/messaging',
  label: 'Messaging',
  icon: MessagesIcon,
  description: MESSAGING_SETTINGS_PAGE_DESCRIPTION,
};

export const PAYMENTS_PAGE = {
  path: '/settings/payout-account',
  label: 'Payout account',
  icon: PaymentsIcon,
  description: BILLING_SETTINGS_PAGE_DESCRIPTION,
};

export const PROFILE_PAGE = {
  path: '/settings/profile',
  label: 'Account',
  icon: AccountIcon,
  description: ACCOUNT_SETTINGS_PAGE_DESCRIPTION,
};

export const SETTINGS_TEAM_PAGE = {
  path: '/settings/team',
  label: 'Team',
  icon: TeamIcon,
  description: TEAMS_SETTINGS_PAGE_DESCRIPTION,
};

export const API_SETTINGS_PAGE = {
  path: '/settings/api',
  label: 'API',
  icon: HardwareChipIcon,
  description: API_SETTINGS_PAGE_DESCRIPTION,
};

export const WEBHOOK_SETUP_PAGE = {
  path: '/settings/api/webhooks',
  label: 'Create webhook',
  description: `Manage your webhook url and events`,
};

export const WEBHOOK_EDIT_PAGE = {
  path: '/settings/api/webhooks/edit',
  label: 'Edit webhook',
  description: `Manage your webhook url and events`,
};

export const APPS_PAGE = {
  path: '/settings/apps',
  label: 'Integrations',
  icon: AppsIcon,
  description: APP_SETTINGS_PAGE_DESCRIPTION,
};

export const GENERAL_SETTINGS_PAGE = {
  path: '/settings/general',
  label: 'General',
  icon: PuzzleIcon,
  description: GENERAL_SETTINGS_PAGE_DESCRIPTION,
};

export const DOMAIN_PAGE = {
  path: '/settings/domains',
  label: 'Domains',
  icon: LinkIcon,
  pageIcon: LinkIcon,
  description: DOMAINS_SETTINGS_PAGE_DESCRIPTION,
};

export const SIGNATURE_PAGE = {
  path: '/signature',
  label: 'Signature',
};

export const PLAN_PAGE = {
  path: '/settings/plans',
  label: 'Plans',
  icon: PlanIcon,
  pageIcon: PlanIcon,
  description: '',
};

export const EXTENSION_ITEM = {
  label: 'More',
  icon: MoreActionsIcon,
};

export const CLIENT_EXPERIENCE_ITEM = {
  label: 'Portal',
  icon: StatusIcon,
  pageIcon: StatusIcon,
};

export const PORTAL_ITEM = {
  label: 'Customization',
  icon: PortalModulesIcon,
  pageIcon: PortalModulesIcon,
};

export const PLAN_PAGE_CANCEL_START = {
  path: '/settings/plans/cancel',
  label: 'Are you sure you want to cancel your plan?',
  description: '',
};

export const PLAN_PAGE_CANCEL_CONFIRM = {
  path: '/settings/plans/cancel/confirm',
  label: 'Would you like to pause your subscription instead?',
  description: '',
};

export const PLAN_PAGE_CANCEL_CONFIRM_CALLOUT =
  'You can pause your Copilot account for up to 3 months. During this period, you will not be charged, and you and your clients still have full access to your portal. Note that you can only pause your subscription once every 2 years.';

export const REFERRAL_PAGE = {
  path: '/settings/referral',
  label: 'Refer Copilot',
  icon: GiftIcon,
  pageIcon: GiftIcon,
  description: `Referral gets $${DEFAULT_REFERRAL_AMOUNT} in Portal credit and you earn a $100 Amazon gift card`,
};

export const REFERRAL_PAGE_V2 = {
  path: '/referrals',
  label: 'Referrals',
  icon: GiftIcon,
  pageIcon: GiftIcon,
  description: 'Refer a friend or colleague and receive Amazon gift cards',
};

export const ADD_APP_PAGE = {
  path: '/app-setup/new',
  label: 'Add an App',
};

export const ONLY_DESKTOP_SUPPORT_PAGES: Set<string> = new Set<string>([
  CUSTOMIZATION_PAGE.path,
]);

export const PORTAL_URL = 'https://copilot.com';

export const LANDING_HOME_PAGE_TITLE = 'Home';

export const FORMS_PAGE = {
  path: '/forms',
  label: 'Forms',
  icon: FormsIcon,
};

export const FORMS_DETAIL_PAGE_V2 = {
  path: '/forms/details',
};

export const FORMS_RESPONSE_PAGE = {
  path: '/forms/response',
};

export const TOS_LINK = 'https://copilot.com/legal/terms-of-service';
export const PRIVACY_POLICY_LINK = 'https://copilot.com/legal/privacy-policy';

export const DEFAULT_SETTINGS_PAGE = 'me';

// these are all the page paths that can lead to different pages based on their
// query param. E.g. the extension path can have multiple items in the sidebar
// that are distinguished by their query param.
export const PagePathsRoutedWithQuery = [CLIENT_APPS_PAGE.path];

// Starting route of path that an IU sees when they want to
// go to a specific portal. E.g. `/portal/portalId`
export const PORTAL_PATH = '/portal';

export const CONTRACTS_PAGE = {
  path: '/contracts',
  label: 'Contracts',
  icon: ContractIcon,
};

export const AUTOMATIONS_PAGE = {
  path: '/automations',
  label: 'Automations',
  icon: AutomationsIcon,
};

export const CONTRACT_DETAILS_PAGE = {
  path: '/contracts/details',
};

export const CONTRACT_SUBMIT_PATH = '/contracts/submit';
export const INVOICE_DETAILS_PAGE_PATH = '/invoices/details';
