export const DateConsts = {
  MONTHS: [
    {
      abbreviation: 'Jan',
      name: 'January',
    },
    {
      abbreviation: 'Feb',
      name: 'February',
    },
    {
      abbreviation: 'Mar',
      name: 'March',
    },
    {
      abbreviation: 'Apr',
      name: 'April',
    },
    {
      abbreviation: 'May',
      name: 'May',
    },
    {
      abbreviation: 'Jun',
      name: 'June',
    },
    {
      abbreviation: 'Jul',
      name: 'July',
    },
    {
      abbreviation: 'Aug',
      name: 'August',
    },
    {
      abbreviation: 'Sep',
      name: 'September',
    },
    {
      abbreviation: 'Oct',
      name: 'October',
    },
    {
      abbreviation: 'Nov',
      name: 'November',
    },
    {
      abbreviation: 'Dec',
      name: 'December',
    },
  ],
};

export const DEFAULT_DATE_FORMAT = 'M/D/YYYY';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const MMMM_DD_YYYY = 'MMMM DD, YYYY';

export default DateConsts;
