import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { InboxSidebarItemData } from 'src/components/Inbox/InboxSidebar';
import { InboxPageContext } from 'src/context/inboxPageContext';
import { ChannelListItem } from '../UI/ListItem/ChannelListItem';
import { Avatar } from '../UI/Avatars';
import { getInitials } from 'src/utils/UserUtils';
import { ItemRenderProps } from '../Channels/BaseChannelSidebar';
import { AvatarInfoType } from '../Channels/ChannelSidebarItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summaryText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: theme.spacing(0.5),
      '& > *:last-child': {
        flex: 1,
      },
    },
    summaryAvatar: {
      height: 20,
      width: 20,
      // initials font size
      '& > *': {
        fontSize: 10,
      },
    },
  }),
);

export const getSidebarTitle = (title: string) =>
  title.charAt(0).toUpperCase() +
  (title.length > 1 ? title.slice(1).toLowerCase() : '');

export const InboxSidebarItem: React.FC<
  ItemRenderProps<InboxSidebarItemData>
> = (props) => {
  const { selectedNotification } = React.useContext(InboxPageContext);
  const { channelOption, onSelect } = props;
  const classes = useStyles();
  const { avatarInfo, isRead, title, id: notificationId } = channelOption;

  const sidebarTitle =
    title.charAt(0).toUpperCase() +
    (title.length > 1 ? title.slice(1).toLowerCase() : '');

  const handleChannelClicked = () => {
    onSelect(channelOption);
  };

  const subTitleRenderer: React.FC = ({ children }) => {
    return (
      <div className={classes.summaryText}>
        <Avatar
          className={classes.summaryAvatar}
          src={avatarInfo?.imageUrl}
          fallbackLetters={getInitials(
            avatarInfo?.companyName || avatarInfo?.name || '',
          )}
          fallbackColor={avatarInfo?.fallbackColor || ''}
          variant={avatarInfo ? getAvatarVariant(avatarInfo) : 'circle'}
        />
        <>{children}</>
      </div>
    );
  };

  /**
   * This function gets the avatar variant based on the notification subtitle.
   * When the sender belongs to a company we append 'from' to the sender name
   * which is available in the avatarInfo.name property.
   * @param avatarData : sender avatar data
   * @returns avatar variant
   */
  const getAvatarVariant = (avatarData: AvatarInfoType) =>
    avatarData.isCompanyUser ? 'rounded' : 'circle';

  return (
    <ChannelListItem
      title={sidebarTitle}
      subTitleRenderer={subTitleRenderer}
      subTitle={`${avatarInfo?.name || ''}${
        avatarInfo?.companyName ? ` from ${avatarInfo?.companyName}` : ''
      }`}
      timestamp={channelOption.timestamp}
      selected={selectedNotification?.id === notificationId}
      onSelect={handleChannelClicked}
      unread={!isRead}
    />
  );
};
