import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import * as Colors from 'src/theme/colors';
import FormPageHeaderBase from 'src/components/Page/FormPageAction/FormPageHeaderBase';
import { FormPageHeaderProps } from 'src/components/Page/formPageTypes';

const useStyles = makeStyles(() =>
  createStyles({
    pageAction: (props: BorderFormPageHeaderProps) => ({
      border: 'none',
      ...(props.borderPosition === 'top'
        ? { borderTop: `1px solid ${Colors.DividersAndCardBorders}` }
        : { borderBottom: `1px solid ${Colors.DividersAndCardBorders}` }),
      borderRadius: 0,
    }),
  }),
);
interface BorderFormPageHeaderProps {
  borderPosition: 'top' | 'bottom';
}
const BorderFormPageHeader: React.FC<
  BorderFormPageHeaderProps & FormPageHeaderProps
> = ({ borderPosition, ...rest }) => {
  const classes = useStyles({ borderPosition });

  return <FormPageHeaderBase {...rest} className={classes.pageAction} />;
};

export default BorderFormPageHeader;
