import { makeStyles, Theme, Button } from '@material-ui/core';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Logger } from 'aws-amplify';
import BaseTypography from 'src/components/Text/BaseTypography';
import { GraySmall } from 'src/theme/colors';
import { ClientSignatureHeader } from 'src/components/Signature/ClientSignatureHeader';
import { RootState } from 'src/store';
import {
  ChangeEsignFlowStateAction,
  SetActiveComponent,
  UpdateClientSignatureFlowStatus,
} from 'src/store/signaturePage/actions';
import { useSignatureRequestComponent } from 'src/components/Signature/SignatureSidebar/useSignatureRequestComponent';
import { ESignatureFlowStatus } from 'src/store/signaturePage/types';

const logger = new Logger('ClientSignatureIndicatorPanel');

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    padding: '20px',
    paddingLeft: theme.spacing(9),
  },
  buttonContainer: {
    paddingTop: 42,
    display: 'flex',
    gap: theme.spacing(4),
  },
  subText: {
    color: GraySmall,
    marginTop: theme.spacing(1),
  },
  formWrapper: {
    padding: theme.spacing(3, 3, 3, 0),
    width: '100%',
  },
}));

// scroll element into view
export const scrollToComponent = (id: string) => {
  const button = document.getElementById(id);
  if (!button) return;
  button.scrollIntoView({
    behavior: 'auto',
    block: 'center', // vertical alignment
  });
};

type Props = {
  onPageSave: () => void;
};

export const ClientSignatureIndicatorPanel = ({ onPageSave }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { SignatureComponentsForm, handleRequestReceiverInfo } =
    useSignatureRequestComponent();

  const { pageComponents, signatureFlowStatus } = useSelector(
    (state: RootState) => ({
      startedEsigning: state.signaturePage.startedEsignFlow,
      pageComponents: state.signaturePage.pageComponents,
      activeComponentKey: state.signaturePage.activeComponentKey,
      signatureFlowStatus: state.signaturePage.signatureFlowStatus,
    }),
    shallowEqual,
  );

  /**
   * getClientComponents - filter and sorts by position client components
   * @returns Array of SignaturePageComponent
   */
  const getClientComponents = () => {
    const clientComponents =
      pageComponents
        ?.filter((component) => component.componentType.startsWith('request'))
        .sort((a, b) => a.yPosition - b.yPosition) || [];
    return clientComponents;
  };

  /**
   * Start signing for client
   */
  const startSigning = () => {
    dispatch(ChangeEsignFlowStateAction({ startedEsignFlow: true }));
    dispatch(
      UpdateClientSignatureFlowStatus(ESignatureFlowStatus.FILL_SIGNATURE),
    );
    const clientComponents = getClientComponents();
    if (clientComponents.length > 0) {
      const firstClientComponent = clientComponents.at(0);
      dispatch(SetActiveComponent(firstClientComponent?.key || ''));
      if (firstClientComponent) {
        handleRequestReceiverInfo(firstClientComponent);
      }
      scrollToComponent(clientComponents.at(0)?.key || '');
    }
  };

  /**
   * when the "Finish and Send" button is clicked ensure that all the components
   * have a page set to continue submission
   */
  const handleFinishClicked = () => {
    if (pageComponents.every((comp) => Boolean(comp.page))) {
      onPageSave();
    } else {
      logger.debug('Not all components are on page', pageComponents);
    }
  };

  return (
    <div className={classes.root}>
      <ClientSignatureHeader />
      <div className={classes.container}>
        {signatureFlowStatus === ESignatureFlowStatus.START_SIGNATURE && (
          <div>
            <BaseTypography fontType="24Medium">
              eSignature request
            </BaseTypography>
            <BaseTypography fontType="13Regular" className={classes.subText}>
              Review the document and start signing when ready.
            </BaseTypography>
            <div className={classes.buttonContainer}>
              <Button
                color="primary"
                variant="contained"
                onClick={startSigning}
              >
                Start signing
              </Button>
            </div>
          </div>
        )}

        {signatureFlowStatus === ESignatureFlowStatus.FILL_SIGNATURE && (
          <div className={classes.formWrapper}>
            <SignatureComponentsForm />
          </div>
        )}

        {signatureFlowStatus === ESignatureFlowStatus.END_SIGNAURE && (
          <div>
            <BaseTypography fontType="24Medium">
              Review before sending
            </BaseTypography>
            <BaseTypography fontType="13Regular" className={classes.subText}>
              Please ensure all required fields have been filled out correctly.
            </BaseTypography>
            <div className={classes.buttonContainer}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleFinishClicked}
              >
                Finish and send
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={startSigning}
              >
                Back to edit
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
