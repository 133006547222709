import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { LightGray } from 'src/theme/colors';
import Button from 'src/components/Button';
import { Step } from 'src/components/Layout/StepperLayout/stepperTypes';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '&:hover': {
        cursor: 'default', // disable hover effect since we don't want to show the button as clickable
      },
    },
    inactive: {
      '&.MuiButton-containedPrimary': {
        backgroundColor: 'transparent',
        color: LightGray,
        border: `1px solid ${LightGray}`,
      },
    },
  }),
);

interface Props {
  step: Step;
}
export const StepperNode: React.FC<Props> = ({ step }) => {
  const { label: stepTitle, active } = step;
  const classes = useStyles({ active });

  return (
    <Button
      variant="contained"
      color="primary"
      className={clsx(classes.root, { [classes.inactive]: !active })}
      htmlId="step-node"
      data-testid="step-node"
    >
      {stepTitle}
    </Button>
  );
};
