import React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Button,
  Grid,
} from '@material-ui/core';
import history from 'src/history';
import BaseTypography from 'src/components/Text/BaseTypography';
import { CopilotGreen, GraySmall } from 'src/theme/colors';
import { TaskDetailInfo } from 'src/components/HomePage/OnboardingTasksSection/helper';
import { UrlUtils } from 'src/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3.5),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2.5, 3),
      },
    },
    descriptionWrapper: {
      marginTop: theme.spacing(0.25),
      minWidth: '250px',
      minHeight: '40px',
    },
    illustrationWrapper: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    actionButton: {
      marginTop: theme.spacing(2.75),
    },
    illustration: {
      maxWidth: '100%',
      marginLeft: theme.spacing(3),
      maxHeight: 160,
    },
    actionButtonContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    learnMore: {
      paddingLeft: theme.spacing(0.25),
      paddingBottom: theme.spacing(0.25),
      marginLeft: theme.spacing(3),
      color: CopilotGreen,
      cursor: 'pointer',
    },
  }),
);

interface TaskProps {
  taskData: TaskDetailInfo;
}

export const TaskDetails: React.FC<TaskProps> = ({ taskData }) => {
  const classes = useStyles();

  const handleLearnMoreAction = (actionLink: string) => {
    // determining action path type: external or internal
    // when action path external url (starts with http/s)
    if (UrlUtils.startsWithProtocol(actionLink)) {
      window.open(actionLink, '__blank');
      return;
    }

    // when action link is not an external link, navigate to it
    history.push(actionLink);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs>
        <BaseTypography fontType="15Medium">{taskData.title}</BaseTypography>
        <div className={classes.descriptionWrapper}>
          <BaseTypography style={{ color: GraySmall }} fontType="15Regular">
            {taskData.description}
          </BaseTypography>
        </div>
        <div className={classes.actionButtonContainer}>
          <Button
            className={classes.actionButton}
            variant="contained"
            color="secondary"
            onClick={() => history.push(taskData.routePath)}
          >
            {taskData.buttonLabel}
          </Button>
          <BaseTypography
            fontType="13Medium"
            className={classes.learnMore}
            onClick={() => handleLearnMoreAction(taskData.learnMoreLink)}
          >
            Learn more
          </BaseTypography>
        </div>
      </Grid>
      <Grid className={classes.illustrationWrapper} item xs>
        <img
          className={classes.illustration}
          src={`/images/${taskData.illustrationName}.jpg`}
          alt="Task illustration"
        />
      </Grid>
    </Grid>
  );
};
