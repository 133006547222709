import { FormControl, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';
import { Field } from 'formik';
import React, { useContext } from 'react';
import { HoverBorder, NonHoverBorder } from 'src/theme/colors';
import { InputsTypes } from 'src/components/FormsV2/formsTypes';
import { FormBuilderPreviewContext } from 'src/components/FormsV2/FormBuilderPreview';
import { typography13MediumStyle } from 'src/components/Text';

interface SelectStylesProps {
  disabled: boolean;
}

export const SelectStyles = makeStyles<Theme, SelectStylesProps>((theme) => ({
  input: {
    height: '24px',
    width: '24px',
    appearance: 'none',
    '&:hover': {
      cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
    },
  },
  radio: {
    border: `1px solid ${NonHoverBorder}`,
    borderRadius: '50%',
    transition: 'all 0.2s ease-in-out',
    // customize radio checked state
    '&:checked': {
      backgroundColor: ({ disabled }) =>
        disabled ? HoverBorder : theme.palette.primary.main,
      border: `4px solid white`,
      outline: ({ disabled }) =>
        `1px solid ${disabled ? HoverBorder : theme.palette.primary.main}`,
      borderRadius: '50%',
    },
  },
  checkbox: {
    border: `1px solid ${NonHoverBorder}`,
    borderRadius: 2,
    transition: 'all 0.2s ease-in-out',
    '& ~ .checked-box': {
      display: 'block',
      position: 'absolute',
      borderRadius: 1,
      width: 16,
      height: 16,
      backgroundColor: theme.palette.primary.main,
      opacity: 0,
      transition: 'all 0.2s ease-in-out',
    },
    // customize checkbox checked state
    '&:checked': {
      outline: ({ disabled }) =>
        `1px solid ${disabled ? HoverBorder : theme.palette.primary.main}`,
      '& ~ .checked-box': {
        opacity: 1,
      },
    },
    '&[disabled]': {},
  },
  readOnlyCheckbox: {
    '&:checked': {
      backgroundColor: HoverBorder,
      border: '4px solid #fff',
    },
  },
  readOnlyChoice: {
    marginRight: '10px',
  },
  ckeckContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginRight: '10px',
  },
  inputContainer: {
    padding: '6px',
    fontSize: typography13MediumStyle.fontSize,
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${NonHoverBorder}`,
    borderRadius: '4px',
    '&:hover': {
      cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
      borderColor: ({ disabled }) => (disabled ? NonHoverBorder : HoverBorder),
    },
  },
  choicesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
}));
type Props = {
  options: string[];
  type?: InputsTypes;
  name?: string;
  value: string[] | string; // store the selected options
  readOnly: boolean;
  onChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onBlur:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
};
export const MultiChoicesSelectInput: React.FC<Props> = ({
  options,
  name,
  value,
  readOnly,
  onChange,
  type: answerType,
}) => {
  const classes = SelectStyles({ disabled: readOnly });
  const fieldType =
    answerType === InputsTypes.SingleSelect ? 'radio' : 'checkbox';

  /**
   * This functions handles the click event of input When the choice
   * input is radio type, the onChange event is not triggered when
   * unselecting a radio option. This is a workaround to solve that issue.
   */
  const handleInputClick: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    // for single select question
    if (answerType === InputsTypes.SingleSelect) {
      // if prev value and current value is same
      if (value.includes(e.target.value)) {
        // deselect the radio option
        e.target.value = '';

        // passing event in formik change handler
        // to update the form value
        if (onChange) onChange(e);
      }
    }
  };

  const { isFormBuilderPreview } = useContext(FormBuilderPreviewContext);

  /**
   * This function is responsible of getting the option label
   * considering the form builder preview mode.
   * When it is form builder preview, we need to show the option placeholder
   * if the option label value is undefined.
   * If the option label is defined, show it.
   * @param optionIndex index of the option
   * @returns option label to be displayed
   */
  const getOptionLabel = (optionIndex: number) => {
    const optionLabel = options[optionIndex];
    // when it is form builder preview, we need to show the option placeholder
    // instead of the option value.
    if (isFormBuilderPreview) {
      return optionLabel || `Choice ${optionIndex + 1}`;
    }
    return options[optionIndex];
  };

  return (
    <div
      role="group"
      aria-labelledby="select-group"
      className={classes.choicesContainer}
    >
      {options.map((option, index) => {
        const props = {
          type: fieldType,
          name,
          value: option,
          checked: Array.isArray(value)
            ? value?.includes(option)
            : value === option,
          className: classNames(
            classes.input,
            fieldType === 'radio' ? classes.radio : classes.checkbox,
            {
              [classes.readOnlyCheckbox]: fieldType === 'checkbox' && readOnly, // disabled checkbox styling
              [classes.readOnlyChoice]: readOnly, // adding margin right on readonly inputs
            },
          ),
          disabled: readOnly, // disable input if readonly
        };
        return (
          <FormControl key={`option-${index.toString()}`}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className={classes.inputContainer}>
              {/** Field is only available in formik context. For readonly purposes, we do not need it */}
              {readOnly ? (
                <input {...props} />
              ) : (
                <div className={classes.ckeckContainer}>
                  <Field {...props} onClick={handleInputClick} />
                  <div className="checked-box" />
                </div>
              )}
              {getOptionLabel(index)}
            </label>
          </FormControl>
        );
      })}
    </div>
  );
};
